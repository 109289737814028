import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';

import { useTabs } from 'common/components/tabs/TabsContext';
import Breakdown from 'components/cashFlows/Breakdown';
import ShareChartButton from 'components/cashFlows/ShareChartButton';
import Card from 'components/lib/ui/Card';
import CardBody from 'components/lib/ui/CardBody';
import DividerLine from 'components/lib/ui/DividerLine';
import Empty from 'components/lib/ui/Empty';
import FlexContainer from 'components/lib/ui/FlexContainer';
import PillTab from 'components/lib/ui/tabs/PillTab';
import TabGroup from 'components/lib/ui/tabs/TabGroup';

import type { BreakdownEntry } from 'lib/cashFlow/BreakdownAdapters';
import CashFlowContext from 'lib/contexts/CashFlowContext';
import { ChartType } from 'lib/ui/charts';

import { VISIBLE_GROUP_BY_OPTIONS } from 'common/constants/cashFlow';

const Content = styled(FlexContainer).attrs({ column: true })``;

export type Props = {
  title: string;
  byCategory: BreakdownEntry[];
  byMerchant: BreakdownEntry[];
  byCategoryGroup: BreakdownEntry[];
  total: number;
};

const BreakdownList = ({ byCategory, byMerchant, byCategoryGroup, total, title }: Props) => {
  const { hideAmounts } = useContext(CashFlowContext);
  const [activeIndex] = useTabs();
  const activeItems = useMemo(() => {
    switch (activeIndex) {
      case 0:
        return byCategory;
      case 1:
        return byCategoryGroup;
      case 2:
        return byMerchant;
      default:
        return [];
    }
  }, [activeIndex, byCategoryGroup, byCategory, byMerchant]);

  return (
    <Breakdown
      items={activeItems}
      total={total}
      hideAmounts={hideAmounts}
      empty={
        <CardBody>
          <Empty
            title={`No ${title.toLowerCase()}`}
            subtitle={`No matching ${title.toLowerCase()} for the selected range.`}
          />
        </CardBody>
      }
    />
  );
};

const BreakdownCard = ({ byCategory, byMerchant, byCategoryGroup, total, title }: Props) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const chartTitle = useMemo(() => `Monarch - Cash Flow - ${title}`, [title]);

  const controls = useMemo(
    () => (
      <FlexContainer gap="default" alignCenter>
        <TabGroup>
          {VISIBLE_GROUP_BY_OPTIONS.map((option, index) => (
            <PillTab index={index} key={option} $background="card">
              {option}
            </PillTab>
          ))}
        </TabGroup>
        <DividerLine />
        <ShareChartButton
          fileName={chartTitle}
          chartType={ChartType.CashFlowBreakdown}
          onSelectElement={() => ref.current}
        />
      </FlexContainer>
    ),
    [],
  );

  return (
    <Card title={title} controls={controls}>
      <Content ref={ref}>
        <BreakdownList
          byCategory={byCategory}
          byMerchant={byMerchant}
          byCategoryGroup={byCategoryGroup}
          total={total}
          title={title}
        />
      </Content>
    </Card>
  );
};

export default BreakdownCard;
