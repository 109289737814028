import React from 'react';

import SubscriptionOfferReminderModal from 'components/settings/billing/reminder/SubscriptionOfferReminderModal';
import Stack from 'components/utils/Stack';

export type SubscriptionEndReminderFlowProps = {
  isOnFreeTrial?: boolean;
  goBack?: () => void;
  analyticsFreemiumSummaryStatus?: string | null;
  next?: (params: SubscriptionEndReminderFlowProps) => void;
  currentPeriodEndsAt?: string;
};

/**
 * New cancel reminder flow which offers the user to set a reminder instead of cancelling it
 */
const SubscriptionEndReminderFlow = ({
  goBack,
  next,
  isOnFreeTrial,
  analyticsFreemiumSummaryStatus,
  currentPeriodEndsAt,
}: SubscriptionEndReminderFlowProps) => (
  <Stack
    initial={{
      component: SubscriptionOfferReminderModal,
      props: { goBack, next, isOnFreeTrial, analyticsFreemiumSummaryStatus, currentPeriodEndsAt },
    }}
  />
);

export default SubscriptionEndReminderFlow;
