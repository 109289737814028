import { DateTime } from 'luxon';
import pluralize from 'pluralize';
import * as R from 'ramda';
import React, { useCallback, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import Select from 'components/lib/form/Select';
import { maskClassProps } from 'components/lib/higherOrder/withSensitiveData';
import DashboardWidget, { Description } from 'components/lib/ui/DashboardWidget';
import Empty from 'components/lib/ui/Empty';
import Flex from 'components/lib/ui/Flex';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Text from 'components/lib/ui/Text';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import RecurringStreamDashboardItem from 'components/recurring/RecurringStreamDashboardItem';

import {
  DATE_RANGE_OPTIONS,
  getFrequencyLabel,
  getWidgetDescription,
  sortItems,
} from 'common/lib/recurring';
import { getRecurringFilters } from 'common/lib/recurring/filters';
import isV2Theme from 'common/lib/theme/isV2Theme';
import { isSameDay } from 'common/utils/date';
import formatTransactionAmount from 'common/utils/formatTransactionAmount';
import useIsV2Theme from 'lib/hooks/useIsV2Theme';
import useMockDataWhenNoAccountsQuery from 'lib/hooks/useMockDataWhenNoAccountsQuery';

import routes from 'constants/routes';

import { gql } from 'common/generated/gql';

const StyledDashboardWidget = styled(DashboardWidget)`
  ${isV2Theme(
    undefined,
    css`
      ${Description} {
        font-size: ${({ theme }) => theme.fontSize.large};
      }
    `,
  )}
`;

const Item = styled(FlexContainer).attrs({ alignCenter: true, justifyBetween: true })`
  padding: ${({ theme }) => theme.spacing.default} ${({ theme }) => theme.spacing.xlarge};
  border-top: 1px solid ${({ theme }) => theme.color.grayBackground};
`;

const StyledSelect = styled(Select)`
  min-width: 150px;
`;

const Footer = styled(Flex)`
  border-top: 1px solid ${({ theme }) => theme.color.grayBackground};
  padding: ${({ theme }) => theme.spacing.default};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.small};
`;

const FullWidthDefaultButton = styled(DefaultButton)`
  width: 100%;
`;

const SpinnerContainer = styled(FlexContainer).attrs({ center: true })`
  margin: ${({ theme }) => theme.spacing.xlarge} 0;
`;

const Amount = styled(Text).attrs({ weight: 'medium', ...maskClassProps })<{ $isIncome: boolean }>`
  color: ${({ $isIncome, theme }) => ($isIncome ? theme.color.green : theme.color.text)};
`;

const RecurringTransactionsDashboardWidget = () => {
  const isV2Theme = useIsV2Theme();
  const today = useMemo(() => DateTime.local(), []);

  const [selectedDate, setSelectedDate] = useState(DATE_RANGE_OPTIONS[0].value);
  const selectedRangeOption = useMemo(
    () => R.find(({ value: getValue }) => getValue() === selectedDate, DATE_RANGE_OPTIONS),
    [selectedDate],
  );

  const { data, isLoadingInitialData } = useMockDataWhenNoAccountsQuery(QUERY, {
    variables: {
      startDate: today.toISODate(),
      endDate: selectedDate,
      includeLiabilities: true,
      filters: getRecurringFilters({ isCompleted: false }),
    },
  });

  const remainingDueAmount = data?.recurringRemainingDue?.amount;

  const items = useMemo(() => {
    const sortedItems = sortItems(data?.recurringTransactionItems ?? []);
    return sortedItems.filter((item) => DateTime.fromISO(item.date) >= today);
  }, [data, today]);

  const daysToDate = useCallback(
    (isoDate: string) => Math.ceil(DateTime.fromISO(isoDate).diffNow('days').days),
    [],
  );

  const content = useMemo(() => {
    if (isLoadingInitialData) {
      return (
        <SpinnerContainer>
          <LoadingSpinner />
        </SpinnerContainer>
      );
    } else if (items.length <= 0) {
      return (
        <Empty
          title="No upcoming transactions for this time period"
          subtitle="Add new recurring transactions to see them here"
        />
      );
    } else {
      return (
        <>
          {items.slice(0, 4).map((item) => {
            const daysDiff = daysToDate(item.date);
            const {
              stream: { id, name, logoUrl, merchant, creditReportLiabilityAccount },
              account,
              amount,
              date,
            } = item;

            return (
              <Item key={id}>
                <RecurringStreamDashboardItem
                  id={merchant?.id ?? creditReportLiabilityAccount?.account?.id ?? ''}
                  name={name}
                  frequency={getFrequencyLabel(item.stream.frequency)}
                  logoUrl={logoUrl}
                  account={account}
                  startDate={today}
                  isMerchant={!!merchant}
                />
                <FlexContainer column alignEnd>
                  {!!amount && (
                    <Amount $isIncome={!!amount && amount > 0}>
                      {formatTransactionAmount(amount)}
                    </Amount>
                  )}
                  {!!date && (
                    <Text color="textLight" size="small">
                      {isSameDay(DateTime.fromISO(date), today)
                        ? 'Today'
                        : `in ${daysDiff} ${pluralize('day', daysDiff)}`}
                    </Text>
                  )}
                </FlexContainer>
              </Item>
            );
          })}

          {items.length > 4 && !isV2Theme && (
            <Footer center>
              <FullWidthDefaultButton linkTo={routes.recurring()}>
                View all {items.length} upcoming
              </FullWidthDefaultButton>
            </Footer>
          )}
        </>
      );
    }
  }, [items, isLoadingInitialData, daysToDate, today]);

  const widgetDescription = getWidgetDescription(remainingDueAmount);

  return (
    <StyledDashboardWidget
      title="Recurring"
      description={isLoadingInitialData ? <LoadingSpinner $size={18} /> : widgetDescription}
      headerLink={routes.recurring()}
      showHeaderBorder={false}
      headerRight={
        <FlexContainer>
          <StyledSelect
            small
            options={DATE_RANGE_OPTIONS}
            value={selectedRangeOption}
            onChange={({ value }: { value: string }) => setSelectedDate(value)}
            isDisabled={isLoadingInitialData}
          />
        </FlexContainer>
      }
    >
      {content}
    </StyledDashboardWidget>
  );
};

const QUERY = gql(/* GraphQL */ `
  query Web_GetDashboardUpcomingRecurringTransactionItems(
    $startDate: Date!
    $endDate: Date!
    $includeLiabilities: Boolean
    $filters: RecurringTransactionFilter
  ) {
    recurringRemainingDue(
      startDate: $startDate
      endDate: $endDate
      includeLiabilities: $includeLiabilities
    ) {
      amount
    }
    recurringTransactionItems(
      startDate: $startDate
      endDate: $endDate
      includeLiabilities: $includeLiabilities
      filters: $filters
    ) {
      stream {
        id
        frequency
        name
        logoUrl
        merchant {
          id
        }
        creditReportLiabilityAccount {
          id
          account {
            id
          }
        }
      }
      isPast
      date
      amount
      account {
        id
        logoUrl
        icon
      }
    }
  }
`);

export default RecurringTransactionsDashboardWidget;
