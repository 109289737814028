import { DateTime } from 'luxon';
import React, { Suspense, useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import BudgetCreationSuccessCard from 'components/budget/BudgetCreationSuccessCard';
import BudgetListLoading from 'components/budget/BudgetListLoading';
import CreateBudgetCard from 'components/budget/CreateBudgetCard';
import PlanSummaryWidget from 'components/budget/PlanSummaryWidget';
import EditCategoryGroupModal from 'components/categories/EditCategoryGroupModal';
import withDefaultErrorBoundary from 'components/lib/higherOrder/withDefaultErrorBoundary';
import RouteModal from 'components/lib/routing/RouteModal';
import FlexContainer from 'components/lib/ui/FlexContainer';
import ModalCard from 'components/lib/ui/ModalCard';
import PageWithNoAccountsEmptyState from 'components/lib/ui/PageWithNoAccountsEmptyState';
import Scroll from 'components/lib/ui/Scroll';
import BudgetSettingsForm from 'components/plan/BudgetSettingsForm';
import { SECTION_HEADER_HEIGHT_PX } from 'components/plan/PlanGrid';
import PlanHeader from 'components/plan/PlanHeader';
import PlanSection from 'components/plan/PlanSection';

import { getMonthProgressForMonthStart, isCurrentMonth } from 'common/lib/budget/Progress';
import useBudgetSettings from 'common/lib/hooks/budget/useBudgetSettings';
import useProfileFlag from 'common/lib/hooks/users/useProfileFlag';
import isV2Theme from 'common/lib/theme/isV2Theme';
import variables from 'common/lib/theme/variables';
import { formatMonthWithYear } from 'common/utils/date';
import PlanContext from 'lib/contexts/PlanContext';
import { useQueryParam } from 'lib/hooks';
import usePlanAdapter from 'lib/hooks/plan/usePlanAdapter';
import usePlanQuery from 'lib/hooks/plan/usePlanQuery';
import usePlanState, { PlanTimeframe } from 'lib/hooks/plan/usePlanState';
import useModal from 'lib/hooks/useModal';
import useRestoreScrollPosition from 'lib/hooks/useRestoreScrollPosition';
import { useWhatsNew } from 'lib/hooks/useWhatsNew';

import { ONBOARDING_PLAN_OVERLAY_TITLE } from 'common/constants/copy';
import routes from 'constants/routes';

const INTRO_MODAL_PARAM = 'introModal';

const ScrollRoot = styled(Scroll)`
  position: relative;
  margin-left: ${({ theme }) => theme.spacing.gutter};
  transform: scale(1); /* create stacking context */
`;

const Container = styled(FlexContainer)`
  position: absolute;
  left: 0;
  top: 0;
  min-width: 100%;
  padding-right: ${({ theme }) => theme.spacing.gutter};
`;

const SectionsContainer = styled.div`
  flex: 1;
`;

const Sticky = styled.div`
  min-width: 25vw;
  position: sticky;
  top: 0;
  height: 100%; /* needed for sticky */
`;

const StickyCover = styled.div`
  background: ${variables.color.background.page};
  height: ${isV2Theme(0, SECTION_HEADER_HEIGHT_PX)}px;
  width: 100%;
`;

const StyledBudgetListLoading = styled(BudgetListLoading)`
  margin-top: ${SECTION_HEADER_HEIGHT_PX}px;
`;

const LazyLoadedFlexBudgetingWalkthrough = React.lazy(
  () => import('components/budget/FlexBudgetingWalkthrough'),
);

const Plan = () => {
  const { push } = useHistory();
  const { dismissWhatsNew } = useWhatsNew();

  const [dismissedWalkthroughAt, setDismissedWalkthroughAt, isLoadingDismissedWalkthroughAt] =
    useProfileFlag('dismissedFlexBudgetingWalkthroughAt');

  const [state, actions] = usePlanState();
  const {
    data,
    refetch,
    fetchedDateRange,
    doesNotHaveBudget,
    budgetStatus,
    refetchBudgetStatus,
    updateCellValue,
  } = usePlanQuery(state);
  const {
    isLoadingInitialData,
    gridDisplayData,
    numColumns,
    getDateForColumn,
    getAmountTypeForColumn,
    getCanEditColumn,
    getIsColumnLoading,
    gridAmounts,
    budgetSummaryData,
    initialScrollOffset,
  } = usePlanAdapter(data, state, fetchedDateRange);

  const { timeframe, highlightedDate, thisMonth, columnDisplayInterval, collapsedSections } = state;
  const { setTimeframe, setHighlightedDate, moveHighlightedDate } = actions;

  const [editingGroupId, setEditingGroupId] = useState<string | null>(null);
  const [EditGroupModal, { open: openEditGroupModal, close: closeEditGroupModal }] = useModal();
  const openEditCategoryGroup = useCallback(
    (id: string) => {
      setEditingGroupId(id);
      openEditGroupModal();
    },
    [openEditGroupModal],
  );

  const { budgetApplyToFutureMonthsDefault } = useBudgetSettings();

  const context = useMemo(
    () => ({
      refetch,
      getIsColumnLoading,
      timeframe,
      openEditCategoryGroup,
      actions,
      getDateForColumn,
      getAmountTypeForColumn,
      getCanEditColumn,
      updateCellValue,
      budgetApplyToFutureMonthsDefault,
    }),
    [
      refetch,
      getIsColumnLoading,
      timeframe,
      openEditCategoryGroup,
      actions,
      getDateForColumn,
      getAmountTypeForColumn,
      getCanEditColumn,
      updateCellValue,
      budgetApplyToFutureMonthsDefault,
    ],
  );

  const onClickColumn = useCallback(
    (column: number) => {
      setHighlightedDate(getDateForColumn(column));
      setTimeframe(
        timeframe === PlanTimeframe.Yearly ? PlanTimeframe.Monthly : PlanTimeframe.SingleMonth,
        { pushHistory: true },
      );
    },
    [setHighlightedDate, getDateForColumn, setTimeframe, timeframe],
  );

  const pageTitle = useMemo(() => {
    if (timeframe === PlanTimeframe.SingleMonth) {
      return formatMonthWithYear(highlightedDate);
    } else {
      const startYear = columnDisplayInterval.start.toFormat('y');
      const endYear = columnDisplayInterval.end.toFormat('y');
      if (startYear === endYear) {
        return startYear;
      } else {
        return `${startYear} - ${endYear}`;
      }
    }
  }, [timeframe, columnDisplayInterval, highlightedDate]);

  const summaryTicks = useMemo(
    () =>
      isCurrentMonth(highlightedDate)
        ? [{ percent: getMonthProgressForMonthStart(highlightedDate) }]
        : undefined,
    [highlightedDate],
  );

  const introModal = useQueryParam(INTRO_MODAL_PARAM);

  const [
    CreationSuccessModal,
    { open: openCreationSuccessModal, close: closeCreationSuccessModal },
  ] = useModal(!!introModal);

  const [preservedScrollOffset, onScroll] = useRestoreScrollPosition();

  const showFlexBudgetingWalkthroughModal =
    !isLoadingDismissedWalkthroughAt && !dismissedWalkthroughAt;

  return (
    <PlanContext.Provider value={context}>
      <PageWithNoAccountsEmptyState
        name="Budget"
        emptyIcon="map"
        emptyTitle={ONBOARDING_PLAN_OVERLAY_TITLE}
        shouldScroll={false}
        header={
          <PlanHeader
            title={pageTitle}
            timeframe={timeframe}
            onClickToday={() => setHighlightedDate(thisMonth)}
            onPanLeft={() => moveHighlightedDate(false)}
            onPanRight={() => moveHighlightedDate(true)}
            onChangeTimeframe={setTimeframe}
          />
        }
        overlayEmptyComponent={
          budgetStatus && doesNotHaveBudget ? (
            <CreateBudgetCard
              date={columnDisplayInterval.start}
              onCompleted={() => {
                refetch();
                refetchBudgetStatus();
                openCreationSuccessModal();
              }}
              budgetStatus={budgetStatus}
            />
          ) : undefined
        }
        overrideTitle={pageTitle}
      >
        <ScrollRoot
          initialOffset={preservedScrollOffset ?? initialScrollOffset}
          onScroll={onScroll}
        >
          <Container>
            <SectionsContainer>
              {isLoadingInitialData ? (
                <StyledBudgetListLoading />
              ) : (
                gridDisplayData.map((section) => (
                  <PlanSection
                    key={section.id}
                    section={section}
                    amounts={gridAmounts[section.id]}
                    timeframe={timeframe}
                    numColumns={numColumns}
                    isCollapsed={!!collapsedSections[section.type]}
                    onClickColumn={onClickColumn}
                  />
                ))
              )}
            </SectionsContainer>
            {timeframe === PlanTimeframe.SingleMonth && (
              <Sticky>
                <StickyCover />

                <PlanSummaryWidget
                  data={budgetSummaryData}
                  ticks={summaryTicks}
                  isLoading={isLoadingInitialData}
                />
              </Sticky>
            )}
            <EditGroupModal>
              <EditCategoryGroupModal
                onDone={() => {
                  refetch();
                  closeEditGroupModal();
                }}
                onDeleted={refetch}
                categoryGroupId={editingGroupId ?? ''}
              />
            </EditGroupModal>
          </Container>
        </ScrollRoot>
        <CreationSuccessModal>
          <BudgetCreationSuccessCard onCompleted={closeCreationSuccessModal} />
        </CreationSuccessModal>
        <RouteModal path={routes.plan.settings.path}>
          {({ close }) => (
            <ModalCard title="Budget Settings">
              <BudgetSettingsForm
                onDone={() => {
                  close();
                }}
              />
            </ModalCard>
          )}
        </RouteModal>
        {showFlexBudgetingWalkthroughModal && (
          <Suspense fallback={null}>
            <LazyLoadedFlexBudgetingWalkthrough
              onStart={() => {
                dismissWhatsNew();
              }}
              onFinish={(wasSkipped) => {
                if (!wasSkipped) {
                  push(routes.budget.onboarding());
                }
                setDismissedWalkthroughAt(DateTime.now().toISO());
              }}
            />
          </Suspense>
        )}
      </PageWithNoAccountsEmptyState>
    </PlanContext.Provider>
  );
};

export default withDefaultErrorBoundary(Plan);
