import { useMutation } from '@apollo/client';
import { DateTime } from 'luxon';
import * as R from 'ramda';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import deepIsEqual from 'react-fast-compare';
import type { Components } from 'react-virtuoso';
import { GroupedVirtuoso } from 'react-virtuoso';
import styled from 'styled-components';

import Checkbox, { DEFAULT_CHECKBOX_SIZE_PX } from 'components/lib/form/Checkbox';
import EventPropagationBoundary from 'components/lib/higherOrder/EventPropagationBoundary';
import { sensitiveClassProps } from 'components/lib/higherOrder/withSensitiveData';
import Card from 'components/lib/ui/Card';
import FlexContainer from 'components/lib/ui/FlexContainer';
import SectionHeader from 'components/lib/ui/SectionHeader';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import Transaction from 'components/transactions/Transaction';
import TransactionBulkUpdateDrawer from 'components/transactions/TransactionBulkUpdateDrawer';
import TransactionListFooter from 'components/transactions/TransactionListFooter';
import type { Props as TransactionListHeaderProps } from 'components/transactions/TransactionListHeader';
import TransactionListHeader, {
  HEADER_HEIGHT_PX,
} from 'components/transactions/TransactionListHeader';
import TransactionRuleToastTrigger from 'components/transactions/TransactionRuleToastTrigger';
import { TransactionsRowsLoading } from 'components/transactions/TransactionsListLoading';
import TransactionsListRow from 'components/transactions/TransactionsListRow';
import TransactionDrawer from 'components/transactions/drawer/TransactionDrawer';

import useHouseholdPreferences from 'common/lib/hooks/household/useHouseholdPreferences';
import type { BulkUpdateStateFunc } from 'common/lib/hooks/transactions/useBulkUpdateTransactionState';
import useBulkUpdateTransactionState from 'common/lib/hooks/transactions/useBulkUpdateTransactionState';
import { spacing } from 'common/lib/theme/dynamic';
import isV2Theme from 'common/lib/theme/isV2Theme';
import { formatCurrency } from 'common/utils/Currency';
import { formatFullDate } from 'common/utils/date';
import ScrollContext from 'lib/contexts/ScrollContext';
import type { AutoFocusOptions } from 'lib/contexts/TransactionsListContext';
import { useTransactionListContext } from 'lib/contexts/TransactionsListContext';
import useFilters from 'lib/hooks/transactions/useFilters';
import useIsBulkTransactionSelectActive from 'lib/hooks/transactions/useIsBulkTransactionSelectActive';
import useGlobalKey from 'lib/hooks/useGlobalKey';
import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';

import { UPDATE_TRANSACTION_REFETCH_QUERIES } from 'constants/graphql';

import { gql as newGql } from 'common/generated/gql';
import type { TransactionsListFieldsFragment } from 'common/generated/graphql';
import type TransactionSection from 'common/types/TransactionSection';
import type { TransactionFilters } from 'types/filters';

const EmptyWrapper = styled.div`
  align-self: center;
`;

const StyledCheckbox = styled(Checkbox)`
  display: inline-block;
  margin-right: ${({ theme }) => theme.spacing.default};

  & ${DefaultButton} {
    margin: 0 ${({ theme }) => theme.spacing.xlarge};
  }
`;

const TransactionRow = styled(Transaction)`
  flex: 1;
  width: 80%;
  transition: border-color 0.1s ease-out;

  /* Negative left/right margin lets the row borders overlap the surrounding list border. */
  margin: -1px -1px 0;
`;

const CheckboxHitTargetBoundary = styled(EventPropagationBoundary)`
  flex: unset;
  width: ${DEFAULT_CHECKBOX_SIZE_PX}px;
  height: ${DEFAULT_CHECKBOX_SIZE_PX}px;
  margin-left: ${({ theme }) => theme.spacing.default};

  ${StyledCheckbox} {
    margin: 0 auto;
  }
`;

const ListContainer = styled(FlexContainer).attrs({ column: true })<{ $paddingBottom: number }>`
  padding-bottom: ${({ $paddingBottom }) => $paddingBottom}px;
  position: relative;
  width: 100%;
`;

const TransactionRowContainer = styled.div<{ $extraTopMargin: number; $isLast: boolean }>`
  margin-top: ${({ $extraTopMargin }) => $extraTopMargin}px;
  position: relative;
  width: 100%;
  border-bottom: ${({ theme, $isLast }) =>
    $isLast ? 'none' : `1px solid ${theme.color.grayBackground}`};
`;

const StyledSectionHeader = styled(SectionHeader)`
  padding-left: ${isV2Theme(spacing.default, spacing.large)};
  padding-right: ${isV2Theme(spacing.default, spacing.large)};
`;

type Props = {
  sections: TransactionSection<TransactionsListFieldsFragment>[];
  totalCount?: number;
  emptyComponent?: React.ReactNode;
  showSectionHeaders?: boolean;
  isReviewModeActive?: boolean | null;
  drawerTransactionId: GraphQlUUID | null;
  setDrawerTransactionId: (id: GraphQlUUID) => void;
  nextTransactionId: GraphQlUUID | null;
  previousTransactionId: GraphQlUUID | null;
  quickViewFilters?: Partial<TransactionFilters>;
  filters?: Partial<TransactionFilters>;
  allowBulkUpdate?: boolean;
  isBulkUpdateFormOpen?: boolean;
  openBulkUpdateForm?: () => void;
  onBulkUpdateStateChange?: BulkUpdateStateFunc;
  onDrawerClose: () => void;
  onCloseBulkUpdateForm?: () => void;
  onRemoveQuickViewFilters?: (filters: Partial<TransactionFilters>) => void;
  isLoadingTransactions?: boolean;
  hasNextPage?: boolean;
  onRequestNextPage?: () => void;
  hideHeader?: boolean;
  totalSelectableCount?: number | null;
} & Pick<
  TransactionListHeaderProps,
  'overrideTitle' | 'extraControls' | 'renderCustomEditButton' | 'sortBy' | 'onChangeSortBy'
>;

const TransactionsList = ({
  sections,
  totalCount,
  emptyComponent,
  hasNextPage,
  previousTransactionId,
  nextTransactionId,
  drawerTransactionId,
  showSectionHeaders = true,
  isReviewModeActive = false,
  allowBulkUpdate,
  isBulkUpdateFormOpen,
  extraControls,
  overrideTitle,
  onDrawerClose,
  onRequestNextPage,
  setDrawerTransactionId,
  openBulkUpdateForm,
  onCloseBulkUpdateForm,
  renderCustomEditButton,
  onBulkUpdateStateChange,
  isLoadingTransactions,
  hideHeader,
  sortBy,
  onChangeSortBy,
  totalSelectableCount,
  quickViewFilters,
  onRemoveQuickViewFilters,
}: Props) => {
  const isTransactionsUpdatesEnabled = useIsFeatureFlagOn('transactions-ui-v2-web');

  const { householdPreferences } = useHouseholdPreferences();
  const arePendingTransactionsEditable =
    householdPreferences?.pendingTransactionsCanBeEdited ?? false;

  const {
    selectedTransactions,
    excludedTransactions,
    isAllSelected,
    totalSelected,
    isChecked,
    toggleTransaction,
    toggleIsAllSelected,
    deselectAll,
    selectAll,
  } = useBulkUpdateTransactionState({
    totalCount: totalSelectableCount ?? totalCount,
    onStateChange: onBulkUpdateStateChange,
  });

  const { isBulkSelectActive, setBulkSelectActive } = useIsBulkTransactionSelectActive({
    onChange: (isActive) => {
      if (isActive === false) {
        deselectAll();
      }
    },
  });

  const { refetchAll, setAutoFocus } = useTransactionListContext();
  const resetAutoFocus = useCallback(
    () => setAutoFocus?.({ notes: false, tags: false }),
    [setAutoFocus],
  );
  const onTransactionDrawerClose = useCallback(() => {
    resetAutoFocus();
    onDrawerClose();
  }, [resetAutoFocus, onDrawerClose]);

  const hoveredTransactionRef = useRef<string | undefined>(undefined);
  const [filters, setFilters] = useFilters();

  const onChevronClick = useCallback(
    (id: string, autoFocus?: Partial<AutoFocusOptions>) => {
      setAutoFocus?.(autoFocus ?? {});
      setDrawerTransactionId(id);
    },
    [setDrawerTransactionId, setAutoFocus],
  );

  const disableBulkUpdateKeyboardShortcuts =
    !allowBulkUpdate || !isBulkSelectActive || drawerTransactionId || hideHeader;

  useGlobalKey('mod+a', (e) => {
    if (disableBulkUpdateKeyboardShortcuts) {
      return;
    }

    e.preventDefault(); // prevent user from highlighting page text
    selectAll();
  });

  useGlobalKey('esc', () => {
    if (disableBulkUpdateKeyboardShortcuts) {
      return;
    }

    deselectAll();
    setBulkSelectActive(false);
  });

  useGlobalKey('x', () => {
    if (!allowBulkUpdate || !hoveredTransactionRef.current || drawerTransactionId) {
      return;
    }

    const transaction = sections
      .flatMap(({ data }) => data)
      .find(({ id }) => id === hoveredTransactionRef.current);

    if (transaction?.pending && !arePendingTransactionsEditable) {
      // don't allow pending transactions to be selected
      return;
    }

    if (!isBulkSelectActive) {
      setBulkSelectActive(true);
    }
    toggleTransaction(hoveredTransactionRef.current);
  });

  const [updateTransaction] = useMutation(UPDATE_TRANSACTION_OVERVIEW_MUTATION, {
    refetchQueries: UPDATE_TRANSACTION_REFETCH_QUERIES,
  });

  // How many transactions are in each section
  const sectionsCount = useMemo(() => sections.map(({ data }) => data.length), [sections]);
  const flatData = useMemo(() => sections.flatMap(({ data }) => data), [sections]);
  const { scrollRef } = useContext(ScrollContext);

  const formatDate = useCallback(
    (date: string) => formatFullDate(DateTime.fromISO(date).toJSDate()),
    [],
  );

  // Used to simulate a offset on initial render because of our top-most sticky header,
  // it's not the most elegant solution but it works for now.
  // More info: https://linear.app/monarch/issue/ENG-5415#comment-2e538e5d
  const [shouldAddBottomOffset, setShouldAddBottomOffset] = useState(true);

  const components = useMemo(
    (): Components => ({
      ...(hideHeader
        ? {}
        : {
            // TopItemList only shows up when there are list items. So use Header when there aren't any.
            [totalCount ? 'TopItemList' : 'Header']: ({
              children,
              ...props
            }: {
              children?: React.ReactNode;
              style: React.CSSProperties;
            }) => (
              <div {...props} style={{ ...(props.style ?? {}), marginTop: 0 }}>
                <TransactionListHeader
                  deselectAll={deselectAll}
                  isAllSelected={isAllSelected}
                  quickViewFilters={quickViewFilters}
                  onRemoveQuickViewFilters={onRemoveQuickViewFilters}
                  filters={filters}
                  setFilters={setFilters}
                  isBulkUpdateActive={isBulkSelectActive}
                  toggleBulkUpdateActive={() => {
                    setBulkSelectActive(!isBulkSelectActive);
                  }}
                  openBulkUpdateForm={openBulkUpdateForm}
                  toggleIsAllSelected={toggleIsAllSelected}
                  totalSelected={totalSelected}
                  totalCount={totalCount}
                  overrideTitle={overrideTitle}
                  extraControls={extraControls}
                  renderCustomEditButton={renderCustomEditButton}
                  sortBy={sortBy}
                  onChangeSortBy={onChangeSortBy}
                />
                {children}
              </div>
            ),
          }),
      Group: ({ children, style, ...props }) => (
        // We need this so section headers don't overlap other elements (see ENG-5527)
        <div style={{ ...style, zIndex: 'auto' }} {...props}>
          {children}
        </div>
      ),
      Footer: () => <TransactionListFooter hide={!hasNextPage} />,
      EmptyPlaceholder: () =>
        !isLoadingTransactions && sections.length === 0 ? (
          <Card>
            <EmptyWrapper>{emptyComponent}</EmptyWrapper>
          </Card>
        ) : null,
    }),
    [
      isBulkSelectActive,
      deselectAll,
      emptyComponent,
      filters,
      hideHeader,
      isAllSelected,
      isLoadingTransactions,
      hasNextPage,
      openBulkUpdateForm,
      sections.length,
      setFilters,
      setBulkSelectActive,
      toggleIsAllSelected,
      totalCount,
      totalSelected,
      overrideTitle,
      extraControls,
      renderCustomEditButton,
      sortBy,
      onChangeSortBy,
      quickViewFilters,
      onRemoveQuickViewFilters,
    ],
  );

  const canEditTransaction = (isPending: boolean) =>
    isPending ? arePendingTransactionsEditable : true;

  return (
    <TransactionRuleToastTrigger>
      <ListContainer $paddingBottom={shouldAddBottomOffset && !hideHeader ? HEADER_HEIGHT_PX : 0}>
        {isLoadingTransactions ? (
          <TransactionsRowsLoading />
        ) : (
          <GroupedVirtuoso
            style={{ height: 'auto' }}
            customScrollParent={scrollRef?.current ?? undefined}
            groupCounts={sectionsCount}
            components={components}
            rangeChanged={({ startIndex }) => setShouldAddBottomOffset(startIndex === 0)}
            groupContent={(index) => {
              const section = sections[index];
              if (!section) {
                return null;
              }

              const { date, amount } = section;
              return (
                showSectionHeaders && (
                  <StyledSectionHeader>
                    <span>{formatDate(date)}</span>
                    {
                      // Don't show amount for last section because it could be wrong due to pagination
                      (hasNextPage && index === sections.length - 1) || R.isNil(amount) ? null : (
                        <span {...sensitiveClassProps}>
                          {amount > 0 ? '+' : ''}
                          {formatCurrency(Math.abs(amount))}
                        </span>
                      )
                    }
                  </StyledSectionHeader>
                )
              );
            }}
            itemContent={(index) => {
              const transaction = flatData[index];

              if (!transaction) {
                return null;
              }

              return (
                <TransactionRowContainer
                  $extraTopMargin={index === 0 && !hideHeader ? HEADER_HEIGHT_PX : 0}
                  $isLast={index === flatData.length - 1}
                >
                  <TransactionsListRow
                    key={transaction.id}
                    onClickWhitespace={
                      isBulkSelectActive && canEditTransaction(transaction?.pending)
                        ? () => toggleTransaction(transaction.id)
                        : undefined
                    }
                    onHover={() => (hoveredTransactionRef.current = transaction.id)}
                    isActiveOnDrawer={drawerTransactionId === transaction.id}
                    isLast={index === flatData.length - 1}
                  >
                    {isBulkSelectActive &&
                      (!canEditTransaction(transaction?.pending) ? (
                        <CheckboxHitTargetBoundary preventDefault>
                          <StyledCheckbox disabled />
                        </CheckboxHitTargetBoundary>
                      ) : (
                        <CheckboxHitTargetBoundary onClick>
                          <StyledCheckbox
                            checked={isChecked(transaction.id)}
                            onChange={() => toggleTransaction(transaction.id)}
                          />
                        </CheckboxHitTargetBoundary>
                      ))}
                    <TransactionRow
                      transaction={transaction}
                      onChevronClick={onChevronClick}
                      updateTransaction={updateTransaction}
                      editsToPendingTransactionsEnabled={arePendingTransactionsEditable}
                      showReviewButton={isReviewModeActive && !isBulkSelectActive}
                      isTransactionsUpdatesEnabled={isTransactionsUpdatesEnabled}
                    />
                  </TransactionsListRow>
                </TransactionRowContainer>
              );
            }}
            endReached={onRequestNextPage}
          />
        )}
      </ListContainer>
      {!!drawerTransactionId && (
        <TransactionDrawer
          id={drawerTransactionId}
          onClose={onTransactionDrawerClose}
          setId={setDrawerTransactionId}
          previousTransactionId={previousTransactionId}
          nextTransactionId={nextTransactionId}
          isReviewModeActive={!!isReviewModeActive}
        />
      )}
      {isBulkUpdateFormOpen && (
        <TransactionBulkUpdateDrawer
          selectedTransactionIds={selectedTransactions}
          excludedTransactionIds={excludedTransactions}
          isAllSelected={isAllSelected}
          selectedCount={totalSelected}
          onClose={() => onCloseBulkUpdateForm?.()}
          onSuccess={() => {
            deselectAll();
            setBulkSelectActive(false);
            refetchAll();
          }}
        />
      )}
    </TransactionRuleToastTrigger>
  );
};

TransactionsList.fragments = {
  TransactionsListFields: newGql(/* GraphQL */ `
    fragment TransactionsListFields on Transaction {
      id
      ...TransactionOverviewFields
    }
  `),
};

export const UPDATE_TRANSACTION_OVERVIEW_MUTATION = newGql(/* GraphQL */ `
  mutation Web_UpdateTransactionOverview($input: UpdateTransactionMutationInput!) {
    updateTransaction(input: $input) {
      transaction {
        id
        ...TransactionOverviewFields
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);

export default React.memo(TransactionsList, deepIsEqual);
