import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Page from 'components/lib/ui/Page';
import SetupHeader from 'components/lib/ui/SetupHeader';
import Text from 'components/lib/ui/Text';

import variables from 'common/lib/theme/variables';

import routes from 'constants/routes';

const StyledPage = styled(Page)`
  height: 100vh;
  background-color: ${variables.color.background.fullscreenHeader};
`;

const TransferAccountData = () => {
  const { goBack, push } = useHistory();

  const onClickCancel = () => {
    push(routes.accounts());
  };

  const onClickBack = () => {
    goBack();
  };

  return (
    <StyledPage
      name="Transfer Account Data"
      header={
        <SetupHeader progress={0.1} onClickBack={onClickBack} onClickCancel={onClickCancel} />
      }
    >
      <FlexContainer center column marginTop="xxlarge">
        <Text>Transfer Account Data</Text>
      </FlexContainer>
    </StyledPage>
  );
};
export default TransferAccountData;
