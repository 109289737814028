import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { transparentize } from 'polished';
import { isString } from 'ramda-adjunct';
import React from 'react';
import styled, { keyframes } from 'styled-components';

import variables from 'common/lib/theme/variables';

const DELAY_DURATION_MS = 200;
const ANIMATION_DURATION_MS = 150;

const TooltipProvider = TooltipPrimitive.Provider;

const Tooltip = TooltipPrimitive.Root;

const TooltipTrigger = TooltipPrimitive.Trigger;

const animateIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const animateOut = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.95);
  }
`;

const StyledTooltipContent = styled(TooltipPrimitive.Content)<{
  $opacity: number;
  $maxWidth?: number;
}>`
  overflow: hidden;
  border-radius: ${({ theme }) => theme.radius.medium};
  background-color: ${({ theme, $opacity }) =>
    theme.uiTheme === 'dark'
      ? transparentize(0.2, theme.color.gray1)
      : // transparentize subtracts from opacity 1, so we need to do this to get the correct value
        transparentize(1 - $opacity, theme.color.black)};
  backdrop-filter: blur(6px);
  color: ${({ theme }) => theme.color.textWhite};
  max-width: ${({ $maxWidth }) => ($maxWidth ? `${$maxWidth}px` : 'auto')};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  text-align: center;
  transform-origin: var(--radix-tooltip-content-transform-origin);
  border: 1px solid
    ${({ theme }) => (theme.uiTheme === 'dark' ? variables.color.divider.primary : 'transparent')};

  /* Animation */
  &[data-state='delayed-open'] {
    animation: ${animateIn} ${ANIMATION_DURATION_MS}ms ease-out;
  }

  &[data-state='closed'] {
    animation: ${animateOut} ${ANIMATION_DURATION_MS}ms ease-in;
  }
`;

/** Default component used for when the content is a string */
const DefaultTooltipContent = styled.div`
  margin: ${({ theme }) => `${theme.spacing.xsmall} ${theme.spacing.small}`};
  line-height: 150%;
`;

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> &
    React.ComponentProps<typeof StyledTooltipContent>
>(({ sideOffset = 8, ...props }, ref) => (
  <StyledTooltipContent ref={ref} sideOffset={sideOffset} {...props} />
));

const TooltipV2 = ({
  children,
  content,
  opacity = 0.9,
  delayShow = DELAY_DURATION_MS,
  maxWidth,
  clickable,
  ...props
}: {
  children: React.ReactNode;
  content: React.ReactNode;
  opacity?: number;
  delayShow?: number;
  maxWidth?: number;
  clickable?: boolean;
} & Omit<
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>,
  'children' | 'content'
>) =>
  content ? (
    <Tooltip delayDuration={delayShow} disableHoverableContent={!clickable}>
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      <TooltipPrimitive.Portal>
        <TooltipContent {...props} $opacity={opacity} $maxWidth={maxWidth}>
          {isString(content) ? <DefaultTooltipContent>{content}</DefaultTooltipContent> : content}
        </TooltipContent>
      </TooltipPrimitive.Portal>
    </Tooltip>
  ) : (
    <>{children}</>
  );

TooltipContent.displayName = TooltipPrimitive.Content.displayName;

export { TooltipV2, TooltipTrigger, TooltipContent, TooltipProvider };
