import { DateTime } from 'luxon';
import * as R from 'ramda';
import React, { useMemo, useEffect, useState } from 'react';
import styled from 'styled-components';

import AssistantMessageDebugModal from 'components/assistant/AssistantMessageDebugModal';
import MessagesList from 'components/assistant/MessagesList';
import Card from 'components/lib/ui/Card';
import Page from 'components/lib/ui/Page';
import Text from 'components/lib/ui/Text';
import TextButton from 'components/lib/ui/TextButton';

import useEventListener from 'lib/hooks/useEventListener';
import useModal from 'lib/hooks/useModal';

import type { MessageListGroupFields } from 'common/generated/graphQlTypes/MessageListGroupFields';

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => theme.spacing.xlarge};
`;

const MainCard = styled(Card)`
  height: 100%;
`;

const InlineTextButton = styled(TextButton)`
  display: inline;
`;

const makeUserMessage = (data: any = {}) =>
  R.mergeDeepLeft(data, {
    __typename: 'UserMessage',
    id: '123',
    threadId: '456',
    createdAt: new Date().toISOString(),
    content: '',
    user: {
      __typename: 'User',
      id: '1',
      name: 'User',
      profilePictureUrl: null,
    },
  });

const makeAssistantMessage = (data: any = {}) =>
  R.mergeDeepLeft(data, {
    __typename: 'AssistantMessage',
    createdAt: new Date().toISOString(),
    name: 'Assistant',
    content: '',
    threadId: '',
    debugInfo: JSON.stringify(data.debugInfo),
    id: `234`,
    suggestedPrompts: null,
  });

const makeStatusMessage = (data: any = {}) =>
  R.mergeDeepLeft(data, {
    __typename: 'AssistantStatusMessage',
    id: '1',
    content: '',
    name: 'Assistant',
    threadId: '123',
    createdAt: new Date().toISOString(),
  });

const factoryByType = {
  assistant: makeAssistantMessage,
  user: makeUserMessage,
  assistant_status: makeStatusMessage,
};

const AssistantEvaluation = () => {
  const [data, setData] = useState<{
    query: string;
    time: string;
    outcome: string;
    agentVersion: string;
    debugInfo: Record<string, any>;
  }>();
  const [feedbackData, setFeedbackData] = useState<{
    messageInfos: {
      content: string;
      type: 'assistant' | 'user' | 'assistant_status';
      debugInfo: Record<string, any>;
    }[];
  }>();
  const { query, time, outcome, agentVersion, debugInfo } = data ?? {};

  useEventListener(window, 'message', (e) => {
    if (e.data?.type === 'assistant-evaluation') {
      const {
        data: { data },
      } = e;

      if (data?.messageInfos) {
        setFeedbackData(data);
      } else {
        setData(data);
      }
    }
  });

  useEffect(() => {
    window.opener?.postMessage({ type: 'ready' }, '*');
  }, []);

  const [DebugModal, { open: openDebugModal }] = useModal();

  const messages = useMemo((): MessageListGroupFields[] => {
    if (feedbackData) {
      return feedbackData.messageInfos.map(({ content, type, debugInfo }, i) =>
        factoryByType[type]?.({
          content,
          debugInfo,
          id: `${i}`,
        }),
      );
    }

    if (!data || !data.query) {
      return [];
    }

    const finalAnswer = data.debugInfo?.logs?.find(
      ({ event }: { event: string }) => event === 'agent_finish',
    )?.data?.output;

    const statusMessages = data.debugInfo?.logs
      ?.filter(
        ({ event, data }: { event: string; data: any }) =>
          event === 'tool_start' && data?.status_update_msg,
      )
      .map(
        ({ data }: { data: any }, i: number): MessageListGroupFields =>
          makeStatusMessage({
            id: `status-${i}`,
            content: data?.status_update_msg,
          }),
      );

    return [
      makeUserMessage({
        content: data.query,
      }),
      ...(statusMessages ?? []),
      makeAssistantMessage({
        content: finalAnswer ?? 'No answer found',
        debugInfo: data.debugInfo,
      }),
    ];
  }, [data, feedbackData]);

  return (
    <Page name="Assistant Evaluation" parentRoute="Assistant">
      <Container>
        <MainCard
          title={query}
          description={
            !time ? undefined : (
              <span>
                This eval{' '}
                <Text color={outcome === 'passed' ? 'greenText' : 'redText'} weight="medium">
                  {outcome}
                </Text>{' '}
                at{' '}
                <Text weight="medium">
                  {time
                    ? DateTime.fromISO(time).toLocaleString({
                        day: 'numeric',
                        month: 'short',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                      })
                    : 'unknown time'}
                </Text>{' '}
                on agent version <Text weight="medium">{agentVersion}</Text>{' '}
                <InlineTextButton onClick={openDebugModal}>Debug Info</InlineTextButton>
              </span>
            )
          }
        >
          <MessagesList messages={messages} threadId="" alignToBottom={false} demo />
        </MainCard>
      </Container>
      <DebugModal large>
        <AssistantMessageDebugModal debugInfo={debugInfo ?? null} />
      </DebugModal>
    </Page>
  );
};

export default AssistantEvaluation;
