import React from 'react';

import FeatureOnboardingPage from 'components/lib/layouts/FeatureOnboardingPage';
import type { FeatureOnboardingPageProps } from 'components/lib/layouts/FeatureOnboardingPage';

type Props = FeatureOnboardingPageProps;

const BudgetOnboardingPage = ({ children, ...props }: Props) => (
  <FeatureOnboardingPage cancelButtonText="Exit" {...props}>
    {children}
  </FeatureOnboardingPage>
);

export default BudgetOnboardingPage;
