import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

import useQuery from 'common/lib/hooks/useQuery';

import { gql } from 'common/generated';
import { RecurringStreamReviewStatus, RecurringType } from 'common/generated/graphql';

const REVIEW_STATUS_PATH = ['stream', 'reviewStatus'];

export const useRecurringStreams = (params?: { skip?: boolean }) => {
  const { skip } = params ?? {};

  const {
    data,
    isLoadingInitialData,
    refetch: refetchHouseholdHasRecurring,
  } = useQuery(GET_RECURRING_STREAMS, {
    variables: { includeLiabilities: true },
    skip,
    fetchPolicy: 'cache-first',
  });
  const hasRecurringMerchants = (data?.recurringTransactionStreams ?? []).length > 0;

  const pendingStreams = R.filter(
    R.pathEq(['stream', 'reviewStatus'], RecurringStreamReviewStatus.PENDING),
    data?.recurringTransactionStreams ?? [],
  );

  const pendingStreamsCount = pendingStreams.length;

  const approvedStreams = R.filter(
    R.either(
      R.pathEq(REVIEW_STATUS_PATH, RecurringStreamReviewStatus.APPROVED),
      R.pathEq(REVIEW_STATUS_PATH, RecurringStreamReviewStatus.AUTOMATIC_APPROVED),
    ),
    data?.recurringTransactionStreams ?? [],
  );

  const expenseStreams = R.filter(
    R.pathEq(['stream', 'recurringType'], RecurringType.EXPENSE),
    approvedStreams,
  );

  const incomeStreams = R.filter(
    R.pathEq(['stream', 'recurringType'], RecurringType.INCOME),
    approvedStreams,
  );

  const [hasExpenses, hasIncome] = R.map(RA.isNotEmpty, [expenseStreams, incomeStreams]);

  return {
    hasRecurringMerchants,
    isLoading: isLoadingInitialData,
    refetchHouseholdHasRecurring,
    pendingStreams,
    pendingStreamsCount,
    hasExpenses,
    hasIncome,
  };
};

export const GET_RECURRING_STREAMS = gql(/* GraphQL */ `
  query Common_GetRecurringStreams($includeLiabilities: Boolean) {
    recurringTransactionStreams(includePending: true, includeLiabilities: $includeLiabilities) {
      stream {
        id
        reviewStatus
        frequency
        amount
        baseDate
        dayOfTheMonth
        isApproximate
        name
        logoUrl
        recurringType
        merchant {
          id
        }
        creditReportLiabilityAccount {
          id
          account {
            id
          }

          lastStatement {
            id
            dueDate
          }
        }
      }
    }
  }
`);
