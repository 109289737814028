import { motion } from 'framer-motion';
import React from 'react';
import styled, { css } from 'styled-components';

import Flex from 'components/lib/ui/Flex';
import FlexItem from 'components/lib/ui/FlexItem';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';
import TextButton from 'components/lib/ui/TextButton';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import { resolveUrl } from 'components/posts/ImageBlockRenderer';

import boxShadow from 'common/lib/styles/boxShadow';
import { fontSize, fontWeight, radius, spacing } from 'common/lib/theme/dynamic';
import isV2Theme from 'common/lib/theme/isV2Theme';
import variables from 'common/lib/theme/variables';
import useIsV2Theme from 'lib/hooks/useIsV2Theme';
import useTheme from 'lib/hooks/useTheme';

import { SIDEBAR_COLLAPSED_CLASS_NAME } from 'constants/classNames';

const WhatsNew = styled(Flex).attrs({ column: true })`
  background-color: ${({ theme }) => theme.color.grayLightBackground};
  border-radius: ${isV2Theme(radius.small, radius.medium)};
  padding: ${isV2Theme(spacing.small, spacing.default)};
  gap: ${({ theme }) => theme.spacing.xsmall};

  ${isV2Theme(css`
    background-color: ${variables.color.background.primary};
    ${boxShadow.medium};
  `)}

  .${SIDEBAR_COLLAPSED_CLASS_NAME} & {
    display: none;
  }
`;

const Box = styled(Flex).attrs({ column: true })``;

const WhatsNewText = styled(Text)`
  color: ${({ theme }) => theme.color.text};
  font-weight: ${isV2Theme(fontWeight.book, fontWeight.medium)};
  font-size: ${({ theme }) => theme.fontSize.small};
  margin: ${({ theme }) => theme.spacing.xxxsmall} 0;
`;

const TopBox = styled(Flex).attrs({ row: true, justifyBetween: true, alignCenter: true })`
  margin-bottom: ${isV2Theme(spacing.xxsmall, spacing.xsmall)};
`;

const Subtitle = styled(Text)`
  color: ${variables.color.content.secondary};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${isV2Theme(fontSize.xsmall, '11px')};
  text-transform: ${isV2Theme('none', 'uppercase')};
`;

const XIcon = styled(Icon).attrs({ size: 16 })`
  color: ${({ theme }) => theme.color.textLight};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  cursor: pointer;
`;

const StyledTextButton = styled(TextButton)`
  justify-content: flex-start;
  padding-left: 0;
  color: ${variables.color.content.info};
`;

const Image = styled.img`
  height: 130px;
  width: 100%;
  object-fit: contain;
`;

const AnimatedContainer = styled(motion.div).attrs({
  key: 'whats-new-side-menu',
  initial: { opacity: 0 },
  transition: { duration: 0.2, ease: 'easeOut' },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
})``;

type Props = {
  description: string;
  callToAction: string;
  image?: { url: string; darkModeUrl?: string };
  onDismiss: () => void;
  onCheckItOut: () => void;
};

const WhatsNewPopup = ({ description, callToAction, image, onDismiss, onCheckItOut }: Props) => {
  const isV2Theme = useIsV2Theme();
  const theme = useTheme();

  const imageUrl = image && resolveUrl(image, theme);

  return (
    <AnimatedContainer>
      <FlexItem>
        <WhatsNew>
          <Box>
            <TopBox>
              <Subtitle>What&apos;s New</Subtitle>
              <XIcon onClick={onDismiss} name="x" />
            </TopBox>
            <WhatsNewText>{description}</WhatsNewText>
            {!!imageUrl && <Image src={imageUrl} />}
          </Box>
          {isV2Theme ? (
            <StyledTextButton onClick={onCheckItOut}>
              {callToAction} <Icon name="chevron-right" size={12} />
            </StyledTextButton>
          ) : (
            <DefaultButton onClick={onCheckItOut}>{callToAction}</DefaultButton>
          )}
        </WhatsNew>
      </FlexItem>
    </AnimatedContainer>
  );
};
export default WhatsNewPopup;
