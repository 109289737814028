import { useMutation } from '@apollo/client';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

import {
  GET_BUDGET_SETTINGS_QUERY,
  UPDATE_BUDGET_SETTINGS_MUTATION,
} from 'common/lib/graphQl/budgets';
import { GET_JOINT_PLANNING_DATA } from 'common/lib/graphQl/planning';
import { HOUSEHOLD_BUDGET_SETTINGS_FRAGMENT } from 'common/lib/hooks/household/useHouseholdPreferences';
import useQuery from 'common/lib/hooks/useQuery';

import type { BudgetSystem, UpdateBudgetSettingsMutationInput } from 'common/generated/graphql';
import type { QueryHookOptionsFromDocument } from 'common/types/graphql';

// This is the default value for the "Apply to all future months" checkbox.
const DEFAULT_BUDGET_APPLY_TO_FUTURE_MONTHS = true;

/*
  Allows fetching and updating budget settings (system, default changes, etc).
  It also fetches values for the rollover period because the mutation requires all values.
*/
type Options = QueryHookOptionsFromDocument<typeof GET_BUDGET_SETTINGS_QUERY>;

type UpdateOptions = {
  onDone?: (hasChangedBudgetSystem: boolean) => void;
};

const useBudgetSettings = (options?: Options) => {
  const { data, error, isLoadingInitialData } = useQuery(GET_BUDGET_SETTINGS_QUERY, options);
  const currentBudgetSystem = data?.budgetSystem;

  const [updateBudgetSettings, { loading: updateLoading }] = useMutation(
    UPDATE_BUDGET_SETTINGS_MUTATION,
    {
      refetchQueries: [GET_JOINT_PLANNING_DATA, GET_BUDGET_SETTINGS_QUERY],
      awaitRefetchQueries: true,
    },
  );

  const performMutation = async (
    input: Partial<UpdateBudgetSettingsMutationInput>,
    { onDone }: UpdateOptions,
  ) => {
    const { data: updateData } = await updateBudgetSettings({
      variables: {
        input: {
          // Just pass the current values since the mutation requires all of them.
          // TODO: We should refactor this to accept a partial update.
          rolloverEnabled: RA.isNotNil(data?.flexExpenseRolloverPeriod),
          rolloverStartMonth: data?.flexExpenseRolloverPeriod?.startMonth,
          rolloverStartingBalance: data?.flexExpenseRolloverPeriod?.startingBalance,
          // Sending undefined for these fields won't update them
          budgetApplyToFutureMonthsDefault: undefined,
          budgetSystem: undefined,
          ...input,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateBudgetSettings: {
          __typename: 'UpdateBudgetSettingsMutation',
          budgetRolloverPeriod: data?.flexExpenseRolloverPeriod,
          budgetSystem: input.budgetSystem ?? data?.budgetSystem,
          budgetApplyToFutureMonthsDefault:
            input.budgetApplyToFutureMonthsDefault ?? data?.budgetApplyToFutureMonthsDefault,
        },
      },
      update: (cache) => {
        cache.writeFragment({
          fragment: HOUSEHOLD_BUDGET_SETTINGS_FRAGMENT,
          data: {
            __typename: 'Query',
            budgetSystem: input.budgetSystem ?? data?.budgetSystem,
            budgetApplyToFutureMonthsDefault:
              input.budgetApplyToFutureMonthsDefault ?? data?.budgetApplyToFutureMonthsDefault,
          },
        });
      },
    });

    const updatedBudgetSystem = updateData?.updateBudgetSettings?.budgetSystem;
    const hasChangedBudgetSystem = currentBudgetSystem !== updatedBudgetSystem;
    onDone?.(hasChangedBudgetSystem);
  };

  const updateBudgetSystem = async (
    selectedBudgetSystem: BudgetSystem,
    { onDone }: { onDone: (hasChangedBudgetSystem: boolean) => void },
  ) => {
    await performMutation({ budgetSystem: selectedBudgetSystem }, { onDone });
  };

  return {
    data,
    loading: isLoadingInitialData || !data,
    budgetApplyToFutureMonthsDefault: R.isNil(data?.budgetApplyToFutureMonthsDefault)
      ? DEFAULT_BUDGET_APPLY_TO_FUTURE_MONTHS
      : !!data?.budgetApplyToFutureMonthsDefault,
    budgetSystem: currentBudgetSystem,
    error,
    updateBudgetSettings: performMutation,
    updateBudgetSystem,
    updateLoading,
  };
};

export default useBudgetSettings;
