import { gql } from '@apollo/client';
import React from 'react';
import styled from 'styled-components';

import CheckCircleTransparent from 'components/lib/ui/CheckCircleTransparent';
import FlexContainer from 'components/lib/ui/FlexContainer';
import ProgressBar from 'components/lib/ui/ProgressBar';
import Text from 'components/lib/ui/Text';
import Currency from 'components/lib/ui/currency/Currency';

import {
  getGoalDisplayAmount,
  getGoalDisplayProgress,
  getIncompleteGoalSetupLabel,
  isGoalSetupIncomplete,
} from 'common/lib/goalsV2/adapters';

import type { WebGoalNameProgressBarFieldsFragment } from 'common/generated/graphql';

const CHECK_CIRCLE_SIZE_PT = 12;

const Root = styled(FlexContainer).attrs({ column: true })`
  position: relative;
  flex: 1;
`;

const Title = styled(Text).attrs({ weight: 'bold' })`
  color: ${({ theme }) => theme.color.textWhite};
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.spacing.xxxsmall};
  letter-spacing: 0.1em;
  font-size: 11px;
`;

const Balance = styled(Currency)`
  color: ${({ theme }) => theme.color.textWhite};
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.xlarge};
  line-height: 150%;
`;

const SetupLabel = styled(Text).attrs({ weight: 'medium', size: 'xlarge' })`
  margin-top: ${({ theme }) => theme.spacing.xsmall};
  color: ${({ theme }) => theme.color.textWhite};
`;

const StyledProgressBar = styled(ProgressBar)`
  margin-top: ${({ theme }) => theme.spacing.xsmall};
  height: 6px;
`;

const StatusText = styled(Text).attrs({ weight: 'medium', size: 'xsmall' })`
  color: ${({ theme }) => theme.color.textWhite};
  margin-left: 6px;
`;

type Props = {
  goal: WebGoalNameProgressBarFieldsFragment;
  right?: React.ReactNode;
};

const GoalNameProgressBar = ({ goal, right }: Props) => {
  const { name, archivedAt, completedAt, defaultName } = goal;

  const progress = getGoalDisplayProgress(goal);

  return (
    <Root>
      {isGoalSetupIncomplete(goal) ? (
        <FlexContainer column>
          <Title>{name}</Title>
          <SetupLabel>{getIncompleteGoalSetupLabel(defaultName || '')}</SetupLabel>
        </FlexContainer>
      ) : (
        <>
          <FlexContainer alignEnd justifyBetween>
            <FlexContainer column>
              <Title>{name}</Title>
              <Balance value={getGoalDisplayAmount(goal)} />
            </FlexContainer>
            {archivedAt || completedAt ? (
              <FlexContainer alignCenter>
                <CheckCircleTransparent size={CHECK_CIRCLE_SIZE_PT} />
                <StatusText>{archivedAt ? 'Archived' : 'Completed'}</StatusText>
              </FlexContainer>
            ) : (
              right
            )}
          </FlexContainer>
          <StyledProgressBar
            value={progress}
            color="textWhite"
            backgroundColor="gray9"
            roundAppearance
          />
        </>
      )}
    </Root>
  );
};

GoalNameProgressBar.fragments = {
  WebGoalNameProgressBarFields: gql`
    fragment WebGoalNameProgressBarFields on GoalV2 {
      id
      name
      defaultName
      targetAmount
      startingAmount
      currentAmount
      completedAt
      archivedAt
      type
      completionPercent
      accountAllocations {
        id
      }
    }
  `,
};

export default GoalNameProgressBar;
