import React, { useMemo } from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import BudgetOnboardingPage from 'components/plan/onboarding/BudgetOnboardingPage';
import FooterButton from 'components/plan/onboarding/FooterButton';
import LeftToBudgetFooter from 'components/plan/onboarding/LeftToBudgetFooter';
import OnboardingBudgetList from 'components/plan/onboarding/OnboardingBudgetList';

import { getSummaryRowsAndLeftToBudget } from 'common/lib/budget/Adapters';
import { spacing } from 'common/lib/theme/dynamic';
import { getFlexGroupDataWithBudgetedAmounts } from 'lib/budget/onboardingAdapters';
import usePlanAdapter from 'lib/hooks/plan/usePlanAdapter';
import usePlanQuery from 'lib/hooks/plan/usePlanQuery';
import usePlanState from 'lib/hooks/plan/usePlanState';

import { BudgetVariability, CategoryGroupType } from 'common/generated/graphql';

const StyledLoadingSpinner = styled(LoadingSpinner)`
  margin-top: ${spacing.xlarge};
`;

const Container = styled(FlexContainer).attrs({ center: true, column: true })`
  margin-bottom: 200px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: ${({ theme }) => theme.spacing.xsmall} 0;
  background: ${({ theme }) => theme.color.grayFocus};
`;

type Props = {
  onBack: () => void;
  onCancel: () => void;
  onNext: () => void;
  budgetVariability: BudgetVariability;
  title: string;
  description: string;
  progress: number;
  pageName: string;
};

const OnboardingFlexExpenses = ({
  onBack,
  onCancel,
  onNext,
  budgetVariability,
  title,
  description,
  pageName,
  progress,
}: Props) => {
  const [state] = usePlanState();
  const { data, fetchedDateRange, updateCellValue, refetch, isRefetching } = usePlanQuery(state);
  const { isLoadingInitialData, gridDisplayData, gridAmounts, budgetSummaryData } = usePlanAdapter(
    data,
    state,
    fetchedDateRange,
  );

  // gridDisplayData has the presentational info that we need, but gridAmounts has the budgeted amounts.
  const expenseDataWithAmountsForVariability = getFlexGroupDataWithBudgetedAmounts(
    gridDisplayData,
    gridAmounts,
    budgetVariability,
    state.thisMonth,
  );

  const { rows: tooltipRows, leftToBudget } = useMemo(
    () => getSummaryRowsAndLeftToBudget(budgetSummaryData, budgetVariability),
    [budgetSummaryData, budgetVariability],
  );

  return (
    <BudgetOnboardingPage
      pageName={pageName}
      title={title}
      description={description}
      descriptionMaxWidth={600}
      progress={progress}
      onClickBack={onBack}
      onClickCancel={onCancel}
      hideNextButton
    >
      <Container>
        {isLoadingInitialData ? (
          <StyledLoadingSpinner />
        ) : (
          <FlexContainer column gap="default">
            <OnboardingBudgetList
              data={expenseDataWithAmountsForVariability}
              groupType={CategoryGroupType.EXPENSE}
              thisMonth={state.thisMonth}
              updateBudgetedValue={updateCellValue}
              initiallyOpen={budgetVariability !== BudgetVariability.FLEXIBLE}
              refetch={budgetVariability === BudgetVariability.FIXED ? refetch : undefined}
            />
            <Divider />
            <LeftToBudgetFooter
              value={leftToBudget}
              tooltipRows={tooltipRows}
              isLoading={isRefetching}
            />
          </FlexContainer>
        )}
      </Container>
      <FooterButton onClickNext={onNext} />
    </BudgetOnboardingPage>
  );
};

export default OnboardingFlexExpenses;
