import * as R from 'ramda';

import darkPaletteV1 from 'common/lib/theme/darkPalette';
import lightPaletteV1 from 'common/lib/theme/lightPalette';
import newPalette from 'common/lib/theme/newPalette';
import type { ThemeContext } from 'common/lib/theme/types';
import v1PaletteMapping, { v1PaletteMappingDarkOverrides } from 'common/lib/theme/v1PaletteMapping';

import type { UiTheme } from 'common/types';

type V1Colors = keyof typeof lightPaletteV1;
type V2Colors = keyof typeof newPalette;
type ColorPalette<T extends string> = {
  [key in T]: string;
};

const getColorPalette = ({ uiTheme }: ThemeContext): ColorPalette<V1Colors | V2Colors> => {
  const v1Palette = uiTheme === 'light' ? lightPaletteV1 : darkPaletteV1;
  const v2Palette = getV2Palette(uiTheme);

  // the palette will always have all the colors from BOTH palettes - this is
  // mostly for typescript reasons, and it's expected that v2 colors will only
  // be used in conjunction with isV2Theme() checks
  const mergedPalette = {
    ...v2Palette,
    ...mapV1PaletteToV2(v1Palette, v2Palette, uiTheme),
  };

  return mergedPalette;
};

const mapV1PaletteToV2 = (
  v1Palette: ColorPalette<V1Colors>,
  v2Palette: ColorPalette<V2Colors>,
  uiTheme: UiTheme,
) =>
  R.mapObjIndexed((value, key) => {
    const lightMappedColorName = v1PaletteMapping[key];
    const darkMappedColorName = v1PaletteMappingDarkOverrides[key];
    const mappedColorName = (uiTheme === 'dark' && darkMappedColorName) || lightMappedColorName;
    if (mappedColorName) {
      return v2Palette[mappedColorName];
    } else {
      return value;
    }
  }, v1Palette);

const getV2Palette = (uiTheme: UiTheme) =>
  R.mapObjIndexed(([light, dark]) => (uiTheme === 'dark' ? dark : light), newPalette);

export default getColorPalette;
