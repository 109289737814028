import { createSelector } from 'reselect';

import type { RootState } from 'state/types';

const getNavigationState = ({ navigation }: RootState) => navigation;

export const getRouteHistory = createSelector(
  getNavigationState,
  ({ routeHistory }) => routeHistory,
);
