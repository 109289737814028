import React, { useEffect } from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import BudgetOnboardingPage from 'components/plan/onboarding/BudgetOnboardingPage';
import FooterButton from 'components/plan/onboarding/FooterButton';
import LeftToBudgetFooter from 'components/plan/onboarding/LeftToBudgetFooter';
import OnboardingBudgetList from 'components/plan/onboarding/OnboardingBudgetList';
import OnboardingGroupFooter from 'components/plan/onboarding/OnboardingGroupFooter';

import { getBudgetedAmountForType, getSummaryRows } from 'common/lib/budget/Adapters';
import { useBudgetOnboardingActions } from 'common/lib/hooks/budget/useBudgetOnboardingActions';
import { spacing } from 'common/lib/theme/dynamic';
import { getGroupsDataWithBudgetedAmounts } from 'lib/budget/onboardingAdapters';
import usePlanAdapter from 'lib/hooks/plan/usePlanAdapter';
import usePlanQuery from 'lib/hooks/plan/usePlanQuery';
import usePlanState from 'lib/hooks/plan/usePlanState';

import { CategoryGroupType } from 'common/generated/graphql';

const StyledLoadingSpinner = styled(LoadingSpinner)`
  margin-top: ${spacing.xlarge};
`;

const Container = styled(FlexContainer).attrs({ center: true, column: true })`
  margin-bottom: 200px;
`;

type Props = {
  title: string;
  description: string;
  progress: number;
  onBack: () => void;
  onCancel: () => void;
  onNext: () => void;
};

const OnboardingExpenses = ({ title, description, progress, onBack, onCancel, onNext }: Props) => {
  const [state] = usePlanState();
  const { data, fetchedDateRange, updateCellValue, refetch, isRefetching } = usePlanQuery(state);
  const { isLoadingInitialData, gridDisplayData, gridAmounts, budgetSummaryData } = usePlanAdapter(
    data,
    state,
    fetchedDateRange,
  );

  const { safeRecalculateExpensesBudget, isLoading: isMutationLoading } =
    useBudgetOnboardingActions();

  useEffect(() => {
    safeRecalculateExpensesBudget();
  }, []);

  // gridDisplayData has the presentational info that we need, but gridAmounts has the budgeted amounts.
  const expenseDataWithAmounts = getGroupsDataWithBudgetedAmounts(
    gridDisplayData,
    gridAmounts,
    CategoryGroupType.EXPENSE,
    state.thisMonth,
  );

  const incomeAmount = getBudgetedAmountForType(budgetSummaryData, CategoryGroupType.INCOME);
  const expensesAmount = getBudgetedAmountForType(budgetSummaryData, CategoryGroupType.EXPENSE);
  const tooltipRows = getSummaryRows(incomeAmount, expensesAmount);
  const leftToBudgetOrSave = incomeAmount - expensesAmount;

  return (
    <BudgetOnboardingPage
      pageName="Budget Onboarding Expenses"
      title={title}
      description={description}
      descriptionMaxWidth={600}
      progress={progress}
      onClickBack={onBack}
      onClickCancel={onCancel}
      hideNextButton
    >
      <Container>
        {isLoadingInitialData || isMutationLoading ? (
          <StyledLoadingSpinner />
        ) : (
          <FlexContainer column gap="small">
            <OnboardingBudgetList
              data={expenseDataWithAmounts}
              thisMonth={state.thisMonth}
              updateBudgetedValue={updateCellValue}
              groupType={CategoryGroupType.EXPENSE}
              footer={
                <OnboardingGroupFooter
                  title="Total budgeted expenses"
                  value={expensesAmount}
                  isLoading={isRefetching}
                />
              }
              refetch={refetch}
            />
            <LeftToBudgetFooter
              value={leftToBudgetOrSave}
              tooltipRows={tooltipRows}
              isLoading={isRefetching}
            />
          </FlexContainer>
        )}
      </Container>
      <FooterButton onClickNext={onNext} />
    </BudgetOnboardingPage>
  );
};

export default OnboardingExpenses;
