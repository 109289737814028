import React from 'react';
import deepIsEqual from 'react-fast-compare';

import FlexContainer from 'components/lib/ui/FlexContainer';
import PlanGroup from 'components/plan/PlanGroup';
import PlanSectionFooter from 'components/plan/PlanSectionFooter';
import PlanSectionHeader from 'components/plan/PlanSectionHeader';

import { PlanTimeframe } from 'lib/hooks/plan/usePlanState';
import type { GridAmountsForSection, PlanSectionData } from 'lib/plan/Adapters';

type Props = {
  section: PlanSectionData;
  amounts: GridAmountsForSection;
  timeframe: PlanTimeframe;
  numColumns: number;
  isCollapsed: boolean;
  onClickColumn: (column: number) => void;
};

const PlanSection = ({
  section: { name, groups, type, aggregateRows },
  amounts,
  timeframe,
  numColumns,
  isCollapsed,
  onClickColumn,
}: Props) => (
  <FlexContainer column>
    <PlanSectionHeader
      name={name}
      sectionType={type}
      isCollapsed={isCollapsed}
      timeframe={timeframe}
      numColumns={numColumns}
      onClickColumn={onClickColumn}
    />
    {!isCollapsed &&
      groups.map((group) => (
        <PlanGroup
          key={group.id}
          amounts={amounts[group.id]}
          group={group}
          numColumns={numColumns}
          sectionType={type}
        />
      ))}
    {aggregateRows?.map(({ title, key, sectionType, isLeftoverRow }, index) => (
      <PlanSectionFooter
        key={key}
        name={title}
        amounts={amounts[key]}
        numColumns={numColumns}
        sectionType={sectionType ?? type}
        showSeparator={index > 0}
        isPlanSummary={isLeftoverRow && timeframe === PlanTimeframe.SingleMonth}
      />
    ))}
  </FlexContainer>
);

export default React.memo(PlanSection, deepIsEqual);
