import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import SelectField from 'components/lib/form/SelectField';
import TextAreaField from 'components/lib/form/TextAreaField';
import TextAreaInput from 'components/lib/form/TextAreaInput';
import Card from 'components/lib/ui/Card';
import CardBody from 'components/lib/ui/CardBody';
import Page from 'components/lib/ui/Page';

import getHackathonApi from 'common/lib/api/hackathon';
import api from 'lib/api';

const Root = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};
  display: flex;
  gap: ${({ theme }) => theme.spacing.xlarge};
`;

const Hackathon = () => {
  const [optionNames, setOptionNames] = useState<string[]>([]);
  const [output, setOutput] = useState<string>('');

  useEffect(() => {
    const fetchOptions = async () => {
      const { options }: { options: string[] } = await getHackathonApi(api).getOptions();
      setOptionNames(options);
    };
    fetchOptions();
  }, []);

  const submit = async (values: any) => {
    const response = await getHackathonApi(api).getOptionResponse(values.name, values.input);
    setOutput(response.output);
  };

  return (
    <Page name="Hackathon" parentRoute={null}>
      <Root>
        <Card>
          <CardBody>
            <Form onSubmit={submit}>
              <SelectField
                options={optionNames.map((name) => ({ label: name, value: name }))}
                name="name"
              />
              <TextAreaField name="input" cols={70} minRows={5} />
              <FormSubmitButton>Submit</FormSubmitButton>
            </Form>
            <TextAreaInput value={output} />
          </CardBody>
        </Card>
      </Root>
    </Page>
  );
};

export default Hackathon;
