const newPalette = {
  // name: [light, dark]
  gray1: ['#fbfaf8', '#111110'],
  gray2: ['#f6f5f3', '#191918'],
  gray3: ['#efece9', '#222221'],
  gray4: ['#ebe8e5', '#2a2a28'],
  gray5: ['#e4e1de', '#31312e'],
  gray6: ['#dcd9d6', '#3b3a37'],
  gray7: ['#d1cecb', '#494846'],
  gray8: ['#bebbb8', '#62605d'],
  gray9: ['#8f8c8a', '#6f6d68'],
  gray10: ['#84827f', '#7c7a75'],
  gray11: ['#777573', '#989691'],
  gray12: ['#22201d', '#eeeeec'],
  orange1: ['#FEFCFB', '#160F0D'],
  orange2: ['#FFF5F0', '#201511'],
  orange3: ['#FFE9DE', '#361B11'],
  orange4: ['#FFD7C4', '#4C1A07'],
  orange5: ['#FFC9B1', '#5B230D'],
  orange6: ['#FFB99C', '#6B311B'],
  orange7: ['#FFA586', '#834129'],
  orange8: ['#F48D68', '#A85335'],
  orange9: ['#FF692D', '#FF692D'],
  orange10: ['#F35B16', '#F15C1C'],
  orange11: ['#D64700', '#FF9B73'],
  orange12: ['#592C1D', '#FFDBCC'],
  yellow1: ['#FEFDFB', '#16120C'],
  yellow2: ['#FEFBE9', '#1D180F'],
  yellow3: ['#FFF7C2', '#302008'],
  yellow4: ['#FFEE9C', '#3F2700'],
  yellow5: ['#FBE577', '#4D3000'],
  yellow6: ['#F3D673', '#5C3D05'],
  yellow7: ['#E9C162', '#714F19'],
  yellow8: ['#E2A336', '#8F6424'],
  yellow9: ['#FFC53D', '#FFC53D'],
  yellow10: ['#FFBA18', '#FFD60A'],
  yellow11: ['#AB6400', '#FFCA16'],
  yellow12: ['#4F3422', '#FFE7B3'],
  blue1: ['#fafdfe', '#0b161a'],
  blue2: ['#f2fafb', '#101b20'],
  blue3: ['#def7f9', '#082c36'],
  blue4: ['#caf1f6', '#003848'],
  blue5: ['#b5e9f0', '#004558'],
  blue6: ['#9ddde7', '#045468'],
  blue7: ['#7dcedc', '#12677e'],
  blue8: ['#3db9cf', '#11809c'],
  blue9: ['#00a2c7', '#00a2c7'],
  blue10: ['#0797b9', '#23afd0'],
  blue11: ['#107d98', '#4ccce6'],
  blue12: ['0d3c48', '#b6ecf7'],
  green1: ['#FBFEFC', '#0E1512'],
  green2: ['#F4FBF6', '#121B17'],
  green3: ['#E6F6EB', '#132D21'],
  green4: ['#D6F1DF', '#113B29'],
  green5: ['#C9E8CA', '#174933'],
  green6: ['#ADDDC0', '#20573E'],
  green7: ['#8ECEAA', '#28684A'],
  green8: ['#5BB98B', '#2F7C57'],
  green9: ['#30A46C', '#30A46C'],
  green10: ['#2B9A66', '#33B074'],
  green11: ['#218358', '#3DD68C'],
  green12: ['#193B2D', '#B1F1CB'],
  pink1: ['#fffcfe', '#191117'],
  pink2: ['#fef7fb', '#21121d'],
  pink3: ['#fee9f5', '#37172f'],
  pink4: ['#fbdcef', '#4b143d'],
  pink5: ['#f6cee7', '#591c47'],
  pink6: ['#efbfdd', '#692955'],
  pink7: ['#e7acd0', '#833869'],
  pink8: ['#dd93c2', '#a84885'],
  pink9: ['#d6409f', '#d6409f'],
  pink10: ['#cf3897', '#de51a8'],
  pink11: ['#c2298a', '#ff8dcc'],
  pink12: ['#651249', '#fdd1ea'],
  red1: ['#FFFCFC', '#191111'],
  red2: ['#FFF7F7', '#201314'],
  red3: ['#FEEBEC', '#3B1219'],
  red4: ['#FFDBDC', '#500F1C'],
  red5: ['#FFCDCE', '#611623'],
  red6: ['#FDBDBE', '#72232D'],
  red7: ['#F4A9AA', '#8C333A'],
  red8: ['#EB8E90', '#B54548'],
  red9: ['#E5484D', '#E5484D'],
  red10: ['#DC3E42', '#EC5D5E'],
  red11: ['#CE2C31', '#FF9592'],
  red12: ['#641723', '#FFD1D9'],
  sky1: ['#f9feff', '#0d141f'],
  sky2: ['#f1fafd', '#111a27'],
  sky3: ['#e1f6fd', '#112840'],
  sky4: ['#d1f0fa', '#113555'],
  sky5: ['#bee7f5', '#154467'],
  sky6: ['#a9daed', '#1b537b'],
  sky7: ['#8dcae3', '#1f6692'],
  sky8: ['#60b3d7', '#197cae'],
  sky9: ['#7ce2fe', '#7ce2fe'],
  sky10: ['#74daf8', '#a8eeff'],
  sky11: ['#00749e', '#75c7f0'],
  sky12: ['#1d3e56', '#c2f3ff'],
  lime1: ['#fcfdfa', '#11130c'],
  lime2: ['#f8faf3', '#151a10'],
  lime3: ['#eef6d6', '#1f2917'],
  lime4: ['#e2f0bd', '#29371d'],
  lime5: ['#d3e7a6', '#334423'],
  lime6: ['#c2da91', '#3d522a'],
  lime7: ['#abc978', '#496231'],
  lime8: ['#8db654', '#577538'],
  lime9: ['#bdee63', '#bdee63'],
  lime10: ['#b0e64c', '#d4ff70'],
  lime11: ['#5c7c2f', '#bde56c'],
  lime12: ['#37401c', '#e3f7ba'],
  purple1: ['#fefcfe', '#18111b'],
  purple2: ['#fbf7fe', '#1e1523'],
  purple3: ['#f7edfe', '#301c3b'],
  purple4: ['#f2e2fc', '#3d224e'],
  purple5: ['#ead5f9', '#48295c'],
  purple6: ['#e0c4f4', '#54346b'],
  purple7: ['#d1afec', '#664282'],
  purple8: ['#be93e4', '#8457aa'],
  purple9: ['#8e4ec6', '#8e4ec6'],
  purple10: ['#8347b9', '#9a5cd0'],
  purple11: ['#8145b5', '#d19dff'],
  purple12: ['#402060', '#ecd9fa'],
  indigo1: ['#fdfdfe', '#11131f'],
  indigo2: ['#f7f9ff', '#141726'],
  indigo3: ['#edf2fe', '#182449'],
  indigo4: ['#e1e9ff', '#1d2e62'],
  indigo5: ['#d2deff', '#253974'],
  indigo6: ['#c1d0ff', '#304384'],
  indigo7: ['#abbdf9', '#3a4f97'],
  indigo8: ['#8da4ef', '#435db1'],
  indigo9: ['#3e63dd', '#3e63dd'],
  indigo10: ['#3358d4', '#5472e4'],
  indigo11: ['#3a5bc7', '#9eb1ff'],
  indigo12: ['#1f2d5c', '#d6e1ff'],
  pureWhite: ['#ffffff', '#ffffff'],
  pureBlack: ['#000000', '#000000'],
};

export default newPalette;
