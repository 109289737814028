import { gql } from '@apollo/client';
import * as R from 'ramda';
import { useMemo, useCallback } from 'react';

import useQuery from 'common/lib/hooks/useQuery';

import type { OnboardingChecklist } from 'common/generated/graphQlTypes/OnboardingChecklist';

export enum ChecklistItemName {
  AddAccount = 'add-account',
  CustomizeCategories = 'customize-categories',
  CreateGoal = 'create-goal',
  CreatePlan = 'create-plan',
}

const CHECKLIST_ITEMS = [
  {
    name: ChecklistItemName.AddAccount,
    icon: '🏦',
    title: 'Add an account',
  },
  {
    name: ChecklistItemName.CustomizeCategories,
    icon: '✨',
    title: 'Customize categories',
  },
  {
    name: ChecklistItemName.CreateGoal,
    icon: '🎯',
    title: 'Create a goal',
  },
  {
    name: ChecklistItemName.CreatePlan,
    icon: '📈',
    title: 'Create a budget',
  },
];

const useOnboardingChecklist = () => {
  const { data, isLoadingInitialData, refetch } = useQuery<OnboardingChecklist>(QUERY);

  const getIsItemCompleted = useCallback(
    (itemName: ChecklistItemName): boolean => {
      if (!data) {
        return false;
      }

      const {
        hasAccounts,
        goalsV2,
        budgetStatus: { hasBudget },
        me: { profile },
      } = data;
      const { hasSeenCategoriesManagementTour } = profile ?? {};

      switch (itemName) {
        case ChecklistItemName.AddAccount:
          return hasAccounts;
        case ChecklistItemName.CustomizeCategories:
          return !!hasSeenCategoriesManagementTour;
        case ChecklistItemName.CreateGoal:
          return goalsV2.length > 0;
        case ChecklistItemName.CreatePlan:
          return hasBudget;
      }
    },
    [data],
  );

  const items = useMemo(
    () => CHECKLIST_ITEMS.map((item) => ({ ...item, completed: getIsItemCompleted(item.name) })),
    [getIsItemCompleted],
  );

  const firstName = R.head(data?.me.name.split(' ') ?? []);
  const completedCount = items.reduce((acc, { completed }) => acc + (completed ? 1 : 0), 0);

  const titleCopy =
    completedCount === items.length
      ? `${
          firstName ? `Congratulations ${firstName}` : 'Congratulations'
        }, you’ve set up your account`
      : `${firstName ? `${firstName}, let's` : `Let's`} finish setting up your account`;

  return { items, titleCopy, isLoadingInitialData, refetch, completedCount };
};

const QUERY = gql`
  query OnboardingChecklist {
    hasAccounts
    goalsV2 {
      id
    }
    budgetStatus {
      hasBudget
    }
    me {
      id
      name
      profile {
        id
        hasSeenCategoriesManagementTour
      }
    }
  }
`;

export default useOnboardingChecklist;
