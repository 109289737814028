import { useMutation } from '@apollo/client';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import FieldCell from 'components/lib/form/FieldCell';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import RadioGroupField from 'components/lib/form/RadioGroupField';
import Badge from 'components/lib/ui/Badge';
import CardFooter from 'components/lib/ui/CardFooter';
import Confirmation from 'components/lib/ui/Confirmation';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Modal from 'components/lib/ui/Modal';
import Text from 'components/lib/ui/Text';
import DefaultButton from 'components/lib/ui/button/DefaultButton';

import { GET_JOINT_PLANNING_DATA } from 'common/lib/graphQl/planning';
import useBudgetSettings from 'common/lib/hooks/budget/useBudgetSettings';
import { fontSize, fontWeight, spacing } from 'common/lib/theme/dynamic';
import noop from 'common/utils/noop';

import { BUDGET } from 'common/constants/copy';
import routes from 'constants/routes';

import { gql } from 'common/generated/gql';
import type { UpdateBudgetSettingsMutationInput } from 'common/generated/graphql';
import { BudgetSystem } from 'common/generated/graphql';

type Props = {
  onDone?: () => void;
};

const Root = styled.div`
  padding: ${spacing.xlarge};
`;

const StyledLoadingSpinner = styled(LoadingSpinner)`
  margin: 0px auto;
`;

const StyledButton = styled(DefaultButton)`
  text-wrap: nowrap;
`;

const RecommendedBadge = styled(Badge).attrs({
  color: 'orange',
})`
  text-transform: unset;
  letter-spacing: unset;
  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.xsmall};
`;

const SectionLabel = styled(Text).attrs({
  size: 'small',
})`
  display: block;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-bottom: ${spacing.xsmall};
`;

const LOADING_STATE = (
  <Root>
    <StyledLoadingSpinner />
  </Root>
);

const ERROR_STATE = <Root>Sorry, there was an error loading the settings.</Root>;

type FormValues = Partial<UpdateBudgetSettingsMutationInput>;

const BudgetSettingsForm = ({ onDone = noop }: Props) => {
  const [confirmingRecalculate, setConfirmingRecalculate] = useState(false);
  const [confirmingClear, setConfirmingClear] = useState(false);
  const {
    budgetSystem,
    budgetApplyToFutureMonthsDefault,
    loading,
    error,
    updateBudgetSettings,
    updateLoading,
  } = useBudgetSettings();

  const { push } = useHistory();

  const [recalculateBudget, { loading: recalculateLoading }] = useMutation(
    RECALCULATE_BUDGET_MUTATION,
    {
      refetchQueries: [GET_JOINT_PLANNING_DATA],
      awaitRefetchQueries: true,
    },
  );
  const [clearAll, { loading: clearAllLoading }] = useMutation(CLEAR_BUDGET_MUTATION, {
    refetchQueries: [GET_JOINT_PLANNING_DATA],
    awaitRefetchQueries: true,
  });

  if (error) {
    return ERROR_STATE;
  }

  if (loading) {
    return LOADING_STATE;
  }

  const initialValues = {
    budgetSystem,
    budgetApplyToFutureMonthsDefault,
  };

  const handleSubmit = async (values: FormValues) => {
    await updateBudgetSettings(values, { onDone });
  };

  return (
    <Form initialValues={initialValues} onSubmit={handleSubmit}>
      <Root>
        <RadioGroupField
          name="budgetSystem"
          label="System"
          options={[
            {
              value: BudgetSystem.FIXED_AND_FLEX,
              label: 'Flex Budget',
              description: 'Simplify your budget by focusing on your flexible expense number.',
              rightAccessory: <RecommendedBadge>Recommended</RecommendedBadge>,
            },
            {
              value: BudgetSystem.GROUPS_AND_CATEGORIES,
              label: 'Category Budget',
              description: 'Budget every category individually, the traditional way.',
            },
          ]}
        />

        <RadioGroupField
          name="budgetApplyToFutureMonthsDefault"
          label="By default, apply budget changes to"
          options={[
            {
              value: false,
              label: BUDGET.CHANGE_OPTIONS.THIS_MONTH.LABEL,
              description: BUDGET.CHANGE_OPTIONS.THIS_MONTH.DESCRIPTION,
            },
            {
              value: true,
              label: BUDGET.CHANGE_OPTIONS.ALL_FUTURE_MONTHS.LABEL,
              description: BUDGET.CHANGE_OPTIONS.ALL_FUTURE_MONTHS.DESCRIPTION,
            },
          ]}
        />

        <SectionLabel>More options</SectionLabel>

        <FieldCell
          rightAccessory={
            <StyledButton onClick={() => setConfirmingRecalculate(true)}>Recalculate</StyledButton>
          }
          title="Recalculate default budgets"
          subtitle={
            <>
              Use your historical averages to automatically generate a new default budget. This will
              override all existing budget amounts.
            </>
          }
        />

        <FieldCell
          rightAccessory={
            <StyledButton onClick={() => setConfirmingClear(true)}>Clear all</StyledButton>
          }
          title="Clear all budget values"
          subtitle={
            <>
              Clear all budget values going back to the starting month and start over from scratch.
            </>
          }
        />

        <FieldCell
          rightAccessory={
            <StyledButton onClick={() => push(routes.budget.onboarding())}>
              Start setup
            </StyledButton>
          }
          title="Budget walkthrough"
          subtitle={
            <>
              Modify your budget step by step with explanations for how budgeting works in Monarch.
            </>
          }
        />

        {/* Modals */}
        {confirmingRecalculate && (
          <Modal onClose={() => setConfirmingRecalculate(false)}>
            {({ close }) => (
              <Confirmation
                title="Are you sure you want to recalculate?"
                confirm="Recalculate"
                onCancel={close}
                onConfirm={async () => {
                  await recalculateBudget({
                    variables: {
                      input: {
                        startDate: DateTime.local().toISODate(),
                        filters: undefined,
                        overwriteExisting: undefined,
                      },
                    },
                  });
                  close();
                  onDone();
                }}
                useDangerButton
                isLoading={recalculateLoading}
              >
                This will override all existing budget amounts and cannot be undone.
              </Confirmation>
            )}
          </Modal>
        )}

        {confirmingClear && (
          <Modal onClose={() => setConfirmingClear(false)}>
            {({ close }) => (
              <Confirmation
                title="Are you sure you want to clear all?"
                confirm="Clear all"
                onCancel={close}
                onConfirm={async () => {
                  await clearAll({
                    variables: {
                      input: {
                        startDate: DateTime.local().toISODate(),
                      },
                    },
                  });
                  close();
                  onDone();
                }}
                useDangerButton
                isLoading={clearAllLoading}
              >
                This will clear all existing budget amounts and cannot be undone.
              </Confirmation>
            )}
          </Modal>
        )}
      </Root>

      <CardFooter>
        <DefaultButton onClick={onDone}>Cancel</DefaultButton>
        <FormSubmitButton size="small" pending={updateLoading}>
          Save
        </FormSubmitButton>
      </CardFooter>
    </Form>
  );
};

const RECALCULATE_BUDGET_MUTATION = gql(/* GraphQL */ `
  mutation Web_RecalculateBudgetMutation($input: ResetBudgetMutationInput!) {
    resetBudget(input: $input) {
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);

const CLEAR_BUDGET_MUTATION = gql(/* GraphQL */ `
  mutation Web_ClearAllMutation($input: ClearBudgetMutationInput!) {
    clearBudget(input: $input) {
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);

export default BudgetSettingsForm;
