import React, { useMemo } from 'react';

import DateRangePickerPopover from 'components/lib/dates/DateRangePickerPopover';
import WithIndicatorContainer from 'components/lib/ui/WithIndicatorContainer';

import { DEFAULT_SHORTCUTS } from 'common/lib/dateRange';
import { formatDateRange } from 'common/utils/date';

import type DateRange from 'common/types/DateRange';

type Props = {
  dateRange: DateRange;
  defaultRange: DateRange;
  requiredBoundedRange?: boolean;
  onChangeDateRange: (dateRange: DateRange) => void;
};

const TransactionDateRangeButton: React.FC<Props> = ({
  dateRange,
  defaultRange,
  requiredBoundedRange,
  onChangeDateRange,
}) => {
  const { startDate, endDate } = dateRange;

  const isDifferentFromDefaultRange = useMemo(
    () =>
      defaultRange
        ? startDate !== defaultRange.startDate || endDate !== defaultRange.endDate
        : false,
    [startDate, endDate, defaultRange],
  );

  const selectedShortcut = useMemo(
    () =>
      DEFAULT_SHORTCUTS.find(
        ({ startDate: shortcutStartDate, endDate: shortcutEndDate }) =>
          startDate === shortcutStartDate && endDate === shortcutEndDate,
      ),
    [startDate, endDate],
  );

  const formattedValue = useMemo(() => {
    if (selectedShortcut) {
      return selectedShortcut.title;
    }

    if (!startDate && !endDate) {
      return 'Date';
    }

    return formatDateRange(startDate, endDate);
  }, [startDate, endDate, selectedShortcut]);

  return (
    <WithIndicatorContainer show={isDifferentFromDefaultRange}>
      <DateRangePickerPopover
        dateRange={dateRange}
        onChange={({ startDate, endDate }) => onChangeDateRange({ startDate, endDate })}
        onClear={() => onChangeDateRange({ startDate: undefined, endDate: undefined })}
        value={formattedValue}
        requiredBoundedRange={requiredBoundedRange}
      />
    </WithIndicatorContainer>
  );
};

export default TransactionDateRangeButton;
