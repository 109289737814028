import { gql } from '@apollo/client';
import pluralize from 'pluralize';
import * as R from 'ramda';
import type { SyntheticEvent } from 'react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import TextInput from 'components/lib/form/TextInput';
import SettingsCard from 'components/lib/layouts/SettingsCard';
import Column from 'components/lib/ui/Column';
import DropdownMenu, { DropdownMenuItem } from 'components/lib/ui/DropdownMenu';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import { OverlayTrigger } from 'components/lib/ui/popover';
import MerchantList from 'components/settings/merchants/MerchantList';

import useDebounce from 'common/lib/hooks/useDebounce';
import useQuery from 'common/lib/hooks/useQuery';
import { mergeNextPage } from 'common/utils/pagination';
import HighlightTextContext from 'lib/contexts/HighlightTextContext';
import usePersistentFilter from 'lib/hooks/usePersistentFilter';

import { TYPEAHEAD_DEBOUNCE_TIME_MS } from 'common/constants/form';
import { ORDER_OPTIONS } from 'common/constants/merchants';
import { MERCHANT_SETTINGS_INTRO } from 'constants/copy';

import type {
  Web_GetMerchantSettingsPage,
  Web_GetMerchantSettingsPageVariables,
} from 'common/generated/graphQlTypes/Web_GetMerchantSettingsPage';
import type { MerchantOrdering } from 'common/generated/graphQlTypes/globalTypes';

const IntroContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

const Header = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge} ${({ theme }) => theme.spacing.xlarge}
    ${({ theme }) => theme.spacing.default};
  padding-top: ${({ theme }) => theme.spacing.default};
`;

const StyledDropdownContainer = styled.div`
  flex: 1;
`;

const StyledTextInput = styled(TextInput)`
  flex: 1;
  height: 40px; /* Align it with the dropdown menu */
  min-width: 240px;
`;

const OptionsContainer = styled(FlexContainer).attrs({ justifyBetween: true, alignCenter: true })`
  gap: ${({ theme }) => theme.spacing.xlarge};
`;

const MerchantCountText = styled(Text).attrs({ weight: 'medium', size: 'large' })`
  display: inline-block;
  margin-top: ${({ theme }) => theme.spacing.default};
`;

const ResetButton = styled(DefaultButton)`
  display: inline-block;
  transform: translateY(-1px);
  margin-left: ${({ theme }) => theme.spacing.small};
`;

const MerchantSettings = () => {
  const { activeFilters, hasFilterChanged, updateFilter, resetFilter } =
    usePersistentFilter('merchants');
  const { order: orderBy, search: searchValue } = activeFilters ?? {};
  const [searchInputValue, setSearchInputValue] = useState(searchValue ?? '');
  const debouncedSearchValue = useDebounce(searchValue, TYPEAHEAD_DEBOUNCE_TIME_MS);

  useEffect(() => {
    if (searchValue) {
      setSearchInputValue(searchValue);
    }
  }, [searchValue]);

  useEffect(() => {
    updateFilter({ search: debouncedSearchValue });
  }, [debouncedSearchValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const { data, isLoadingInitialData, refetch, fetchMore } = useQuery<
    Web_GetMerchantSettingsPage,
    Web_GetMerchantSettingsPageVariables
  >(GET_MERCHANTS_QUERY, {
    variables: {
      search: debouncedSearchValue,
      orderBy,
    },
  });

  const merchants = useMemo(() => data?.merchants ?? [], [data?.merchants]);
  const { merchantCount: maybeMerchantCount } = data ?? {};
  const merchantCount = maybeMerchantCount ?? 0;

  const onFetchMore = useCallback(
    () =>
      fetchMore({
        variables: { offset: merchants.length, orderBy },
        updateQuery: (prev, { fetchMoreResult }) =>
          R.evolve(
            {
              merchants: mergeNextPage(fetchMoreResult?.merchants),
            },
            prev,
          ),
      }),
    [fetchMore, merchants, orderBy],
  );

  const activeOrderBy = ORDER_OPTIONS.find(({ value }) => value === orderBy);

  return (
    <Column md={9}>
      <SettingsCard title="Merchants">
        <Helmet>
          <title>Merchants</title>
        </Helmet>
        <Header>
          <IntroContainer>
            <Text>{MERCHANT_SETTINGS_INTRO}</Text>
          </IntroContainer>

          <OptionsContainer>
            <OrderByDropdown
              activeLabel={activeOrderBy?.label ?? 'Order by'}
              onClickOption={(value) => updateFilter({ order: value })}
            />
            <div>
              <StyledTextInput
                name="merchant"
                placeholder={
                  merchantCount
                    ? `Search ${merchantCount.toLocaleString()} ${pluralize(
                        'merchant',
                        merchantCount,
                      )}...`
                    : 'Search for a merchant...'
                }
                value={searchValue}
                onChange={(e: SyntheticEvent<HTMLInputElement>) => {
                  const target = e.target as HTMLInputElement;
                  updateFilter({ search: target.value });
                }}
              />
              {hasFilterChanged && (
                <ResetButton onClick={() => resetFilter()}>Reset filter</ResetButton>
              )}
            </div>
          </OptionsContainer>
          {merchantCount > 0 && (
            <MerchantCountText>
              {merchantCount.toLocaleString()} {pluralize('Merchant', merchantCount)}
            </MerchantCountText>
          )}
        </Header>
        <HighlightTextContext.Provider
          value={{ searchWords: debouncedSearchValue ? [debouncedSearchValue] : [] }}
        >
          <MerchantList
            merchants={merchants}
            isLoading={isLoadingInitialData}
            refetchMerchants={refetch}
            onFetchMore={onFetchMore}
            hideFooter={merchants.length + 1 >= merchantCount || searchInputValue.length > 0}
            search={searchInputValue}
          />
        </HighlightTextContext.Provider>
      </SettingsCard>
    </Column>
  );
};

type OrderByDropdownProps = {
  activeLabel: string;
  onClickOption: (option: MerchantOrdering) => void;
};

const OrderByDropdown = ({ activeLabel, onClickOption }: OrderByDropdownProps) => (
  <StyledDropdownContainer>
    <OverlayTrigger
      placement="bottom-start"
      overlay={
        <DropdownMenu>
          {ORDER_OPTIONS.map(({ value, label }) => (
            <DropdownMenuItem
              key={value}
              onClick={() => onClickOption(value)}
              selected={label === activeLabel}
            >
              {label}
            </DropdownMenuItem>
          ))}
        </DropdownMenu>
      }
    >
      {({ toggleOpen, isOpen }) => (
        <DefaultButton onClick={toggleOpen} active={isOpen}>
          <span>{activeLabel}</span>
          <ButtonIcon name="chevron-down" />
        </DefaultButton>
      )}
    </OverlayTrigger>
  </StyledDropdownContainer>
);

const GET_MERCHANTS_QUERY = gql`
  query Web_GetMerchantSettingsPage($offset: Int, $orderBy: MerchantOrdering, $search: String) {
    merchants(offset: $offset, orderBy: $orderBy, search: $search) {
      id
      name
      transactionCount
      createdAt
      logoUrl
      recurringTransactionStream {
        id
      }
    }
    merchantCount
  }
`;

export default MerchantSettings;
