import * as R from 'ramda';
import storage from 'redux-persist/lib/storage';
import { createReducer } from 'typesafe-actions';

import {
  togglePlanGroupCollapsed,
  toggleUnplannedExpandedForGroup,
  toggleExpandedGroupRow,
  togglePlanSectionCollapsed,
} from 'actions';
import type { PlanSectionType } from 'lib/plan/Adapters';

export const planPersistConfig = {
  key: 'plan',
  storage,
  whitelist: ['collapsedGroups', 'collapsedSections'],
};

const INITIAL_STATE: PlanState = {
  collapsedGroups: {},
  collapsedSections: {},
  isUnplannedExpandedByGroup: {},
  expandedGroupRows: {},
};

export type PlanState = {
  collapsedGroups: { [id: string]: boolean };
  collapsedSections: { [sectionType in PlanSectionType]?: boolean };
  isUnplannedExpandedByGroup: { [id: string]: boolean };
  expandedGroupRows: { [id: string]: boolean };
};

const plan = createReducer<PlanState>(INITIAL_STATE)
  .handleAction(togglePlanGroupCollapsed, (state, { payload: groupId }) =>
    R.evolve(
      {
        collapsedGroups: (prev) => ({
          ...prev,
          [groupId]: !prev[groupId],
        }),
      },
      state,
    ),
  )
  .handleAction(togglePlanSectionCollapsed, (state, { payload: sectionType }) => ({
    ...state,
    collapsedSections: {
      ...state.collapsedSections,
      [sectionType]: !state.collapsedSections[sectionType],
    },
  }))
  .handleAction(toggleUnplannedExpandedForGroup, (state, { payload: groupId }) =>
    R.evolve(
      {
        isUnplannedExpandedByGroup: (prev) => ({
          ...prev,
          [groupId]: !prev[groupId],
        }),
      },
      state,
    ),
  )
  .handleAction(toggleExpandedGroupRow, (state, { payload: groupId }) =>
    R.evolve(
      {
        expandedGroupRows: (prev) => ({
          ...prev,
          [groupId]: !prev[groupId],
        }),
      },
      state,
    ),
  );

export default plan;
