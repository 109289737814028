import * as R from 'ramda';
import { useMemo } from 'react';

import useQuery from 'common/lib/hooks/useQuery';
import { shuffleArrayWithFixedLastOption } from 'common/utils/Random';

import { gql } from 'common/generated/gql';
import { CancellationReason, CancellationSubreason } from 'common/generated/graphql';

const useGetCancelSubscriptionOptions = () => {
  const { data } = useQuery(QUERY_CANCELLATION_REASONS);
  // useMemo is important here so it keeps the order if the user
  // selects a reason and then changes it.
  const cancellationReasons = useMemo(
    () =>
      shuffleArrayWithFixedLastOption(
        data?.cancellationReasons ?? [],
        CancellationReason.OTHER,
        'reason',
      ),
    [data],
  );

  // creating a map so it gets easier to access it with getSelectedReasonData
  const cancellationReasonsMap = R.indexBy(R.prop('reason') || '', cancellationReasons ?? []);

  const cancellationReasonsSelectOptions = cancellationReasons?.map((reason) => ({
    value: reason.reason,
    label: reason.label,
  }));

  // get the selected reason data and shuffle the subreasons
  // it assumes it contains a "CancellatioNSubreason.NONE"
  // If it doesn't have it, the shuffleArrayWithFixedLastOption fn will shuffle all the options
  const getSelectedReasonData = (reason: string) => ({
    ...cancellationReasonsMap[reason],
    subreasons: shuffleArrayWithFixedLastOption(
      cancellationReasonsMap[reason]?.subreasons ?? [],
      CancellationSubreason.NONE,
      'value',
    ),
  });

  return { cancellationReasonsSelectOptions, getSelectedReasonData };
};

const QUERY_CANCELLATION_REASONS = gql(`
  query Web_GetCancellationReasonsWithSubreasons {
    cancellationReasons {
        reason
        label
        subquestion
        subquestionType
        subreasons {
            value
            label
        }
    }
  }
`);

export default useGetCancelSubscriptionOptions;
