import type { DateTime } from 'luxon';
import { propEq } from 'ramda';

import type { BudgetedRowData } from 'components/plan/onboarding/OnboardingBudgetCardRow';
import { OnboardingFlowStep } from 'components/routes/budget/BudgetOnboardingFlow';

import type { PlanSectionType, GridAmounts, PlanSectionData } from 'lib/plan/Adapters';
import { RowType } from 'lib/plan/Adapters';

import type { BudgetVariability } from 'common/generated/graphql';
import { CategoryGroupType } from 'common/generated/graphql';

export const getGroupsDataWithBudgetedAmounts = (
  gridDisplayData: PlanSectionData[],
  gridAmounts: GridAmounts,
  type: PlanSectionType,
  month: DateTime,
) => {
  const dataForType = gridDisplayData?.find(propEq('type', type));
  const monthString = month.toISODate();

  return (
    dataForType?.groups.map((group) => {
      const groupData = gridAmounts?.[type][group.id];
      const groupBudgetedAmount = groupData.aggregate[monthString]?.budgeted;

      return {
        ...group,
        budgeted: groupBudgetedAmount,
        rows: group.rows
          .filter((row) => !row.excludeFromBudget)
          .map((row) => {
            const rowBudgetedAmount = groupData[row.id][monthString]?.budgeted;

            return {
              ...row,
              budgeted: rowBudgetedAmount,
            };
          }),
      };
    }) ?? []
  );
};

export const getFlexGroupDataWithBudgetedAmounts = (
  gridDisplayData: PlanSectionData[],
  gridAmounts: GridAmounts,
  budgetVariability: BudgetVariability,
  month: DateTime,
) => {
  const groups = getGroupsDataWithBudgetedAmounts(
    gridDisplayData,
    gridAmounts,
    CategoryGroupType.EXPENSE,
    month,
  );
  return groups.filter(propEq('id', budgetVariability));
};

/**
 * Determines if a row is a category group with group-level budgeting enabled.
 * If so, we don't want to show it since its categories will be shown instead.
 */
const isRowGroupWithGroupBudget = (row: BudgetedRowData) =>
  row.rowType === RowType.CategoryGroup && row.groupLevelBudgetingEnabled;

/**
 * Filters out rows that are either excluded from the budget or are category groups with group-level budgeting enabled.
 */
export const filterVisibleRows = (rows: BudgetedRowData[]) =>
  rows.filter((row) => !row.excludeFromBudget && !isRowGroupWithGroupBudget(row));

export const getCurrentStep = (
  step: unknown,
  skipConnectingAccounts: boolean | undefined,
): OnboardingFlowStep => {
  if (isStepValid(step)) {
    return step as OnboardingFlowStep;
  }

  if (skipConnectingAccounts) {
    return OnboardingFlowStep.Income;
  }

  return OnboardingFlowStep.Accounts;
};

function isStepValid(step: unknown): step is OnboardingFlowStep {
  return typeof step === 'string' && step in OnboardingFlowStep;
}
