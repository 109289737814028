import React from 'react';

import type { AbstractFieldProps } from 'components/lib/form/AbstractField';
import AbstractField from 'components/lib/form/AbstractField';
import type { Props as RadioProps } from 'components/lib/form/RadioGroup';
import RadioGroup from 'components/lib/form/RadioGroup';

const RadioGroupField = <ValueT = unknown,>({
  className,
  ...props
}: AbstractFieldProps<RadioProps<ValueT>>) => (
  <AbstractField {...props} className={className}>
    {({ value, setFieldValue }) => (
      <RadioGroup {...props} value={value} onChange={(value) => setFieldValue(props.name, value)} />
    )}
  </AbstractField>
);

export default RadioGroupField;
