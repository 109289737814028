import type { DateTime } from 'luxon';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import { DynamicPageTitle } from 'components/lib/ui/Page';
import RecurringAllList from 'components/recurring/list/RecurringAllList';
import RecurringListLoading from 'components/recurring/list/RecurringListLoading';

import { toggleCollapsedRecurringGroup } from 'actions';
import useQuery from 'common/lib/hooks/useQuery';
import { getFrequencyLabel, GroupByValues, splitActiveCanceledItems } from 'common/lib/recurring';
import { getRecurringFilters } from 'common/lib/recurring/filters';
import type { AllRecurringStream } from 'common/lib/recurring/types';
import isV2Theme from 'common/lib/theme/isV2Theme';
import type { RecurringFilters } from 'state/recurring/reducer';
import { getCollapsedGroupsMap } from 'state/recurring/selectors';

import { gql } from 'common/generated/gql';
import type { Web_GetAllRecurringTransactionItemsQuery } from 'common/generated/graphql';
import type { ElementOf } from 'common/types/utility';

type RecurringTransactionStream = ElementOf<
  Web_GetAllRecurringTransactionItemsQuery,
  'recurringTransactionStreams'
>;

const Root = styled(FlexContainer).attrs({ column: true })`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.gutter} ${({ theme }) => theme.spacing.gutter} 0;
  margin-bottom: ${({ theme }) => theme.spacing.gutter};
  gap: ${({ theme }) => theme.spacing.gutter};
  ${isV2Theme(css`
    padding-top: 0;
  `)}
`;

type Props = {
  startDate: DateTime;
  filters: RecurringFilters;
};

const RecurringAll = ({ filters, startDate }: Props) => {
  const dispatch = useDispatch();
  const collapsedGroupsMap = useSelector(getCollapsedGroupsMap);
  const toggleCollapsed = useCallback((key: string) => {
    dispatch(toggleCollapsedRecurringGroup(key));
  }, []);

  const { data, isLoadingInitialData, refetch } = useQuery(RECURRING_ALL_QUERY, {
    variables: {
      filters: getRecurringFilters(filters),
      includeLiabilities: true,
    },
  });
  const streams = R.pathOr<RecurringTransactionStream[]>([], ['recurringTransactionStreams'], data);

  const groupBy = filters.groupBy ?? GroupByValues.Type;

  const sortedGroups = useMemo(() => {
    const { active } = splitActiveCanceledItems(streams);

    const filteredStreams = groupBy === GroupByValues.Type ? streams : active;

    const groups = R.groupBy((item: AllRecurringStream) => {
      if (groupBy === GroupByValues.Category) {
        if (!item?.category) {
          return 'No category';
        }
        return `${item?.category?.icon} ${item?.category?.name}`;
      }

      if (groupBy === GroupByValues.Frequency) {
        return getFrequencyLabel(item.stream?.frequency);
      }

      return item.stream.isActive ? 'Active' : 'Canceled';
    }, filteredStreams);

    const nonEmptyGroups = R.filter(RA.propNotEq(0, ''), R.toPairs(groups));
    return R.sortWith([R.ascend(([key, _]) => key)])(nonEmptyGroups);
  }, [groupBy, streams]);

  return (
    <>
      <DynamicPageTitle>All</DynamicPageTitle>
      <Root>
        {isLoadingInitialData && <RecurringListLoading />}

        {sortedGroups.map(([key, value], index) => (
          <RecurringAllList
            key={index}
            title={key}
            startDate={startDate}
            isLoading={isLoadingInitialData}
            items={value}
            refetch={refetch}
            isCollapsed={collapsedGroupsMap?.[key]}
            toggleCollapsed={() => toggleCollapsed(key)}
          />
        ))}
      </Root>
    </>
  );
};

const RECURRING_ALL_QUERY = gql(/* GraphQL */ `
  query Web_GetAllRecurringTransactionItems(
    $filters: RecurringTransactionFilter
    $includeLiabilities: Boolean
  ) {
    recurringTransactionStreams(filters: $filters, includeLiabilities: $includeLiabilities) {
      stream {
        id
        frequency
        isActive
        isApproximate
        name
        logoUrl
        merchant {
          id
        }
        creditReportLiabilityAccount {
          id
          account {
            id
          }
        }
      }
      nextForecastedTransaction {
        date
        amount
      }
      category {
        id
        name
        icon
      }
      account {
        id
        displayName
        icon
        logoUrl
      }
    }
  }
`);

export default RecurringAll;
