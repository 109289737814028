import * as React from 'react';
import styled, { css } from 'styled-components';

import { useDraggableContext } from 'components/lib/dnd/DraggableContext';
import Card from 'components/lib/ui/Card';
import CardTitle from 'components/lib/ui/CardTitle';
import DragDots from 'components/lib/ui/DragDots';
import Flex from 'components/lib/ui/Flex';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LinkArrow from 'components/lib/ui/LinkArrow';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import NavLink from 'components/lib/ui/link/NavLink';

import { fontSize, spacing } from 'common/lib/theme/dynamic';
import isV2Theme from 'common/lib/theme/isV2Theme';
import variables from 'common/lib/theme/variables';
import useIsV2Theme from 'lib/hooks/useIsV2Theme';

type Props = {
  title: string;
  description?: React.ReactNode;
  headerRight?: React.ReactNode;
  children?: React.ReactNode;
  loading?: boolean;
  minHeightPx?: number;
  headerLink: string;
  showHeaderBorder?: boolean;
  hideHeader?: boolean;
  tooltip?: React.ReactNode;
  className?: string;
};

const Root = styled(({ minHeightPx, ...props }) => <Card {...props} />)<{ minHeightPx?: number }>`
  min-height: ${({ minHeightPx }) => minHeightPx ?? 0}px;
  position: relative;
`;

const Loading = styled(FlexContainer)`
  margin: ${({ theme }) => theme.spacing.xlarge};
`;

const Header = styled(FlexContainer)<{ showBorder: boolean }>`
  padding: ${spacing.large} ${spacing.large} ${spacing.default} ${spacing.xlarge};

  ${({ showBorder, theme }) =>
    showBorder &&
    css`
      border-bottom: 1px solid ${theme.color.grayBackground};
    `}

  ${isV2Theme(css`
    padding: ${spacing.small};
    padding-left: ${spacing.large};
    min-height: 56px;
  `)}
`;

const HeaderClickable = styled(NavLink)<{ tooltip: React.ReactNode }>`
  ${({ tooltip }) =>
    !tooltip &&
    `
    flex: 1;
  `}

  ${isV2Theme(css<{ $column?: boolean }>`
    display: flex;
    gap: 1px;
    flex-direction: column;
    align-items: stretch;

    @media (min-width: ${({ theme }) => theme.breakPoints.xl}px) {
      flex-direction: row;
      align-items: center;
      gap: ${spacing.xsmall};
    }
  `)}
`;

const HeaderRight = styled(Flex)`
  margin-left: ${spacing.default};
  align-items: ${isV2Theme('center', 'flex-start')};
`;

const Title = styled(CardTitle).attrs({ $largeWhenV2Enabled: true })`
  display: flex;
  align-items: center;

  ${HeaderClickable}:hover & {
    color: ${({ theme }) => theme.color.blue};
  }
`;

export const Description = styled.div`
  font-size: 20px;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 150%;
  margin-top: ${({ theme }) => theme.spacing.xxsmall};

  ${isV2Theme(css`
    margin-top: 1px;
    font-size: ${fontSize.base};
    color: ${variables.color.content.secondary};
  `)}
`;

const StyledDragDots = styled(DragDots)`
  position: absolute;
  top: ${isV2Theme('unset', '26px')};
  left: 0;
  cursor: move;
  transition: ${({ theme }) => theme.transition.default};
  opacity: 0;

  ${Root}:hover & {
    opacity: 1;
  }
`;

const DragHandle = () => {
  const { dragHandleProps } = useDraggableContext();
  return <StyledDragDots {...dragHandleProps} />;
};

const DashboardWidget = ({
  title,
  description,
  headerRight,
  children,
  loading = false,
  minHeightPx,
  headerLink,
  showHeaderBorder = true,
  hideHeader = false,
  tooltip,
  className,
}: Props) => {
  const isV2Theme = useIsV2Theme();

  return (
    <Root className={className} minHeightPx={minHeightPx}>
      {!hideHeader && (
        <Header showBorder={showHeaderBorder}>
          <HeaderClickable to={headerLink} tooltip={tooltip}>
            <DragHandle />
            <Title>
              {title} {!isV2Theme && <LinkArrow hoverTarget={HeaderClickable} />}
            </Title>
            {!!description && <Description>{description}</Description>}
          </HeaderClickable>
          {tooltip}
          {!!headerRight && <HeaderRight justifyEnd>{headerRight}</HeaderRight>}
        </Header>
      )}
      {loading ? (
        <Loading center>
          <LoadingSpinner />
        </Loading>
      ) : (
        children
      )}
    </Root>
  );
};

export default DashboardWidget;
