// THIS FILE IS GENERATED, DO NOT MODIFY MANUALLY. ##checksum##: 3653317406
import { createAction } from 'typesafe-actions';

import type { PlanSectionType } from 'lib/plan/Adapters';

export const toggleExpandedGroupRow = createAction('PLAN/TOGGLE_EXPANDED_GROUP_ROW')<string>();

export const togglePlanGroupCollapsed = createAction('PLAN/TOGGLE_PLAN_GROUP_COLLAPSED')<string>();

export const togglePlanSectionCollapsed = createAction(
  'PLAN/TOGGLE_PLAN_SECTION_COLLAPSED',
)<PlanSectionType>();

export const toggleUnplannedExpandedForGroup = createAction(
  'PLAN/TOGGLE_UNPLANNED_EXPANDED_FOR_GROUP',
)<string>();
