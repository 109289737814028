import styled from 'styled-components';

import isV2Theme from 'common/lib/theme/isV2Theme';

import avatarV2 from 'static/images/avatar-v2@2x.png';
import avatarUrl from 'static/images/avatar@2x.png';

const DEFAULT_SIZE_PX = 24;
const DEFAULT_AVATAR = isV2Theme(avatarV2, avatarUrl);

const Avatar = styled.div.attrs({
  referrerpolicy: 'no-referrer', // needed for Google
})<{
  $size?: number;
  $url?: Maybe<string>;
}>`
  height: ${({ $size }) => $size ?? DEFAULT_SIZE_PX}px;
  width: ${({ $size }) => $size ?? DEFAULT_SIZE_PX}px;
  border-radius: ${({ theme }) => theme.radius.round};
  background: ${({ $url, theme }) => `url('${$url ?? DEFAULT_AVATAR({ theme })}')`};
  background-size: contain;
`;

export default Avatar;
