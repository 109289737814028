import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React from 'react';
import styled from 'styled-components';

import Dot from 'components/lib/ui/Dot';
import DropdownMenu, { DropdownMenuItem } from 'components/lib/ui/DropdownMenu';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import { OverlayTrigger, Popover } from 'components/lib/ui/popover';
import RecurringFilters from 'components/recurring/RecurringFilters';

import { GroupByValues } from 'common/lib/recurring';
import useRouteMatch from 'lib/hooks/useRouteMatch';
import useTheme from 'lib/hooks/useTheme';
import type { RecurringFilters as RecurringFiltersType } from 'state/recurring/reducer';

import routes from 'constants/routes';

const ACTIVE_FILTER_INDICATOR_DOT_SIZE_PX_BIG = 12;

const StyledPopover = styled(Popover)`
  border: 1px solid ${({ theme }) => theme.color.grayFocus};
  border-radius: ${({ theme }) => theme.radius.medium};
`;

const Divider = styled.div`
  height: 16px;
  width: 1px;
  background-color: ${({ theme }) => theme.color.gray};
  margin-right: ${({ theme }) => theme.spacing.small};
`;

const StyledIcon = styled(Icon)`
  margin-top: ${({ theme }) => theme.spacing.xxxsmall};
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

const FilterButton = styled(DefaultButton).attrs({ size: 'small' })`
  position: relative;
  margin-right: ${({ theme }) => theme.spacing.small};
`;

const ActiveFilterIndicator = styled(Dot)<{
  $right?: number;
  $top?: number;
}>`
  position: absolute;
  right: ${({ $right = 28 }) => $right ?? 0}px;
  top: ${({ $top = 6 }) => $top ?? 0}px;
`;

const FiltersLabel = styled.span``;

type Props = {
  filters?: RecurringFiltersType;
  onChangeFilters: (filters: RecurringFiltersType) => void;
  onClickAddRecurring: () => void;
};

const RecurringHeaderControls = ({ filters, onChangeFilters, onClickAddRecurring }: Props) => {
  const theme = useTheme();

  const allRouteMatch = useRouteMatch(routes.recurring.all);

  return (
    <FlexContainer alignCenter justifyEnd>
      {allRouteMatch ? (
        <OverlayTrigger
          placement="bottom-end"
          closeOnEscape={false}
          overlay={({ close, toggleOpen }) => (
            <DropdownMenu>
              <DropdownMenuItem
                onClick={() =>
                  onChangeFilters({
                    ...filters,
                    groupBy: GroupByValues.Type,
                  })
                }
              >
                By status
              </DropdownMenuItem>

              <DropdownMenuItem
                onClick={() =>
                  onChangeFilters({
                    ...filters,
                    groupBy: GroupByValues.Category,
                  })
                }
              >
                By category
              </DropdownMenuItem>

              <DropdownMenuItem
                onClick={() =>
                  onChangeFilters({
                    ...filters,
                    groupBy: GroupByValues.Frequency,
                  })
                }
              >
                By frequency
              </DropdownMenuItem>
            </DropdownMenu>
          )}
        >
          {({ toggleOpen, isOpen }) => (
            <FilterButton onClick={toggleOpen} active={isOpen}>
              By {filters?.groupBy === 'type' ? 'status' : filters?.groupBy ?? 'status'}
            </FilterButton>
          )}
        </OverlayTrigger>
      ) : null}

      <OverlayTrigger
        placement="bottom-end"
        closeOnEscape={false}
        overlay={({ close, toggleOpen }) => (
          <StyledPopover>
            <RecurringFilters toggleOpen={toggleOpen} />
          </StyledPopover>
        )}
      >
        {({ toggleOpen, isOpen }) => (
          <FilterButton onClick={toggleOpen} active={isOpen}>
            <ButtonIcon name="filter" />
            <FiltersLabel>Filters</FiltersLabel>

            {RA.isNotNilOrEmpty(R.omit(['groupBy'], filters)) && (
              <ActiveFilterIndicator
                color={theme.color.orange}
                $right={-4}
                $top={-4}
                size={ACTIVE_FILTER_INDICATOR_DOT_SIZE_PX_BIG}
              />
            )}
          </FilterButton>
        )}
      </OverlayTrigger>
      <Divider />
      <PrimaryButton onClick={onClickAddRecurring}>
        <StyledIcon name="settings" size={14} />
        Manage recurring
      </PrimaryButton>
    </FlexContainer>
  );
};

export default RecurringHeaderControls;
