import { useMutation } from '@apollo/client';
import type { DateTime } from 'luxon';
import React from 'react';
import styled from 'styled-components';

import Form from 'components/lib/form/Form';
import type { FormSubmitButtonProps } from 'components/lib/form/FormSubmitButton';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';

import { CREATE_BUDGET_MUTATION } from 'common/lib/graphQl/budgets';

import { BudgetTimeframeInput } from 'common/generated/graphql';

type Props = {
  date: DateTime;
  onCompleted: () => void;
} & Omit<FormSubmitButtonProps, 'children' | 'disableWhenValuesUnchanged'>;

const StyledSubmitButton = styled(FormSubmitButton)`
  margin: 0px auto;
`;

const CreateBudgetButton = ({ date, onCompleted, ...props }: Props) => {
  const [createBudget] = useMutation(CREATE_BUDGET_MUTATION);

  const formValue = {
    startDate: date.toISODate(),
    timeframe: BudgetTimeframeInput.MONTH,
  };

  return (
    <Form mutation={createBudget} initialValues={formValue} onSubmitSuccess={onCompleted}>
      <StyledSubmitButton disableWhenValuesUnchanged={false} {...props}>
        Create my budget
      </StyledSubmitButton>
    </Form>
  );
};

export default CreateBudgetButton;
