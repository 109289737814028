import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useFormContext } from 'common/components/form/FormContext';
import SelectField from 'components/lib/form/SelectField';
import TextField from 'components/lib/form/TextField';
import Text from 'components/lib/ui/Text';

import { track } from 'lib/analytics/segment';
import useGetCancelSubscriptionOptions from 'lib/hooks/billing/useGetCancelSubscriptionOptions';

import { CancelFlowEventNames } from 'common/constants/analytics';
import { CANCEL_SUBSCRIPTION_MODAL_SUBTITLE } from 'common/constants/copy';

import type {
  CancellationReason,
  CancellationReasonWithSubreason,
  CancellationSubreason,
} from 'common/generated/graphql';
import { CancellationSubquestionType } from 'common/generated/graphql';

const CardBody = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};
`;

const MainText = styled(Text)`
  display: inline-block;
  margin-bottom: ${({ theme }) => theme.spacing.xlarge};
`;

type CancelSubscriptionReasonForm = {
  reason: CancellationReason;
  subreason: CancellationSubreason;
};

/**
 * This is part of the new cancellation flow which is behind the feature flag: web-cancel-discount-flow
 */
const CancelSubscriptionReasonModalFormFields = () => {
  const [userSelectedReason, setUserSelectedReason] = useState<
    CancellationReasonWithSubreason | undefined
  >(undefined);

  const { cancellationReasonsSelectOptions, getSelectedReasonData } =
    useGetCancelSubscriptionOptions();

  const isFollowUpQuestionSelect =
    userSelectedReason?.subquestionType === CancellationSubquestionType.OPTION;

  const isFollowUpQuestionFreeText =
    userSelectedReason?.subquestionType === CancellationSubquestionType.FREE_TEXT;

  const { values, setFieldValue, getFieldMeta } = useFormContext<CancelSubscriptionReasonForm>();

  const subreason = getFieldMeta('subreason');
  const reason = getFieldMeta('reason');

  useEffect(() => {
    // resets the subreason everytime the reason is updated
    const selectedReason = getSelectedReasonData(values.reason);

    // logs if user is reselecting a reason
    const previousReason = userSelectedReason?.reason;
    if (previousReason) {
      track(CancelFlowEventNames.CancelReasonReselected, {
        previousReason,
        reason: reason.value,
      });
    }

    // this is a workaround to prevent the subreason from displaying an outdated value based
    // on the previous reason selected

    // if there is a subreason filled from the previous reason, selects the first option if there are options
    // it covers the scenario where a user selects a reason with options, selects a subreason and then
    // changes the reason for another reason with options
    if (subreason.value) {
      setFieldValue('subreason', selectedReason?.subreasons?.[0]?.value || '');
    } else {
      setFieldValue('subreason', '');
    }

    setUserSelectedReason(selectedReason);
  }, [values.reason]);

  return (
    <CardBody>
      <MainText>{CANCEL_SUBSCRIPTION_MODAL_SUBTITLE}</MainText>
      <SelectField
        name="reason"
        options={cancellationReasonsSelectOptions}
        placeholder="Select a reason for cancelling your subscription..."
        required
      />

      {isFollowUpQuestionSelect && (
        <SelectField
          name="subreason"
          label={userSelectedReason?.subquestion || ''}
          options={userSelectedReason?.subreasons}
          required
        />
      )}

      {isFollowUpQuestionFreeText && (
        <TextField
          placeholder="Write your answer here"
          name="subreason"
          label={userSelectedReason?.subquestion || ''}
          required
        />
      )}
    </CardBody>
  );
};

export default CancelSubscriptionReasonModalFormFields;
