import clsx from 'clsx';
import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';

import type { Props as CardHeaderProps } from 'components/lib/ui/CardHeader';
import CardHeader from 'components/lib/ui/CardHeader';
import Flex from 'components/lib/ui/Flex';

import boxShadow from 'common/lib/styles/boxShadow';
import variables from 'common/lib/theme/variables';

import type { StyleMixin } from 'common/types/Styles';

const isNotNil = R.complement(R.isNil);

export type CardProps = CardHeaderProps & {
  children?: React.ReactNode;
  className?: string;
  hideHeader?: boolean;
  draggable?: boolean;
};

export const cardDropShadowStyleMixin: StyleMixin = boxShadow.card;

const CardRoot = styled(Flex)`
  background-color: ${variables.color.background.primary};
  border-radius: ${({ theme }) => theme.radius.medium};
  ${cardDropShadowStyleMixin}
`;

const Card: React.ForwardRefRenderFunction<HTMLDivElement, CardProps> = (
  { hideHeader = false, children, className, ...headerProps },
  ref,
) => {
  const shouldShowHeaderProps = R.omit(['hideBottomBorder'], headerProps);
  const showHeader =
    !hideHeader &&
    !R.isEmpty(shouldShowHeaderProps) &&
    Object.values(shouldShowHeaderProps).some(isNotNil);
  return (
    <CardRoot
      column
      ref={ref}
      className={clsx(
        className,
        // Needed to allow styling from parent components
        'card',
      )}
    >
      {showHeader && <CardHeader {...headerProps} />}
      {children}
    </CardRoot>
  );
};

export default React.forwardRef(Card);
