import useQuery from 'common/lib/hooks/useQuery';
import { adaptSelectedOptionsToTransactionsFilters } from 'lib/filters';
import { trackFiltersApplied } from 'lib/filters/sections';
import type { SectionAdapterOptions } from 'lib/filters/types';
import useFilterMenu from 'lib/hooks/useFilterMenu';
import { getFilterSectionsFromQuery } from 'lib/recurring/filters';

import { gql } from 'common/generated/gql';
import type { RecurringTransactionFilter } from 'common/generated/graphql';

type Options<TFilters extends Partial<RecurringTransactionFilter>> = {
  page: string;
  filters: TFilters;
  onApplyFilters: (filters: Partial<RecurringTransactionFilter>) => void;
} & Omit<SectionAdapterOptions, 'hasTransactionsImportedFromMint'>;

/**
 * Hook responsible for managing the state of recurring filters in the FilterMenu component.
 * It doesn't dispatch any actions or mutates anything, it just provides the state and the
 * logic to calculate the filters to update based on the selected options.
 */
export const useRecurringFilterMenu = <TFilters extends Partial<RecurringTransactionFilter>>(
  options: Options<TFilters>,
) => {
  const { page, onApplyFilters, filters, renderAccountLogo } = options;

  const { data, isLoadingInitialData } = useQuery(FILTER_MENU_QUERY, {});

  const { sections, onChangeSections, resetUnappliedChanges, handleApply } = useFilterMenu<
    TFilters,
    typeof data
  >({
    data,
    filters,
    renderAccountLogo,
    getFilterSectionsFromQuery,
    onApplyFilters: (filters, selectedOptions) => {
      onApplyFilters(filters);
      if (selectedOptions) {
        trackFiltersApplied(page, selectedOptions);
      }
    },
    adaptSelectedOptionsToFilters: adaptSelectedOptionsToTransactionsFilters,
  });

  return {
    sections,
    isLoading: isLoadingInitialData,
    onChangeSections,
    resetUnappliedChanges,
    handleApply,
  };
};

export const FILTER_MENU_QUERY = gql(/* GraphQL */ `
  query Web_RecurringFilterQuery {
    categoryGroups {
      id
      name
      order

      categories {
        id
        name
        icon
        order
      }
    }

    accounts {
      id
      displayName
      logoUrl
      icon
      type {
        name
        display
      }
    }
  }
`);

export default useRecurringFilterMenu;
