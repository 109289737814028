import React, { useEffect } from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import BudgetOnboardingPage from 'components/plan/onboarding/BudgetOnboardingPage';
import OnboardingBudgetList from 'components/plan/onboarding/OnboardingBudgetList';
import OnboardingGroupFooter from 'components/plan/onboarding/OnboardingGroupFooter';
import type { OnboardingSharedPageProps } from 'components/routes/budget/BudgetOnboardingFlow';

import { getBudgetedAmountForType } from 'common/lib/budget/Adapters';
import { useBudgetOnboardingActions } from 'common/lib/hooks/budget/useBudgetOnboardingActions';
import { spacing } from 'common/lib/theme/dynamic';
import { getGroupsDataWithBudgetedAmounts } from 'lib/budget/onboardingAdapters';
import usePlanAdapter from 'lib/hooks/plan/usePlanAdapter';
import usePlanQuery from 'lib/hooks/plan/usePlanQuery';
import usePlanState from 'lib/hooks/plan/usePlanState';

import { CategoryGroupType } from 'common/generated/graphql';

const StyledLoadingSpinner = styled(LoadingSpinner)`
  margin-top: ${spacing.xlarge};
`;

type Props = {
  onBack: () => void;
  onNext: () => void;
  onCancel: () => void;
} & OnboardingSharedPageProps;

const OnboardingIncome = ({ title, description, progress, onBack, onNext, onCancel }: Props) => {
  const { safeRecalculateIncomeBudget, isLoading: isMutationLoading } =
    useBudgetOnboardingActions();

  const [state] = usePlanState();
  const { data, fetchedDateRange, updateCellValue, refetch, isRefetching } = usePlanQuery(state, {
    debounceRefetch: false,
  });

  const { isLoadingInitialData, gridDisplayData, gridAmounts, budgetSummaryData } = usePlanAdapter(
    data,
    state,
    fetchedDateRange,
  );

  // gridDisplayData has the presentational info that we need, but gridAmounts has the budgeted amounts.
  const incomeDataWithAmounts = getGroupsDataWithBudgetedAmounts(
    gridDisplayData,
    gridAmounts,
    CategoryGroupType.INCOME,
    state.thisMonth,
  );

  const incomeAmount = getBudgetedAmountForType(budgetSummaryData, CategoryGroupType.INCOME);

  useEffect(() => {
    safeRecalculateIncomeBudget();
  }, []);

  return (
    <BudgetOnboardingPage
      pageName="Budget Onboarding Income"
      title={title}
      description={description}
      progress={progress}
      onClickBack={onBack}
      onClickNext={onNext}
      onClickCancel={onCancel}
    >
      <FlexContainer center column>
        {isLoadingInitialData || isMutationLoading ? (
          <StyledLoadingSpinner />
        ) : (
          <OnboardingBudgetList
            data={incomeDataWithAmounts}
            groupType={CategoryGroupType.INCOME}
            thisMonth={state.thisMonth}
            footer={
              <OnboardingGroupFooter
                emphasis
                title="Total budgeted income"
                value={incomeAmount}
                isLoading={isRefetching}
              />
            }
            updateBudgetedValue={updateCellValue}
            refetch={refetch}
          />
        )}
      </FlexContainer>
    </BudgetOnboardingPage>
  );
};

export default OnboardingIncome;
