import { mapValues } from 'lodash/fp';
import { useParams as _useParams } from 'react-router-dom';

const useParams = () => {
  const params: { [param: string]: string } = _useParams();

  const convertParams = mapValues((param: string) => {
    if (param === 'undefined') {
      return undefined;
    } else if (param === 'null') {
      return null;
    }
    return param;
  });

  return convertParams(params);
};

export default useParams;
