import React from 'react';
import styled from 'styled-components';

import FlexItem from 'components/lib/ui/FlexItem';
import NavBarLink from 'components/sidebar/NavBarLink';

import isV2Theme from 'common/lib/theme/isV2Theme';
import variables from 'common/lib/theme/variables';

import routes from 'constants/routes';

const SecondaryNavBarLink = styled(NavBarLink)`
  color: ${isV2Theme(variables.color.content.secondary, undefined)};
`;

type Props = {
  onSupportButtonClick?: () => Promise<void> | void;
};

const SideBarAdvisorPortalContent = ({ onSupportButtonClick }: Props) => (
  <>
    <FlexItem>
      <NavBarLink to={routes.advisorPortal.clients()} exact iconName="home">
        Clients
      </NavBarLink>
      <NavBarLink to={routes.advisorPortal.profile()} exact iconName="user">
        Profile
      </NavBarLink>
      <NavBarLink to={routes.advisorPortal.billing()} exact iconName="credit-card">
        Billing
      </NavBarLink>
      <NavBarLink to={routes.advisorPortal.security()} exact iconName="lock">
        Security
      </NavBarLink>
    </FlexItem>
    <FlexItem grow={1} />
    <FlexItem>
      <SecondaryNavBarLink onClick={onSupportButtonClick} iconName="message-circle">
        Help & Support
      </SecondaryNavBarLink>
    </FlexItem>
  </>
);

export default SideBarAdvisorPortalContent;
