import { isNotEmpty, isNotNil } from 'ramda-adjunct';
import React from 'react';
import styled from 'styled-components';

import Card from 'components/lib/ui/Card';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Text from 'components/lib/ui/Text';
import { TooltipV2 } from 'components/lib/ui/TooltipV2';
import CashFlowCurrency from 'components/lib/ui/currency/CashFlowCurrency';
import type { AmountsTooltipRowProps } from 'components/plan/AmountsTooltip';
import LeftToBudgetTooltip from 'components/plan/onboarding/LeftToBudgetTooltip';

import { compare } from 'common/utils/Math';

import type { Color } from 'types/Styles';

const SPINNER_SIZE_PX = 20;

const Container = styled(Card)<{ $color: Color }>`
  box-shadow: none;
  background: ${({ theme, $color }) => theme.color[$color]};

  padding: ${({ theme }) => theme.spacing.default};
  padding-left: ${({ theme }) => theme.spacing.large};
  padding-right: ${({ theme }) => theme.spacing.xlarge};

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const InfoIcon = styled(Icon).attrs({ name: 'info', size: 12 })`
  margin-left: ${({ theme }) => theme.spacing.xxsmall};
  cursor: default;
`;

const ValueLabel = styled(CashFlowCurrency)`
  font-size: ${({ theme }) => theme.fontSize.base};
`;

type Props = {
  tooltipRows?: AmountsTooltipRowProps[];
  value: number;
  isLoading?: boolean;
};

const LeftToBudgetFooter = ({ tooltipRows, value, isLoading }: Props) => {
  const cardColor = compare<Color>(value, 0, {
    equal: 'grayLightBackground',
    greaterThan: 'greenBackground',
    lessThan: 'redBackground',
  });

  const textColor = compare<Color>(value, 0, {
    equal: 'textLight',
    greaterThan: 'greenText',
    lessThan: 'redText',
  });

  return (
    <Container $color={cardColor}>
      <Text size="base" weight="medium" color={textColor}>
        Left to budget or save
        {isNotNil(tooltipRows) && isNotEmpty(tooltipRows) && (
          <TooltipV2
            side="right"
            content={<LeftToBudgetTooltip leftToBudget={value} rows={tooltipRows} />}
          >
            <FlexContainer inline>
              <InfoIcon />
            </FlexContainer>
          </TooltipV2>
        )}
      </Text>
      {isLoading ? (
        <LoadingSpinner $size={SPINNER_SIZE_PX} />
      ) : (
        <ValueLabel type="income" value={value} bold emphasis round />
      )}
    </Container>
  );
};

export default LeftToBudgetFooter;
