import React from 'react';
import styled from 'styled-components';

import PostBlocksRenderer from 'components/posts/PostBlocksRenderer';

import type { Post } from 'common/lib/posts/types';
import { spacing } from 'common/lib/theme/dynamic';

const Root = styled.div`
  padding: 0 ${spacing.xlarge};
`;

type Props = {
  post: Post;
};

const PostContent = ({ post }: Props) => (
  <Root>
    <PostBlocksRenderer blocks={post.content} />
  </Root>
);

export default PostContent;
