import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { GET_JOINT_PLANNING_DATA } from 'common/lib/graphQl/planning';
import useBudgetOnboardingState from 'common/lib/hooks/budget/useBudgetOnboardingState';
import { getStartOfCurrentMonthISO } from 'common/utils/date';

import { gql } from 'common/generated';
import { BudgetVariability, CategoryType } from 'common/generated/graphql';

export const useBudgetOnboardingActions = () => {
  const {
    skipRecalculateIncomeBudget,
    skipRecalculateExpensesBudget,
    skipRecalculateFixedAndFlexExpensesBudget,
  } = useBudgetOnboardingState();

  const startOfMonthISODate = getStartOfCurrentMonthISO();

  // This should be called before getting to the Flex expenses page (i.e., before the data is fetched)
  // so no need to refetch GET_JOINT_PLANNING_DATA here
  const [maybeInitializeFlexBudget, { loading: isInitializingFlexBudget }] = useMutation(
    INITIALIZE_FLEX_BUDGET_MUTATION,
  );

  const [resetBudget, { loading: isResettingBudget }] = useMutation(RESET_BUDGET_MUTATION, {
    // This query is only used in the web package. We want it the refetch to be completed before this mutation completes so we use awaitRefetchQueries.
    // This doens't affect the mobile usage since if the query is not active (not used by any components) there, refetchQueries is ignored.
    // Refetch queries are not needed on the mobile flow because we call the methods before rendering the screen where the generated data is displayed.
    refetchQueries: [GET_JOINT_PLANNING_DATA],
    awaitRefetchQueries: true,
  });

  const safeRecalculateExpensesBudget = useCallback(() => {
    if (skipRecalculateExpensesBudget === true) {
      return;
    }

    // Reset the expense budgets for the month, but don't overwrite existing budgets
    return resetBudget({
      variables: {
        input: {
          filters: {
            categoryType: CategoryType.EXPENSE,
            budgetVariability: undefined,
            categoryIds: undefined,
          },
          startDate: startOfMonthISODate,
          overwriteExisting: false,
        },
      },
    });
  }, [resetBudget, startOfMonthISODate, skipRecalculateExpensesBudget]);

  const safeRecalculateIncomeBudget = useCallback(() => {
    if (skipRecalculateIncomeBudget === true) {
      return;
    }

    // Initialize the income budget if it doesn't exist (we won't overwrite existing budgets)
    return resetBudget({
      variables: {
        input: {
          startDate: startOfMonthISODate,
          filters: {
            categoryType: CategoryType.INCOME,
            budgetVariability: undefined,
            categoryIds: undefined,
          },
          overwriteExisting: false,
        },
      },
    });
  }, [resetBudget, startOfMonthISODate, skipRecalculateIncomeBudget]);

  const safeRecalculateFixedAndFlexExpensesBudget = useCallback(() => {
    if (skipRecalculateFixedAndFlexExpensesBudget === true) {
      return;
    }

    return Promise.all([
      resetBudget({
        variables: {
          input: {
            overwriteExisting: false,
            startDate: startOfMonthISODate,
            filters: {
              categoryType: CategoryType.EXPENSE,
              budgetVariability: BudgetVariability.FIXED,
              categoryIds: undefined,
            },
          },
        },
      }),
      maybeInitializeFlexBudget({
        variables: {
          input: {
            startDate: startOfMonthISODate,
          },
        },
      }),
    ]);
  }, [
    resetBudget,
    maybeInitializeFlexBudget,
    startOfMonthISODate,
    skipRecalculateFixedAndFlexExpensesBudget,
  ]);

  return {
    safeRecalculateExpensesBudget,
    safeRecalculateIncomeBudget,
    safeRecalculateFixedAndFlexExpensesBudget,
    isLoading: isInitializingFlexBudget || isResettingBudget,
  };
};

export const INITIALIZE_FLEX_BUDGET_MUTATION = gql(/* GraphQL */ `
  mutation Common_InitializeFlexBudget($input: InitializeFlexBudgetMutationInput!) {
    initializeFlexBudget(input: $input) {
      budgetItem {
        id
        plannedCashFlowAmount
        budgetTarget
      }
    }
  }
`);

export const RESET_BUDGET_MUTATION = gql(/* GraphQL */ `
  mutation Common_ResetBudget($input: ResetBudgetMutationInput!) {
    resetBudget(input: $input) {
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);
