import React from 'react';

import Confirmation from 'components/lib/ui/Confirmation';
import Text from 'components/lib/ui/Text';

type Props = {
  onCancel?: () => void;
  onConfirm?: () => void;
  isLoading?: boolean;
};

const RevokeSponsorAccessConfirmation = ({ onConfirm, onCancel, isLoading }: Props) => (
  <Confirmation
    title="Revoke professional access"
    confirm="Revoke access"
    isLoading={isLoading}
    onCancel={onCancel}
    onConfirm={onConfirm}
  >
    <Text>
      Are you sure you want to revoke access to your account? This action cannot be undone.
    </Text>
  </Confirmation>
);

export default RevokeSponsorAccessConfirmation;
