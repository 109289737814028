import React from 'react';
import styled from 'styled-components';

import Checkbox from 'components/lib/form/Checkbox';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';
import { TooltipV2 } from 'components/lib/ui/TooltipV2';
import Currency from 'components/lib/ui/currency/Currency';
import RouteLink from 'components/lib/ui/link/RouteLink';

import { spacing } from 'common/lib/theme/dynamic';

import routes from 'constants/routes';

const CHECKBOX_SIZE_PX = 16;
const POPOVER_WIDTH_PX = 160;

const StyledCheckbox = styled(Checkbox)`
  margin-top: ${spacing.xxsmall};

  ${Text} {
    display: inline-block;
    transform: translateY(-1px);
  }
`;

const InfoIcon = styled(Icon).attrs({ name: 'info', size: 14 })`
  display: inline-block;
  margin-left: ${spacing.xxsmall};
  cursor: pointer;
`;

const ChevronRight = styled(Icon).attrs({ name: 'chevron-right', size: 14 })`
  position: relative;
  top: 2px;
`;

const PopoverContent = styled(FlexContainer).attrs({ column: true, full: true })`
  padding: ${spacing.xsmall} ${spacing.small};
  width: ${POPOVER_WIDTH_PX}px;
  text-align: left;
`;

type Props = {
  appliedToFuture?: boolean;
  onCheckApplyToFuture?: (applyToFuture: boolean) => void;
  value?: number;
};

const BudgetApplyToFutureMonths = ({ appliedToFuture, onCheckApplyToFuture, value }: Props) => (
  <FlexContainer justifyBetween alignCenter>
    <StyledCheckbox
      size={CHECKBOX_SIZE_PX}
      checked={appliedToFuture}
      onChange={({ target: { checked } }) => onCheckApplyToFuture?.(checked)}
    >
      <Text weight="book">
        Apply <Currency round value={value || 0} /> to all future months
      </Text>
    </StyledCheckbox>
    <TooltipV2
      content={
        <PopoverContent>
          <Text size="xsmall">
            You can edit the default behavior for how all budget changes are applied.
          </Text>
          <FlexContainer alignCenter>
            <RouteLink to={routes.plan.settings()}>
              Edit budget settings
              <ChevronRight />
            </RouteLink>
          </FlexContainer>
        </PopoverContent>
      }
      clickable
      maxWidth={POPOVER_WIDTH_PX}
      side="right"
    >
      <div>
        <InfoIcon />
      </div>
    </TooltipV2>
  </FlexContainer>
);

export default BudgetApplyToFutureMonths;
