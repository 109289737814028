import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { routeChanged } from 'actions';
import { useDispatch } from 'lib/hooks';

// Hook for tracking navigation events to a page
// PageName should be a static name for the page or route.
// PageTitle can be a dynamic name representing what the user sees
const useNavigationTracking = (pageName: string, pageTitle: string) => {
  const { pathname, search, key } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      routeChanged({
        path: pathname,
        search,
        pageTitle,
        pageName,
        key,
      }),
    );
  }, [pathname, search]);
};

export default useNavigationTracking;
