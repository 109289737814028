import * as R from 'ramda';

import useQuery from 'common/lib/hooks/useQuery';

import { gql } from 'common/generated/gql';
import type { QueryHookOptionsFromDocument } from 'common/types/graphql';

export const ANYONE_ID = 'anyone';

type Options = QueryHookOptionsFromDocument<typeof HOUSEHOLD_MEMBERS_FOR_REVIEW>;

const useHouseholdUsers = (options: Options = {}) => {
  const { data, isLoadingInitialData } = useQuery(HOUSEHOLD_MEMBERS_FOR_REVIEW, {
    ...options,
    fetchPolicy: options?.fetchPolicy ?? 'cache-first',
  });
  const pathOrAnyone = R.curry(R.pathOr)(ANYONE_ID);
  const indexedBy = R.indexBy(
    pathOrAnyone(['groupBy', 'needsReviewByUser', 'id']),
    data?.byNeedsReviewByUser ?? [],
  );
  const reviewSummaryByUser = R.mapObjIndexed((value) => value.summary.count, indexedBy);
  const users = data?.myHousehold?.users ?? [];
  const currentUser = data?.me;
  return [{ users, isLoadingInitialData, reviewSummaryByUser, currentUser }] as const;
};

export const HOUSEHOLD_MEMBERS_QUERY_NAME = 'Common_GetHouseholdMembers';

const HOUSEHOLD_MEMBERS_FOR_REVIEW = gql(/* GraphQL */ `
  query Common_GetHouseholdMembers {
    me {
      id
    }
    myHousehold {
      id
      users {
        id
        name
      }
    }
    byNeedsReviewByUser: aggregates(
      groupBy: ["needsReviewByUser"]
      filters: { needsReview: true }
    ) {
      groupBy {
        needsReviewByUser {
          id
          name
        }
      }
      summary {
        count
      }
    }
  }
`);

export default useHouseholdUsers;
