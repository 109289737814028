import React from 'react';
import styled from 'styled-components';

import type { Props as ShareChartButtonProps } from 'components/cashFlows/ShareChartButton';
import ShareChartButton from 'components/cashFlows/ShareChartButton';
import DividerLine from 'components/lib/ui/DividerLine';
import FlexContainer from 'components/lib/ui/FlexContainer';
import PillTab from 'components/lib/ui/tabs/PillTab';
import TabGroup from 'components/lib/ui/tabs/TabGroup';

import { SANKEY_GROUP_MODE_TO_LABEL } from 'lib/cashFlow/sankey';
import { ChartType } from 'lib/ui/charts';

export const SANKEY_CONTAINER_ID = 'monarch-cash-flow-sankey';

const Root = styled(FlexContainer).attrs({ alignCenter: true, gap: 'default' })`
  margin: -${({ theme }) => theme.spacing.xxsmall};
  margin-right: -10px;
`;

const CashFlowSankeyCardControls = (props: Pick<ShareChartButtonProps, 'fileName' | 'view'>) => (
  <Root>
    <TabGroup>
      {Object.values(SANKEY_GROUP_MODE_TO_LABEL).map((label, index) => (
        <PillTab key={`${label}-${index}`} index={index} $background="card">
          {label}
        </PillTab>
      ))}
    </TabGroup>
    <DividerLine />
    <ShareChartButton
      onSelectElement={() => document.getElementById(SANKEY_CONTAINER_ID)}
      chartType={ChartType.CashFlowSankey}
      {...props}
    />
  </Root>
);

export default CashFlowSankeyCardControls;
