import { rgba } from 'polished';
import React from 'react';
import type { NavLinkProps } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';
import styled, { css } from 'styled-components';

import type { IconProps } from 'components/lib/ui/Icon';
import Icon, { monarchIconExists } from 'components/lib/ui/Icon';
import Tooltip from 'components/lib/ui/Tooltip';
import NavLink, { ACTIVE_CLASS_NAME } from 'components/lib/ui/link/NavLink';
import LinkIcon from 'components/sidebar/LinkIcon';

import { color, spacing } from 'common/lib/theme/dynamic';
import isV2Theme from 'common/lib/theme/isV2Theme';
import useTheme from 'lib/hooks/useTheme';

import { SIDEBAR_COLLAPSED_CLASS_NAME } from 'constants/classNames';

const Root = styled.div`
  & > .custom-tooltip {
    display: none;
  }
  .${SIDEBAR_COLLAPSED_CLASS_NAME} & > .custom-tooltip {
    display: block;
  }
`;

const UnsavedChangesBadge = styled.span`
  width: 6px;
  height: 6px;
  position: absolute;
  background-color: ${({ theme }) => theme.color.red};
  top: 0;
  right: -2px;
  border-radius: 100%;
`;

const NavIcon = (props: IconProps) => <Icon {...props} size={16} />;

const NavBadge = styled.span<{ $color: string; $textColor?: string }>`
  display: inline-block;
  margin-left: auto;
  padding: 0 ${({ theme }) => theme.spacing.xsmall};
  font-size: ${({ theme }) => theme.fontSize.xxsmall};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-transform: uppercase;
  border-radius: ${({ theme }) => theme.radius.small};
  background-color: ${({ $color }) => $color};
  color: ${({ $textColor, theme }) => $textColor ?? isV2Theme(color.gray11, color.textWhite)};

  .${SIDEBAR_COLLAPSED_CLASS_NAME} & {
    display: none;
  }
`;

const Title = styled.span`
  .${SIDEBAR_COLLAPSED_CLASS_NAME} & {
    display: none;
  }
`;

const Container = styled.div`
  cursor: pointer;

  :hover {
    color: ${isV2Theme(color.text, color.textWhite)};
  }
`;

export type NavBarLinkProps = Partial<
  Omit<NavLinkProps<unknown>, 'activeClassName' | 'children'>
> & {
  iconName: string;
  children: string;
  activeIcon?: React.ReactNode;
  hasUnsavedChanges?: boolean;
  badge?: string;
  badgeColor?: string;
  badgeTextColor?: string;
};

const NavBarLink = styled(
  ({
    iconName,
    children,
    hasUnsavedChanges,
    isActive,
    badge,
    badgeColor,
    badgeTextColor,
    to,
    ...rest
  }: NavBarLinkProps) => {
    const theme = useTheme();
    const routeMatches = useRouteMatch(to?.toString() ?? '');
    const routeMatch = to && routeMatches;

    const filledIconName = `${iconName}-filled`;
    const activeIconName = monarchIconExists(filledIconName) ? filledIconName : iconName;
    const icon = routeMatch ? activeIconName : iconName;

    return (
      <Root>
        <Tooltip place="right" content={children}>
          <Container as={to ? NavLink : undefined} to={to ?? ''} {...rest}>
            <LinkIcon>
              <NavIcon name={icon} />
              {hasUnsavedChanges && <UnsavedChangesBadge />}
            </LinkIcon>

            <Title>{children}</Title>

            {!!badge && (
              <NavBadge
                $color={
                  badgeColor ?? (theme.isV2ThemeEnabled ? theme.color.gray5 : theme.color.blue)
                }
                $textColor={badgeTextColor}
              >
                {badge}
              </NavBadge>
            )}
          </Container>
        </Tooltip>
      </Root>
    );
  },
)`
  color: ${isV2Theme(color.text, ({ theme }) => rgba(theme.color.textWhite, 0.7))};
  display: flex;
  align-items: center;

  ${isV2Theme(
    css`
      padding: ${spacing.xsmall} ${spacing.small};
      min-height: 40px;
    `,
    css`
      padding: ${spacing.small};
    `,
  )}

  transition: ${({ theme }) => theme.transition.default};
  margin-bottom: ${({ theme }) => theme.spacing.xxsmall};
  border-radius: ${({ theme }) => theme.radius.small};

  &:not(.${ACTIVE_CLASS_NAME}) :hover {
    background-color: ${isV2Theme(color.gray4, color.navyLight)};
  }

  &.${ACTIVE_CLASS_NAME}, && :active {
    opacity: 1;
    background-color: ${isV2Theme(color.gray4, color.navyDark)};
  }
`;

export default NavBarLink;
