import React from 'react';
import styled from 'styled-components';

import AccountBalanceIndicator from 'components/accounts/AccountBalanceIndicator';
import AccountCompositionBar from 'components/accounts/AccountCompositionBar';
import { sensitiveClassProps } from 'components/lib/higherOrder/withSensitiveData';
import Text from 'components/lib/ui/Text';

import { formatCurrency } from 'common/utils/Currency';
import type { AccountSummaryCardItem } from 'lib/accounts/AccountGraphs';

import type { StyleMixin } from 'common/types/Styles';

const CardSection = styled.div`
  :not(:last-child) {
    padding: ${({ theme }) => theme.spacing.xlarge};
    border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
  }

  :last-child {
    padding: 0;
  }
`;

const cardItemMixin: StyleMixin = ({ theme }) => `
  display: flex;
  justify-content: space-between;
  color: ${theme.color.text};

  :not(:last-child) {
    margin-bottom: ${theme.spacing.default};
  }
`;
const CardHeaderItem = styled.div`
  ${cardItemMixin};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const CompositionBarContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.default};
`;

type Props = {
  title: string;
  items: AccountSummaryCardItem[];
  total: number;
  displayType: 'balance' | 'percentage';
  onClickType: (accountType: string) => void;
};

const AccountSummaryCardGroup = ({ title, items, total, displayType, onClickType }: Props) => (
  <CardSection>
    <CardHeaderItem>
      <span>{title}</span>
      <Text color="textLight" {...sensitiveClassProps}>
        {formatCurrency(total)}
      </Text>
    </CardHeaderItem>
    <CompositionBarContainer>
      <AccountCompositionBar items={items} />
    </CompositionBarContainer>
    {items.map(({ type, amount, percentage }) => (
      <AccountBalanceIndicator
        key={type.name}
        onClick={() => onClickType(type.display)}
        balance={amount}
        percentage={displayType === 'percentage' ? percentage : undefined}
        name={type.display}
        isAsset
      />
    ))}
  </CardSection>
);

export default AccountSummaryCardGroup;
