import type { DateTime } from 'luxon';
import React from 'react';
import styled from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import type { AccountLogoProps } from 'components/accounts/AccountLogo';
import AccountLogo from 'components/accounts/AccountLogo';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import RouteLink from 'components/lib/ui/link/RouteLink';
import MerchantLogo from 'components/merchants/MerchantLogo';

import { color, spacing } from 'common/lib/theme/dynamic';
import { linkUrlForRecurringItem } from 'lib/recurring/links';

const LOGO_SIZE_PX = 40;
const SUB_AVATAR_SIZE_PX = 16;

const ComboAvatar = styled.div`
  padding: ${spacing.xxsmall};
  position: relative;
`;

const StyledAccountLogo = styled(AccountLogo)`
  outline: 4px solid ${color.white};
  position: absolute;
  right: 0;
  bottom: 0;
`;

const Root = styled(FlexContainer).attrs({ alignCenter: true })`
  gap: ${spacing.default};
`;

type Props = {
  id: string;
  name: string;
  frequency: string;
  logoUrl?: string | null;
  logoSize?: number;
  account?: AccountLogoProps | null;
  className?: string;
  startDate?: Maybe<DateTime>;
  isMerchant: boolean;
};

const RecurringStreamDashboardItem = ({
  id,
  name,
  frequency,
  logoUrl,
  account,
  logoSize = LOGO_SIZE_PX,
  className,
  startDate,
  isMerchant,
}: Props) => {
  const queryParams = startDate ? { date: startDate.startOf('month').toISODate() } : {};
  const linkUrl = linkUrlForRecurringItem({ id, isMerchant, queryParams });

  return (
    <Root className={className}>
      <RouteLink to={linkUrl} stealthy>
        <ComboAvatar>
          <MerchantLogo url={logoUrl} size={logoSize} />
          {!!account && (
            <StyledAccountLogo
              logoUrl={account.logoUrl}
              icon={account.icon}
              size={SUB_AVATAR_SIZE_PX}
            />
          )}
        </ComboAvatar>
      </RouteLink>
      <FlexContainer column>
        <RouteLink to={linkUrl} stealthy>
          <Text weight="medium">{name}</Text>
        </RouteLink>

        <Switch>
          <Case when={!isMerchant}>
            <Text color="textLight">Account • {frequency}</Text>
          </Case>
          <Case when={isMerchant}>
            <Text color="textLight">Merchant • {frequency}</Text>
          </Case>
        </Switch>
      </FlexContainer>
    </Root>
  );
};

export default RecurringStreamDashboardItem;
