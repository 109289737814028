import pluralize from 'pluralize';
import * as RA from 'ramda-adjunct';
import * as React from 'react';
import styled from 'styled-components';

import Badge from 'components/lib/ui/Badge';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Highlighter from 'components/lib/ui/Highlighter';
import Text from 'components/lib/ui/Text';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import RouteLink from 'components/lib/ui/link/RouteLink';
import MerchantLogo from 'components/merchants/MerchantLogo';

import { formatThousands } from 'common/utils/Number';

import routes from 'constants/routes';

import type { Web_GetMerchantSettingsPage_merchants } from 'common/generated/graphQlTypes/Web_GetMerchantSettingsPage';

const Root = styled(FlexContainer).attrs({ justifyBetween: true, alignCenter: true })`
  padding: ${({ theme }) => theme.spacing.default} ${({ theme }) => theme.spacing.xlarge};
  border-top: 1px solid ${({ theme }) => theme.color.grayBackground};
  gap: ${({ theme }) => theme.spacing.default};
`;

const MerchantInfo = styled(FlexContainer).attrs({ column: true })`
  gap: ${({ theme }) => theme.spacing.xxsmall};
  flex-grow: 1;
`;

type Props = {
  merchant: Web_GetMerchantSettingsPage_merchants;
  onClickEdit: () => void;
};

const MerchantListItem = ({ merchant, onClickEdit }: Props) => {
  const isRecurring = RA.isNotNil(merchant.recurringTransactionStream);

  return (
    <Root>
      <MerchantLogo url={merchant.logoUrl} size={48} />
      <MerchantInfo>
        <FlexContainer alignCenter>
          <Text weight="medium" size="large">
            <Highlighter>{merchant.name}</Highlighter>{' '}
          </Text>
          {isRecurring && <Badge color="grayLight">Recurring</Badge>}
        </FlexContainer>
        <RouteLink to={routes.transactions({ queryParams: { merchants: [merchant.id] } })} stealthy>
          <Text size="small">
            {formatThousands(merchant.transactionCount)}{' '}
            {pluralize('transaction', merchant.transactionCount)}
          </Text>
        </RouteLink>
      </MerchantInfo>
      <DefaultButton size="small" onClick={onClickEdit}>
        <ButtonIcon name="edit" />
        <span>Edit</span>
      </DefaultButton>
    </Root>
  );
};

export default MerchantListItem;
