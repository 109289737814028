import { TrialType as TrialTypeMobile } from 'common/generated/graphQlTypes/globalTypes';
import { TrialType } from 'common/generated/graphql';

export const TRIAL_TYPE_TO_LABEL: Record<TrialType, string> = {
  [TrialType.FREE_TRIAL]: 'Free trial',
  [TrialType.REFERRALS]: 'Free trial',
  [TrialType.PREMIUM]: 'Free trial',
  [TrialType.GUEST_PASS]: 'Guest pass', // TODO[vanessa]: ENG-3987
};

// TODO: refactor it on the mobile app to use only the TRIAL_TYPE_TO_LABEL
export const TRIAL_TYPE_TO_LABEL_MOBILE: Record<TrialTypeMobile, string> = {
  [TrialTypeMobile.free_trial]: 'Free trial',
  [TrialTypeMobile.referrals]: 'Free trial',
  [TrialTypeMobile.premium]: 'Free trial',
  [TrialTypeMobile.guest_pass]: 'Guest pass', // TODO[vanessa]: ENG-3987
};

/**
 * Map from Stripe coupon description to the corresponding banner text.
 *
 * Ideally we would use the coupon ID instead of description, but we don't have access to it.
 */
export const PROMO_CODE_BANNER_TEXT: Record<string, string> = {
  'Walmart+ 2 month free trial':
    '$5 Walmart+ cash offer available only for Walmart+ members who start a free trial and connect at least one financial account.',
};
