import React from 'react';
import styled from 'styled-components';

import AccountLogo from 'components/accounts/AccountLogo';
import RouteLink from 'components/lib/ui/link/RouteLink';

import type { RecurringTransactionItem } from 'common/lib/recurring/types';

import routes from 'constants/routes';

import type { Web_GetAllRecurringTransactionItems_recurringTransactionStreams_account } from 'common/generated/graphQlTypes/Web_GetAllRecurringTransactionItems';

const ACCOUNT_LOGO_SIZE_PX = 16;

type Props = {
  account:
    | RecurringTransactionItem['account']
    | Web_GetAllRecurringTransactionItems_recurringTransactionStreams_account;
};

const StyledAccountLogo = styled(AccountLogo)`
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

const StyledRouteLink = styled(RouteLink)`
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 120px;
`;

const Name = styled.span`
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const RecurringAccount = ({ account }: Props) => (
  <StyledRouteLink to={routes.accounts.accountDetails({ id: account!.id })} stealthy>
    <StyledAccountLogo
      logoUrl={account?.logoUrl}
      icon={account?.icon}
      size={ACCOUNT_LOGO_SIZE_PX}
    />
    <Name>{account?.displayName}</Name>
  </StyledRouteLink>
);

export default RecurringAccount;
