import * as R from 'ramda';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import SparkLineChart from 'components/lib/charts/SparkLineChart';
import TimeframeTrendIndicator, { TimeframeText } from 'components/lib/ui/TimeframeTrendIndicator';
import Tooltip from 'components/lib/ui/Tooltip';

import { getTrendIndicatorLineColor } from 'common/lib/accounts/accountCharts';
import { getCurrentTimeframeText } from 'common/lib/accounts/netWorthCharts';
import useToggle from 'common/lib/hooks/useToggle';
import usePersistentFilter from 'lib/hooks/usePersistentFilter';
import useTheme from 'lib/hooks/useTheme';

type Props = {
  balances: (number | null)[];
  isAsset: boolean;
};

const TooltipContent = styled.div`
  padding: ${({ theme }) => theme.spacing.default};
  white-space: nowrap;
`;

const CustomTimeframeIndicator = styled(TimeframeTrendIndicator)`
  ${TimeframeText} {
    color: ${({ theme }) => theme.color.textWhite};
  }
`;

const SparklineChartArea = styled.div`
  padding-top: ${({ theme }) => theme.spacing.xxsmall};
`;

const AccountSparklines = ({ balances, isAsset }: Props) => {
  const theme = useTheme();
  const [isHovering, { setOff: setIsHoveringOff, setOn: setIsHovering }] = useToggle(false);
  const adjustedBalances = useMemo(
    () =>
      R.map((balance) => {
        if (balance === null) {
          return { balance: 0 };
        } else {
          return { balance: balance * (isAsset ? 1 : -1) };
        }
      }, balances),
    [balances, isAsset],
  );
  const currentBalance = R.last(adjustedBalances)?.balance ?? 0;
  const startBalance = R.head(adjustedBalances)?.balance ?? currentBalance;

  const {
    activeFilters: { timeframe: activeTimeframe, dateRange, chartType },
  } = usePersistentFilter('accounts');

  const graphColor = isHovering
    ? getTrendIndicatorLineColor({ isAsset, balance: currentBalance - startBalance, theme })
    : theme.color.gray8;

  return (
    <Tooltip
      fitContent
      content={
        <TooltipContent>
          <CustomTimeframeIndicator
            startAmount={startBalance}
            endAmount={currentBalance}
            timeframeDisplay={getCurrentTimeframeText(activeTimeframe, dateRange, chartType)}
            isAsset={isAsset}
          />
        </TooltipContent>
      }
    >
      <SparklineChartArea>
        <SparkLineChart
          data={adjustedBalances}
          dataKey="balance"
          widthPx={100}
          heightPx={40}
          lineColor={graphColor}
          referenceLineY={startBalance}
          onMouseEnter={setIsHovering}
          onMouseLeave={setIsHoveringOff}
        />
      </SparklineChartArea>
    </Tooltip>
  );
};

export default AccountSparklines;
