import { useMutation } from '@apollo/client';
import { debounce } from 'lodash/fp';
import type { DateTime } from 'luxon';
import { isNumber } from 'ramda-adjunct';
import { useCallback, useRef } from 'react';

import { useOptimisticBudgetUpdates } from 'common/lib/hooks/budget/useOptimisticBudgetUpdates';

import { gql } from 'common/generated/gql';
import { BudgetTimeframeInput } from 'common/generated/graphql';
import type {
  ExtractInputFromDocument,
  MutationHookOptionsFromDocument,
} from 'common/types/graphql';

const DEBOUNCE_TIME_MS = 2000;

type MutationKey = string;

type BudgetUpdaterContext = {
  startDate: DateTime;
  categoryId?: string;
  categoryGroupId?: string;
};

type BudgetUpdateDetails = {
  amount: number | undefined;
  applyToFuture?: boolean;
};

type Options = MutationHookOptionsFromDocument<typeof UPDATE_BUDGET_ITEM> & {
  onMutationsComplete?: () => Promise<unknown> | void;
  /**
   * If false, will not debounce the onMutationsComplete callback.
   * If a number, will debounce the callback by that number of milliseconds.
   * This is useful for mobile, where we don't want to debounce callbacks.
   */
  debounce?: false | number;
};

const useUpdateBudgetItemMutation = (options: Options, optimisticCacheUpdate = true) => {
  const {
    onMutationsComplete,
    debounce: debounceTime = DEBOUNCE_TIME_MS,
    ...mutationOptions
  } = options;
  const [performMutation, { loading }] = useMutation(UPDATE_BUDGET_ITEM, mutationOptions);
  const pendingUpdatesRef = useRef<Record<MutationKey, Promise<unknown>>>({});

  const debouncedProcessMutations = useCallback(
    (debounceTime: number) =>
      debounce(debounceTime, async () => {
        if (Object.keys(pendingUpdatesRef.current).length === 0) {
          return;
        }

        // Wait for all pending mutations to complete
        const promises = Object.values(pendingUpdatesRef.current);
        await Promise.all(promises);

        pendingUpdatesRef.current = {};
        return onMutationsComplete?.();
      }),
    [onMutationsComplete],
  );

  const { optimisticUpdateCacheForCategoryOrGroup } = useOptimisticBudgetUpdates();

  const executeMutation = async (input: ExtractInputFromDocument<typeof UPDATE_BUDGET_ITEM>) => {
    await performMutation({ variables: { input } });
    if (onMutationsComplete) {
      return onMutationsComplete();
    }
  };

  const createBudgetItemUpdater =
    (context: BudgetUpdaterContext) => (updateDetails: BudgetUpdateDetails) => {
      const { startDate, categoryId, categoryGroupId } = context;
      const { amount = 0, applyToFuture } = updateDetails;

      // Only allow one of categoryId or categoryGroupId to be provided
      if (categoryId && categoryGroupId) {
        throw new Error('Only one of categoryId or categoryGroupId can be provided');
      }

      const input: ExtractInputFromDocument<typeof UPDATE_BUDGET_ITEM> = {
        defaultAmount: undefined,
        startDate: startDate.toISODate(),
        timeframe: BudgetTimeframeInput.MONTH,
        amount,
        applyToFuture,
        categoryId,
        categoryGroupId,
      };

      // Debounce is considered "enabled" if it's a number greater than 0 (milliseconds)
      const debounceEnabled = isNumber(debounceTime) && debounceTime > 0;

      // Main logic for handling budget item updates
      if ((categoryId || categoryGroupId) && debounceEnabled) {
        // Make sure the mutation key is unique so that we don't fire multiple mutations for the same item
        const mutationKey: MutationKey = `${categoryId || categoryGroupId}-${startDate.toISODate()}`;
        // Add the mutation promise to the pending updates
        pendingUpdatesRef.current[mutationKey] = performMutation({ variables: { input } });
      }

      if (optimisticCacheUpdate && (categoryId || categoryGroupId)) {
        optimisticUpdateCacheForCategoryOrGroup({
          startDate,
          id: categoryId || categoryGroupId!,
          amount,
          isGroup: !!categoryGroupId,
          applyToFuture,
        });
      }

      return debounceEnabled ? debouncedProcessMutations(debounceTime)() : executeMutation(input);
    };

  return { createBudgetItemUpdater, loading };
};

export const UPDATE_BUDGET_ITEM = gql(/* GraphQL */ `
  mutation Common_UpdateBudgetItem($input: UpdateOrCreateBudgetItemMutationInput!) {
    updateOrCreateBudgetItem(input: $input) {
      budgetItem {
        id
        plannedCashFlowAmount
      }
    }
  }
`);

export default useUpdateBudgetItemMutation;
