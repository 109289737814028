import styled from 'styled-components';

import Tab from 'components/lib/ui/tabs/Tab';

import boxShadow from 'common/lib/styles/boxShadow';
import variables from 'common/lib/theme/variables';

import type { StyleMixin } from 'common/types/Styles';

type Props = {
  /** what color background this component is on top of */
  $background: 'base' | 'card';
};

const pillTabStyleMixin =
  ({ $background }: Props): StyleMixin =>
  ({ theme }) => `
  --color: ${theme.color.textLight};
  --activeColor: ${theme.color.text};
  --activeBackgroundColor: ${
    {
      base: variables.color.background.tertiary({ theme }),
      card: variables.color.background.secondary({ theme }),
    }[$background]
  };

  border-radius: ${theme.radius.pill};
  padding: ${theme.spacing.xsmall} ${theme.spacing.default};
  font-size: ${theme.fontSize.small};
  line-height: 20px;
  text-align: center;

  transition: ${theme.transition.default};
  :hover {
    background-color: var(--activeBackgroundColor);
  }
  :active {
    background-color: var(--activeBackgroundColor);
    ${boxShadow.inset({ theme })}
  }
`;

const PillTab = styled(Tab).attrs({ underline: false })<Props>`
  ${({ $background }) => pillTabStyleMixin({ $background })};
`;

export default PillTab;
