import { reject } from 'ramda';
import { isNilOrEmpty, isNotNilOrEmpty } from 'ramda-adjunct';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import AccountLogo from 'components/accounts/AccountLogo';
import Dot from 'components/lib/ui/Dot';
import Icon from 'components/lib/ui/Icon';
import WithIndicatorContainer from 'components/lib/ui/WithIndicatorContainer';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import { OverlayTrigger, Popover } from 'components/lib/ui/popover';
import TransactionFilterMenuPopover from 'components/transactions/header/TransactionFilterMenuPopover';

import { color, fontSize, fontWeight, spacing } from 'common/lib/theme/dynamic';
import { formatThousands } from 'common/utils/Number';
import useTransactionFilterMenu from 'lib/hooks/useTransactionFilterMenu';

import type { TransactionFilters } from 'types/filters';

const TAG_DOT_SIZE_PX = 10;
const CREDIT_CARD_ICON_SIZE_PX = 12;
const ACCOUNT_LOGO_SIZE_PX = 14;

const CreditCardIcon = styled(Icon).attrs({
  name: 'credit-card',
  size: CREDIT_CARD_ICON_SIZE_PX,
})``;

const MerchantTransactionCount = styled.span`
  display: flex;
  align-items: center;
  gap: ${spacing.xxsmall};
  font-size: ${fontSize.small};
  color: ${color.textLight};
  font-weight: ${fontWeight.book};
`;

const SmallAccountLogo = styled(AccountLogo).attrs({ size: ACCOUNT_LOGO_SIZE_PX })`
  margin-right: ${spacing.xxsmall};
`;

const TagDot = styled(Dot).attrs({ size: TAG_DOT_SIZE_PX })`
  margin-right: ${spacing.xxsmall};
`;

type Props = {
  filters: Omit<TransactionFilters, 'search' | 'startDate' | 'endDate' | 'timeframePeriod'>;
  onChangeFilters: (filters: Partial<TransactionFilters>) => void;
};

const TransactionFilterButton: React.FC<Props> = ({ filters, onChangeFilters }) => {
  const hasAtLeastOneFilter = useMemo(
    () => isNotNilOrEmpty(reject(isNilOrEmpty, filters)),
    [filters],
  );

  const {
    sections,
    isLoading,
    isFetchingMoreData,
    onSearchChange,
    onChangeSections,
    resetUnappliedChanges,
    handleApply,
  } = useTransactionFilterMenu({
    page: 'transactions',
    filters,
    onApplyFilters: (filters) => {
      onChangeFilters(filters);
      resetUnappliedChanges();
    },
    renderAccountLogo: (acc) => <SmallAccountLogo logoUrl={acc.logoUrl} icon={acc.icon} />,
    renderMerchantAccessory: (merchant) =>
      merchant.transactionCount && (
        <MerchantTransactionCount>
          <CreditCardIcon />
          {formatThousands(merchant.transactionCount)}
        </MerchantTransactionCount>
      ),
    renderTag: (tag) => <TagDot color={tag.color} />,
  });

  return (
    <OverlayTrigger
      placement="bottom-end"
      onClose={resetUnappliedChanges}
      overlay={({ close }) => (
        <Popover>
          <TransactionFilterMenuPopover
            sections={sections}
            isLoading={isLoading}
            onCancel={() => {
              close();
            }}
            onChangeSections={onChangeSections}
            onSearchChange={onSearchChange}
            isSearchLoading={isFetchingMoreData}
            onClickApply={(options) => {
              handleApply(options);
              close();
            }}
          />
        </Popover>
      )}
    >
      {({ toggleOpen, isOpen }) => (
        <WithIndicatorContainer show={hasAtLeastOneFilter}>
          <DefaultButton
            onClick={() => {
              toggleOpen();
              resetUnappliedChanges();
            }}
            active={isOpen}
          >
            <ButtonIcon name="filter" />
            <span>Filters</span>
          </DefaultButton>
        </WithIndicatorContainer>
      )}
    </OverlayTrigger>
  );
};

export default TransactionFilterButton;
