import { gql } from '@apollo/client';
import { DateTime } from 'luxon';
import { rgba } from 'polished';
import * as R from 'ramda';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import Tabs from 'common/components/tabs/Tabs';
import HoldingValueChart from 'components/holdings/drawer/HoldingValueChart';
import InvestmentsHoldingDrawerAccountRow from 'components/investments/drawer/InvestmentsHoldingDrawerAccountRow';
import Flex from 'components/lib/ui/Flex';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import TimeframeTrendIndicator from 'components/lib/ui/TimeframeTrendIndicator';
import PillTab from 'components/lib/ui/tabs/PillTab';
import TabGroup from 'components/lib/ui/tabs/TabGroup';

import useQuery from 'common/lib/hooks/useQuery';
import { formatCurrency } from 'common/utils/Currency';
import { formatThousands } from 'common/utils/Number';
import { randomArrayElement } from 'common/utils/Random';

import type {
  Web_GetHoldingDrawerHistoricalPerformance,
  Web_GetHoldingDrawerHistoricalPerformanceVariables,
} from 'common/generated/graphQlTypes/Web_GetHoldingDrawerHistoricalPerformance';
import type { Web_GetPortfolio_portfolio_aggregateHoldings_edges_node_holdings } from 'common/generated/graphQlTypes/Web_GetPortfolio';

const AVAILABLE_TIME_PERIODS = [
  { display: '1M', duration: { months: 1 }, longDisplay: 'Past Month' },
  { display: '3M', duration: { months: 3 }, longDisplay: 'Past 3 Months' },
  { display: '6M', duration: { months: 6 }, longDisplay: 'Past 6 Months' },
  {
    display: 'YTD',
    duration: { days: -DateTime.local().set({ day: 1, month: 1 }).diffNow('day').days },
    longDisplay: 'Year to date',
  },
  { display: '1Y', duration: { years: 1 }, longDisplay: 'Past 1 Year' },
  { display: '5Y', duration: { years: 5 }, longDisplay: 'Past 5 Years' },
];

type Props = {
  totalQuantity?: number;
  totalValue?: number;
  securityId?: GraphQlUUID;
  securityName?: string | null;
  securityTicker?: string | null;
  securityCurrentPrice?: number | null;
  holdings?: Web_GetPortfolio_portfolio_aggregateHoldings_edges_node_holdings[];
};

const Root = styled.div`
  margin-bottom: ${(props) => props.theme.spacing.default};
`;

const HeaderContainer = styled(Flex)`
  flex-direction: column;
  padding: 0 ${(props) => props.theme.spacing.xxlarge};
  margin-bottom: ${(props) => props.theme.spacing.large};
`;

const ChartContainer = styled(Flex)`
  flex-direction: column;
  border-bottom: 1px solid ${(props) => props.theme.color.grayLight};
`;

const Section = styled(Flex)`
  flex-direction: column;
  padding-bottom: ${(props) => props.theme.spacing.default};
  :not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.color.grayLight};
  }
`;

const SectionTitle = styled(Text).attrs({
  size: 'base',
  weight: 'medium',
})`
  padding: ${(props) => `${props.theme.spacing.large} ${props.theme.spacing.xxlarge}`};
  padding-bottom: ${(props) => props.theme.spacing.small};
`;

const SectionRow = styled(Flex)`
  padding: ${(props) => `${props.theme.spacing.xsmall} ${props.theme.spacing.xxlarge}`};
  justify-content: space-between;
`;

const StyledTimeframeTrendIndicator = styled(TimeframeTrendIndicator)`
  margin-left: ${(props) => props.theme.spacing.small};
  font-size: ${({ theme }) => theme.fontSize.base};
  span {
    text-transform: initial;
    display: inline-block;
    margin-right: ${({ theme }) => theme.spacing.xsmall};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }
`;

const StyledTabGroup = styled(TabGroup)`
  width: max-content;
  margin-left: auto;
  padding-right: ${(props) => props.theme.spacing.small};
`;

const HoldingValueChartEmptyOverlay = styled(FlexContainer)`
  background: ${(props) => rgba(props.theme.color.grayBackground, 0.5)};
  position: absolute;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: ${(props) => `0 ${props.theme.spacing.large}`};
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  gap: ${(props) => props.theme.spacing.xxsmall};
`;

const HoldingDrawerBody: React.ForwardRefRenderFunction<HTMLDivElement, Props> = (
  {
    securityId,
    securityName,
    securityTicker,
    securityCurrentPrice,
    totalQuantity,
    totalValue,
    holdings,
  },
  ref,
) => {
  const now = DateTime.local();
  const [activeTimeFrameIndex, setActiveTimeFrameIndex] = useState<number>(1);
  const availableTimePeriods = AVAILABLE_TIME_PERIODS;

  const { duration, longDisplay } = availableTimePeriods[activeTimeFrameIndex];

  const {
    data: historicalPerformanceData,
    isNetworkRequestInFlight: isLoadingChartData,
    isLoadingInitialData,
  } = useQuery<
    Web_GetHoldingDrawerHistoricalPerformance,
    Web_GetHoldingDrawerHistoricalPerformanceVariables
  >(GET_HISTORICAL_PERFORMANCE_QUERY, {
    variables: {
      input: {
        securityIds: securityId ? [securityId] : [],
        startDate: now.minus(duration).toISODate(),
        endDate: now.toISODate(),
      },
    },
  });

  const mockHistoricalData = useMemo(() => {
    const startDate = now.minus(duration);
    const days = now.diff(startDate).as('days');
    return R.range(0, days).map((el) => ({
      date: startDate.plus({ days: el }).toISO(),
      returnPercent: randomArrayElement([0, 10, -10, 0]) ?? 0,
    }));
  }, [duration]);

  const historicalSecurityData =
    historicalPerformanceData?.securityHistoricalPerformance[0]?.historicalChart ?? [];

  let startAmount = 0;
  let endAmount = 0;

  if (historicalSecurityData && historicalSecurityData.length > 0) {
    startAmount = historicalSecurityData[0]?.value ?? 0;
    endAmount = historicalSecurityData[historicalSecurityData.length - 1]?.value ?? 0;
  }

  const isLoading = isLoadingChartData || isLoadingInitialData;

  return (
    <Root ref={ref}>
      <HeaderContainer>
        <Text weight="medium" size="small">
          {securityTicker}
        </Text>
        <Text weight="medium" size="xlarge">
          {securityName}
        </Text>
        <Flex>
          <Text weight="medium" size="xlarge">
            {formatCurrency(securityCurrentPrice)}
          </Text>
          <StyledTimeframeTrendIndicator
            startAmount={startAmount}
            endAmount={endAmount}
            timeframeDisplay={longDisplay}
          />
        </Flex>
      </HeaderContainer>
      {(historicalSecurityData.length > 0 || isLoading) && (
        <Tabs index={activeTimeFrameIndex} onChangeIndex={(i) => setActiveTimeFrameIndex(i)}>
          <StyledTabGroup>
            {availableTimePeriods.map(({ display }, i) => (
              <PillTab key={display} index={i} $background="card">
                {display}
              </PillTab>
            ))}
          </StyledTabGroup>
        </Tabs>
      )}
      <ChartContainer>
        <HoldingValueChart
          securityId={securityId ?? ''}
          securityName={securityName}
          securityTicker={securityTicker ?? ''}
          dataKey="returnPercent"
          data={historicalSecurityData.map(({ date, returnPercent }) => ({
            date,
            returnPercent,
          }))}
          isLoading={isLoading}
          emptyComponent={
            <HoldingValueChartEmptyOverlay>
              <Text weight="medium">Historical information missing</Text>
              <Text>The history for this holding is not currently available.</Text>
            </HoldingValueChartEmptyOverlay>
          }
          emptyData={mockHistoricalData}
        />
      </ChartContainer>
      <Section>
        <SectionTitle>Summary</SectionTitle>
        <SectionRow>
          <Text>Quantity</Text>
          <Text>{formatThousands(totalQuantity ?? 0)}</Text>
        </SectionRow>
        <SectionRow>
          <Text>Value</Text>
          <Text>{formatCurrency(totalValue)}</Text>
        </SectionRow>
      </Section>
      <Section>
        <SectionTitle>Accounts</SectionTitle>
        {holdings?.map(
          (el) =>
            el.account && (
              <InvestmentsHoldingDrawerAccountRow
                account={el.account}
                quantity={el.quantity}
                value={el.value}
              />
            ),
        )}
      </Section>
    </Root>
  );
};

const GET_HISTORICAL_PERFORMANCE_QUERY = gql`
  query Web_GetInvestmentsHoldingDrawerHistoricalPerformance(
    $input: SecurityHistoricalPerformanceInput!
  ) {
    securityHistoricalPerformance(input: $input) {
      security {
        id
      }
      historicalChart {
        date
        returnPercent
        value
      }
    }
  }
`;

export default React.forwardRef(HoldingDrawerBody);
