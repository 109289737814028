// THIS FILE IS GENERATED, DO NOT MODIFY MANUALLY. ##checksum##: 2443840679
import { createAction } from 'typesafe-actions';

export const appLoaded = createAction('UI/APP_LOADED')();

export const routeChanged = createAction('UI/ROUTE_CHANGED')<{
  path: string;
  search: string;
  pageTitle: string;
  pageName: string;
  key: string | undefined;
}>();

export const setDemoHouseholdModalVisible = createAction(
  'UI/SET_DEMO_HOUSEHOLD_MODAL_VISIBLE',
)<boolean>();

export const setGlobalSearchModalVisible = createAction(
  'UI/SET_GLOBAL_SEARCH_MODAL_VISIBLE',
)<boolean>();

export const setIsSideDrawerVisible = createAction('UI/SET_IS_SIDE_DRAWER_VISIBLE')<boolean>();
