import React, { useMemo } from 'react';
import styled from 'styled-components';

import { useFormContext } from 'common/components/form/FormContext';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import TextField from 'components/lib/form/TextField';
import Banner from 'components/lib/ui/Banner';
import CardBody from 'components/lib/ui/CardBody';
import CardFooter from 'components/lib/ui/CardFooter';
import ModalCard from 'components/lib/ui/ModalCard';
import Text from 'components/lib/ui/Text';
import DangerButton from 'components/lib/ui/button/DangerButton';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import TransactionFiltersOverview from 'components/transactions/TransactionFiltersOverview';

import { spacing } from 'common/lib/theme/dynamic';
import { useModalContext } from 'lib/contexts/ModalContext';
import { areFiltersEquivalent } from 'lib/filters/utils';
import useTrack from 'lib/hooks/useTrack';
import { getReportFiltersForTransactionFilterSet } from 'lib/reports';
import { convertFiltersToInput } from 'lib/transactions/Filters';

import { ReportsEventNames } from 'common/constants/analytics';

import type { ReportConfiguration } from 'common/generated/graphql';
import type { TransactionFilters } from 'types/filters';

const StyledCardBody = styled(CardBody)`
  gap: ${spacing.xlarge};
`;

const StyledTextField = styled(TextField)`
  :not(:last-child) {
    margin-bottom: 0;
  }
`;

const BannerText = styled(Text)`
  display: block;

  :not(:last-child) {
    margin-bottom: ${spacing.xsmall};
  }
`;

const SimilarReportConfigurationBanner = ({ reportName }: { reportName: string }) => (
  <Banner type="error">
    <BannerText>
      Your report <strong>{reportName}</strong> has the same configuration as the report you&apos;re
      trying to create.
    </BannerText>
    <BannerText>Change the date range or selected filters to save a new report.</BannerText>
  </Banner>
);

const SaveReportConfigurationInnerForm = ({
  filters,
  editingReportConfiguration,
  similarReportConfiguration,
  onDeleteReportConfiguration,
}: {
  filters: Partial<TransactionFilters>;
  editingReportConfiguration: ReportConfiguration | undefined;
  similarReportConfiguration: ReportConfiguration | undefined;
  onDeleteReportConfiguration: (reportConfiguration: ReportConfiguration) => void;
}) => {
  const { close } = useModalContext();
  const { isSubmitting } = useFormContext();

  const displayedFilters = useMemo(
    () =>
      editingReportConfiguration
        ? getReportFiltersForTransactionFilterSet(
            editingReportConfiguration.transactionFilterSet,
            filters.startDate,
            filters.endDate,
          )
        : filters,
    [editingReportConfiguration, filters],
  );

  return (
    <>
      <StyledCardBody>
        {!!similarReportConfiguration && (
          <SimilarReportConfigurationBanner reportName={similarReportConfiguration.displayName} />
        )}
        <StyledTextField name="displayName" label="Report name" placeholder="Enter report name" />
        <TransactionFiltersOverview filters={displayedFilters} />
      </StyledCardBody>
      <CardFooter justifyEnd>
        {editingReportConfiguration && (
          <DangerButton
            onClick={() => {
              onDeleteReportConfiguration(editingReportConfiguration);
              close();
            }}
          >
            Delete
          </DangerButton>
        )}
        <DefaultButton onClick={close}>Cancel</DefaultButton>
        <FormSubmitButton
          size="small"
          pending={isSubmitting}
          disabled={!!similarReportConfiguration}
        >
          Save
        </FormSubmitButton>
      </CardFooter>
    </>
  );
};

const SaveReportConfigurationModal = ({
  filters,
  editingReportConfigurationId,
  reportConfigurations,
  onDeleteReportConfiguration,
  onSubmit,
}: {
  filters: Partial<TransactionFilters>;
  reportConfigurations: ReportConfiguration[];
  editingReportConfigurationId?: Maybe<string>;
  onDeleteReportConfiguration: (reportConfiguration: ReportConfiguration) => void;
  onSubmit: (values: { displayName: string }) => void;
}) => {
  useTrack(ReportsEventNames.ReportConfigurationCreationStarted);

  const { close } = useModalContext();

  const editingReportConfiguration = useMemo(
    () =>
      reportConfigurations.find(
        (reportConfiguration: ReportConfiguration) =>
          reportConfiguration.id === editingReportConfigurationId,
      ),
    [reportConfigurations, editingReportConfigurationId],
  );

  // We only care about "similar" report configurations when creating a new one
  const similarReportConfiguration = useMemo(
    () =>
      !editingReportConfiguration
        ? reportConfigurations.find((reportConfiguration: ReportConfiguration) =>
            areFiltersEquivalent(
              convertFiltersToInput(filters),
              reportConfiguration.transactionFilterSet,
            ),
          )
        : undefined,
    [editingReportConfiguration, reportConfigurations, filters],
  );

  return (
    <ModalCard title={`${editingReportConfigurationId ? 'Edit' : 'Create'} saved report`}>
      <Form
        initialValues={{ displayName: editingReportConfiguration?.displayName }}
        onSubmit={onSubmit}
        onSubmitSuccess={close}
      >
        <SaveReportConfigurationInnerForm
          filters={filters}
          editingReportConfiguration={editingReportConfiguration}
          similarReportConfiguration={similarReportConfiguration}
          onDeleteReportConfiguration={onDeleteReportConfiguration}
        />
      </Form>
    </ModalCard>
  );
};

export default SaveReportConfigurationModal;
