import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Text from 'components/lib/ui/Text';
import CashFlowCurrency from 'components/lib/ui/currency/CashFlowCurrency';
import { CardWithHeavyShadow } from 'components/plan/onboarding/OnboardingCard';

const SPINNER_SIZE_PX = 20;

const Root = styled(CardWithHeavyShadow)`
  padding: ${({ theme }) => theme.spacing.default};
  padding-left: ${({ theme }) => theme.spacing.large};
  padding-right: ${({ theme }) => theme.spacing.xlarge};

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

type Props = {
  title: string;
  value: number;
  emphasis?: boolean;
  isLoading?: boolean;
};

const GroupFooter = ({ title, value, emphasis = false, isLoading }: Props) => (
  <Root>
    <Text size="base" weight="book">
      {title}
    </Text>
    <FlexContainer gap="small" alignCenter justifyEnd>
      {isLoading ? (
        <LoadingSpinner $size={SPINNER_SIZE_PX} />
      ) : (
        <CashFlowCurrency type="income" emphasis={emphasis} value={value} bold round />
      )}
    </FlexContainer>
  </Root>
);

export default GroupFooter;
