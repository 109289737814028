// THIS FILE IS GENERATED, DO NOT MODIFY MANUALLY. ##checksum##: 1755508583
import { createAction } from 'typesafe-actions';

import type { UiThemePreference } from 'common/types';

export const setBulkSelectState = createAction('PERSISTENT_UI/SET_BULK_SELECT_STATE')<boolean>();

export const setDismissedMobileBrowserWarning = createAction(
  'PERSISTENT_UI/SET_DISMISSED_MOBILE_BROWSER_WARNING',
)<boolean>();

export const setPlaidLinkInfo = createAction('PERSISTENT_UI/SET_PLAID_LINK_INFO')<{
  token: string;
  reconnectCredentialId: string | undefined;
}>();

export const setPlanDashboardWidgetViewIndex = createAction(
  'PERSISTENT_UI/SET_PLAN_DASHBOARD_WIDGET_VIEW_INDEX',
)<number>();

export const setTransactionsSummaryColumnVisible = createAction(
  'PERSISTENT_UI/SET_TRANSACTIONS_SUMMARY_COLUMN_VISIBLE',
)<boolean>();

export const setUiThemePreference = createAction(
  'PERSISTENT_UI/SET_UI_THEME_PREFERENCE',
)<UiThemePreference>();

export const toggleSidebarExpanded = createAction('PERSISTENT_UI/TOGGLE_SIDEBAR_EXPANDED')();

export const viewedMobileBrowserWarning = createAction(
  'PERSISTENT_UI/VIEWED_MOBILE_BROWSER_WARNING',
)();
