import { useCookies } from 'react-cookie';

import type { UserAttributionData } from 'common/lib/api/user';
import { getGoogleAnalyticsClientId } from 'lib/analytics/googleAnalytics';

// Get some data from cookies to help with user attribution
// We pass these to the BE, which then passes them down on analytics events
// https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/fbp-and-fbc/
const useUserAttributionData = (): UserAttributionData => {
  const [cookies] = useCookies();
  return {
    fbp: cookies._fbp as string | undefined,
    fbc: cookies._fbc as string | undefined,
    anonymous_id: window.analytics?.user && window.analytics.user().anonymousId(),
    google_analytics_client_id: getGoogleAnalyticsClientId(),
    signup_apps_flyer_af_user_id: cookies.afUserId as string | undefined,
  };
};

export default useUserAttributionData;
