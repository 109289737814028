import { SplitContext } from '@splitsoftware/splitio-react';
import * as React from 'react';
import styled from 'styled-components';

import Column from 'components/lib/ui/Column';
import Page from 'components/lib/ui/Page';

import FEATURES from 'lib/features/featureList';
import { useFeatureFlags } from 'lib/hooks/useFeatureFlag';

const Root = styled.div`
  padding: 0 ${({ theme }) => theme.spacing.xlarge};
  display: flex;
  gap: ${({ theme }) => theme.spacing.xlarge};
`;

const Code = styled.pre`
  font-size: 11px;
`;

const Title = styled.h2`
  margin-bottom: 0;
`;

const SplitClientTest = () => {
  // @ts-ignore - this is a test component
  const flags = useFeatureFlags(FEATURES);
  const context = React.useContext(SplitContext);
  // @ts-ignore - this is a test component
  const config = context.factory?.config;

  return (
    <Page name="Split.io Client Test" parentRoute={null}>
      <Root>
        <Column>
          <Title>Flags</Title>
          <Code>{JSON.stringify(flags, null, 2)}</Code>
        </Column>
        <Column>
          <Title>Split config</Title>
          <Code>{JSON.stringify(config, null, 2)}</Code>
        </Column>
      </Root>
    </Page>
  );
};

export default SplitClientTest;
