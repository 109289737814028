import type { MutationFunctionOptions } from '@apollo/client';
import type { ExecutionResult } from 'graphql';
import { DateTime } from 'luxon';
import pluralize from 'pluralize';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React from 'react';
import type { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';
import styled from 'styled-components';

import AccountLink from 'components/accounts/AccountLink';
import CategorySelect from 'components/categories/FullHeightCategorySelect';
import GoalSelect from 'components/goalsV2/GoalSelect';
import DateField from 'components/lib/form/DateField';
import Form from 'components/lib/form/Form';
import SelectField from 'components/lib/form/SelectField';
import TextAreaField from 'components/lib/form/TextAreaField';
import Banner from 'components/lib/ui/Banner';
import Divider from 'components/lib/ui/Divider';
import DragAndDropUploadInput from 'components/lib/ui/DragAndDropFileUploadInput';
import Flex from 'components/lib/ui/Flex';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';
import TextButton from 'components/lib/ui/TextButton';
import Tooltip from 'components/lib/ui/Tooltip';
import DangerButton from 'components/lib/ui/button/DangerButton';
import TransactionCurrency from 'components/lib/ui/currency/TransactionCurrency';
import RouteLink from 'components/lib/ui/link/RouteLink';
import MerchantSelect from 'components/merchants/FullHeightMerchantSelect';
import MerchantLogo from 'components/merchants/MerchantLogo';
import PremiumBadge from 'components/premium/PremiumBadge';
import PremiumFeatureOverlayTrigger from 'components/premium/PremiumFeatureOverlayTrigger';
import SplitTransactionBanner from 'components/transactions/SplitTransactionBanner';
import TransactionExplainModal from 'components/transactions/TransactionExplainModal';
import AttachmentItem from 'components/transactions/drawer/AttachmentItem';
import TransactionDrawerAmountField, {
  amountMixin,
} from 'components/transactions/drawer/TransactionDrawerAmountField';
import TransactionDrawerFieldRow from 'components/transactions/drawer/TransactionDrawerFieldRow';
import TransactionDrawerLoading from 'components/transactions/drawer/TransactionDrawerLoading';
import TransactionDrawerNotFoundMessage from 'components/transactions/drawer/TransactionDrawerNotFoundMessage';
import TransactionDrawerOriginalStatement from 'components/transactions/drawer/TransactionDrawerOriginalStatement';
import TransactionDrawerSplitMessage from 'components/transactions/drawer/TransactionDrawerSplitMessage';
import TransactionTagSelect from 'components/transactions/tags/TransactionTagSelect';

import {
  setTransactionTagsOptimisticResponse,
  updateTransactionOptimisticResponse,
} from 'common/lib/graphQl/transactions';
import type { Item } from 'common/lib/hooks/useItemArray';
import { spacing } from 'common/lib/theme/dynamic';
import { sortTags } from 'common/lib/transactions/tags';
import { sort } from 'common/utils/Array';
import { useSideDrawerContext } from 'lib/contexts/SideDrawerContext';
import { useTransactionRuleToastContext } from 'lib/contexts/TransactionRuleToastContext';
import { useTransactionListContext } from 'lib/contexts/TransactionsListContext';
import useModal from 'lib/hooks/useModal';
import useToast from 'lib/hooks/useToast';
import { transactionUpdateInputToOptimisticResponse } from 'lib/transactions/Adapters';

import { ProductFeature } from 'common/constants/premium';
import { SPLIT_PENDING_DISABLED_TEXT } from 'constants/copy';
import routes from 'constants/routes';

import type {
  GetTransactionDrawerQuery,
  SetTransactionTagsInput,
  UpdateTransactionMutationInput,
  Web_SetTransactionTagsMutation,
  Web_TransactionDrawerUpdateTransactionMutation,
} from 'common/generated/graphql';
import AmountType, { AMOUNT_TYPE_OPTIONS, UNIT_FOR_AMOUNT_TYPE } from 'types/AmountType';
import type { TransactionRulePrefillValues } from 'types/rules';
import { isSingleValue } from 'types/select';

const ANYONE = 'anyone';

const Root = styled.div`
  --main-padding: ${({ theme }) => theme.spacing.xlarge};
`;

const HeaderRoot = styled.div`
  padding: var(--main-padding);
  padding-top: ${({ theme }) => theme.spacing.xlarge};
  padding-bottom: 0;
`;

const AmountText = styled(TransactionCurrency)`
  ${amountMixin}
`;

const BodyRoot = styled.div`
  display: flex;
  flex-direction: column;
  padding: var(--main-padding);
`;

const MerchantSelectContainer = styled(Flex)`
  button {
    margin-top: ${({ theme }) => theme.spacing.xsmall};
    height: ${({ theme }) => theme.spacing.xxlarge};
    padding: ${({ theme }) => theme.spacing.xsmall};
    font-size: ${({ theme }) => theme.fontSize.xlarge};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    margin-right: ${({ theme }) => -theme.spacing.medium};
    width: 100%;
  }
  width: 100%;
  position: relative;
  /* this is necessary to have it perfectly aligned with the rest of the text while account for the input's internal padding */
  left: -10px;
`;

const StyledTitle = styled(Text).attrs({ size: 'xlarge', weight: 'medium' })`
  display: block;
  margin-bottom: ${({ theme }) => theme.spacing.small};
`;

const SplitIcon = styled(Icon).attrs({ name: 'split' })`
  position: relative;
  color: ${({ theme }) => theme.color.blue};
  margin-right: ${({ theme }) => theme.spacing.xsmall};
  width: ${({ theme }) => theme.spacing.small};
  height: ${({ theme }) => theme.spacing.small};
  bottom: -1px;
`;

const SplitBanner = styled(SplitTransactionBanner)`
  margin-bottom: ${({ theme }) => theme.spacing.xxlarge};
`;

const ErrorBanner = styled(Banner)`
  margin-bottom: ${({ theme }) => theme.spacing.xlarge};
`;

const TopInputContainer = styled(FlexContainer)`
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
`;

const PendingBanner = styled(Banner)`
  margin-bottom: ${({ theme }) => theme.spacing.xlarge};
`;

const StyledTextButton = styled(TextButton)`
  :disabled {
    pointer-events: auto;
  }
`;

const StyledAccountLink = styled(AccountLink)`
  max-width: 240px;
  margin-top: ${spacing.small};
`;

const DangerArea = styled(FlexContainer).attrs({ column: true, gap: 'gutter' })``;

export type FileData = {
  attachmentId?: string;
  name: string;
  sizeBytes: number;
  displaySize: string;
  progressPercent: number;
  publicId?: string;
  localUri?: string;
  originalAssetUrl?: string;
  extension: string;
};

type OnRemoveAttachmentClickParams = {
  attachmentId: string | undefined;
  remove: () => void;
};

type UpdateTransactionVariables = {
  input: Partial<UpdateTransactionMutationInput>;
};

type UpdateTransactionOptions = MutationFunctionOptions<
  Web_TransactionDrawerUpdateTransactionMutation,
  UpdateTransactionVariables
>;

type UpdateTransactionResult = Promise<
  ExecutionResult<Web_TransactionDrawerUpdateTransactionMutation>
>;

type SetTagsVariables = {
  input: Partial<SetTransactionTagsInput>;
};

type SetTagsOptions = MutationFunctionOptions<Web_SetTransactionTagsMutation, SetTagsVariables>;

type SetTagsResult = Promise<ExecutionResult<Web_SetTransactionTagsMutation>>;

type Props = {
  transaction: NonNullable<GetTransactionDrawerQuery['getTransaction']>;
  household: NonNullable<GetTransactionDrawerQuery['myHousehold']>;
  isEditingDisabled: boolean;
  disableUploads: boolean;
  errorText: string | null;
  files: Item<FileData>[];
  getInputProps: (props?: DropzoneInputProps | undefined) => DropzoneInputProps;
  isDragActive: boolean;
  isLoadingInitialData: boolean;
  onRemoveAttachmentClick: (params: OnRemoveAttachmentClickParams) => void;
  onUploadClick: () => void;
  openSplitModal: (id: GraphQlUUID) => void;
  setTransactionId: (id: GraphQlUUID) => void;
  transactionId: GraphQlUUID;
  updateTransaction: (options?: UpdateTransactionOptions) => UpdateTransactionResult;
  setTransactionTags: (options?: SetTagsOptions) => SetTagsResult;
  onClickDelete: () => void;
  isReviewModeActive: boolean;
} & DropzoneRootProps;

const TransactionDrawerBody: React.ForwardRefRenderFunction<HTMLDivElement, Props> = (
  {
    transaction,
    household,
    disableUploads,
    errorText,
    files,
    isEditingDisabled,
    getInputProps,
    isDragActive,
    isLoadingInitialData,
    onRemoveAttachmentClick,
    onUploadClick,
    openSplitModal,
    setTransactionId,
    transactionId,
    updateTransaction,
    setTransactionTags,
    onClickDelete,
    isReviewModeActive,
    ...dropZoneRootProps
  },
  ref,
) => {
  const { openErrorToast } = useToast();
  const { autoFocus } = useTransactionListContext();
  const { close } = useSideDrawerContext();
  const { showToast: showCreateTransactionRuleToast } = useTransactionRuleToastContext();

  const [
    ExplainTransactionModal,
    { isOpen: explainTransactionModalIsOpen, open: openExplainTransactionModal },
  ] = useModal();

  if (isLoadingInitialData || !transaction) {
    return <TransactionDrawerLoading />;
  }

  if (transaction === null || transaction === undefined) {
    return <TransactionDrawerNotFoundMessage onOkayClick={close} />;
  }
  const hasBeenSplit = transaction?.hasSplitTransactions;
  const currentGoalId = transaction?.goal?.id;
  const householdMembers = household?.users ?? [];
  const currentNeedsReviewBy = transaction?.needsReviewByUser?.id ?? null;

  const householdMembersOptions = [
    {
      value: ANYONE,
      label: 'Anyone',
    },
    ...householdMembers.map((user) => ({
      label: user.name,
      value: user.id,
    })),
  ];

  const {
    amount,
    plaidName,
    date,
    originalDate,
    notes,
    needsReview,
    needsReviewByUser,
    reviewedAt,
    pending,
    reviewedByUser,
    isSplitTransaction,
    isManual,
    splitTransactions,
    originalTransaction,
    account,
    tags,
    merchant: {
      id: merchantId,
      name: merchantName,
      transactionCount: merchantTransactionCount,
      logoUrl: merchantLogoUrl,
    },
    category: { id: categoryId },
  } = transaction;

  const defaultNeedsReviewBy = needsReview ? ANYONE : null;
  const currentTagIds = tags ? R.map(R.prop('id'), sortTags(tags)) : [];

  const handleUpdateError = () => {
    openErrorToast({ description: "Couldn't update transaction." });
  };

  const showCreateRuleToast = (values: Omit<TransactionRulePrefillValues, 'merchantName'>) => {
    showCreateTransactionRuleToast({ merchantName, ...values });
  };

  return (
    <Root {...dropZoneRootProps} ref={ref}>
      <Form
        initialValues={{
          date,
          notes,
          category: categoryId,
          goal: currentGoalId,
          merchantName,
          needsReview,
          reviewed: !!reviewedAt,
          reviewedByUserName: reviewedByUser?.name ?? '',
          reviewedAt,
          amount,
          amountType: amount < 0 ? AmountType.Debit : AmountType.Credit,
          tags: RA.isNotEmpty(currentTagIds) ? currentTagIds : undefined,
          needsReviewByUser: needsReview ? needsReviewByUser?.id ?? defaultNeedsReviewBy : null,
        }}
        onSubmit={async ({
          date: newDate,
          notes: newNotes,
          category: newCategoryId,
          goal: newGoalId,
          merchantName: newMerchantName,
          reviewed,
          amount: newAmount,
          amountType,
          tags: newTagIds,
          needsReviewByUser,
        }) => {
          // Only include values if changed
          // TODO find more generic way to only send changed values
          const newAmountSigned = isManual
            ? Math.abs(newAmount) * UNIT_FOR_AMOUNT_TYPE[amountType]
            : undefined;

          const needsReviewValue = !!needsReviewByUser;
          const needsReviewByValue = needsReviewByUser === ANYONE ? null : needsReviewByUser;

          const input = {
            id: transactionId,
            amount: newAmountSigned !== amount ? newAmountSigned : undefined,
            date: newDate !== date ? newDate : undefined,
            notes: newNotes !== notes ? newNotes : undefined,
            category: newCategoryId !== categoryId ? newCategoryId : undefined,
            goalId: newGoalId !== currentGoalId ? newGoalId : undefined,
            name: newMerchantName !== merchantName ? newMerchantName : undefined,
            reviewed: reviewed !== !!reviewedAt ? reviewed : undefined,
            needsReview: needsReviewValue !== needsReview ? needsReviewValue : undefined,
            needsReviewByUser:
              needsReviewByValue !== currentNeedsReviewBy ? needsReviewByValue : undefined,
          };

          const tagIds = RA.isNotNil(newTagIds) ? newTagIds : [];

          // Only fire the updateTransaction mutation if needed
          const shouldUpdateTransaction =
            R.keys(R.reject((value) => value === undefined, R.omit(['id'], input))).length > 0;

          // Only fire the setTransactionTags mutation if needed
          const shouldUpdateTransactionTags = !R.equals(sort(tagIds), sort(currentTagIds));

          const updateTransactionPromise = shouldUpdateTransaction
            ? updateTransaction({
                variables: { input },
                onError: handleUpdateError,
                optimisticResponse: updateTransactionOptimisticResponse(
                  transactionUpdateInputToOptimisticResponse(transaction, input),
                ),
              })
            : undefined;

          const setTransactionTagsPromise = shouldUpdateTransactionTags
            ? setTransactionTags({
                variables: { input: { transactionId, tagIds } },
                optimisticResponse: setTransactionTagsOptimisticResponse(transactionId, tagIds),
              })
            : undefined;

          await Promise.all([updateTransactionPromise, setTransactionTagsPromise]);
        }}
        submitOnChangeDebounceMs={350}
        submitOnChange
      >
        <HeaderRoot>
          {isEditingDisabled && (
            <PendingBanner type="info">
              This transaction is <Text weight="medium">pending</Text>. You can make changes to it
              once it has posted to your account. These transactions will not count toward your
              budget or cash flow reports until they post.
            </PendingBanner>
          )}
          <TopInputContainer justifyBetween alignCenter full>
            <Tooltip place="top" effect="solid" content="View merchant" minTooltipLeft={0}>
              <RouteLink to={routes.merchants({ id: merchantId })}>
                <MerchantLogo size={64} url={merchantLogoUrl} />
              </RouteLink>
            </Tooltip>
            <FlexContainer column alignEnd>
              {isManual && !isSplitTransaction && !hasBeenSplit ? (
                <TransactionDrawerAmountField />
              ) : (
                <AmountText value={amount} />
              )}
              <StyledAccountLink account={account} />
            </FlexContainer>
          </TopInputContainer>

          {isEditingDisabled ? (
            <StyledTitle>{merchantName}</StyledTitle>
          ) : (
            <TopInputContainer justifyBetween alignCenter full>
              <MerchantSelectContainer>
                <MerchantSelect
                  hideUntilHover
                  fullWidthTrigger
                  transactionId={transactionId}
                  transactionMerchantName={merchantName}
                  transactionProviderDescription={plaidName}
                  alwaysShowBorder={false}
                  value={{ value: merchantId, label: merchantName }}
                  onChange={async (newMerchantName) => {
                    // Prevent updates with empty name when moving between TXNs using the keyboard
                    if (newMerchantName) {
                      // This selector is fully controlled so it won't trigger the form's onSubmit
                      updateTransaction({
                        variables: { input: { id: transactionId, name: newMerchantName } },
                        onCompleted: () => showCreateRuleToast({ newMerchantName }),
                        onError: handleUpdateError,
                      });
                    }
                  }}
                />
              </MerchantSelectContainer>
            </TopInputContainer>
          )}

          <RouteLink to={routes.merchants({ id: merchantId })}>
            View {pluralize('transaction', merchantTransactionCount, true)}
          </RouteLink>
          {!!plaidName && (
            <TransactionDrawerOriginalStatement
              originalStatement={plaidName}
              onExplainTransaction={openExplainTransactionModal}
            />
          )}
        </HeaderRoot>
        <BodyRoot>
          {hasBeenSplit ? (
            <TransactionDrawerSplitMessage
              data={splitTransactions}
              onTransactionPreviewClick={setTransactionId}
            />
          ) : (
            <>
              {originalTransaction && (
                <SplitBanner
                  originalTransaction={originalTransaction}
                  onClickOpenSplits={() => openSplitModal(originalTransaction.id)}
                />
              )}
              {isManual && (
                <TransactionDrawerFieldRow label="Type">
                  <SelectField name="amountType" hideLabel options={AMOUNT_TYPE_OPTIONS} />
                </TransactionDrawerFieldRow>
              )}
              <TransactionDrawerFieldRow label="Date">
                <DateField
                  hideLabel
                  name="date"
                  displayFormat="MMMM D, yyyy"
                  disabled={isEditingDisabled}
                />
                {date !== originalDate && (
                  <Text size="xsmall" color="textLight" weight="medium">
                    {`Original Date: ${DateTime.fromISO(originalDate).toFormat('MMMM d, yyyy')}`}
                  </Text>
                )}
              </TransactionDrawerFieldRow>
              <TransactionDrawerFieldRow
                label="Category"
                right={
                  isSplitTransaction ? null : (
                    <Tooltip
                      place="left"
                      effect="solid"
                      content={SPLIT_PENDING_DISABLED_TEXT}
                      disable={!pending}
                    >
                      <span>
                        <StyledTextButton
                          onClick={() => openSplitModal(transactionId)}
                          disabled={pending}
                        >
                          <SplitIcon />
                          <Text size="small">Split</Text>
                        </StyledTextButton>
                      </span>
                    </Tooltip>
                  )
                }
              >
                <SelectField
                  name="category"
                  disabled={isEditingDisabled}
                  InputComponent={CategorySelect}
                  transactionProviderDescription={plaidName}
                  afterChange={(option) => {
                    // The rule creation toast will be shown even if the TXN update fails
                    // That's fine since we only care about the user's intent to create the rule
                    if (isSingleValue(option)) {
                      showCreateRuleToast({ newCategoryId: option.value });
                    }
                  }}
                  transactionId={transactionId}
                  hideLabel
                  alwaysShowBorder
                  isCreatable
                />
              </TransactionDrawerFieldRow>

              {explainTransactionModalIsOpen && plaidName && (
                <ExplainTransactionModal>
                  <TransactionExplainModal id={transactionId} description={plaidName} />
                </ExplainTransactionModal>
              )}

              {!isEditingDisabled && (
                <>
                  <TransactionDrawerFieldRow label="Goal">
                    <SelectField
                      name="goal"
                      hideLabel
                      InputComponent={GoalSelect}
                      transactionId={transactionId}
                      afterChange={(option) => {
                        if (isSingleValue(option)) {
                          showCreateRuleToast({
                            newGoalId: option.value,
                            accountIds: [account.id],
                            toastId: `${transactionId}-goal-rule`,
                          });
                        }
                      }}
                    />
                  </TransactionDrawerFieldRow>
                  <TransactionDrawerFieldRow label="Notes">
                    <TextAreaField
                      name="notes"
                      hideLabel
                      hideResizeHandle
                      placeholder="Add notes to this transaction..."
                      minRows={2}
                      autoFocus={autoFocus?.notes}
                    />
                  </TransactionDrawerFieldRow>
                  <PremiumFeatureOverlayTrigger feature={ProductFeature.tags}>
                    {({ hasAccess }) => (
                      <TransactionDrawerFieldRow
                        label="Tags"
                        right={!hasAccess && <PremiumBadge />}
                      >
                        <SelectField
                          name="tags"
                          InputComponent={TransactionTagSelect}
                          autoFocus={autoFocus?.tags}
                          hideLabel
                          isDisabled={!hasAccess}
                        />
                      </TransactionDrawerFieldRow>
                    )}
                  </PremiumFeatureOverlayTrigger>

                  {!reviewedAt && (
                    <TransactionDrawerFieldRow label="Needs review by">
                      <SelectField
                        name="needsReviewByUser"
                        placeholder="Assign to..."
                        label="Needs review by"
                        hideLabel
                        isClearable
                        menuPortalTarget={document.body}
                        options={householdMembersOptions}
                      />
                    </TransactionDrawerFieldRow>
                  )}

                  <PremiumFeatureOverlayTrigger feature={ProductFeature.attachments}>
                    {({ hasAccess }) => (
                      <TransactionDrawerFieldRow
                        label="Receipts & attachments"
                        right={!hasAccess && <PremiumBadge />}
                      >
                        {RA.isNotNil(errorText) && (
                          <ErrorBanner type="error">{errorText}</ErrorBanner>
                        )}
                        <DragAndDropUploadInput
                          isDragActive={isDragActive}
                          getInputProps={getInputProps}
                          hasUpload={files.length > 0}
                          disableUploads={disableUploads}
                          onUploadClick={onUploadClick}
                          disabled={!hasAccess}
                        >
                          {files.map(
                            ({
                              value: {
                                publicId,
                                localUri,
                                name,
                                displaySize,
                                progressPercent,
                                attachmentId,
                                originalAssetUrl,
                                extension,
                              },
                              remove,
                            }) => (
                              <AttachmentItem
                                key={`${publicId}:${localUri}`}
                                name={name}
                                displaySize={displaySize}
                                progressPercent={progressPercent}
                                useThumbnailPlaceholder={extension === 'pdf'}
                                onRemoveClick={() =>
                                  onRemoveAttachmentClick({ attachmentId, remove })
                                }
                                publicId={publicId}
                                localUri={localUri}
                                originalAssetUrl={originalAssetUrl}
                              />
                            ),
                          )}
                        </DragAndDropUploadInput>
                      </TransactionDrawerFieldRow>
                    )}
                  </PremiumFeatureOverlayTrigger>
                </>
              )}

              <DangerArea>
                <Divider text="Other Options" />
                <DangerButton type="button" onClick={onClickDelete}>
                  Delete transaction
                </DangerButton>
              </DangerArea>
            </>
          )}
        </BodyRoot>
      </Form>
    </Root>
  );
};

export default React.forwardRef(TransactionDrawerBody);
