import { useMutation } from '@apollo/client';
import type { PaymentMethod } from '@stripe/stripe-js';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { throwIfHasMutationErrors } from 'common/lib/form/errors';
import { GET_SUBSCRIPTION_STATUS_QUERY } from 'common/lib/graphQl/billing';
import usePollQueryUntil from 'common/lib/hooks/usePollQueryUntil';
import { getUser } from 'common/state/user/selectors';
import { track, trackDedupedFacebookPixelEvent } from 'lib/analytics/segment';
import { getDedupEventId } from 'lib/analytics/util';
import { useDispatch } from 'lib/hooks';
import { fetchUserAction } from 'state/user/thunks';

import { AnalyticsEventNames, PurchaseEventNames } from 'common/constants/analytics';
import { MS_PER_SECOND } from 'common/constants/time';

import { gql } from 'common/generated/gql';

const POLL_TIMEOUT_MS = 30 * MS_PER_SECOND;

/** Hook used to create a subscription using a stripe price id & payment method. */
const useCreateSubscription = () => {
  const dispatch = useDispatch();
  const [createSubscriptionMutation, { loading }] = useMutation(CREATE_SUBSCRIPTION);

  const user = useSelector(getUser);

  const [startPolling] = usePollQueryUntil(GET_SUBSCRIPTION_STATUS_QUERY, {
    pollUntil: (data) => Boolean(data?.subscription.hasPremiumEntitlement),
    pollTimeoutMs: POLL_TIMEOUT_MS,
  });

  const createSubscription = useCallback(
    async (
      paymentMethod: PaymentMethod | null,
      stripePriceId: string,
      stripePromoCode: string | null,
    ) => {
      const { data } = await createSubscriptionMutation({
        variables: {
          input: { paymentMethodId: paymentMethod?.id || '', stripePriceId, stripePromoCode },
        },
      });
      throwIfHasMutationErrors(data);
      track(PurchaseEventNames.PurchaseCompleted, { stripePriceId });

      // Send an extra, standard event to FB that can be deduplicated.
      const eventId = user?.external_id ? getDedupEventId(user.external_id) : undefined;
      trackDedupedFacebookPixelEvent(AnalyticsEventNames.AddPaymentInfo, {}, eventId);

      // wait for polling to stop before returning
      await startPolling();

      // We should refetch the user to get the latest information on
      // their sponsor status on Redux layer, in case they activate
      // their subscription with a sponsor promo code. By doing this,
      // we can safely redirect them to the advisor portal after onboarding.
      //
      // We're fetching the user state from REST because it's the one we use to
      // determine if the user is logged in, show the pages, redirect to login, etc.
      // It should prevent flashes in the UI where the advisor briefly sees
      // the dashboard then is redirected to the portal.
      if (data?.createStripeSubscription?.subscription?.isSponsor) {
        await dispatch(fetchUserAction());
      }
    },
    [createSubscriptionMutation, dispatch],
  );

  return { createSubscription, loading };
};

const CREATE_SUBSCRIPTION = gql(`
  mutation Web_CreateSubscription($input: CreateStripeSubscriptionMutationInput!) {
    createStripeSubscription(input: $input) {
      subscription {
        id
        ...SubscriptionFields
      }
      errors {
        message
      }
    }
  }
`);

export default useCreateSubscription;
