import { transparentize } from 'polished';
import React from 'react';
import styled from 'styled-components';

import AsyncButton from 'components/lib/ui/button/AsyncButton';
import { primaryButtonMixin } from 'components/lib/ui/button/PrimaryButton';

import boxShadow from 'common/lib/styles/boxShadow';
import { color } from 'common/lib/theme/dynamic';
import variables from 'common/lib/theme/variables';
import { ifDark } from 'common/lib/theme/variables/types';

import type { StyleMixin } from 'common/types/Styles';

const TRANSPARENCY_HEIGHT_PX = 50;
export const BUTTON_MAX_WIDTH_PX = 440;

const getBackgroundColor: StyleMixin = ({ theme }) =>
  ifDark(variables.color.background.fullscreenHeader, color.white)({ theme });

const Root = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: ${getBackgroundColor};
  padding: ${({ theme }) => theme.spacing.large};
  display: flex;
  justify-content: center;
  align-items: center;
  ${boxShadow.medium}

  &::before {
    content: '';
    position: absolute;
    top: -${TRANSPARENCY_HEIGHT_PX}px;
    height: ${TRANSPARENCY_HEIGHT_PX}px;
    left: 0;
    right: 0;
    background: linear-gradient(
      to bottom,
      ${({ theme }) => transparentize(1, getBackgroundColor({ theme }))},
      ${getBackgroundColor}
    );
    pointer-events: none;
    backdrop-filter: blur(5px);
    mask-image: linear-gradient(to bottom, transparent, black ${TRANSPARENCY_HEIGHT_PX}px);
  }
`;

const Button = styled(AsyncButton).attrs({ size: 'large' })`
  width: 100%;
  max-width: ${BUTTON_MAX_WIDTH_PX}px;
  ${primaryButtonMixin}
`;

type Props = (
  | {
      customButton?: never;
      onClickNext: () => void;
    }
  | {
      customButton: React.ReactNode;
      onClickNext?: never;
    }
) & {
  isLoading?: boolean;
};

const FooterButton = ({ customButton, isLoading, onClickNext }: Props) => (
  <Root>
    {customButton ?? (
      <Button onClick={onClickNext} pending={isLoading}>
        Continue
      </Button>
    )}
  </Root>
);

export default FooterButton;
