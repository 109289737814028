import routes from 'constants/routes';

export const linkUrlForRecurringItem = ({
  id,
  isMerchant,
  queryParams,
}: {
  id: string;
  isMerchant: boolean;
  queryParams?: Record<string, unknown>;
}) => {
  if (!id) {
    // there is a case where id is an empty string and was causing the app to crash
    return '';
  } else if (isMerchant) {
    return routes.merchants({ id, queryParams });
  } else {
    return routes.accounts.accountDetails({ id, queryParams });
  }
};
