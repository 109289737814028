import axios from 'axios';

const customerioApi = axios.create({
  baseURL: 'https://email.monarchmoney.com',
});

/**
 * Notify Customer.io that a universal link was clicked for tracking purposes.
 *
 * https://docs.customer.io/journeys/universal-links/#track-universal-and-app-links-in-email
 */
export const trackCustomerIOLinkClicked = async (cioLinkId: string) => {
  const response = await customerioApi.post(`/click/${cioLinkId}`);

  if (response.status !== 200) {
    throw new Error(`Failed to track Customer.io link click: ${response.status}`);
  }

  return response;
};
