import type { RecurringTransactionFilter } from 'common/generated/graphql';

export const getRecurringFilters = (
  filters: Partial<RecurringTransactionFilter>,
): RecurringTransactionFilter => ({
  accounts: filters.accounts ?? undefined,
  categories: filters.categories ?? undefined,
  isCompleted: filters.isCompleted ?? undefined,
  recurringTypes: filters.recurringTypes ?? undefined,
  frequencies: filters.frequencies ?? undefined,
});
