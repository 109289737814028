import * as RA from 'ramda-adjunct';
import * as React from 'react';
import styled from 'styled-components';

import withSensitiveData from 'components/lib/higherOrder/withSensitiveData';

import { getTransactionDisplayAmount } from 'common/utils/Currency';

type Props = {
  className?: string;
  value: number;
  /** Round to the nearest dollar. */
  round?: boolean;
};

const Root = styled.span<{ isInflow: boolean }>`
  color: ${({ theme, isInflow }) => theme.color[isInflow ? 'greenText' : 'text']};
`;

// Similar to Currency, but makes inflow values, i.e. > 0,  green
const TransactionCurrency = ({ value, className, round }: Props) => {
  const isInflow = RA.isNotNil(value) && value > 0;

  return (
    <Root className={className} isInflow={isInflow}>
      {RA.isNotNil(value) ? getTransactionDisplayAmount(value, round) : '-'}
    </Root>
  );
};

export default withSensitiveData(TransactionCurrency);
