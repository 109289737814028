import React, { useMemo } from 'react';
import styled from 'styled-components';

import NetWorthChartOptions from 'components/accounts/NetWorthChartOptions';
import DropdownMenu, { DropdownMenuItem } from 'components/lib/ui/DropdownMenu';
import FlexContainer from 'components/lib/ui/FlexContainer';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import { OverlayTrigger } from 'components/lib/ui/popover';
import Stack from 'components/utils/Stack';

import type { DateRange, NetWorthChartType } from 'common/lib/accounts/accountCharts';
import { getDateRanges } from 'common/lib/accounts/accountCharts';

import type { TimeframeBase } from 'common/constants/timeframes';

const Container = styled(FlexContainer)`
  gap: ${({ theme }) => theme.spacing.default};
`;

const StyledDefaultButton = styled(DefaultButton)`
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: ${({ theme }) => theme.fontWeight.book};
  display: flex;
  justify-content: space-between;
`;

const ChartOptionButton = styled(StyledDefaultButton)`
  width: 230px;
`;

const TimeFrameOptionButton = styled(StyledDefaultButton)`
  width: 160px;
`;

const NET_WORTH_CHART_TYPES_LABELS = {
  performance: 'Net worth performance',
  breakdown: 'Net worth breakdown',
};

export const NET_WORTH_CHART_TIMEFRAME_OPTIONS: {
  value: TimeframeBase;
  label: string;
}[] = [
  {
    value: 'month',
    label: 'Monthly',
  },
  {
    value: 'quarter',
    label: 'Quarterly',
  },
  {
    value: 'year',
    label: 'Yearly',
  },
];

type Props = {
  activeTimeframeIndex: number;
  selectedDateRange: DateRange;
  setSelectedDateRange: (dateRange: DateRange) => void;
  selectedAccountTypeDisplay: string | null;
  onChangeTimeframe: (timeframe: TimeframeBase) => void;
  chartType: NetWorthChartType;
  onChangeChartType: (chart: NetWorthChartType) => void;
  availableAccountTypes: { name: string; display: string; group: string }[];
  onChangeSelectedAccountType: (accountType: string | null) => void;
};

const NetWorthChartControls = ({
  chartType,
  activeTimeframeIndex,
  availableAccountTypes,
  selectedAccountTypeDisplay,
  selectedDateRange,
  onChangeSelectedAccountType,
  onChangeTimeframe,
  onChangeChartType,
  setSelectedDateRange,
}: Props) => {
  const activeTimeframe = NET_WORTH_CHART_TIMEFRAME_OPTIONS[activeTimeframeIndex];

  const availableAccountTypesByGroup = useMemo(() => {
    const availableTypes = availableAccountTypes.reduce(
      (acc, accountType) => {
        const group = accountType.group || 'Other';
        if (!acc[group]) {
          acc[group] = [];
        }
        acc[group] = [...acc[group], accountType];
        return acc;
      },
      {} as { [key: string]: any },
    );

    if (availableTypes.asset) {
      availableTypes.asset = [
        { name: 'ALL_ASSETS', display: 'Total Assets', group: 'asset' },
        ...availableTypes.asset,
      ];
    }

    if (availableTypes.liability) {
      availableTypes.liability = [
        { name: 'ALL_LIABILITIES', display: 'Total Liabilities', group: 'liability' },
        ...availableTypes.liability,
      ];
    }

    return availableTypes;
  }, [availableAccountTypes]);

  const contextualMenuOptions = useMemo(() => {
    if (chartType === 'performance') {
      return getDateRanges().map((dateRange) => ({
        value: dateRange,
        label: dateRange.longDisplay,
        onClick: () => setSelectedDateRange(dateRange),
      }));
    } else {
      return NET_WORTH_CHART_TIMEFRAME_OPTIONS.map((timeframe) => ({
        value: timeframe.value,
        label: timeframe.label,
        onClick: () => onChangeTimeframe(timeframe.value),
      }));
    }
  }, [chartType, setSelectedDateRange, onChangeTimeframe]);

  return (
    <Container>
      <OverlayTrigger
        placement="bottom-end"
        overlay={
          <DropdownMenu>
            <Stack
              initial={{
                component: NetWorthChartOptions,
                props: {
                  assetOptions: availableAccountTypesByGroup.asset,
                  liabilityOptions: availableAccountTypesByGroup.liability,
                  onChangeChartType,
                  onChangeSelectedAccountType,
                },
              }}
            />
          </DropdownMenu>
        }
      >
        {({ toggleOpen, isOpen }) => (
          <ChartOptionButton onClick={toggleOpen} active={isOpen}>
            <span>{selectedAccountTypeDisplay || NET_WORTH_CHART_TYPES_LABELS[chartType]}</span>
            <ButtonIcon name="chevron-down" />
          </ChartOptionButton>
        )}
      </OverlayTrigger>

      <OverlayTrigger
        placement="bottom-end"
        overlay={
          <DropdownMenu>
            {contextualMenuOptions.map(({ label, onClick }) => (
              <DropdownMenuItem key={label} onClick={onClick}>
                {label}
              </DropdownMenuItem>
            ))}
          </DropdownMenu>
        }
      >
        {({ toggleOpen, isOpen }) => (
          <TimeFrameOptionButton onClick={toggleOpen} active={isOpen}>
            <span>
              {chartType === 'performance' ? selectedDateRange?.longDisplay : activeTimeframe.label}
            </span>
            <ButtonIcon name="chevron-down" />
          </TimeFrameOptionButton>
        )}
      </OverlayTrigger>
    </Container>
  );
};

export default NetWorthChartControls;
