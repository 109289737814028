import React from 'react';
import styled from 'styled-components';

import Banner from 'components/lib/ui/Banner';
import Flex from 'components/lib/ui/Flex';
import TextButton from 'components/lib/ui/TextButton';

const StyledTextButton = styled(TextButton)`
  margin-top: ${(props) => props.theme.spacing.large};
  display: inline;
  font-weight: bold;
  font-size: inherit;
  padding: 0;
`;

type Props = {
  sponsorName?: string;
  sponsorProfilePictureUrl?: string | null;
  hideRevokeAccessButton?: boolean;
  onRevokeAccess?: () => Promise<void> | void;
  className?: string;
};

const SponsoredSubscriptionBanner = ({
  sponsorName,
  sponsorProfilePictureUrl,
  hideRevokeAccessButton = false,
  onRevokeAccess,
  className,
}: Props) => (
  <Banner type="info" className={className}>
    <Flex>
      <div>
        You currently have access to <b>Monarch Premium</b> through your financial
        professional,&nbsp;
        <b>{sponsorName}</b>.
        <br />
        <br />
        As your financial professional, they have full access to your Monarch account and can view
        it at any time.
      </div>
    </Flex>
    {!hideRevokeAccessButton && (
      <StyledTextButton onClick={onRevokeAccess}>Revoke professional access</StyledTextButton>
    )}
  </Banner>
);

export default SponsoredSubscriptionBanner;
