import * as React from 'react';
import styled from 'styled-components';

import ReturnPercentPill from 'components/investments/ReturnPercentPill';
import SparkLineChart from 'components/lib/charts/SparkLineChart';
import FlexContainer from 'components/lib/ui/FlexContainer';

import { spacing } from 'common/lib/theme/dynamic';
import isV2Theme from 'common/lib/theme/isV2Theme';
import { formatCurrency } from 'common/utils/Currency';
import useTheme from 'lib/hooks/useTheme';

type Props = {
  ticker: string;
  securityName: string;
  sparkLineData?: { returnPercent: number }[];
  currentPriceDollars: number;
  returnPercent: number | null;
};

const Root = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spacing.default} ${isV2Theme(spacing.large, spacing.xlarge)};
  justify-content: space-between;
  align-items: center;
  overflow-x: hidden;
  border-top: 1px solid ${({ theme }) => theme.color.grayBackground};
`;

const SecurityTickerText = styled.span`
  margin-right: ${({ theme }) => theme.spacing.xsmall};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const TextContainer = styled.div`
  overflow-x: hidden;
  display: flex;
`;

const SecurityNameText = styled.span`
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.color.textLight};
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CurrentPriceText = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-right: ${({ theme }) => theme.spacing.default};
  text-align: right;
`;

const InvestmentsDashboardWidgetTopMoverRow = ({
  ticker,
  securityName,
  sparkLineData,
  currentPriceDollars,
  returnPercent,
}: Props) => {
  const theme = useTheme();

  return (
    <Root>
      <TextContainer>
        <SecurityTickerText>{ticker}</SecurityTickerText>
        <SecurityNameText>{securityName}</SecurityNameText>
      </TextContainer>
      <FlexContainer alignCenter>
        {sparkLineData && (
          <SparkLineChart
            data={sparkLineData}
            dataKey="returnPercent"
            widthPx={64}
            heightPx={24}
            referenceLineY={0}
            lineColor={(returnPercent ?? 0) < 0 ? theme.color.red : theme.color.green}
          />
        )}
        <CurrentPriceText>{formatCurrency(currentPriceDollars)}</CurrentPriceText>
        <ReturnPercentPill returnPercent={returnPercent} />
      </FlexContainer>
    </Root>
  );
};

export default InvestmentsDashboardWidgetTopMoverRow;
