import getColorPalette from 'common/lib/theme/getColorPalette';
import staticTheme from 'common/lib/theme/staticTheme';
import type { ThemeContext } from 'common/lib/theme/types';

export const makeTheme = (context: ThemeContext) => {
  const { uiTheme } = context;
  const color = getColorPalette(context);

  const spacing = {
    ...staticTheme.spacing,
    gutter: '16px', // used for spacing between containers
  };

  return {
    ...staticTheme,
    spacing,
    uiTheme,
    isV2ThemeEnabled: true,
    color,
  } as const;
};
