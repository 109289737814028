import type { DateTime } from 'luxon';
import * as R from 'ramda';
import { useCallback, useMemo } from 'react';

import { GET_BUDGET_STATUS_QUERY } from 'common/lib/graphQl/budgets';
import { GET_JOINT_PLANNING_DATA } from 'common/lib/graphQl/planning';
import useUpdateBudgetItemMutation from 'common/lib/hooks/budget/useUpdateBudgetItemMutation';
import useUpdateFlexBudgetMutation from 'common/lib/hooks/budget/useUpdateFlexBudgetMutation';
import useUpdateGoalV2PlannedContribution from 'common/lib/hooks/goalsV2/useUpdateGoalV2PlannedContribution';
import useLoading from 'common/lib/hooks/useLoading';
import { useMockedQueryData } from 'common/lib/hooks/useMockableQuery';
import useQuery from 'common/lib/hooks/useQuery';
import type { PlanState } from 'lib/hooks/plan/usePlanState';
import { PlanTimeframe } from 'lib/hooks/plan/usePlanState';
import { usePlanningData } from 'lib/hooks/planning/usePlanningData';
import type { PlanSectionType } from 'lib/plan/Adapters';

import { BudgetVariability } from 'common/generated/graphql';

type UpdateBudgetItemArgs = {
  startDate: DateTime;
  id: string;
  amount: number;
  isGroup?: boolean;
  applyToFuture: boolean;
};

type Options = {
  debounceRefetch?: number | false;
};

/**
 * This hook handles all of the data fetching for the Plan page.
 */
const usePlanQuery = (state: PlanState, options?: Options) => {
  const { columnDisplayInterval, timeframe, doesNotHaveAccounts } = state;

  const { fetchedDateRange, data, initialQueryVariables, refetch } = usePlanningData(
    columnDisplayInterval,
    timeframe !== PlanTimeframe.Yearly,
  );

  const [isRefetching, refetchWithLoading] = useLoading(refetch);

  const { createBudgetItemUpdater } = useUpdateBudgetItemMutation({
    onMutationsComplete: refetchWithLoading,
    debounce: options?.debounceRefetch,
  });

  const updateBudgetItemMutation = useCallback(
    ({ startDate, id, amount, isGroup, applyToFuture }: UpdateBudgetItemArgs) =>
      createBudgetItemUpdater({
        startDate,
        categoryId: isGroup ? undefined : id,
        categoryGroupId: isGroup ? id : undefined,
      })({
        amount,
        applyToFuture,
      }),
    [createBudgetItemUpdater],
  );

  const { updateGoalV2PlannedContribution } = useUpdateGoalV2PlannedContribution({
    onCompleted: refetch,
  });

  const { createFlexBudgetUpdater } = useUpdateFlexBudgetMutation({
    onCompleted: refetch,
  });

  const updateCellValue = useMemo(
    () =>
      R.memoizeWith(
        (id, date) => `${id}-${date.toISODate()}`,
        (itemId: string, date: DateTime, sectionType: PlanSectionType, isGroup?: boolean) =>
          (amount: number, applyToFuture: boolean) => {
            if (sectionType === 'savings') {
              updateGoalV2PlannedContribution(date, itemId, amount);
            } else if (itemId === BudgetVariability.FLEXIBLE) {
              createFlexBudgetUpdater(date)({ amount, applyToFuture });
            } else if (isGroup) {
              updateBudgetItemMutation({
                startDate: date,
                id: itemId,
                amount,
                isGroup: true,
                applyToFuture,
              });
            } else {
              updateBudgetItemMutation({ startDate: date, id: itemId, amount, applyToFuture });
            }
          },
      ),
    [createFlexBudgetUpdater, updateGoalV2PlannedContribution, updateBudgetItemMutation],
  );

  const { data: budgetStatusData, refetch: refetchBudgetStatus } =
    useQuery(GET_BUDGET_STATUS_QUERY);

  const { budgetStatus } = budgetStatusData || {};
  const doesNotHaveBudget = budgetStatus?.hasBudget === false;
  const showMockData = doesNotHaveAccounts || doesNotHaveBudget;

  const [mockData] = useMockedQueryData(GET_JOINT_PLANNING_DATA, {
    variables: initialQueryVariables,
    skip: !showMockData,
  });

  return {
    data: showMockData ? mockData ?? undefined : data,
    doesNotHaveBudget,
    budgetStatus,
    refetchBudgetStatus,
    fetchedDateRange: showMockData ? columnDisplayInterval : fetchedDateRange,
    refetch: refetchWithLoading,
    updateCellValue,
    isRefetching,
  };
};

export default usePlanQuery;
