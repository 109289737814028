import { useMutation } from '@apollo/client';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import AdviceQuestionnaireLoadingModal from 'components/advice/AdviceQuestionnaireLoadingModal';
import QuestionForm from 'components/advice/QuestionForm';
import QuestionHeader from 'components/advice/QuestionHeader';
import QuestionnaireHeader from 'components/advice/QuestionnaireHeader';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Page from 'components/lib/ui/Page';

import useQuery from 'common/lib/hooks/useQuery';
import variables from 'common/lib/theme/variables';
import { getQuestionnaireRoute, getQuestionNameFromRouteParameter } from 'lib/advice/questionnaire';
import useModal from 'lib/hooks/useModal';

import { AdviceQuestionnaireName, HOME_CATEGORY, AdviceItemType } from 'constants/advice';
import routes from 'constants/routes';

import { gql } from 'common/generated/gql';
import type { AnswerProfileQuestionInput } from 'common/generated/graphql';
import type RouteProps from 'types/RouteProps';

const StyledPage = styled(Page)`
  height: 100vh;
  background-color: ${variables.color.background.fullscreenHeader};
`;

const Container = styled(FlexContainer).attrs({ column: true, alignCenter: true })`
  flex: 1;
  padding: ${({ theme }) => theme.spacing.xlarge};

  @media (min-width: ${({ theme }) => theme.breakPoints.sm}px) {
    padding: ${({ theme }) => theme.spacing.xxxxlarge};
  }
`;

const Column = styled(FlexContainer).attrs({ column: true, alignCenter: true })`
  max-width: 672px;
  width: 100%;
`;

const AdviceQuestionnaire = ({
  match: {
    params: { questionnaireName, questionName: questionNameParam },
  },
}: RouteProps<typeof routes.advice.questionnaire>) => {
  const questionName = getQuestionNameFromRouteParameter(questionNameParam);

  const { push } = useHistory();

  const [LoadingModal, { open: openLoadingModal, close: closeLoadingModal }] = useModal();

  const { data, isLoadingInitialData } = useQuery(QUERY, {
    variables: {
      questionnaireName,
      questionName,
    },
  });
  const { profileQuestionnaire } = data ?? {};
  const { state, question } = profileQuestionnaire ?? {};
  const { numQuestions = 0 } = state ?? {};
  const { index } = question ?? {};
  const questionIndex = index ?? 0;
  const progress = (questionIndex + 1) / numQuestions;
  const isLastQuestion = index === numQuestions - 1;

  const [answerQuestion] = useMutation(ANSWER_QUESTION_MUTATION, {
    update: (proxy, { data }) => {
      const { answerProfileQuestion } = data ?? {};
      const { nextQuestion, questionnaireState } = answerProfileQuestion ?? {};

      if (nextQuestion && profileQuestionnaire && questionnaireState) {
        proxy.writeQuery({
          query: QUERY,
          variables: { questionnaireName, questionName: nextQuestion.name },
          data: {
            __typename: 'Query',
            profileQuestionnaire: {
              ...profileQuestionnaire,
              question: nextQuestion,
              state: questionnaireState,
            },
          },
        });

        push(getQuestionnaireRoute(questionnaireName, nextQuestion.name));
      } else if (
        questionnaireState?.completed &&
        questionnaireName === AdviceQuestionnaireName.General
      ) {
        openLoadingModal();
      } else if (questionnaireState?.completed) {
        push(
          routes.advice({
            category: HOME_CATEGORY,
            queryParams: { scroll: AdviceItemType.Objective },
          }),
        );
      }
    },
  });

  const fetchAnswerMutation = useCallback(
    (input: Pick<AnswerProfileQuestionInput, 'answer' | 'dontKnow'>) =>
      question &&
      answerQuestion({
        variables: {
          input: {
            ...input,
            questionName: question.name,
            questionnaireName,
          },
        },
      }),
    [question, questionnaireName, answerQuestion],
  );

  const onSubmit = useCallback(
    (answer: string) => Promise.resolve(fetchAnswerMutation({ answer, dontKnow: false })),
    [fetchAnswerMutation],
  );

  const onClickSkip = useCallback(
    () => Promise.resolve(fetchAnswerMutation({ dontKnow: true, answer: null })),
    [fetchAnswerMutation],
  );

  return (
    <>
      <StyledPage
        name="Advice Questionnaire"
        overrideTitle={question?.title}
        scrollKey={question?.name} // Reset scroll on each question
        header={
          <QuestionnaireHeader
            progress={isLoadingInitialData ? 0 : progress}
            showBackButton={questionnaireName !== AdviceQuestionnaireName.Objectives}
            showCancelButton={questionnaireName === AdviceQuestionnaireName.Objectives}
            showSaveButton={questionnaireName !== AdviceQuestionnaireName.Objectives}
          />
        }
        isLoading={isLoadingInitialData}
      >
        <Container>
          {question && (
            <Column>
              <QuestionHeader question={question} />
              <QuestionForm
                question={question}
                onSubmit={onSubmit}
                onClickSkip={onClickSkip}
                allowSkip={questionnaireName !== AdviceQuestionnaireName.Objectives}
                nextButtonText={
                  questionnaireName === AdviceQuestionnaireName.Objectives && isLastQuestion
                    ? 'Done'
                    : 'Next'
                }
              />
            </Column>
          )}
        </Container>
      </StyledPage>
      <LoadingModal onClose={() => push(routes.advice({}))}>
        <AdviceQuestionnaireLoadingModal onAnimationEnd={closeLoadingModal} />
      </LoadingModal>
    </>
  );
};

AdviceQuestionnaire.fragments = {
  StateFields: gql(`
  fragment StateFields on ProfileQuestionnaireState {
    numQuestionsAnswered
    numQuestions
    completed
  }
`),
  QuestionFields: gql(`
  fragment QuestionFields on ProfileQuestion {
    index
    ...QuestionHeaderFields
    ...QuestionFormFields
  }
  `),
};

const QUERY = gql(`
  query Web_AdviceQuestionnaire($questionnaireName: String!, $questionName: String!) {
    profileQuestionnaire(name: $questionnaireName) {
      name
      state {
        ...StateFields
      }
      question(name: $questionName) {
        ...QuestionFields
      }
    }
  }
`);

const ANSWER_QUESTION_MUTATION = gql(`
  mutation Web_AnswerQuestionMutation($input: AnswerProfileQuestionInput!) {
    answerProfileQuestion(input: $input) {
      success
      nextQuestion {
        ...QuestionFields
      }
      questionnaireState {
        ...StateFields
      }
    }
  }
`);

export default AdviceQuestionnaire;
