import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';

import Card from 'components/lib/ui/Card';
import ContentLoader from 'components/lib/ui/ContentLoader';

const NUM_ROWS = 6;

const PlaceholderAmounts = () => (
  <>
    <rect x="20%" y="24" rx="5" ry="5" width="200" height="20" />
    <rect x="40%" y="24" rx="5" ry="5" width="200" height="20" />
    <rect x="60%" y="24" rx="5" ry="5" width="200" height="20" />
    <rect x="88%" y="24" rx="5" ry="5" width="150" height="20" />
  </>
);

const CategoryRowRoot = styled.div`
  height: 68px;
  border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
`;

const RowLoading = () => (
  <CategoryRowRoot>
    <ContentLoader>
      <circle cx="50" cy="34" r="20" />
      <rect x="80" y="24" rx="5" ry="5" width="10%" height="20" />
      <PlaceholderAmounts />
    </ContentLoader>
  </CategoryRowRoot>
);

const CategoryGroupHeaderRoot = styled.div`
  height: 68px;
  border-bottom: 1px solid ${({ theme }) => theme.color.grayBackground};
`;

const HeaderLoading = () => (
  <CategoryGroupHeaderRoot>
    <ContentLoader>
      <rect x="40" y="20" rx="5" ry="5" width="10%" height="28" />
      <PlaceholderAmounts />
    </ContentLoader>
  </CategoryGroupHeaderRoot>
);

const CategoryGroupRoot = styled(Card)`
  :not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.gutter};
  }
`;

const Root = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.gutter};
`;

const RecurringListLoading = ({ className }: { className?: string }) => (
  <Root className={className}>
    <CategoryGroupRoot>
      <HeaderLoading />
      {_.range(NUM_ROWS).map((i) => (
        <RowLoading key={i} />
      ))}
    </CategoryGroupRoot>
  </Root>
);

export default RecurringListLoading;
