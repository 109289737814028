import { createSelector } from 'reselect';

import type { FlagsState } from 'common/state/flags/reducer';

type RootStateFlags = {
  flags: FlagsState;
};

const getFlags = ({ flags }: RootStateFlags) => flags;

export const getFlag = createSelector(
  getFlags,
  ({ flags }) =>
    (flag: string) =>
      flags[flag] ?? false,
);
