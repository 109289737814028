import { Duration } from 'luxon';
import { evolve, pathSatisfies } from 'ramda';
import { isNilOrEmpty } from 'ramda-adjunct';
import { useCallback, useState } from 'react';

import useQuery from 'common/lib/hooks/useQuery';
import useQueryWithCacheExpiration from 'common/lib/hooks/useQueryWithCacheExpiration';
import { mergeNextPage } from 'common/utils/pagination';
import { adaptSelectedOptionsToTransactionsFilters, getFilterSectionsFromQuery } from 'lib/filters';
import { trackFiltersApplied } from 'lib/filters/sections';
import type { SectionAdapterOptions } from 'lib/filters/types';
import useFilterMenu from 'lib/hooks/useFilterMenu';

import { gql } from 'common/generated/gql';
import type { TransactionFilters } from 'types/filters';

type Options<TFilters extends Partial<TransactionFilters>> = {
  page: string;
  filters: TFilters;
  onApplyFilters: (filters: Partial<TransactionFilters>) => void;
} & Omit<SectionAdapterOptions, 'hasTransactionsImportedFromMint'>;

/**
 * Hook responsible for managing the state of transactions filters in the FilterMenu component.
 * It doesn't dispatch any actions or mutates anything, it just provides the state and the
 * logic to calculate the filters to update based on the selected options.
 */
export const useTransactionFilterMenu = <TFilters extends Partial<TransactionFilters>>(
  options: Options<TFilters>,
) => {
  const { page, onApplyFilters, filters, renderAccountLogo, renderMerchantAccessory, renderTag } =
    options;

  // isNetworkRequestInFlight doesn't change when we call fetchMore, so we track it manually.
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const { data, isLoadingInitialData, fetchMore } = useQuery(FILTER_MENU_QUERY, {
    variables: { search: undefined, includeIds: filters.merchants },
  });

  const onSearchChange = useCallback(
    (value: string) => {
      if (isNilOrEmpty(value)) {
        return;
      }

      setIsFetchingMore(true);
      return fetchMore({
        variables: { search: value },
        updateQuery: (prev, { fetchMoreResult }) =>
          evolve(
            {
              merchants: mergeNextPage(fetchMoreResult.merchants),
            },
            prev,
          ),
      }).then(() => setIsFetchingMore(false));
    },
    [fetchMore],
  );

  const { data: mintTransactionsCountQueryData } = useQueryWithCacheExpiration(
    MINT_TRANSACTIONS_COUNT_QUERY,
    {
      cacheExpiration: Duration.fromObject({ minutes: 5 }),
    },
  );
  const hasTransactionsImportedFromMint = pathSatisfies(
    (count: number | undefined = 0) => count > 0,
    ['allMintTransactions', 'totalCount'],
    mintTransactionsCountQueryData,
  );

  const users = data?.myHousehold?.users ?? [];

  const { sections, onChangeSections, resetUnappliedChanges, handleApply } = useFilterMenu({
    data,
    filters,
    getFilterSectionsFromQuery: (data, filters, options) =>
      getFilterSectionsFromQuery(data, filters, {
        ...options,
        hasTransactionsImportedFromMint,
        householdUsers: users,
        renderAccountLogo,
        renderMerchantAccessory,
        renderTag,
      }),
    onApplyFilters: (filters, selectedOptions) => {
      onApplyFilters(filters);
      if (selectedOptions) {
        trackFiltersApplied(page, selectedOptions);
      }
    },
    adaptSelectedOptionsToFilters: adaptSelectedOptionsToTransactionsFilters,
  });

  return {
    sections,
    isLoading: isLoadingInitialData,
    isFetchingMoreData: isFetchingMore,
    onChangeSections,
    onSearchChange,
    resetUnappliedChanges,
    handleApply,
  };
};

export const FILTER_MENU_QUERY = gql(/* GraphQL */ `
  query Web_TransactionsFilterQuery($search: String, $includeIds: [ID!]) {
    categoryGroups {
      id
      name
      order

      categories {
        id
        name
        icon
        order
      }
    }

    merchants(search: $search, includeIds: $includeIds) {
      id
      name
      transactionCount
    }

    accounts {
      id
      displayName
      logoUrl
      icon
      type {
        name
        display
      }
    }

    householdTransactionTags {
      id
      name
      order
      color
    }

    myHousehold {
      id

      users {
        id
        name
      }
    }
  }
`);

const MINT_TRANSACTIONS_COUNT_QUERY = gql(/* GraphQL */ `
  query Web_MintTransactionsCountQuery {
    allMintTransactions: allTransactions(filters: { importedFromMint: true }) {
      totalCount
    }
  }
`);

export default useTransactionFilterMenu;
