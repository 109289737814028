import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React from 'react';
import styled from 'styled-components';

import { useTabs } from 'common/components/tabs/TabsContext';
import CashFlowFilterCard from 'components/cashFlows/CashFlowFilterCard';
import Dot from 'components/lib/ui/Dot';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import { OverlayTrigger, Popover } from 'components/lib/ui/popover';
import PillTab from 'components/lib/ui/tabs/PillTab';
import TabGroup from 'components/lib/ui/tabs/TabGroup';

import { getDateForTimeFrame, getDefaultDateForTimeframe } from 'common/lib/cashFlow/adapters';
import useUpdateEffect from 'common/lib/hooks/useUpdateEffect';
import usePersistentFilter from 'lib/hooks/usePersistentFilter';
import useTheme from 'lib/hooks/useTheme';

import { TIMEFRAME_OPTIONS } from 'common/constants/timeframes';

const ACTIVE_FILTER_INDICATOR_DOT_SIZE = 8;

const ControlsContainer = styled(FlexContainer).attrs({ alignCenter: true })`
  gap: ${({ theme }) => theme.spacing.default};
  margin-left: ${({ theme }) => theme.spacing.default};
`;

const FilterContainer = styled(FlexContainer).attrs({ alignCenter: true })`
  margin-left: ${({ theme }) => theme.spacing.default};
`;

const ChevronDownIcon = styled(Icon).attrs({ name: 'chevron-down', size: 12 })`
  transform: translateY(1px);
`;

const FilterButton = styled(DefaultButton)`
  position: relative;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xsmall};
`;

const ActiveFilterIndicator = styled(Dot).attrs({ size: ACTIVE_FILTER_INDICATOR_DOT_SIZE })`
  position: absolute;
  right: 28px;
  top: 6px;
`;

type Props = {
  controls?: React.ReactNode;
  filtersKey?: 'cashFlow' | 'cashFlowSankey';
};

const CashFlowControls = ({ controls, filtersKey }: Props) => {
  const theme = useTheme();

  const [activeIndex] = useTabs();
  const selectedTimeframe = TIMEFRAME_OPTIONS[activeIndex];

  const { activeFilters, updateFilter, resetFilter } = usePersistentFilter(
    filtersKey ?? 'cashFlow',
  );
  const isAnyFilterApplied = R.any(
    RA.isNotNilOrEmpty,
    R.values(R.pick(['accounts', 'tags'], activeFilters)),
  );

  useUpdateEffect(() => {
    updateFilter({
      timeframe: selectedTimeframe.value,
      date: activeFilters.date
        ? getDateForTimeFrame(selectedTimeframe.value, activeFilters.date)
        : getDefaultDateForTimeframe(selectedTimeframe.value),
    });
  }, [selectedTimeframe.value]);

  return (
    <FlexContainer alignCenter justifyEnd>
      <FlexContainer alignCenter>
        <TabGroup>
          {TIMEFRAME_OPTIONS.map(({ value, label }, index) => (
            <PillTab key={value} index={index} $background="base">
              {label}
            </PillTab>
          ))}
        </TabGroup>
        {RA.isNotNil(controls) && <ControlsContainer>{controls}</ControlsContainer>}
        <FilterContainer>
          <OverlayTrigger
            placement="bottom-end"
            closeOnEscape={false}
            overlay={({ close }) => (
              <Popover>
                <CashFlowFilterCard
                  activeFilters={activeFilters}
                  onClickReset={() => {
                    resetFilter();
                    close();
                  }}
                  onClickApply={(values) => {
                    updateFilter(values);
                    close();
                  }}
                />
              </Popover>
            )}
          >
            {({ toggleOpen, isOpen }) => (
              <FilterButton onClick={toggleOpen} active={isOpen}>
                Filters <ChevronDownIcon />{' '}
                {isAnyFilterApplied && <ActiveFilterIndicator color={theme.color.orange} />}
              </FilterButton>
            )}
          </OverlayTrigger>
        </FilterContainer>
      </FlexContainer>
    </FlexContainer>
  );
};
export default CashFlowControls;
