import React from 'react';

import type { NavBarLinkProps } from 'components/sidebar/NavBarLink';
import NavBarLink from 'components/sidebar/NavBarLink';

import type { SidebarRouteName } from 'constants/sidebar';
import { SIDEBAR_ROUTES } from 'constants/sidebar';

type Props = Omit<NavBarLinkProps, 'iconName' | 'children' | 'to'> & {
  name: SidebarRouteName;
};

const SideBarLink = ({ name, ...props }: Props) => {
  const { route, icon } = SIDEBAR_ROUTES[name];

  return (
    <NavBarLink to={route} iconName={icon} {...props}>
      {name}
    </NavBarLink>
  );
};

export default SideBarLink;
