import React from 'react';
import styled from 'styled-components';

import type { AccountSummaryCardItem } from 'lib/accounts/AccountGraphs';

const HEIGHT_PX = 12;

const InnerBar = styled.div<{ $color: string; $width: number }>`
  height: 100%;
  width: ${({ $width }) => $width * 100}%;
  background-color: ${({ $color }) => $color};
  border-radius: 3px;
`;

const Root = styled.div`
  display: flex;
  height: ${HEIGHT_PX}px;
  width: 100%;
  gap: 3px;
`;

type Props = {
  items: AccountSummaryCardItem[];
};

const AccountCompositionBar = ({ items }: Props) => (
  <Root>
    {items.map(({ percentage, color, type }) => (
      <InnerBar key={type.name} $color={color} $width={percentage} />
    ))}
  </Root>
);

export default AccountCompositionBar;
