import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';

import AnimatedProgressSteps from 'components/accounts/AnimatedProgressSteps';
import ManualAccountLogo from 'components/accounts/ManualAccountLogo';
import Mark from 'components/brand/Mark';
import InstitutionLogo from 'components/institutions/InstitutionLogo';
import Flex from 'components/lib/ui/Flex';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';

import useTheme from 'lib/hooks/useTheme';

const LOADING_STEPS = [
  'Syncing accounts',
  'Syncing transactions',
  'Updating net worth',
  'Updating spending reports',
  'Wrapping everything up',
];

const StyledAnimatedProgressSteps = styled(AnimatedProgressSteps)`
  margin-bottom: ${({ theme }) => theme.spacing.xxlarge};
`;

const StyledSpinner = styled(LoadingSpinner)`
  margin-bottom: ${({ theme }) => theme.spacing.xxlarge};
`;

const MonarchImgContainer = styled(Flex).attrs({ center: true })`
  border-radius: ${({ theme }) => theme.radius.round};
  width: 48px;
  height: 48px;
  background-color: ${({ theme }) => theme.color.orange};
`;

const InstitutionSyncContainer = styled(Flex)`
  width: 50%;
  margin-bottom: ${({ theme }) => theme.spacing.xxlarge};
`;

const DotContainer = styled(Flex)`
  margin: auto 0px;

  div:nth-child(even) {
    transform: translate(0px, 7px);
  }
  div:nth-child(odd) {
    transform: translate(0px, -7px);
  }
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: ${({ theme }) => theme.radius.round};
  background-color: ${({ theme }) => theme.color.grayLight};
`;

type Props = {
  institutionLogo: string | undefined | null;
  onAnimationEnd?: () => void;
  stepAnimationDurationMs?: number;
  loadingSteps?: string[];
};

const AddAccountsInstitutionSyncing = ({
  institutionLogo,
  onAnimationEnd,
  stepAnimationDurationMs,
  loadingSteps = LOADING_STEPS,
}: Props) => {
  const theme = useTheme();

  return (
    <>
      <InstitutionSyncContainer justifyBetween>
        <MonarchImgContainer>
          <Mark size={30} color={theme.color.textWhite} />
        </MonarchImgContainer>
        <DotContainer>
          {R.range(0, 11).map((i) => (
            <Dot key={i} />
          ))}
        </DotContainer>
        {institutionLogo ? (
          <InstitutionLogo logo={institutionLogo} />
        ) : (
          <ManualAccountLogo icon="dollar-sign" />
        )}
      </InstitutionSyncContainer>
      <StyledAnimatedProgressSteps
        onAnimationEnd={onAnimationEnd}
        steps={loadingSteps}
        stepAnimationDurationMs={stepAnimationDurationMs}
      />
      <StyledSpinner />
    </>
  );
};

export default AddAccountsInstitutionSyncing;
