import type { MutationFunctionOptions } from '@apollo/client';
import type { ExecutionResult } from 'graphql';
import * as R from 'ramda';
import React from 'react';
import deepIsEqual from 'react-fast-compare';
import styled from 'styled-components';

import FullHeightCategorySelect from 'components/categories/FullHeightCategorySelect';
import EventPropagationBoundary from 'components/lib/higherOrder/EventPropagationBoundary';
import { sensitiveClassProps } from 'components/lib/higherOrder/withSensitiveData';
import FlexContainer from 'components/lib/ui/FlexContainer';
import FlexItem from 'components/lib/ui/FlexItem';
import Highlighter from 'components/lib/ui/Highlighter';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';
import Tooltip from 'components/lib/ui/Tooltip';
import IconButton, { BUTTON_SIZE as ICON_BUTTON_SIZE } from 'components/lib/ui/button/IconButton';
import FullHeightMerchantSelect from 'components/merchants/FullHeightMerchantSelect';
import TransactionAttachmentsIcon from 'components/transactions/TransactionAttachmentsIcon';
import TransactionNotesIcon from 'components/transactions/TransactionNotesIcon';
import TransactionAccount from 'components/transactions/list/TransactionAccount';
import TransactionCategorySelect from 'components/transactions/list/TransactionCategorySelect';
import TransactionMerchantSelect from 'components/transactions/list/TransactionMerchantSelect';
import TransactionPendingBadge from 'components/transactions/list/TransactionPendingBadge';
import TransactionSplitIcon from 'components/transactions/list/TransactionSplitIcon';
import TransactionTagDots from 'components/transactions/tags/TransactionTagDots';
import TransactionTagsIcon from 'components/transactions/tags/TransactionTagsIcon';

import { updateTransactionOptimisticResponse } from 'common/lib/graphQl/transactions';
import { getRecurringTooltipText } from 'common/lib/recurring';
import { spacing } from 'common/lib/theme/dynamic';
import isV2Theme from 'common/lib/theme/isV2Theme';
import { ReviewStatus } from 'common/lib/transactions/review';
import formatTransactionAmount from 'common/utils/formatTransactionAmount';
import { useTransactionRuleToastContext } from 'lib/contexts/TransactionRuleToastContext';
import type { AutoFocusOptions } from 'lib/contexts/TransactionsListContext';
import { useTransactionListContext } from 'lib/contexts/TransactionsListContext';
import stopEventPropagation from 'lib/events/stopEventPropagation';
import useRouteMatch from 'lib/hooks/useRouteMatch';
import useToast from 'lib/hooks/useToast';
import { amountMixin } from 'lib/styles/amountMixin';

import type { Frequency } from 'common/constants/recurringTransactions';
import { HIDDEN_TOOLTIP_TEXT } from 'constants/copy';
import routes from 'constants/routes';
import { TRANSACTION_ROW_ICON_SIZE_PX } from 'constants/transactions';

import type {
  TransactionOverviewFieldsFragment,
  UpdateTransactionMutationInput,
  Web_UpdateTransactionOverviewMutation,
  Web_UpdateTransactionOverviewMutationVariables,
} from 'common/generated/graphql';

const TOOLTIP_DELAY_SHOW_MS = 500;
const ICON_SIZE_PX = 12;

type Props<T> = {
  transaction: T;
  className?: string;
  showReviewButton?: boolean | null;
  editsToPendingTransactionsEnabled?: boolean;
  onChevronClick: (id: GraphQlUUID, autoFocus?: Partial<AutoFocusOptions>) => void;
  onMarkedAsReviewed?: () => void;
  updateTransaction: (
    options?: MutationFunctionOptions<
      Web_UpdateTransactionOverviewMutation,
      Web_UpdateTransactionOverviewMutationVariables
    >,
  ) => Promise<ExecutionResult<Web_UpdateTransactionOverviewMutation>>;
  isTransactionsUpdatesEnabled: boolean;
};

const Root = styled(FlexContainer)`
  padding: ${isV2Theme(6, 8)}px 0px;
  padding-left: 7px;
  padding-right: ${({ theme }) => theme.spacing.xsmall};
  font-size: ${({ theme }) => theme.fontSize.base};
  gap: ${({ theme }) => theme.spacing.xxsmall};

  > * {
    flex: 1 0 auto;
    width: 20%;

    &:last-child {
      flex: 0 1 ${ICON_BUTTON_SIZE.xsmall}px;
    }
  }
`;

// This is so that we don't have to change the style of the EventPropagationBoundary
// container, which is a legacy component used all over the app.
const StyledEventPropagationBoundary = styled(EventPropagationBoundary)`
  display: contents;
`;

const Name = styled.div`
  display: flex;
  align-items: center;
  width: 25%;
`;

const Category = styled.div`
  .react-select__menu {
    font-style: normal;
    min-width: 400px;
  }

  .react-select__loading-indicator {
    display: none;
  }
`;

const Icons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${({ theme }) => theme.color.textLight};
  gap: ${({ theme }) => theme.spacing.xxsmall};
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: ${TRANSACTION_ROW_ICON_SIZE_PX}px;
  height: ${TRANSACTION_ROW_ICON_SIZE_PX}px;
  border-radius: ${({ theme }) => theme.radius.round};
  transition: ${({ theme }) => theme.transition.default};
  user-select: none;

  :hover {
    background: ${({ theme }) => theme.color.grayBackground};
    color: ${({ theme }) => theme.color.black};
  }
`;

const RecurringIcon = styled(Icon).attrs({ name: 'calendar', size: ICON_SIZE_PX })`
  display: block;
  cursor: default;
`;

const Amount = styled(FlexItem)<{ $isIncome: boolean }>`
  ${({ theme, $isIncome }) => amountMixin({ theme, isGreen: $isIncome, defaultColor: 'text' })};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: ${({ $isIncome, theme }) =>
    $isIncome ? theme.fontWeight.medium : theme.fontWeight.book};
  margin-left: ${({ theme }) => theme.spacing.xsmall};
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 0;
`;

const AmountAndIconsContainer = styled(FlexContainer).attrs({ justifyEnd: true })`
  flex-basis: 18%;
`;

const ChevronPropagationBoundary = styled(EventPropagationBoundary).attrs({ onClick: true })``;

const ReviewButtonContainer = styled.div`
  display: flex;
  flex: 0 0 auto;
  width: auto;
  margin-left: ${spacing.small};
`;

const ItalicText = styled(Text).attrs({ italic: true }).attrs({ clampLines: 1 })`
  flex: 1;
  margin-left: 15px;
  vertical-align: middle;
`;

const StyledHighlighter = styled(Highlighter)<{ $italic: boolean }>`
  font-style: ${({ $italic }) => ($italic ? 'italic' : 'normal')};
`;

const TransactionOverview = <T extends TransactionOverviewFieldsFragment>({
  transaction,
  className,
  showReviewButton,
  editsToPendingTransactionsEnabled,
  updateTransaction,
  onMarkedAsReviewed,
  onChevronClick,
  isTransactionsUpdatesEnabled,
}: Props<T>) => {
  const {
    id,
    pending,
    category: { icon, name: categoryName, id: categoryId },
    merchant: { name: merchantName, id: merchantId, ...merchant },
    amount,
    attachments,
    hideFromReports,
    notes,
    isSplitTransaction,
    tags,
    isRecurring,
    account,
  } = transaction;
  const { openErrorToast } = useToast();

  const isIncome = amount > 0;
  const isEditingDisabled = pending && !editsToPendingTransactionsEnabled;

  const { showAccountColumn } = useTransactionListContext();
  const { showToast: showCreateTransactionRuleToast } = useTransactionRuleToastContext();

  const handleReviewButtonClick = async () => {
    await updateTransaction({
      variables: { input: { id, reviewed: true } as UpdateTransactionMutationInput },
      optimisticResponse: updateTransactionOptimisticResponse(
        R.evolve(
          {
            needsReview: () => false,
            reviewStatus: () => ReviewStatus.Reviewed,
          },
          transaction,
        ),
      ),
    });
    onMarkedAsReviewed?.();
  };

  const isInCategoryDetails = !!useRouteMatch(routes.categories);
  const isInMerchantDetails = !!useRouteMatch(routes.merchants);

  const recurringTooltip = getRecurringTooltipText(
    merchant.recurringTransactionStream?.frequency as Frequency,
  );

  return (
    <Root className={className} alignCenter>
      {showReviewButton && (
        <Tooltip content="Mark as reviewed" delayShow={TOOLTIP_DELAY_SHOW_MS} fitContent>
          <ReviewButtonContainer>
            <IconButton icon="check" onClick={handleReviewButtonClick} size="xsmall" bordered />
          </ReviewButtonContainer>
        </Tooltip>
      )}
      <Name {...sensitiveClassProps}>
        {/* IIFE to avoid nested ternary */}
        {(() => {
          if (!isTransactionsUpdatesEnabled && isEditingDisabled) {
            return <ItalicText>{merchantName}</ItalicText>;
          } else if (isTransactionsUpdatesEnabled || isEditingDisabled) {
            return (
              <TransactionMerchantSelect
                transaction={transaction}
                updateTransaction={updateTransaction}
                hideDetailsLink={isInMerchantDetails}
                isEditingDisabled={isEditingDisabled}
              />
            );
          } else {
            return (
              <StyledEventPropagationBoundary onClick>
                <FullHeightMerchantSelect
                  hideUntilHover
                  fullWidthTrigger
                  transactionId={id}
                  transactionMerchantName={transaction.merchant.name}
                  transactionProviderDescription={transaction.dataProviderDescription}
                  alwaysShowBorder={false}
                  value={{ value: merchantId, label: merchantName }}
                  onChange={async (selectedMerchantName: string) => {
                    updateTransaction({
                      variables: {
                        input: {
                          id,
                          name: selectedMerchantName,
                        } as UpdateTransactionMutationInput,
                      },
                      optimisticResponse: updateTransactionOptimisticResponse({
                        ...transaction,
                        merchant,
                      }),
                      onCompleted: () => {
                        showCreateTransactionRuleToast({
                          merchantName: transaction.merchant.name,
                          newMerchantName: selectedMerchantName,
                        });
                      },
                      onError: () => {
                        openErrorToast({
                          description: "We couldn't update this transaction.",
                        });
                      },
                    });
                  }}
                />
              </StyledEventPropagationBoundary>
            );
          }
        })()}
      </Name>
      <Category onClick={stopEventPropagation}>
        {/* IIFE to avoid nested ternary */}
        {(() => {
          if (isEditingDisabled && !isTransactionsUpdatesEnabled) {
            return (
              <ItalicText>
                {icon} {categoryName}
              </ItalicText>
            );
          } else if (isTransactionsUpdatesEnabled || isEditingDisabled) {
            return (
              <TransactionCategorySelect
                categoryId={categoryId}
                categoryName={categoryName}
                icon={icon}
                transaction={transaction}
                updateTransaction={updateTransaction}
                hideDetailsLink={isInCategoryDetails}
                isEditingDisabled={isEditingDisabled}
              />
            );
          } else {
            return (
              <FullHeightCategorySelect
                hideUntilHover
                value={{ value: categoryId, label: categoryName, icon }}
                isCreatable
                onChange={async ({ isRecommendedCategory }, action, [category]) => {
                  if (category) {
                    await updateTransaction({
                      variables: {
                        input: {
                          id,
                          category: category.id,
                          isRecommendedCategory,
                        } as UpdateTransactionMutationInput,
                      },
                      optimisticResponse: updateTransactionOptimisticResponse({
                        ...transaction,
                        category,
                      }),
                    });
                    showCreateTransactionRuleToast({
                      merchantName: transaction.merchant.name,
                      newCategoryId: category.id,
                    });
                  }
                }}
                transactionId={id}
                alwaysShowBorder={false}
                fullWidthTrigger
                transactionProviderDescription={transaction.dataProviderDescription}
              />
            );
          }
        })()}
      </Category>
      {isTransactionsUpdatesEnabled && showAccountColumn && (
        <TransactionAccount
          id={account.id}
          displayName={account.displayName}
          icon={account.icon}
          logoUrl={account.logoUrl}
        />
      )}

      <AmountAndIconsContainer>
        <Icons>
          {tags.length > 0 &&
            (isTransactionsUpdatesEnabled ? (
              <TransactionTagsIcon tags={tags} onClick={() => onChevronClick(id, { tags: true })} />
            ) : (
              <TransactionTagDots tags={tags} onClick={() => onChevronClick(id, { tags: true })} />
            ))}
          {hideFromReports && (
            <Tooltip content={HIDDEN_TOOLTIP_TEXT}>
              <IconWrapper>
                <Icon name="eye-off" size={ICON_SIZE_PX} />
              </IconWrapper>
            </Tooltip>
          )}
          {!!notes && (
            <TransactionNotesIcon
              notes={notes}
              onClick={() => {
                onChevronClick(id, { notes: true });
              }}
            />
          )}
          {!!isRecurring && (
            <Tooltip content={recurringTooltip}>
              <IconWrapper>
                <RecurringIcon />
              </IconWrapper>
            </Tooltip>
          )}
          {isSplitTransaction && <TransactionSplitIcon onClick={() => onChevronClick(id)} />}
          {attachments.length > 0 && (
            <TransactionAttachmentsIcon
              onClick={() => onChevronClick(id)}
              count={attachments.length}
            />
          )}
          {pending && <TransactionPendingBadge isEditingDisabled={isEditingDisabled} />}
        </Icons>
        <Amount $isIncome={isIncome} {...sensitiveClassProps}>
          <StyledHighlighter $italic={isEditingDisabled}>
            {formatTransactionAmount(amount)}
          </StyledHighlighter>
        </Amount>
      </AmountAndIconsContainer>

      <ChevronPropagationBoundary>
        <IconButton
          className="fs-drawer-toggle"
          icon="chevron-right"
          onClick={() => onChevronClick(id)}
          size="xsmall"
        />
      </ChevronPropagationBoundary>
    </Root>
  );
};

export default React.memo(TransactionOverview, deepIsEqual);
