import { DateTime } from 'luxon';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import CashFlowBarChartTooltip from 'components/lib/charts/CashFlowBarChartTooltip';
import StackedBarChart from 'components/lib/charts/StackedBarChart';
import Card from 'components/lib/ui/Card';

import { getChartDateFromTimeframe } from 'common/lib/charts';
import THEME from 'common/lib/theme/staticTheme';
import { formatCurrencyThousandsDecimal } from 'common/utils/Currency';
import type { ChartDataPoint } from 'lib/cashFlow/Adapters';
import {
  cashFlowChartReferenceLineAdapter,
  findActiveBarIndex,
  getBarColor,
} from 'lib/cashFlow/Adapters';
import useMeasure from 'lib/hooks/useMeasure';
import useTheme from 'lib/hooks/useTheme';
import { getDateShorthand } from 'utils/dateRange';

import type { Timeframe } from 'common/constants/timeframes';

const CHART_HEIGHT_PX = 275;
const ROOT_PADDING = parseInt(THEME.spacing.default, 10);

const Root = styled(Card)`
  padding: 0px ${ROOT_PADDING}px ${ROOT_PADDING}px;
`;

type Props = {
  data: ChartDataPoint[];
  timeframe: Timeframe;
  activeDate: string;
  onBarClick: (date: ISODate) => void;
};

const CashFlowBarChart = ({ data: chartData, activeDate, timeframe, onBarClick }: Props) => {
  const [ref, { width = 0 }] = useMeasure<HTMLDivElement>();
  const theme = useTheme();
  const onBarAreaClick = useCallback(
    ({ startDate }: { startDate: ISODate }) => onBarClick(startDate),
    [onBarClick],
  );
  const activeBarIndex = findActiveBarIndex(chartData, activeDate);

  const yearDividers = useMemo(
    // Hide year dividers if grouping by year since it's redundant
    () => (timeframe !== 'year' ? cashFlowChartReferenceLineAdapter(chartData) : []),
    [chartData, timeframe],
  );

  const formatTooltipDate = useCallback(
    (startDate: string) =>
      getDateShorthand({
        startDate,
        endDate: DateTime.fromISO(startDate).endOf(timeframe).toISODate(),
      }) ?? startDate,
    [timeframe],
  );

  return (
    <Root ref={ref}>
      <StackedBarChart
        activeBarIndex={activeBarIndex}
        data={chartData}
        barDataKeys={[
          {
            name: 'income',
            color: getBarColor(theme.color.green, theme.color.green6),
          },
          {
            name: 'expense',
            color: getBarColor(theme.color.red, theme.color.red6),
          },
        ]}
        totalDataKey="savings"
        dashedLineDataKey="currentMonthSavings"
        xAxisDataKey="startDate"
        heightPx={CHART_HEIGHT_PX}
        widthPx={width - ROOT_PADDING}
        formatXAxis={getChartDateFromTimeframe(timeframe)}
        formatYAxis={formatCurrencyThousandsDecimal}
        onBarAreaClick={onBarAreaClick}
        xAxisReferenceLines={yearDividers}
        tooltipComponent={({ payload, active }) => {
          if (!payload?.[0]) {
            return null;
          }

          const { income, expense, savings, savingsRate, startDate, currentMonthSavings } =
            payload[0].payload ?? {};

          return (
            <CashFlowBarChartTooltip
              active={active}
              income={income}
              expenses={expense}
              savings={savings ?? currentMonthSavings ?? 0}
              title={formatTooltipDate(startDate)}
              savingsRate={savingsRate}
            />
          );
        }}
      />
    </Root>
  );
};

export default CashFlowBarChart;
