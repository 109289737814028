import { DateTime } from 'luxon';
import React from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import styled from 'styled-components';

import Card from 'components/lib/ui/Card';
import DividerLine from 'components/lib/ui/DividerLine';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import IconButton from 'components/lib/ui/button/IconButton';
import {
  AggregatedSummary,
  AggregateSummaryItem,
} from 'components/recurring/list/AggregatedSummary';
import { RecurringView } from 'components/routes/recurring/RecurringUpcoming';

import { useRecurringStreams } from 'common/lib/hooks/recurring/useRecurringStreams';
import useSpinwheel from 'common/lib/hooks/recurring/useSpinwheel';
import type { AggregatedSummaryByRecurringType } from 'common/lib/recurring/types';
import { color, spacing } from 'common/lib/theme/dynamic';

import * as COPY from 'common/constants/copy';

const Root = styled(FlexContainer).attrs({ alignCenter: true, justifyBetween: true })<{
  $extraPadding?: boolean;
}>`
  padding: ${({ theme, $extraPadding }) =>
    $extraPadding ? `16px 24px` : `${theme.spacing.small} 0`};
`;

const CurrentMonth = styled.h1`
  margin: 0;
  padding: 0;
  font-size: ${({ theme }) => theme.fontSize.xlarge};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

const CalendarActions = styled(FlexContainer).attrs({ alignEnd: true, justifyEnd: true })`
  gap: ${({ theme }) => theme.spacing.small};
`;

const TodayButton = styled(DefaultButton)``;

const ButtonGroup = styled(FlexContainer)`
  ${DefaultButton} {
    height: 38px;

    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    :first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    :last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    :not(:first-child) {
      margin-left: -1px;
    }
  }
`;

const Arrows = styled.div`
  height: 38px;
  display: flex;
  gap: 0;
`;

const ArrowButton = styled(IconButton).attrs({
  size: 'medium',
})`
  cursor: pointer;
  padding: 10px;
  width: 36px;
  height: 36px;
`;

const ButtonGroupIcon = styled(Icon).attrs({
  size: 16,
})`
  margin-right: 5px;
`;

const RecurringActionsWrapper = styled(Card)`
  margin-bottom: ${spacing.default};
  background: ${color.white};
`;

type Props = {
  startDate: DateTime;
  setStartDate: (date: string) => void;
  recurringView: RecurringView;
  setRecurringView: (view: RecurringView) => void;
  aggregatedSummary: Maybe<AggregatedSummaryByRecurringType>;
  isLoadingSummary: boolean;
  openAddManualRecurringModal: () => void;
  onClickAddBills: () => void;
};

const RecurringActions = ({
  startDate,
  setStartDate,
  recurringView,
  setRecurringView,
  aggregatedSummary,
  isLoadingSummary,
  openAddManualRecurringModal,
  onClickAddBills,
}: Props) => {
  const { hasExpenses, hasIncome } = useRecurringStreams();
  const { connectionStatus } = useSpinwheel();

  const onClickToday = () => {
    setStartDate(DateTime.local().startOf('month').toISODate());
  };

  const onClickNext = () => {
    setStartDate(startDate.plus({ months: 1 }).toISODate());
  };

  const onClickPrev = () => {
    setStartDate(startDate.minus({ months: 1 }).toISODate());
  };

  const currentMonthLabel = startDate.toFormat('LLLL yyyy');

  return (
    <RecurringActionsWrapper>
      <Root $extraPadding>
        <CurrentMonth>{currentMonthLabel}</CurrentMonth>
        <CalendarActions>
          <Arrows>
            <ArrowButton icon="arrow-left" onClick={onClickPrev} />
            <ArrowButton icon="arrow-right" onClick={onClickNext} />
          </Arrows>
          {/* Today Button */}
          <TodayButton onClick={onClickToday}>Today</TodayButton>

          <DividerLine />

          {/* View control */}
          <ButtonGroup>
            <DefaultButton
              onClick={() => setRecurringView(RecurringView.List)}
              active={recurringView === RecurringView.List}
            >
              <ButtonGroupIcon name="menu" />
              List
            </DefaultButton>
            <DefaultButton
              onClick={() => setRecurringView(RecurringView.Calendar)}
              active={recurringView === RecurringView.Calendar}
            >
              <ButtonGroupIcon name="calendar" />
              Calendar
            </DefaultButton>
          </ButtonGroup>
        </CalendarActions>
      </Root>

      <AggregatedSummary>
        <AggregateSummaryItem
          title="Income"
          aggregatedSummary={aggregatedSummary?.income}
          completedText="received"
          isLoading={isLoadingSummary}
          isEmpty={!hasIncome}
          onEmptyAction={openAddManualRecurringModal}
          emptyText={COPY.RECURRING.SUMMARY.EMPTY_INCOME}
          tooltipText={COPY.RECURRING.NO_TRANSFER_IN_SUMMARY_DESCRIPTION}
        />

        <AggregateSummaryItem
          title="Expenses"
          aggregatedSummary={aggregatedSummary?.expense}
          isLoading={isLoadingSummary}
          isEmpty={!hasExpenses}
          onEmptyAction={openAddManualRecurringModal}
          emptyText={COPY.RECURRING.SUMMARY.EMPTY_EXPENSES}
          tooltipText={COPY.RECURRING.NO_TRANSFER_IN_SUMMARY_DESCRIPTION}
        />

        <AggregateSummaryItem
          title="Credit cards"
          aggregatedSummary={aggregatedSummary?.creditCard}
          isLoading={isLoadingSummary}
          isEmpty={!connectionStatus.isConnected}
          onEmptyAction={onClickAddBills}
          emptyText={COPY.RECURRING.SUMMARY.EMPTY_CREDIT_CARDS}
          tooltipText={COPY.RECURRING.INCLUDES_SPINWHEEL_CREDIT_CARDS}
        />
      </AggregatedSummary>
    </RecurringActionsWrapper>
  );
};

export default RecurringActions;
