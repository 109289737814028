import { useMediaQuery } from '@react-hook/media-query';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import type { ThemeType } from 'styled-components';

import { makeTheme } from 'common/lib/theme';
import resolveUiTheme from 'common/lib/theme/resolveUiTheme';
import { getUiThemePreference } from 'selectors';

import type { UiTheme } from 'common/types';

const useTheme = (forceTheme?: UiTheme): ThemeType => {
  const uiThemePreference = useSelector(getUiThemePreference);
  const systemUiTheme = useMediaQuery('(prefers-color-scheme: dark)') ? 'dark' : 'light';

  const uiTheme = forceTheme ?? resolveUiTheme({ uiThemePreference, systemUiTheme });

  const theme = useMemo(() => makeTheme({ uiTheme }), [uiTheme]);

  return theme;
};

export default useTheme;
