import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import GoalAccountsMapRow from 'components/goalsV2/GoalAccountsMapRow';
import GoalSelectAccountsModal from 'components/goalsV2/GoalSelectAccountsModal';
import GoalsSetupHeader from 'components/goalsV2/GoalsSetupHeader';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Page from 'components/lib/ui/Page';
import SetupContentHeader from 'components/lib/ui/SetupContentHeader';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import { sortGoalsByPriority } from 'common/lib/goalsV2/adapters';
import useQuery from 'common/lib/hooks/useQuery';
import variables from 'common/lib/theme/variables';
import { getProgressPercent } from 'lib/goals/progress';
import useModal from 'lib/hooks/useModal';

import * as COPY from 'common/constants/copy';
import routes from 'constants/routes';

import { gql } from 'common/generated/gql';
import type { Web_GoalsMapAccounts } from 'common/generated/graphQlTypes/Web_GoalsMapAccounts';

const StyledPage = styled(Page)`
  height: 100vh;
  background-color: ${variables.color.background.fullscreenHeader};
`;

const List = styled(FlexContainer).attrs({ column: true, alignCenter: true })`
  margin-bottom: ${({ theme }) => theme.spacing.xlarge};
`;

const GoalRow = styled(FlexContainer).attrs({ column: true })`
  padding: ${({ theme }) => theme.spacing.xlarge};
  border-top: 1px solid ${({ theme }) => theme.color.grayBackground};

  @media (min-width: ${({ theme }) => theme.breakPoints.lg}px) {
    width: 502px;
  }
`;

const NextButton = styled(PrimaryButton)`
  width: 100%;
  max-width: 438px;
  margin-bottom: ${({ theme }) => theme.spacing.xxxlarge};
`;

const SpinnerContainer = styled(FlexContainer).attrs({ center: true, column: true })`
  margin-top: ${({ theme }) => theme.spacing.xxxlarge};
`;

const GoalsMapAccounts = () => {
  const { data, isLoadingInitialData } = useQuery<Web_GoalsMapAccounts>(QUERY);
  const { push } = useHistory();

  const goals = useMemo(() => sortGoalsByPriority(data?.goalsV2 ?? []), [data]);

  const [selectedGoalId, setSelectedGoalId] = useState<string | null>(null);

  const [SelectAccountsModal, { open }] = useModal();

  return (
    <StyledPage name="Goals setup" header={<GoalsSetupHeader progress={getProgressPercent(4)} />}>
      <FlexContainer center column>
        <SetupContentHeader
          title={COPY.GOALS.MAP_ACCOUNTS.TITLE}
          description={COPY.GOALS.MAP_ACCOUNTS.SUBTITLE}
        />
      </FlexContainer>
      {isLoadingInitialData ? (
        <SpinnerContainer>
          <LoadingSpinner />
        </SpinnerContainer>
      ) : (
        <>
          <List>
            {goals.map((item, idx) => (
              <GoalRow key={idx}>
                <GoalAccountsMapRow
                  data={item}
                  onClickAddAccounts={() => {
                    setSelectedGoalId(item.id);
                    open();
                  }}
                />
              </GoalRow>
            ))}
          </List>

          <FlexContainer center column>
            <NextButton size="large" onClick={() => push(routes.goalsV2.monthlyBudgets())}>
              Next
            </NextButton>
          </FlexContainer>
        </>
      )}
      {selectedGoalId && (
        <SelectAccountsModal onClose={() => setSelectedGoalId(null)}>
          <GoalSelectAccountsModal goalId={selectedGoalId} />
        </SelectAccountsModal>
      )}
    </StyledPage>
  );
};

const QUERY = gql(/* GraphQL */ `
  query Web_GoalsMapAccounts {
    goalsV2 {
      id
      ...GoalAccountsMapRowFields
    }
  }
`);

export default GoalsMapAccounts;
