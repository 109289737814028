import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import Tabs from 'common/components/tabs/Tabs';
import FlexContainer from 'components/lib/ui/FlexContainer';
import ModalCard from 'components/lib/ui/ModalCard';
import Scroll from 'components/lib/ui/Scroll';
import SearchBar from 'components/lib/ui/SearchBar';
import PillTab from 'components/lib/ui/tabs/PillTab';
import TabGroup from 'components/lib/ui/tabs/TabGroup';

import { EXPLORE_PROMPTS } from 'common/constants/assistant';

const StyledModalCard = styled(ModalCard)`
  overflow: hidden;
`;

const Header = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};
  padding-top: 0;
  padding-bottom: ${({ theme }) => theme.spacing.default};
`;

const TabsContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.default};
`;

const Row = styled(FlexContainer).attrs({ alignCenter: true })`
  padding: ${({ theme }) => theme.spacing.xlarge};
  cursor: pointer;

  transition: ${({ theme }) => theme.transition.default};
  :hover {
    background: ${({ theme }) => theme.color.grayBackground};
  }
`;

const Circle = styled(FlexContainer).attrs({ center: true })`
  width: 32px;
  height: 32px;
  background: ${({ theme }) => theme.color.grayBackground};
  margin-right: ${({ theme }) => theme.spacing.default};
  border-radius: ${({ theme }) => theme.radius.round};
  flex-shrink: 0;
  border: 1px solid transparent;

  ${Row}:hover & {
    border-color: ${({ theme }) => theme.color.white};
  }
`;

const ScrollContainer = styled(Scroll)`
  max-height: 40vh;
`;

type Props = {
  onClickPrompt: (prompt: string) => void;
};

const ExplorePromptsModal = ({ onClickPrompt }: Props) => {
  const categories = Object.keys(EXPLORE_PROMPTS) as (keyof typeof EXPLORE_PROMPTS)[];

  const [search, setSearch] = useState<string>();
  const [categoryIndex, setCategoryIndex] = useState(0);

  const prompts = useMemo(() => {
    let prompts = EXPLORE_PROMPTS[categories[categoryIndex]];

    if (search) {
      prompts = prompts.filter((prompt) =>
        prompt.text.toLowerCase().includes(search.toLowerCase()),
      );
    }

    return prompts;
  }, [categories, search, categoryIndex]);

  return (
    <StyledModalCard title="Find more questions" hideBottomBorder>
      <Header>
        <SearchBar
          focusOnRender={false}
          name="search"
          value={search}
          onChange={setSearch}
          placeholder="Search..."
        />
        <TabsContainer>
          <Tabs index={categoryIndex} onChangeIndex={setCategoryIndex}>
            <TabGroup>
              {categories.map((category, index) => (
                <PillTab key={index} index={index} $background="card">
                  {category}
                </PillTab>
              ))}
            </TabGroup>
          </Tabs>
        </TabsContainer>
      </Header>
      <ScrollContainer>
        {prompts.map((prompt) => (
          <Row key={prompt.text} onClick={() => onClickPrompt(prompt.text)}>
            <Circle>{prompt.emoji}</Circle>
            {prompt.text}
          </Row>
        ))}
      </ScrollContainer>
    </StyledModalCard>
  );
};

export default ExplorePromptsModal;
