import * as R from 'ramda';

/** Sum an array of numbers */
export const sum = (values: number[]) => R.reduce(R.add, 0, values);

export const meanBy = <ItemT>(accessor: (item: ItemT) => number, items: ItemT[]): number => {
  const values = R.map(accessor, items);
  return R.mean(values);
};

/**
 * Util to make comparing two numbers more readable.
 *
 * compare(value, 0, {
 *   equal: 'gray',
 *   lessThan: 'red',
 *   greaterThan: 'green',
 * })
 * */
export const compare = <T>(
  a: number,
  b: number,
  output: { equal: T; lessThan: T; greaterThan: T },
) => {
  if (a === b) {
    return output.equal;
  } else if (a < b) {
    return output.lessThan;
  } else {
    return output.greaterThan;
  }
};

export const getOrderOfMagnitude = (value: number) => 10 ** Math.floor(Math.log10(Math.abs(value)));
