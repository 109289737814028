import React from 'react';
import styled, { css } from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';

import { fontSize, fontWeight, spacing } from 'common/lib/theme/dynamic';
import variables from 'common/lib/theme/variables';

const Root = styled(FlexContainer).attrs({ alignCenter: true })`
  overflow: hidden;
`;

const Separator = styled(Icon).attrs({ name: 'chevron-right', size: 16 })`
  color: ${variables.color.content.secondary};
  margin: 0 ${spacing.xsmall};
  user-select: none;
`;

const Item = styled(FlexContainer).attrs({ alignCenter: true })<{ onClick?: () => void }>`
  flex-shrink: 1;
  overflow: hidden;
  font-size: ${fontSize.large};
  font-weight: ${fontWeight.medium};
  gap: ${spacing.xsmall};

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
      color: ${variables.color.content.secondary};

      :hover {
        color: ${variables.color.content.info};
      }
    `}
`;

const ItemTitle = styled.span`
  flex-shrink: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export type BreadcrumbItem = {
  label: React.ReactNode;
  icon?: React.ReactNode;
  onClick?: () => void;
};

type Props = {
  items: BreadcrumbItem[];
  className?: string;
};

const Breadcrumbs = ({ items, className }: Props) => (
  <Root className={className}>
    {items.map((item, i) => (
      <React.Fragment key={i}>
        {i > 0 && <Separator />}
        <BreadcrumbItem {...item} />
      </React.Fragment>
    ))}
  </Root>
);

const BreadcrumbItem = ({ label, icon, onClick }: BreadcrumbItem) => (
  <Item onClick={onClick}>
    {icon}
    <ItemTitle>{label}</ItemTitle>
  </Item>
);

export default Breadcrumbs;
