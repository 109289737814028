import React from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';

import { cardDropShadowStyleMixin } from 'components/lib/ui/Card';
import Flex from 'components/lib/ui/Flex';
import IconButton from 'components/lib/ui/button/IconButton';

import { toggleSidebarExpanded } from 'actions';
import { fontSize, spacing } from 'common/lib/theme/dynamic';
import isV2Theme from 'common/lib/theme/isV2Theme';
import variables from 'common/lib/theme/variables';
import useIsV2Theme from 'lib/hooks/useIsV2Theme';

export const HEADER_HEIGHT = '64px';

const Root = styled.div`
  position: relative;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr;
  gap: ${({ theme }) => theme.spacing.default};
  padding: 0 ${({ theme }) => theme.spacing.gutter};
  ${isV2Theme(css`
    padding-left: calc(${spacing.gutter} + ${spacing.xsmall});
  `)}
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${isV2Theme(fontSize.large, '20px')};
  min-height: ${HEADER_HEIGHT};
  background-color: ${variables.color.background.page};
  ${isV2Theme(undefined, cardDropShadowStyleMixin)}

  @media (max-width: ${({ theme }) => theme.breakPoints.xs}px) {
    padding: 0 ${({ theme }) => theme.spacing.xsmall};
    ${isV2Theme(css`
      padding-left: ${spacing.default};
    `)}
  }
`;

const StyledFlex = styled(Flex)`
  overflow: hidden;
`;

const ToggleSidebarButton = styled(IconButton)`
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

type Props = {
  children?: React.ReactNode;
  controls?: React.ReactNode;
  showSidebarToggle?: boolean;
  className?: string;
};

const Header = ({ controls, children, showSidebarToggle = true, className }: Props) => {
  const dispatch = useDispatch();
  const isV2Theme = useIsV2Theme();

  return (
    <Root className={className}>
      <StyledFlex alignCenter>
        {showSidebarToggle && !isV2Theme && (
          <ToggleSidebarButton icon="menu" onClick={() => dispatch(toggleSidebarExpanded())} />
        )}
        {children ?? null}
      </StyledFlex>
      {controls ?? null}
    </Root>
  );
};

export default Header;
