import pluralize from 'pluralize';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import type { ReactNode } from 'react';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import Checkbox from 'components/lib/form/Checkbox';
import DividerLine from 'components/lib/ui/DividerLine';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import KeyboardShortcut from 'components/lib/ui/KeyboardShortcut';
import Text from 'components/lib/ui/Text';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import IconButton from 'components/lib/ui/button/IconButton';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import MerchantLogo from 'components/merchants/MerchantLogo';
import TransactionsListSortByMenu from 'components/reports/TransactionsListSortByMenu';
import ReviewAllTransactionsButton from 'components/transactions/ReviewAllTransactionsButton';
import ReviewSelect from 'components/transactions/review/ReviewSelect';

import useCategories from 'common/lib/hooks/categories/useCategories';
import useMerchant from 'common/lib/hooks/merchant/useMerchant';
import { color, fontSize, radius, spacing } from 'common/lib/theme/dynamic';
import { TransactionsDropdownFilter } from 'common/lib/transactions/review';
import { isoDateToAbbreviatedMonthDayAndYear } from 'common/utils/date';

import type { TransactionOrdering } from 'common/generated/graphql';
import type { TransactionFilters } from 'types/filters';
import { SUPPORTED_QUICK_VIEW_FILTERS } from 'types/filters';

export const HEADER_HEIGHT_PX = 60;

export const QUICK_VIEW_FILTER_HEIGHT_PX = 32;

const ListHeader = styled.div`
  height: ${HEADER_HEIGHT_PX}px;
  display: flex;
  background: ${color.white};
  padding: ${spacing.default};
  justify-content: space-between;
  border-top: 1px solid ${color.grayBackground};
  border-radius: ${radius.medium};
`;

const TransactionTitle = styled.div`
  display: flex;
  align-self: flex-start;
  font-size: ${fontSize.large};
  height: 100%;
  align-items: center;
`;

const HeaderText = styled(Text).attrs({ weight: 'medium' })``;

const HeaderActions = styled(FlexContainer).attrs({ alignCenter: true })`
  gap: ${spacing.small};

  & ${DefaultButton}:last-child {
    margin-right: 0;
  }
`;

const StyledReviewSelect = styled(ReviewSelect)`
  min-width: 200px;
`;

const StyledCheckbox = styled(Checkbox)`
  display: inline-block;
  margin-right: ${spacing.default};
  & ${DefaultButton} {
    margin: 0 ${spacing.large};
  }
`;

const StyledShortcut = styled(KeyboardShortcut)`
  margin-left: ${spacing.small};
  transform: translateY(1px);
`;

const QuickViewFilterIcon = styled(Icon).attrs({ size: 16 })`
  font-size: ${fontSize.base};
`;

const QuickViewFiltersContainer = styled(FlexContainer).attrs({
  justifyBetween: true,
  gap: 'xsmall',
})`
  margin: 0 ${spacing.small};
`;

const QuickViewFilterContainer = styled(FlexContainer).attrs({
  justifyBetween: true,
  alignCenter: true,
  gap: 'xsmall',
})`
  height: ${QUICK_VIEW_FILTER_HEIGHT_PX}px;
  background: ${color.grayBackground};
  padding: ${spacing.xxxsmall} ${spacing.xsmall};
  border-radius: ${radius.small};
`;

type QuickViewFilterDisplayItem = {
  key: string;
  icon?: ReactNode;
  description?: string;
  filtersToRemove: Partial<TransactionFilters>;
};

export type CustomEditButtonProps = {
  openBulkUpdateForm?: () => void;
  count: number;
};

export type Props = {
  isBulkUpdateActive: boolean;
  isAllSelected: boolean;
  totalSelected: number;
  onRemoveQuickViewFilters?: (filters: Partial<TransactionFilters>) => void;
  quickViewFilters?: Partial<TransactionFilters>;
  filters: TransactionFilters;
  totalCount: number | undefined;
  overrideTitle?: React.ReactNode;
  extraControls?: React.ReactNode;
  sortBy?: Maybe<TransactionOrdering>;
  openBulkUpdateForm: (() => void) | undefined;
  toggleBulkUpdateActive: () => void;
  toggleIsAllSelected: () => void;
  deselectAll: () => void;
  setFilters: (filters: TransactionFilters, override?: boolean) => void;
  renderCustomEditButton?: (props: CustomEditButtonProps) => React.ReactNode;
  onChangeSortBy?: (sortBy: TransactionOrdering) => void;
};

const TransactionListHeader = ({
  isBulkUpdateActive,
  isAllSelected,
  totalSelected,
  onRemoveQuickViewFilters,
  quickViewFilters,
  filters,
  totalCount,
  overrideTitle,
  extraControls,
  sortBy,
  renderCustomEditButton,
  toggleIsAllSelected,
  toggleBulkUpdateActive,
  openBulkUpdateForm,
  deselectAll,
  setFilters,
  onChangeSortBy,
}: Props) => {
  const [merchantId, setMerchantId] = useState<string | undefined>();

  const { getCategory, getCategoryGroup } = useCategories();
  const { merchant } = useMerchant(merchantId);

  const transactionsCount = totalCount ?? 0;

  const needsReviewSelectValue = useMemo(() => {
    if (filters.needsReviewByUser) {
      return filters.needsReviewByUser;
    }
    if (filters.needsReviewUnassigned) {
      return TransactionsDropdownFilter.NeedsReview;
    }

    return TransactionsDropdownFilter.All;
  }, [filters]);

  const getNeedsReviewFiltersFromSelectValue = (value: string) => {
    if (value === TransactionsDropdownFilter.All) {
      return {
        needsReview: undefined,
        needsReviewByUser: undefined,
        needsReviewUnassigned: undefined,
      };
    }

    if (value === TransactionsDropdownFilter.NeedsReview) {
      return {
        needsReview: true,
        needsReviewByUser: undefined,
        needsReviewUnassigned: true,
      };
    }

    return {
      needsReview: true,
      needsReviewByUser: value,
      needsReviewUnassigned: undefined,
    };
  };

  useEffect(() => {
    if (R.isNil(merchantId) && quickViewFilters?.merchants?.[0]) {
      setMerchantId(quickViewFilters.merchants[0]);
    }
  }, [quickViewFilters?.merchants, merchantId]);

  const quickViewFilterDisplayItems: QuickViewFilterDisplayItem[] = useMemo(() => {
    if (!quickViewFilters) {
      return [];
    }

    let unsupportedFilterDisplayItems: QuickViewFilterDisplayItem[] = [];
    R.forEachObjIndexed((value, key) => {
      if (RA.isNotNil(value) && !SUPPORTED_QUICK_VIEW_FILTERS.includes(key as any)) {
        unsupportedFilterDisplayItems = [
          ...unsupportedFilterDisplayItems,
          {
            key,
            icon: <QuickViewFilterIcon name="x-circle" />,
            description: `[UNSUPPORTED] ${key}: ${value}`,
            filtersToRemove: { [key]: value },
          },
        ];
      }
    }, quickViewFilters);

    const categoryFilterItems: QuickViewFilterDisplayItem[] =
      quickViewFilters.categories?.map((categoryId) => {
        const category = getCategory(categoryId);
        return {
          key: `category-${categoryId}`,
          icon: category?.icon && <Text size="base">{category?.icon}</Text>,
          description: category?.name,
          filtersToRemove: { categories: [categoryId] },
        };
      }) || [];

    const categoryGroupFilterItems: QuickViewFilterDisplayItem[] =
      quickViewFilters.categoryGroups?.map((categoryGroupId) => {
        const categoryGroup = getCategoryGroup(categoryGroupId);
        return {
          key: `category-group-${categoryGroupId}`,
          description: categoryGroup?.name,
          filtersToRemove: { categoryGroups: [categoryGroupId] },
        };
      }) || [];

    // For simplicity, only the single merchant is supported in quick view filters
    const getMerchantFilterItems: QuickViewFilterDisplayItem[] = merchant
      ? [
          {
            key: `merchant-${merchant?.id}`,
            icon: <MerchantLogo url={merchant?.logoUrl} size={16} />,
            description: merchant?.name,
            filtersToRemove: { merchants: [merchant?.id] },
          },
        ]
      : [];

    const dateFilterItem: QuickViewFilterDisplayItem[] =
      quickViewFilters.startDate && quickViewFilters.endDate
        ? [
            {
              key: 'date-range',
              icon: <QuickViewFilterIcon name="calendar" />,
              description:
                quickViewFilters.startDate === quickViewFilters.endDate
                  ? isoDateToAbbreviatedMonthDayAndYear(quickViewFilters.startDate || '')
                  : `${isoDateToAbbreviatedMonthDayAndYear(quickViewFilters.startDate || '')} – ${isoDateToAbbreviatedMonthDayAndYear(quickViewFilters.endDate || '')}`,
              filtersToRemove: {
                startDate: quickViewFilters.startDate,
                endDate: quickViewFilters.endDate,
              },
            },
          ]
        : [];

    return [
      ...unsupportedFilterDisplayItems,
      ...categoryFilterItems,
      ...categoryGroupFilterItems,
      ...getMerchantFilterItems,
      ...dateFilterItem,
    ];
  }, [getCategory, getCategoryGroup, quickViewFilters, merchant]);

  return (
    <ListHeader>
      <TransactionTitle>
        {isBulkUpdateActive && (
          <StyledCheckbox
            checked={isAllSelected}
            onChange={() => {
              toggleIsAllSelected();
              if (isAllSelected) {
                deselectAll();
              }
            }}
          />
        )}
        {totalSelected > 0 ? (
          <FlexContainer>
            <Text weight="medium">
              {totalSelected.toLocaleString()} {pluralize('transaction', totalSelected)} selected
            </Text>
            <StyledShortcut text="(Esc)" />
          </FlexContainer>
        ) : (
          <FlexContainer alignCenter>
            {!isBulkUpdateActive ? (
              overrideTitle ?? (
                <StyledReviewSelect
                  value={needsReviewSelectValue}
                  onChange={({ value }: { value: string }) => {
                    setFilters({
                      ...filters,
                      ...getNeedsReviewFiltersFromSelectValue(value),
                    });
                  }}
                />
              )
            ) : (
              <HeaderText weight="medium">All transactions</HeaderText>
            )}

            {isBulkUpdateActive && <StyledShortcut text="(modA)" />}

            {!isBulkUpdateActive && (
              <QuickViewFiltersContainer>
                {quickViewFilterDisplayItems.map(
                  ({ key, icon, description, filtersToRemove }) =>
                    description && (
                      <QuickViewFilterContainer key={key}>
                        {icon}
                        <Text size="base">{description}</Text>
                        <IconButton
                          icon="x"
                          size="xxsmall"
                          onClick={() => onRemoveQuickViewFilters?.(filtersToRemove)}
                        />
                      </QuickViewFilterContainer>
                    ),
                )}
              </QuickViewFiltersContainer>
            )}
          </FlexContainer>
        )}
      </TransactionTitle>
      <HeaderActions>
        {isBulkUpdateActive ? (
          <>
            <DefaultButton
              onClick={() => {
                toggleBulkUpdateActive();
                deselectAll();
              }}
            >
              Cancel
            </DefaultButton>
            {renderCustomEditButton?.({ openBulkUpdateForm, count: totalSelected }) ?? (
              <PrimaryButton disabled={totalSelected < 1} onClick={openBulkUpdateForm}>
                Edit {totalSelected.toLocaleString() || null}
              </PrimaryButton>
            )}
          </>
        ) : (
          <>
            {transactionsCount > 0 && (
              <DefaultButton onClick={toggleBulkUpdateActive}>
                <ButtonIcon name="check" />
                <span>Edit multiple</span>
              </DefaultButton>
            )}
            {filters.needsReview && totalCount !== 0 && (
              <ReviewAllTransactionsButton totalCount={totalCount} />
            )}
            {extraControls}
          </>
        )}
        {transactionsCount > 0 && !!onChangeSortBy && (
          <>
            <DividerLine />
            <TransactionsListSortByMenu value={sortBy} onChangeValue={onChangeSortBy} />
          </>
        )}
      </HeaderActions>
    </ListHeader>
  );
};

export default React.memo(TransactionListHeader);
