import React from 'react';

import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import CardFooter from 'components/lib/ui/CardFooter';
import ModalCard from 'components/lib/ui/ModalCard';
import CancelSubscriptionContactSupport from 'components/settings/billing/cancel/CancelSubscriptionContactSupport';
import CancelSubscriptionDiscountOfferModal from 'components/settings/billing/cancel/CancelSubscriptionDiscountOfferModal';
import CancelSubscriptionReasonModalFormFields from 'components/settings/billing/cancel/CancelSubscriptionReasonModalFormFields';
import PerformCancelSubscriptionModal from 'components/settings/billing/cancel/PerformCancelSubscriptionModal';

import { useStackContext } from 'common/lib/contexts/StackContext';
import { track } from 'lib/analytics/segment';
import useTrack from 'lib/hooks/useTrack';

import { CancelFlowEventNames } from 'common/constants/analytics';
import { CANCEL_SUBSCRIPTION_MODAL_TITLE } from 'common/constants/copy';

import type { CancellationSubreason } from 'common/generated/graphql';
import { CancellationReason } from 'common/generated/graphql';

type Props = {
  isOnFreeTrial?: boolean;
  analyticsFreemiumSummaryStatus?: string | null;
  goBack?: () => void;
};

/**
 * This is part of the new cancellation flow which is behind the feature flag: web-cancel-discount-flow
 */
const CancelSubscriptionReasonModal = ({
  isOnFreeTrial,
  goBack,
  analyticsFreemiumSummaryStatus,
}: Props) => {
  const { push } = useStackContext();
  useTrack(CancelFlowEventNames.CancelFlowStarted, { analyticsFreemiumSummaryStatus });

  return (
    <ModalCard title={CANCEL_SUBSCRIPTION_MODAL_TITLE} onClickBackButton={goBack}>
      <Form<{ reason: CancellationReason; subreason: CancellationSubreason }>
        onSubmit={({ reason, subreason }) => {
          track(CancelFlowEventNames.CancelReasonSelected, {
            reason,
            subreason,
            analyticsFreemiumSummaryStatus,
          });

          if (isOnFreeTrial) {
            // If user is on a trial, skip all the discount offers and just go straight to cancel.
            // In the future we may have a special flow for these users.
            push(PerformCancelSubscriptionModal, {
              reason,
              subreason,
              analyticsFreemiumSummaryStatus,
            });
          } else if (reason === CancellationReason.CONNECTION_ISSUES) {
            // Offer contact support before discount
            push(CancelSubscriptionContactSupport, {
              reason,
              subreason,
              analyticsFreemiumSummaryStatus,
            });
          } else {
            // Go to the discount flow - this will either show a discount offer or cancel the subscription
            push(CancelSubscriptionDiscountOfferModal, {
              reason,
              subreason,
              analyticsFreemiumSummaryStatus,
            });
          }
        }}
      >
        <CancelSubscriptionReasonModalFormFields />
        <CardFooter justifyEnd>
          <FormSubmitButton size="small" disableWhenValuesUnchanged={false}>
            Continue
          </FormSubmitButton>
        </CardFooter>
      </Form>
    </ModalCard>
  );
};

export default CancelSubscriptionReasonModal;
