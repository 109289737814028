import { useSelector } from 'react-redux';

import useQuery from 'common/lib/hooks/useQuery';
import { getPlanOnboardingUserIsNew } from 'common/state/employee/selectors';

import { gql } from 'common/generated';

const useBudgetOnboardingState = () => {
  const { data, isLoadingInitialData } = useQuery(QUERY, {
    fetchPolicy: 'cache-first',
  });

  const hasBudget = data?.budgetStatus?.hasBudget;

  // This flag indicates if the user is forced into the "new user" experience,
  // regardless of their actual status. It's set by the employee menu.
  const isForcedNewUserExperience = useSelector(getPlanOnboardingUserIsNew);

  // In the budget onboarding flow, we consider users that have a budget to be "existing users"
  // whereas "new users" are users that don't have a budget.
  const isExistingUser =
    isForcedNewUserExperience !== undefined ? !isForcedNewUserExperience : !!hasBudget;

  // Just aliases, but useful to have in case we want to change any logic in the future.
  // We want to skip recalculation if the user is an existing user.
  const skipRecalculation = isLoadingInitialData ? undefined : !!isExistingUser;
  const skipRecalculateIncomeBudget = skipRecalculation;
  const skipRecalculateExpensesBudget = skipRecalculation;
  const skipRecalculateFixedAndFlexExpensesBudget = skipRecalculation;

  return {
    isExistingUser,
    skipConnectingAccounts: isExistingUser,
    skipRecalculateIncomeBudget,
    skipRecalculateExpensesBudget,
    skipRecalculateFixedAndFlexExpensesBudget,
    isLoadingInitialData,
  };
};

const QUERY = gql(/* GraphQL */ `
  query Common_GetPlanOnboardingState {
    budgetStatus {
      ...BudgetStatusFields
    }
  }
`);

export default useBudgetOnboardingState;
