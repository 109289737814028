import React from 'react';
import styled from 'styled-components';

import { sensitiveClassProps } from 'components/lib/higherOrder/withSensitiveData';
import type { BreadcrumbItem } from 'components/lib/ui/Breadcrumbs';
import Breadcrumbs from 'components/lib/ui/Breadcrumbs';
import Flex from 'components/lib/ui/Flex';
import Header from 'components/routes/Header';

import useIsV2Theme from 'lib/hooks/useIsV2Theme';

const FlexNoOverflow = styled(Flex)`
  overflow: hidden;
  height: 100%;
`;

const IconWrapper = styled.div`
  margin-right: ${({ theme }) => theme.spacing.small};
`;

const Title = styled.span`
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type Props = {
  icon?: React.ReactNode;
  /** The name of the page */
  name: string;
  /** Dynamic title to override the page. Used just for display. */
  overrideTitle?: string;
  controls?: React.ReactNode;
  /** Tabs component to show when page has sub routes. */
  tabs?: React.ReactNode;
  titleIsSensitive?: boolean;
  breadcrumbs?: BreadcrumbItem[];
};

const PageHeader = ({
  controls,
  icon,
  titleIsSensitive,
  overrideTitle,
  tabs,
  name,
  breadcrumbs = [],
}: Props) => {
  const isV2ThemeEnabled = useIsV2Theme();

  const title = overrideTitle ?? name;

  const breadcrumbItems = [...breadcrumbs, { label: title, icon }];

  return (
    <Header controls={controls}>
      <FlexNoOverflow alignCenter {...(titleIsSensitive ? sensitiveClassProps : {})}>
        {isV2ThemeEnabled ? (
          <Breadcrumbs items={breadcrumbItems} />
        ) : (
          <>
            {!!icon && <IconWrapper>{icon}</IconWrapper>}
            <Title title={title}>{title}</Title>
          </>
        )}
        {tabs}
      </FlexNoOverflow>
    </Header>
  );
};

export default PageHeader;
