import pluralize from 'pluralize';

import type { AggregatedSummary as AggregatedSummaryType } from 'common/lib/recurring/types';
import { formatCurrency } from 'common/utils/Currency';

import type { Maybe } from 'common/generated/graphql';

export const getStatementsPendingInfo = (summary: Maybe<Partial<AggregatedSummaryType>>) => {
  const { count, pendingAmountCount } = summary ?? {};

  const isAllPending = !!count && count === pendingAmountCount && count > 0;
  const somePending = !!pendingAmountCount && pendingAmountCount > 0;

  return {
    isAllPending,
    somePending,
  };
};

export const getPendingStatementsLabel = (summary: Maybe<Partial<AggregatedSummaryType>>) => {
  const { count, pendingAmountCount, total } = summary ?? {};
  const somePending = !!pendingAmountCount && pendingAmountCount > 0;

  if (!somePending) {
    return `${formatCurrency(Math.abs(total ?? 0))} total`;
  }

  return `${pendingAmountCount} / ${count} ${pluralize('statement', count ?? 0)} pending`;
};
