import { createReducer } from 'typesafe-actions';

import { routeChanged } from 'actions';

const INITIAL_STATE: NavigationState = {
  routeHistory: [],
};

export type RouteHistoryEntry = {
  path: string;
  search: string;
  pageTitle: string;
  key?: string;
};

export type NavigationState = {
  routeHistory: RouteHistoryEntry[];
};

const navigation = createReducer<NavigationState>(INITIAL_STATE).handleAction(
  routeChanged,
  (state, { payload: route }) => ({
    ...state,
    routeHistory: [...state.routeHistory, route],
  }),
);

export default navigation;
