import { formatCurrencyNoCents } from 'common/utils/Currency';

export const getAccountsDashboardWidgetTitle = ({
  isV2Theme,
  currentNetWorth,
  isLoading,
}: {
  isV2Theme: boolean;
  currentNetWorth: number;
  isLoading: boolean;
}) => {
  if (isLoading) {
    return '';
  } else if (isV2Theme) {
    return `${formatCurrencyNoCents(currentNetWorth)} net worth`;
  } else {
    return 'Net Worth';
  }
};

export const getInvestmentsDashboardWidgetTitle = ({
  isV2Theme,
  currentAmount,
  isLoading,
}: {
  isV2Theme: boolean;
  currentAmount: number;
  isLoading: boolean;
}) => {
  if (isLoading) {
    return '';
  } else if (isV2Theme) {
    return `${formatCurrencyNoCents(currentAmount)} investments`;
  } else {
    return 'Investments';
  }
};
