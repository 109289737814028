import React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import type { BlockRenderer, CtaBlock } from 'common/lib/posts/types';
import { spacing } from 'common/lib/theme/dynamic';

const Root = styled(FlexContainer)`
  margin-top: ${spacing.xlarge};
  margin-bottom: ${spacing.xxlarge};
`;

const CtaBlockRenderer: BlockRenderer<CtaBlock> = ({ data: { text, href } }) => (
  <Root>
    <PrimaryButton size="medium" onClick={() => window.open(href, '_blank')}>
      {text}
    </PrimaryButton>
  </Root>
);

export default CtaBlockRenderer;
