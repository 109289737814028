import { useEffect } from 'react';

import { trackCustomerIOLinkClicked } from 'common/lib/external/customerio';
import noop from 'common/utils/noop';
import { useQueryParam } from 'lib/hooks/useQueryParams';

/**
 * Hook to track universal link clicks from emails with Customer.io.
 *
 * When the `cio_link_id` query param is present, this will POST to
 * the Customer.io API to track the link click.
 */
const useCustomerioLinkTracking = () => {
  const cioLinkId = useQueryParam('cio_link_id');

  useEffect(() => {
    if (cioLinkId) {
      trackCustomerIOLinkClicked(cioLinkId).catch(noop); // ignore error
    }
  }, [cioLinkId]);
};

export default useCustomerioLinkTracking;
