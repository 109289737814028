import { useMutation } from '@apollo/client';
import * as R from 'ramda';
import { useCallback } from 'react';

import useQuery from 'common/lib/hooks/useQuery';

import { gql } from 'common/generated/gql';
import type { UpdateHouseholdPreferencesInput } from 'common/generated/graphql';
import { BudgetSystem } from 'common/generated/graphql';

/**
 * Hook used to fetch and update the user's household preferences.
 */
const useHouseholdPreferences = () => {
  const { data, isLoadingInitialData } = useQuery(QUERY, {
    // This data is used everywhere and it only changes when users update their preferences
    // so we can rely on the cache to avoid doing a network request every time
    fetchPolicy: 'cache-first',
  });
  const { householdPreferences, budgetSystem } = data ?? {};

  const [performUpdate] = useMutation(MUTATION);

  const updateHouseholdPreferences = useCallback(
    (input: Partial<UpdateHouseholdPreferencesInput>) =>
      performUpdate({
        variables: {
          input: {
            ...R.omit(['__typename', 'id', 'accountGroupOrder'], householdPreferences),
            ...input,
          } as Required<typeof input>,
        },
        optimisticResponse: householdPreferences
          ? {
              __typename: 'Mutation',
              updateHouseholdPreferences: {
                __typename: 'UpdateHouseholdPreferences',
                householdPreferences: {
                  ...householdPreferences,
                  ...input,
                },
              },
            }
          : undefined,
      }),
    [performUpdate, householdPreferences],
  );

  const isFlexBudgeting = budgetSystem === BudgetSystem.FIXED_AND_FLEX;

  return {
    householdPreferences,
    budgetSystem,
    isFlexBudgeting,
    isLoadingInitialData,
    updateHouseholdPreferences,
  };
};

export const HOUSEHOLD_PREFERENCES_FRAGMENT = gql(`
  fragment HouseholdPreferencesFields on HouseholdPreferences {
    id
    newTransactionsNeedReview
    uncategorizedTransactionsNeedReview
    pendingTransactionsCanBeEdited
    accountGroupOrder
    aiAssistantEnabled
    llmEnrichmentEnabled
    investmentTransactionsEnabled
    budgetApplyToFutureMonthsDefault
  }
`);

export const HOUSEHOLD_BUDGET_SETTINGS_FRAGMENT = gql(`
  fragment HouseholdBudgetSettingsFields on Query {
    budgetSystem
    budgetApplyToFutureMonthsDefault
  }
`);

export const QUERY = gql(`
  query Common_GetHouseholdPreferences {
    householdPreferences {
      id
      ...HouseholdPreferencesFields
    }
    ...HouseholdBudgetSettingsFields
  }
`);

const MUTATION = gql(`
  mutation Common_UpdateHouseholdPreferences($input: UpdateHouseholdPreferencesInput!) {
    updateHouseholdPreferences(input: $input) {
      householdPreferences {
        id
        ...HouseholdPreferencesFields
      }
    }
  }
`);

export default useHouseholdPreferences;
