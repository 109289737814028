import { Interval } from 'luxon';
import { useCallback, useMemo } from 'react';

import { COLUMN_WIDTH_PX } from 'components/plan/PlanGrid';

import useHouseholdPreferences from 'common/lib/hooks/household/useHouseholdPreferences';
import usePlanAdapterColumns from 'lib/hooks/plan/usePlanAdapterColumns';
import type { PlanState } from 'lib/hooks/plan/usePlanState';
import { PlanTimeframe } from 'lib/hooks/plan/usePlanState';
import {
  getBudgetSummaryData,
  getGridAmounts,
  getGridDisplayData,
  getYearlyAggregateAmounts,
} from 'lib/plan/Adapters';
import { getMergedPlanningAmountsFromQueryData } from 'lib/planning';

import type { Common_GetJointPlanningDataQuery } from 'common/generated/graphql';

/**
 * This hook handles the interface between query data, state, and display data for the Plan page.
 */
const usePlanAdapter = (
  data: Common_GetJointPlanningDataQuery | undefined,
  state: PlanState,
  fetchedDateRange: Interval | undefined,
) => {
  const { columnDisplayInterval, timeframe, highlightedDate } = state;

  const {
    timeframeUnit,
    numColumns,
    getDateForColumn,
    getAmountTypeForColumn,
    getCanEditColumn,
    columnDates,
  } = usePlanAdapterColumns(state);

  const { isFlexBudgeting } = useHouseholdPreferences();

  const rowAmountsByMonth = useMemo(() => {
    const isYearly = timeframe === PlanTimeframe.Yearly;
    const amountsByMonth = getMergedPlanningAmountsFromQueryData(columnDisplayInterval, data);
    return isYearly ? getYearlyAggregateAmounts(amountsByMonth) : amountsByMonth;
  }, [data, columnDisplayInterval, timeframe]);

  const isLoadingInitialData = !data;

  const gridDisplayData = useMemo(
    () =>
      data
        ? getGridDisplayData(
            data.categoryGroups,
            data.goalsV2,
            rowAmountsByMonth,
            columnDates,
            timeframe,
            columnDisplayInterval,
            isFlexBudgeting,
          )
        : [],
    [data, rowAmountsByMonth, columnDates, timeframe, columnDisplayInterval, isFlexBudgeting],
  );

  const gridAmounts = useMemo(
    () => getGridAmounts(gridDisplayData, rowAmountsByMonth),
    [gridDisplayData, rowAmountsByMonth],
  );

  const budgetSummaryData = useMemo(
    () =>
      getBudgetSummaryData(
        gridDisplayData,
        gridAmounts,
        data?.budgetData.totalsByMonth ?? [],
        highlightedDate,
        isFlexBudgeting,
      ),
    [gridDisplayData, gridAmounts, highlightedDate, data, isFlexBudgeting],
  );

  const getIsColumnLoading = useCallback(
    (column: number) => {
      const date = getDateForColumn(column);
      const endDate = date.endOf(timeframeUnit);
      const isLoading = !fetchedDateRange?.engulfs(Interval.fromDateTimes(date, endDate));
      return isLoading;
    },
    [getDateForColumn, fetchedDateRange, timeframeUnit],
  );

  const initialScrollOffset = useMemo(() => {
    if (timeframe === PlanTimeframe.Monthly && data) {
      const columnIndex = columnDates.findIndex((date) => date.hasSame(highlightedDate, 'day'));
      return { scrollTop: 0, scrollLeft: (columnIndex - 1) * COLUMN_WIDTH_PX };
    }
  }, [timeframe, highlightedDate, columnDates, data]);

  return {
    timeframeUnit,
    isLoadingInitialData,
    gridDisplayData,
    numColumns,
    getDateForColumn,
    getAmountTypeForColumn,
    getCanEditColumn,
    getIsColumnLoading,
    gridAmounts,
    budgetSummaryData,
    initialScrollOffset,
    shouldShowSafeToSpend: isFlexBudgeting,
  };
};

export default usePlanAdapter;
