import React from 'react';
import styled from 'styled-components';

import FullHeightCategorySelect from 'components/categories/FullHeightCategorySelect';
import { SelectTrigger, SelectIconWrapper } from 'components/lib/form/FullScreenSelect';
import Tooltip from 'components/lib/ui/Tooltip';
import NarrowLinkButton from 'components/transactions/list/NarrowLinkButton';

import { updateTransactionOptimisticResponse } from 'common/lib/graphQl/transactions';
import { color, spacing } from 'common/lib/theme/dynamic';
import { useTransactionRuleToastContext } from 'lib/contexts/TransactionRuleToastContext';

import routes from 'constants/routes';

import type {
  TransactionOverviewFieldsFragment,
  UpdateTransactionMutationInput,
  Web_UpdateTransactionOverviewDocument,
} from 'common/generated/graphql';
import type { MutationFunctionFromDocument } from 'common/types/graphql';

const TOOLTIP_SHOW_DELAY_MS = 500;

const Root = styled.div`
  flex: 1;
  display: flex;
  align-items: stretch;
  gap: ${spacing.xxsmall};

  &:hover,
  &:focus-within {
    ${SelectIconWrapper} {
      opacity: 1;
      display: block;
    }

    ${SelectTrigger} {
      &[data-disabled] {
        border-color: ${color.grayFocus};
        background-color: ${color.grayBackground};
      }
    }

    ${NarrowLinkButton} {
      display: flex;
    }
  }
`;

type Props = {
  categoryId: string;
  categoryName: string;
  icon: string;
  transaction: TransactionOverviewFieldsFragment;
  hideDetailsLink?: boolean;
  isEditingDisabled?: boolean;
  updateTransaction: MutationFunctionFromDocument<typeof Web_UpdateTransactionOverviewDocument>;
};

const TransactionCategorySelect: React.FC<Props> = ({
  categoryId,
  categoryName,
  transaction,
  icon,
  hideDetailsLink,
  isEditingDisabled,
  updateTransaction,
}) => {
  const { showToast: showCreateTransactionRuleToast } = useTransactionRuleToastContext();
  return (
    <Root>
      <>
        <FullHeightCategorySelect
          disabled={isEditingDisabled}
          italicizeValueLabel={isEditingDisabled}
          value={{ value: categoryId, label: categoryName, icon }}
          onChange={async ({ isRecommendedCategory }, action, [category]) => {
            if (category) {
              await updateTransaction({
                variables: {
                  input: {
                    id: transaction.id,
                    category: category.id,
                    isRecommendedCategory,
                  } as UpdateTransactionMutationInput,
                },
                optimisticResponse: updateTransactionOptimisticResponse({
                  ...transaction,
                  category,
                }),
              });
              showCreateTransactionRuleToast({
                merchantName: transaction.merchant.name,
                newCategoryId: category.id,
              });
            }
          }}
          transactionId={transaction.id}
          transactionProviderDescription={transaction.dataProviderDescription}
          alwaysShowBorder={false}
          fullWidthTrigger
          isCreatable
        />
        {!hideDetailsLink && (
          <Tooltip content="View category" delayShow={TOOLTIP_SHOW_DELAY_MS} fitContent>
            <NarrowLinkButton linkTo={routes.categories({ id: categoryId })} />
          </Tooltip>
        )}
      </>
    </Root>
  );
};

export default TransactionCategorySelect;
