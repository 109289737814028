// THIS FILE IS GENERATED, DO NOT MODIFY MANUALLY. ##checksum##: 4275704714
import { createAction } from 'typesafe-actions';

import type { RecurringFilters } from 'state/recurring/reducer';

export const setRecurringFilters = createAction(
  'RECURRING/SET_RECURRING_FILTERS',
)<RecurringFilters>();

export const toggleCollapsedRecurringGroup = createAction(
  'RECURRING/TOGGLE_COLLAPSED_RECURRING_GROUP',
)<string>();
