import { useEffect } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { useQueryParam, useDispatch } from 'lib/hooks';
import { markNotificationAsOpened } from 'state/notification/thunks';

import routes from 'constants/routes';

// We use this for links coming in to the app. By default, it'll strip '/links'
// from the beginning of the path, but we can add other redirect logic.
// In the future, we'll probably want a more robust way to do this routing and to keep
// these links in sync with the backend.
const Links = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const notificationId = useQueryParam('notification');

  useEffect(() => {
    if (notificationId) {
      dispatch(markNotificationAsOpened(notificationId));
    }
  }, []);

  const accountDetailsMatch = useRouteMatch('/links/accounts/:accountId');
  const budgetExceededMatch = useRouteMatch('/links/budget-exceeded/:categoryId');
  const plaidLinkOauthMatch = useRouteMatch(`/links${routes.accounts.plaidLinkOauth.path}`);
  const notificationsMatch = useRouteMatch('/links/notifications');
  // Mobile version with this route is already out to customers so this is a workaround to make it work for emails
  const referralsMatch = useRouteMatch('/links/settings/share-monarch');

  if (budgetExceededMatch) {
    location.pathname = routes.budget.path;
  } else if (accountDetailsMatch && !plaidLinkOauthMatch) {
    location.pathname = routes.accounts.accountDetails({
      // @ts-ignore TS doesn't type the params object
      id: accountDetailsMatch?.params.accountId,
    });
  } else if (notificationsMatch) {
    location.pathname = routes.dashboard();
  } else if (referralsMatch) {
    location.pathname = routes.settings.referrals();
  } else {
    location.pathname = location.pathname.replace(/^\/links/, '');
  }

  useEffect(() => {
    history.replace(location);
  });

  return null;
};

export default Links;
