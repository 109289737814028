import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import TabsContext from 'common/components/tabs/Tabs';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Grid, { GridItem } from 'components/lib/ui/Grid';
import PageWithNoAccountsEmptyState from 'components/lib/ui/PageWithNoAccountsEmptyState';
import StatisticCard from 'components/lib/ui/StatisticCard';
import Text from 'components/lib/ui/Text';
import ReportsCashFlowValue from 'components/reports/ReportsCashFlowValue';
import ReportsChartCard from 'components/reports/ReportsChartCard';
import ReportsChartCardEmpty from 'components/reports/ReportsChartCardEmpty';
import ReportsHeaderControls from 'components/reports/ReportsHeaderControls';
import ReportsHeaderTabs from 'components/reports/ReportsHeaderTabs';
import SaveReportConfigurationModal from 'components/reports/SaveReportConfigurationModal';
import TransactionsListContainer from 'components/transactions/TransactionsListContainer';
import TransactionsSummaryCard from 'components/transactions/TransactionsSummaryCard';

import {
  resetToDefaultView,
  setGroupByTimeframe,
  setReportsFilters,
  setReportsTransactionsSortBy,
} from 'actions';
import type { GroupDetail } from 'common/lib/reports';
import { ensureGroupBy, getAllowedTimeframesFromDateRange } from 'common/lib/reports';
import boxShadow from 'common/lib/styles/boxShadow';
import { breakPoints, color, radius, spacing } from 'common/lib/theme/dynamic';
import isV2Theme from 'common/lib/theme/isV2Theme';
import { formatCurrency } from 'common/utils/Currency';
import { formatPercent } from 'common/utils/Number';
import { track } from 'lib/analytics/segment';
import typewriter from 'lib/analytics/typewriter';
import { areFiltersEquivalent } from 'lib/filters/utils';
import { useDispatch } from 'lib/hooks';
import useAdaptedReportsData from 'lib/hooks/reports/useAdaptedReportsData';
import useReportConfigurations from 'lib/hooks/reports/useReportConfigurations';
import useReportsCurrentTab from 'lib/hooks/reports/useReportsCurrentTab';
import useModal from 'lib/hooks/useModal';
import useSelectors from 'lib/hooks/useSelectors';
import useToast from 'lib/hooks/useToast';
import {
  formatReportsValue,
  getReportFiltersForTransactionFilterSet,
  makeChartTitle,
} from 'lib/reports';
import {
  selectDisplayPropertiesForTab,
  selectReportsFilters,
  selectReportsGroupBy,
  selectReportsGroupByTimeframe,
  selectReportsSortBy,
} from 'state/reports/selectors';
import { ReportsChart } from 'state/reports/types';
import { downloadTransactions } from 'state/transactions/thunks';
import type { RootState } from 'state/types';
import { isElementBottomBelowViewport } from 'utils/viewport';

import { ReportsEventNames } from 'common/constants/analytics';
import { REPORTS } from 'common/constants/copy';
import routes from 'constants/routes';

import type {
  ReportConfiguration,
  TransactionFilterInput,
  TransactionOrdering,
} from 'common/generated/graphql';
import type { TransactionFilters } from 'types/filters';

const DEFAULT_ROUTE = routes.reports.cashFlow.path;

const TransactionsSummaryRefContainer = styled.div`
  scroll-margin-bottom: ${spacing.gutter};
`;

const WithShadow = styled.div`
  ${boxShadow.medium}
  background-color: ${color.white};
  border-radius: ${radius.medium};
`;

const StatisticCardGrow = styled(StatisticCard)`
  flex: 1;
`;

const SummaryCardsContainer = styled(FlexContainer).attrs({
  justifyBetween: true,
  alignCenter: true,
  gap: 'gutter',
})`
  margin-top: 0;
  margin-bottom: ${spacing.gutter};

  @media (max-width: ${({ theme }) => theme.breakPoints.md}px) {
    flex-flow: row wrap;
    gap: ${({ theme }) => theme.spacing.small};
    margin: ${spacing.gutter} 0 ${spacing.small};

    > ${StatisticCardGrow} {
      flex-basis: 33%;
    }
  }

  @media (max-width: ${breakPoints.xs}px) {
    > ${StatisticCardGrow} {
      flex-basis: 50%;
    }
  }
`;

const StyledGrid = styled(Grid)`
  ${isV2Theme(css`
    padding-top: 0;
  `)}
`;

const Reports = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentTab = useReportsCurrentTab();
  const transactionsSummaryRef = useRef<HTMLDivElement>(null);

  const [ReportConfigurationModal, { open: openReportConfigurationModal }] = useModal();

  const [showAllEntities, setShowAllEntities] = useState(false);

  const [filters, groupBy, groupByTimeframe, sortTransactionsBy] = useSelectors([
    selectReportsFilters,
    selectReportsGroupBy,
    selectReportsGroupByTimeframe,
    selectReportsSortBy,
  ]);
  const { chartType, groupMode, viewMode } = useSelector((state: RootState) =>
    selectDisplayPropertiesForTab(state, currentTab),
  );

  const [quickViewFilters, setQuickViewFilters] = useState<Partial<TransactionFilters>>({});
  const [selectedDatumId, setSelectedDatumId] = useState<Maybe<string>>(null);
  const [selectedDateRange, setSelectedDateRange] =
    useState<Maybe<{ startDate: string; endDate: string }>>(null);

  const {
    reportConfigurations,
    createReportConfiguration,
    updateReportConfiguration,
    deleteReportConfiguration,
  } = useReportConfigurations();
  const [editingReportConfigurationId, setEditingReportConfigurationId] =
    useState<Maybe<string>>(null);

  const hasQuickViewFilters = useMemo(
    () => Object.keys(quickViewFilters).length > 0,
    [quickViewFilters],
  );

  const allFilters = useMemo(
    () => R.mergeRight(filters, quickViewFilters),
    [filters, quickViewFilters],
  );

  const selectedReportConfigurationId = useMemo(() => {
    if (R.isNil(filters)) {
      return undefined;
    }

    const selectedReportConfiguration = reportConfigurations.find((reportConfig) =>
      // @ts-expect-error: ReportsFilters is not the same as TransactionFilterInput,
      // but we can still use the same function to check for equivalence
      areFiltersEquivalent(filters, reportConfig.transactionFilterSet),
    );

    return selectedReportConfiguration?.id;
  }, [reportConfigurations, filters]);

  const analyticsMetadata = useMemo(
    () => ({
      chartType,
      viewMode: ['spending', 'income'].includes(currentTab) ? viewMode : undefined,
      grouping: groupMode ?? groupBy,
      timeframe: [
        ReportsChart.CashFlowChart,
        ReportsChart.StackedCashFlowChart,
        ReportsChart.BarChart,
        ReportsChart.StackedBarChart,
      ].includes(chartType)
        ? groupByTimeframe
        : undefined,
    }),
    [chartType, currentTab, viewMode, groupMode, groupBy, groupByTimeframe],
  );

  const onResetToDefaultView = useCallback(() => {
    dispatch(resetToDefaultView());
    history.push(routes.reports.cashFlow());
  }, [history, dispatch]);

  const removeQuickViewFilters = useCallback(
    (filtersToRemove?: Partial<TransactionFilters>, userInitiatedAction = true) => {
      if (filtersToRemove) {
        // This logic currently clobbers the entire filter provided; i.e., if a single category is
        // requested for removal (and is passed as ['sample-id'] to the categories sub-field), all
        // categories are actually removed, not just the one requested. This is acceptable for now
        // because, technically, only a single quick view filter should ever be applied at a time,
        // even though there is support for multiple. If a use case arises where multiple quick
        // view filters can actually be applied at once, this logic will need to be revisited.
        setQuickViewFilters(R.omit(Object.keys(filtersToRemove), quickViewFilters));
      } else {
        setQuickViewFilters({});
      }

      if (userInitiatedAction) {
        track(ReportsEventNames.ReportsChartDataSelectionReset, analyticsMetadata);
      }
    },
    [analyticsMetadata, quickViewFilters],
  );

  useEffect(() => {
    // Reset every time the tab or filters change
    setShowAllEntities(false);
  }, [currentTab, filters]);

  useEffect(() => {
    // Only track this event for changes to the reports configuration (except the tab). Tab changes
    // are already tracked as a page level event, and filters changes are captured elsewhere.
    track(ReportsEventNames.ReportsConfigurationChanged, analyticsMetadata);
  }, [chartType, viewMode, groupMode, groupBy, groupByTimeframe, analyticsMetadata]);

  useEffect(() => {
    // Reset the quick-view filters every time top-level changes occur. Changes to the quick-view
    // filters themselves should not trigger this, as it would mean *any change is destructive.
    removeQuickViewFilters(undefined, false);
  }, [currentTab, filters, chartType, viewMode, groupMode, groupBy, groupByTimeframe]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!hasQuickViewFilters) {
      setSelectedDatumId(null);
      setSelectedDateRange(null);
    }
  }, [hasQuickViewFilters]);

  const supportsSingleGroupBy = ['spending', 'income'].includes(currentTab);

  // This set of data is used for the main chart and the top level summary. We do not want to
  // include quick view filters here because we want the chart and summary to include all the
  // transactions that apply based on the top level filters.
  const { result, summary, isLoading, queryVariables } = useAdaptedReportsData({
    groupBy: supportsSingleGroupBy ? ensureGroupBy(groupBy) : undefined,
    groupByTimeframe,
    currentTab,
    filters,
    showAllEntities,
  });

  const allowedTimeframes = useMemo(
    () =>
      getAllowedTimeframesFromDateRange(
        filters.startDate ?? summary.first,
        filters.endDate ?? summary.last,
      ),
    [filters.startDate, filters.endDate, summary.first, summary.last],
  );

  useEffect(() => {
    // If the previously selected timeframe is not allowed, set it to the last allowed timeframe.
    // The last allowed timeframe is the coarsest (least granular) one, so performance is better.
    if (!allowedTimeframes.includes(groupByTimeframe)) {
      dispatch(setGroupByTimeframe(allowedTimeframes[allowedTimeframes.length - 1]));
    }
  }, [dispatch, groupByTimeframe, allowedTimeframes]);

  // This set of data is used for the transactions summary. We _do_ want to include quick view
  // filters here because we want the transactions summary to sync with the transactions list based
  // on the quick view filters. However, if we don't have any quick view filters, we don't need to
  // run this query, so we set `skipQuery` to true.
  const { summary: quickViewFilteredSummary, isLoading: isLoadingQuickViewFilteredSummary } =
    useAdaptedReportsData({
      groupBy: supportsSingleGroupBy ? ensureGroupBy(groupBy) : undefined,
      currentTab,
      filters: allFilters,
      showAllEntities,
      skipQuery: !hasQuickViewFilters,
      onCompletedQuerySuccessfully: () => {
        if (
          transactionsSummaryRef.current &&
          hasQuickViewFilters &&
          isElementBottomBelowViewport(transactionsSummaryRef.current)
        ) {
          transactionsSummaryRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
      },
    });

  const onChangeSortBy = useCallback(
    (sortBy: TransactionOrdering) => dispatch(setReportsTransactionsSortBy(sortBy)),
    [dispatch],
  );

  const chartTitle = useMemo(() => makeChartTitle(groupBy, currentTab), [currentTab, groupBy]);

  const dataMap = useMemo(
    () =>
      result[0].reduce(
        (acc, datum) => {
          if (datum.entities) {
            Object.values(datum.entities).forEach((entity) => {
              if (entity?.id) {
                acc[entity.id] = datum;
              }
            });
          }
          return acc;
        },
        {} as Record<string, GroupDetail>,
      ),
    [result],
  );

  const onSelectDatum = useCallback(
    (id: string) => {
      if (id === selectedDatumId) {
        setSelectedDatumId(id);
        removeQuickViewFilters();
        return;
      }

      const datum = dataMap[id];
      if (R.isNil(datum)) {
        return;
      }
      setSelectedDatumId(id);

      const { category, categoryGroup, merchant } = datum.original.groupBy;
      if (RA.isNotNil(category) && category.id === id) {
        setQuickViewFilters({ categories: [category.id] });
      } else if (RA.isNotNil(categoryGroup) && categoryGroup.id === id) {
        setQuickViewFilters({ categoryGroups: [categoryGroup.id] });
      } else if (RA.isNotNil(merchant) && merchant.id === id) {
        setQuickViewFilters({ merchants: [merchant.id] });
      }

      track(ReportsEventNames.ReportsChartDataSelected, analyticsMetadata);
    },
    [selectedDatumId, dataMap, analyticsMetadata, removeQuickViewFilters],
  );

  const onSelectDateRange = useCallback(
    (startDate: string, endDate: string) => {
      if (selectedDateRange?.startDate === startDate && selectedDateRange?.endDate === endDate) {
        setSelectedDateRange(null);
        removeQuickViewFilters();
        return;
      }
      setSelectedDateRange({ startDate, endDate });
      setQuickViewFilters({ startDate, endDate });

      track(ReportsEventNames.ReportsChartDataSelected, analyticsMetadata);
    },
    [
      selectedDateRange?.startDate,
      selectedDateRange?.endDate,
      analyticsMetadata,
      removeQuickViewFilters,
    ],
  );

  const onSelectReportConfiguration = useCallback(
    (reportConfiguration: ReportConfiguration) => {
      dispatch(
        setReportsFilters(
          getReportFiltersForTransactionFilterSet(
            reportConfiguration.transactionFilterSet,
            filters.startDate,
            filters.endDate,
          ),
        ),
      );
      track(ReportsEventNames.ReportConfigurationSelected);
    },
    [dispatch, filters.endDate, filters.startDate],
  );

  const renderChartCard = useCallback(
    (props: Partial<React.ComponentProps<typeof ReportsChartCard>>) => (
      <ReportsChartCard
        data={result}
        isLoading={isLoading}
        title={chartTitle}
        currentTab={currentTab}
        groupByTimeframe={groupByTimeframe}
        allowedTimeframes={allowedTimeframes}
        summary={summary}
        {...R.omit(['total'], props)}
        total={formatReportsValue(props.total)}
        showAllEntities={showAllEntities}
        onChangeShowAllEntities={setShowAllEntities}
        selectedDatumId={selectedDatumId}
        onSelectDatum={onSelectDatum}
        onSelectDateRange={onSelectDateRange}
        selectedDateRange={selectedDateRange}
      />
    ),
    [
      result,
      isLoading,
      chartTitle,
      currentTab,
      groupByTimeframe,
      summary,
      showAllEntities,
      selectedDatumId,
      selectedDateRange,
    ],
  );

  const { openErrorToast, openToast } = useToast();
  const [isDownloadingCsv, setIsDownloadingCsv] = useState(false);
  const onClickDownloadCsv = useCallback(async () => {
    setIsDownloadingCsv(true);

    const { startDate, endDate, ...rest } = allFilters;
    const filtersWithCounts = Object.keys(rest).reduce((acc, key) => {
      const count = R.pathOr(0, [key, 'length'], allFilters);
      return count > 0 ? { ...acc, [key]: count } : acc;
    }, {});

    typewriter.transactionsDownloadedToCsv({
      filtersWithCounts: R.mergeRight(filtersWithCounts, { startDate, endDate }),
      origin: 'reports',
    });

    try {
      await dispatch(downloadTransactions(allFilters));
      openToast({
        title: 'Transactions downloaded',
        description: 'Your transactions have been downloaded to your computer.',
      });
    } catch (error) {
      openErrorToast({
        title: 'Error downloading your transactions',
      });
    } finally {
      setIsDownloadingCsv(false);
    }
  }, [allFilters, dispatch]);

  return (
    <TabsContext>
      <PageWithNoAccountsEmptyState
        name="Reports"
        tabs={<ReportsHeaderTabs />}
        controls={
          <ReportsHeaderControls
            reportConfigurations={reportConfigurations}
            selectedReportConfigurationId={selectedReportConfigurationId}
            onResetToDefaultReportConfiguration={onResetToDefaultView}
            onSelectReportConfiguration={onSelectReportConfiguration}
            onSaveReportConfiguration={openReportConfigurationModal}
            onEditReportConfiguration={({ id }) => {
              setEditingReportConfigurationId(id);
              openReportConfigurationModal();
            }}
          />
        }
        emptyIcon="pie-chart"
        emptyTitle={REPORTS.EMPTY_STATE_TITLE}
        emptyButtonText={REPORTS.EMPTY_STATE_BUTTON}
        scrollKey={currentTab}
      >
        <StyledGrid
          template={`"chart chart" "transactions summary" / 1fr 30%`}
          md={`"chart" "summary" "transactions"`}
        >
          <GridItem area="chart">
            <Switch>
              {/* This redirects the user to the default route if they try to access the page by
               the direct URL (/reports). Without this we'd render a blank page. */}
              <Route
                exact
                path={routes.reports.path}
                render={() => <Redirect to={DEFAULT_ROUTE} />}
              />
              <Route path={routes.reports.cashFlow.path}>
                <SummaryCardsContainer>
                  <StatisticCardGrow
                    label="Total Income"
                    value={
                      <ReportsCashFlowValue
                        formatter={formatCurrency}
                        value={summary?.sumIncome ?? 0}
                        color="greenText"
                      />
                    }
                    isLoading={isLoading}
                  />
                  <StatisticCardGrow
                    label="Total Expenses"
                    value={
                      <ReportsCashFlowValue
                        formatter={formatCurrency}
                        value={(summary?.sumExpense ?? 0) * -1}
                        color="redText"
                      />
                    }
                    isLoading={isLoading}
                  />
                  <StatisticCardGrow
                    label="Total Net Income"
                    value={
                      <ReportsCashFlowValue
                        formatter={formatCurrency}
                        value={summary?.savings ?? 0}
                      />
                    }
                    isLoading={isLoading}
                  />
                  <StatisticCardGrow
                    label="Savings Rate"
                    value={
                      <ReportsCashFlowValue
                        formatter={formatPercent}
                        value={Math.max(0, summary?.savingsRate ?? 0)}
                      />
                    }
                    isLoading={isLoading}
                  />
                </SummaryCardsContainer>
                {renderChartCard({ title: 'Cash Flow' })}
              </Route>
              <Route path={routes.reports.spending.path}>
                {renderChartCard({ total: summary?.sumExpense })}
              </Route>
              <Route path={routes.reports.income.path}>
                {renderChartCard({ total: summary?.sumIncome })}
              </Route>
            </Switch>
          </GridItem>
          <GridItem area="transactions">
            <WithShadow>
              <TransactionsListContainer
                transactionFilters={queryVariables.filters ?? {}}
                quickViewFilters={quickViewFilters ?? {}}
                onRemoveQuickViewFilters={removeQuickViewFilters}
                emptyComponent={<ReportsChartCardEmpty />}
                sortBy={sortTransactionsBy}
                onChangeSortBy={onChangeSortBy}
                overrideTitle={
                  <Text size="large" weight="medium">
                    Transactions
                  </Text>
                }
              />
            </WithShadow>
          </GridItem>
          <GridItem area="summary" sticky>
            <TransactionsSummaryRefContainer ref={transactionsSummaryRef}>
              <TransactionsSummaryCard
                transactionsSummary={hasQuickViewFilters ? quickViewFilteredSummary : summary}
                empty={<ReportsChartCardEmpty />}
                filters={allFilters}
                onChangeFilters={RA.noop}
                onClickDownloadCsv={onClickDownloadCsv}
                isDownloadingCsv={isDownloadingCsv}
                isLoading={hasQuickViewFilters ? isLoadingQuickViewFilteredSummary : isLoading}
              />
            </TransactionsSummaryRefContainer>
          </GridItem>
          <ReportConfigurationModal onClose={() => setEditingReportConfigurationId(null)}>
            <SaveReportConfigurationModal
              editingReportConfigurationId={editingReportConfigurationId}
              reportConfigurations={reportConfigurations}
              filters={filters}
              onDeleteReportConfiguration={async (reportConfiguration: ReportConfiguration) =>
                deleteReportConfiguration(reportConfiguration.id)
              }
              onSubmit={({ displayName }) => {
                if (editingReportConfigurationId) {
                  updateReportConfiguration(editingReportConfigurationId, displayName.trim());
                } else {
                  const cleanedFilters = R.omit(
                    ['amountFilter', 'order'],
                    filters,
                  ) as TransactionFilterInput;

                  createReportConfiguration(displayName.trim(), cleanedFilters);
                }
              }}
            />
          </ReportConfigurationModal>
        </StyledGrid>
      </PageWithNoAccountsEmptyState>
    </TabsContext>
  );
};

export default Reports;
