import queryString from 'query-string';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Markdown from 'components/lib/ui/Markdown';
import OnboardingAccounts from 'components/routes/budget/OnboardingAccounts';
import OnboardingExpenses from 'components/routes/budget/OnboardingExpenses';
import OnboardingFlexExpenses from 'components/routes/budget/OnboardingFlexExpenses';
import OnboardingIncome from 'components/routes/budget/OnboardingIncome';
import OnboardingMethodChoice from 'components/routes/budget/OnboardingMethodChoice';
import OnboardingNonMonthly from 'components/routes/budget/OnboardingNonMonthly';
import OnboardingSelectVariabilities from 'components/routes/budget/OnboardingSelectVariabilities';
import OnboardingSuccess from 'components/routes/budget/OnboardingSuccess';

import useBudgetOnboardingState from 'common/lib/hooks/budget/useBudgetOnboardingState';
import { getCurrentStep } from 'lib/budget/onboardingAdapters';

import * as COPY from 'common/constants/copy';
import routes from 'constants/routes';

import { BudgetSystem, BudgetVariability } from 'common/generated/graphql';

export enum OnboardingFlowStep {
  Accounts = 'Accounts',
  Income = 'Income',
  MethodChoice = 'MethodChoice',
  Variabilities = 'Variabilities',
  Expenses = 'Expenses',
  FixedExpenses = 'FixedExpenses',
  NonMonthlyExpenses = 'NonMonthlyExpenses',
  FlexibleExpenses = 'FlexibleExpenses',
  Success = 'Success',
}

export type OnboardingSharedPageProps = {
  title: string;
  description: React.ReactNode;
  progress: number;
};

const BudgetOnboardingFlow = () => {
  const history = useHistory();
  const [selectedBudgetSystem, setSelectedBudgetSystem] = useState<BudgetSystem>();

  const { skipConnectingAccounts, isExistingUser } = useBudgetOnboardingState();

  const isFlexBudgeting = selectedBudgetSystem === BudgetSystem.FIXED_AND_FLEX;

  const stepParam = queryString.parse(window.location.search).step;
  const steps = Object.values(OnboardingFlowStep);
  const currentStep = getCurrentStep(stepParam, skipConnectingAccounts);
  const progress = (steps.indexOf(currentStep) + 1) / steps.length;

  const updateStep = (newStep: OnboardingFlowStep) => {
    const params = { step: newStep };

    const url = queryString.stringifyUrl({
      url: window.location.pathname,
      query: params,
    });

    history.push(url);
  };

  const onCancel = () => {
    history.push(routes.budget.path);
  };

  switch (currentStep) {
    case OnboardingFlowStep.Accounts: {
      return (
        <OnboardingAccounts
          title={COPY.BUDGET.ONBOARDING.ACCOUNTS.TITLE}
          description={COPY.BUDGET.ONBOARDING.ACCOUNTS.DESCRIPTION}
          onCancel={onCancel}
          onBack={onCancel}
          onNext={() => updateStep(OnboardingFlowStep.Income)}
          progress={progress}
        />
      );
    }
    case OnboardingFlowStep.Income: {
      return (
        <OnboardingIncome
          title={COPY.BUDGET.ONBOARDING.INCOME.TITLE}
          description={COPY.BUDGET.ONBOARDING.INCOME.DESCRIPTION(isExistingUser)}
          onCancel={onCancel}
          onBack={() => updateStep(OnboardingFlowStep.Accounts)}
          onNext={() => updateStep(OnboardingFlowStep.MethodChoice)}
          progress={progress}
        />
      );
    }
    case OnboardingFlowStep.MethodChoice: {
      return (
        <OnboardingMethodChoice
          title={COPY.BUDGET.ONBOARDING.METHOD.TITLE(isExistingUser)}
          description={COPY.BUDGET.ONBOARDING.METHOD.DESCRIPTION(isExistingUser)}
          onCancel={onCancel}
          onBack={() => updateStep(OnboardingFlowStep.Income)}
          onSelectFixFlexBudget={() => {
            setSelectedBudgetSystem(BudgetSystem.FIXED_AND_FLEX);
            updateStep(OnboardingFlowStep.Variabilities);
          }}
          onSelectCategoryBudget={() => {
            setSelectedBudgetSystem(BudgetSystem.GROUPS_AND_CATEGORIES);
            updateStep(OnboardingFlowStep.Expenses);
          }}
          progress={progress}
        />
      );
    }
    case OnboardingFlowStep.Expenses: {
      return (
        <OnboardingExpenses
          title={COPY.BUDGET.ONBOARDING.EXPENSES.TITLE}
          description={COPY.BUDGET.ONBOARDING.EXPENSES.DESCRIPTION(isExistingUser)}
          onCancel={onCancel}
          onBack={() => updateStep(OnboardingFlowStep.MethodChoice)}
          onNext={() => updateStep(OnboardingFlowStep.Success)}
          progress={progress}
        />
      );
    }
    case OnboardingFlowStep.Variabilities: {
      return (
        <OnboardingSelectVariabilities
          title={COPY.BUDGET.ONBOARDING.SELECT_VARIABILITIES.TITLE}
          description={
            <Markdown source={COPY.BUDGET.ONBOARDING.SELECT_VARIABILITIES.DESCRIPTION} />
          }
          onCancel={onCancel}
          onBack={() => updateStep(OnboardingFlowStep.MethodChoice)}
          onNext={() => updateStep(OnboardingFlowStep.FixedExpenses)}
          progress={progress}
        />
      );
    }
    case OnboardingFlowStep.FixedExpenses: {
      return (
        <OnboardingFlexExpenses
          title={COPY.BUDGET.ONBOARDING.FIXED_EXPENSES.TITLE}
          description={COPY.BUDGET.ONBOARDING.FIXED_EXPENSES.DESCRIPTION(isExistingUser)}
          onCancel={onCancel}
          onBack={() => updateStep(OnboardingFlowStep.Variabilities)}
          onNext={() => updateStep(OnboardingFlowStep.NonMonthlyExpenses)}
          progress={progress}
          budgetVariability={BudgetVariability.FIXED}
          pageName="Budget Onboarding Fixed Expenses"
        />
      );
    }
    case OnboardingFlowStep.NonMonthlyExpenses: {
      return (
        <OnboardingNonMonthly
          title={COPY.BUDGET.ONBOARDING.NON_MONTHLY_EXPENSES.TITLE}
          description={COPY.BUDGET.ONBOARDING.NON_MONTHLY_EXPENSES.DESCRIPTION}
          onCancel={onCancel}
          onBack={() => updateStep(OnboardingFlowStep.FixedExpenses)}
          onNext={() => updateStep(OnboardingFlowStep.FlexibleExpenses)}
          progress={progress}
          pageName="Budget Onboarding Non-Monthly Expenses"
        />
      );
    }
    case OnboardingFlowStep.FlexibleExpenses: {
      return (
        <OnboardingFlexExpenses
          title={COPY.BUDGET.ONBOARDING.FLEXIBLE_EXPENSES.TITLE}
          description={COPY.BUDGET.ONBOARDING.FLEXIBLE_EXPENSES.DESCRIPTION(isExistingUser)}
          onCancel={onCancel}
          onBack={() => updateStep(OnboardingFlowStep.NonMonthlyExpenses)}
          onNext={() => updateStep(OnboardingFlowStep.Success)}
          progress={progress}
          budgetVariability={BudgetVariability.FLEXIBLE}
          pageName="Budget Onboarding Flexible Expenses"
        />
      );
    }
    case OnboardingFlowStep.Success: {
      return (
        <OnboardingSuccess
          title={COPY.BUDGET.ONBOARDING.SUCCESS.TITLE}
          description={COPY.BUDGET.ONBOARDING.SUCCESS.DESCRIPTION}
          onBack={() =>
            updateStep(
              isFlexBudgeting ? OnboardingFlowStep.FlexibleExpenses : OnboardingFlowStep.Expenses,
            )
          }
          onCancel={onCancel}
          onNext={onCancel}
          progress={progress}
        />
      );
    }
  }
};

export default BudgetOnboardingFlow;
