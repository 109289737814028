import { createSelector } from 'reselect';

import type { RootState } from 'state/types';

const getRecurringState = ({ recurring }: RootState) => recurring;

export const getFilters = createSelector(getRecurringState, ({ filters }) => filters);

export const getCollapsedGroupsMap = createSelector(
  getRecurringState,
  ({ collapsedGroupsMap }) => collapsedGroupsMap,
);
