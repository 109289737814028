import * as React from 'react';
import { Line, LineChart, ReferenceLine, YAxis } from 'recharts';

import useTheme from 'lib/hooks/useTheme';

type Props<DataT> = {
  data: DataT;
  dataKey: string;
  widthPx: number;
  heightPx: number;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  referenceLineY: number;
  lineColor: string;
  className?: string;
};

const SparkLineChart = <DataT extends Record<string, unknown>[], _>({
  data,
  dataKey,
  widthPx,
  heightPx,
  onMouseEnter,
  onMouseLeave,
  lineColor,
  referenceLineY,
  className,
}: Props<DataT>) => {
  const theme = useTheme();
  return (
    <LineChart
      width={widthPx}
      height={heightPx}
      data={data}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      // @ts-ignore
      cursor="pointer"
      className={className}
    >
      <YAxis axisLine={false} domain={['dataMin', 'dataMax']} hide />
      <ReferenceLine
        y={referenceLineY}
        stroke={theme.color.gray6}
        strokeDasharray="4 4"
        strokeWidth={1}
      />
      <Line dataKey={dataKey} strokeWidth={2} dot={false} stroke={lineColor} />
    </LineChart>
  );
};

export default SparkLineChart;
