import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { cardDropShadowStyleMixin } from 'components/lib/ui/Card';
import FlexContainer from 'components/lib/ui/FlexContainer';
import MonarchLogo from 'components/lib/ui/MonarchLogo';
import ProgressBar from 'components/lib/ui/ProgressBar';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import Header from 'components/routes/Header';

import isV2Theme from 'common/lib/theme/isV2Theme';
import variables from 'common/lib/theme/variables';

const StyledProgressBar = styled(ProgressBar)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 4px;
`;

const Container = styled(FlexContainer)`
  flex: 1;
`;

const StyledHeader = styled(Header)`
  background-color: ${variables.color.background.fullscreenHeader};
  ${isV2Theme(cardDropShadowStyleMixin)}
`;

type Props = {
  progress: number;
  onClickBack: () => void;
  onClickCancel?: () => void;
  cancelButtonText?: string;
};

const SetupHeader = ({ progress, onClickBack, onClickCancel, cancelButtonText }: Props) => {
  const { goBack } = useHistory();

  return (
    <StyledHeader showSidebarToggle={false}>
      <Container alignCenter justifyBetween>
        <Container alignCenter>
          <DefaultButton onClick={onClickBack ?? goBack}>
            <ButtonIcon name="arrow-left" />
            <span>Back</span>
          </DefaultButton>
        </Container>
        <MonarchLogo dark />
        <Container alignCenter justifyEnd>
          <DefaultButton onClick={onClickCancel}>{cancelButtonText ?? 'Cancel'}</DefaultButton>
        </Container>
      </Container>
      <StyledProgressBar value={progress} color="orange" backgroundColor="white" animated />
    </StyledHeader>
  );
};

export default SetupHeader;
