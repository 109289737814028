import { gql } from '@apollo/client';
import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import AdviceAvatar from 'components/advice/AdviceAvatar';
import QuestionnaireHeader from 'components/advice/QuestionnaireHeader';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Page from 'components/lib/ui/Page';
import Text from 'components/lib/ui/Text';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import useQuery from 'common/lib/hooks/useQuery';
import variables from 'common/lib/theme/variables';
import { getFirstName } from 'common/lib/user';
import { getQuestionnaireRoute } from 'lib/advice/questionnaire';

import type routes from 'constants/routes';

import type {
  QuestionnaireIntroQuery_Web,
  QuestionnaireIntroQuery_WebVariables,
} from 'common/generated/graphQlTypes/QuestionnaireIntroQuery_Web';
import type RouteProps from 'types/RouteProps';

const StyledPage = styled(Page)`
  height: 100vh;
  background-color: ${variables.color.background.fullscreenHeader};
`;

const Container = styled(FlexContainer).attrs({ column: true, center: true })`
  flex: 1;
  min-height: 100%;
  padding: ${({ theme }) => theme.spacing.xxlarge};
`;

const Column = styled(FlexContainer).attrs({ column: true, justifyCenter: true })`
  max-width: 470px;
`;

const Title = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.xxlarge};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin: 40px 0 ${({ theme }) => theme.spacing.xlarge} 0;
`;

const Description = styled(Text)`
  margin-bottom: 85px;
`;

const QuestionnaireIntro = ({
  match: {
    params: { questionnaireName },
  },
}: RouteProps<typeof routes.advice.questionnaireIntro>) => {
  const { push } = useHistory();

  const { data, isLoadingInitialData } = useQuery<
    QuestionnaireIntroQuery_Web,
    QuestionnaireIntroQuery_WebVariables
  >(QUERY, {
    variables: { questionnaireName },
  });
  const { profileQuestionnaire, me } = data ?? {};
  const { nextQuestion, state } = profileQuestionnaire ?? {};
  const { name } = me ?? {};
  const { numQuestionsAnswered } = state ?? {};

  const firstName = getFirstName(name ?? '');

  return (
    <StyledPage
      name="Advice Questionnaire Intro"
      overrideTitle="Profile"
      header={<QuestionnaireHeader progress={0} />}
      isLoading={isLoadingInitialData}
    >
      <Container>
        <Column>
          <AdviceAvatar />
          <Title>Hello{firstName ? ` ${firstName},` : ','}</Title>
          <Description>
            My name is Natalie and I&apos;m a Certified Financial Planner (CFP ®) and a Behavioral
            Financial Advisor (BFA {`™️`}).
            <br />
            <br />
            I&apos;m here to guide you through some questions so Monarch can help prioritize and
            give you financial advice.
            <br />
            <br />
            We hold Monarch to a fiduciary standard which means we are committed to looking out for
            your best interests. Monarch believes in financial guidance that is free from any
            conflicts of interest so advice will never be used to sell you something you don&apos;t
            need. Advice is here to help you make progress on what&apos;s most important to you.
          </Description>
          {nextQuestion && (
            <FlexContainer>
              <PrimaryButton
                size="large"
                onClick={() => push(getQuestionnaireRoute(questionnaireName, nextQuestion.name))}
              >
                {(numQuestionsAnswered ?? 0) > 0 ? 'Resume' : 'Get started'}
              </PrimaryButton>
            </FlexContainer>
          )}
        </Column>
      </Container>
    </StyledPage>
  );
};

const QUERY = gql`
  query QuestionnaireIntroQuery_Web($questionnaireName: String!) {
    me {
      id
      name
    }
    profileQuestionnaire(name: $questionnaireName) {
      nextQuestion {
        name
      }
      state {
        numQuestionsAnswered
      }
    }
  }
`;

export default QuestionnaireIntro;
