import { DateTime } from 'luxon';
import React, { useMemo, useState, useCallback } from 'react';

import AccountNetWorthChartTooltip from 'components/accounts/AccountNetWorthChartTooltip';
import { NET_WORTH_CHART_TIMEFRAME_OPTIONS } from 'components/accounts/NetWorthChartControls';
import NetWorthChartLabelContentLoader from 'components/accounts/NetWorthChartLabelContentLoader';
import StackedBarChart from 'components/lib/charts/StackedBarChart';

import {
  compileMonthlyNetWorthData,
  getComparativeDataForTimeframe,
} from 'common/lib/accounts/netWorthCharts';
import { getChartDateFromTimeframe } from 'common/lib/charts';
import THEME from 'common/lib/theme/staticTheme';
import { formatCurrencyThousandsDecimal } from 'common/utils/Currency';
import useTheme from 'lib/hooks/useTheme';
import { getDateShorthand } from 'utils/dateRange';

import { ACCOUNT_TYPE_TO_COLOR_MAP } from 'common/constants/accounts';
import type { TimeframeBase } from 'common/constants/timeframes';

import type { GetMonthlySnapshots_monthlySnapshotsByAccountType } from 'common/generated/graphQlTypes/GetMonthlySnapshots';
import type { ThemeType } from 'common/types/Styles';

const ROOT_PADDING = parseInt(THEME.spacing.default, 10);
const CHART_BAR_WIDTH_PX = 40;
const CHART_HEIGHT_PX = 275;
const getChartDataKeys = (theme: ThemeType) =>
  Object.entries(ACCOUNT_TYPE_TO_COLOR_MAP(theme)).map(([type, color]) => ({
    name: type,
    color: theme.color[color],
  }));
const QUARTERLY_TIMEFRAME_X_AXIS_HEIGHT = 38;
const DEFAULT_X_AXIS_HEIGHT = 26;

type Props = {
  snapShotsDataByAccountType: GetMonthlySnapshots_monthlySnapshotsByAccountType[];
  className?: string;
  isLoadingSnapshotsByAccountType?: boolean;
  isSnapshotsNetworkRequestInFlight?: boolean;
  activeTimeframeIndex?: number;
  width: number;
};

const NetWorthBreakdownChart = ({
  snapShotsDataByAccountType,
  isSnapshotsNetworkRequestInFlight,
  activeTimeframeIndex = 0,
  width,
}: Props) => {
  const theme = useTheme();

  const activeTimeframe = useMemo(
    () => NET_WORTH_CHART_TIMEFRAME_OPTIONS[activeTimeframeIndex].value,
    [activeTimeframeIndex],
  );

  const chartDataByAccountType = useMemo(
    () => compileMonthlyNetWorthData(snapShotsDataByAccountType, activeTimeframe, true),
    [snapShotsDataByAccountType],
  );

  const [loadedTimeframes, setLoadedTimeframes] = useState<Set<TimeframeBase>>(
    new Set([activeTimeframe]),
  );
  // Show a loading label if the snapshots are loading but the chart data for the active timeframe wasn't set yet
  const isLoadingLabelForTimeframe =
    isSnapshotsNetworkRequestInFlight && !loadedTimeframes.has(activeTimeframe);

  const formatTooltipDate = useCallback(
    (date: string) => {
      const baseDate = DateTime.fromISO(date);
      const startDate = baseDate.startOf(activeTimeframe).toISODate();
      const endDate = baseDate.endOf(activeTimeframe).toISODate();
      return getDateShorthand({ startDate, endDate }) ?? date;
    },
    [activeTimeframe],
  );

  const chartDataKeys = useMemo(() => getChartDataKeys(theme), [theme]);

  return (
    <StackedBarChart
      data={chartDataByAccountType}
      barDataKeys={chartDataKeys}
      onAnimationEnd={() => setLoadedTimeframes((prev) => prev.add(activeTimeframe))}
      xAxisDataKey="month"
      totalDataKey="net"
      dashedLineDataKey="currentMonthNet"
      heightPx={CHART_HEIGHT_PX}
      widthPx={width - ROOT_PADDING}
      barWidthPx={CHART_BAR_WIDTH_PX}
      formatXAxis={getChartDateFromTimeframe(activeTimeframe, {
        showFirstQuarterWithYear: true,
      })}
      xAxisProps={
        isLoadingLabelForTimeframe
          ? {
              // @ts-ignore - Recharts clones the element and adds the props to the clone
              tick: <NetWorthChartLabelContentLoader />,
            }
          : {
              height:
                activeTimeframe === 'quarter'
                  ? QUARTERLY_TIMEFRAME_X_AXIS_HEIGHT
                  : DEFAULT_X_AXIS_HEIGHT,
            }
      }
      formatYAxis={formatCurrencyThousandsDecimal}
      activeBarIndex={chartDataByAccountType.length - 1}
      tooltipComponent={({ active, payload }) => {
        const hoveredBarMonthYear = payload[0]?.payload?.month; // yyyy-MM
        const hoveredBarMonthDate = DateTime.fromISO(hoveredBarMonthYear);
        const title = formatTooltipDate(hoveredBarMonthYear);
        const tooltipData = active
          ? getComparativeDataForTimeframe(
              snapShotsDataByAccountType,
              hoveredBarMonthDate,
              activeTimeframe,
            )
          : null;
        return tooltipData ? (
          <AccountNetWorthChartTooltip
            title={title}
            currentMonthData={tooltipData.current}
            previousMonthData={tooltipData.previous}
          />
        ) : null;
      }}
    />
  );
};

export default NetWorthBreakdownChart;
