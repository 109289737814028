import { useMutation, gql } from '@apollo/client';
import React, { useCallback, useState, useMemo } from 'react';
import styled from 'styled-components';

import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import TextField from 'components/lib/form/TextField';
import CardBody from 'components/lib/ui/CardBody';
import CardFooter from 'components/lib/ui/CardFooter';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import ModalCard from 'components/lib/ui/ModalCard';
import DangerButton from 'components/lib/ui/button/DangerButton';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import MerchantLogoSettings from 'components/settings/MerchantLogoSettings';
import DeleteMerchantConfirmation from 'components/settings/merchants/DeleteMerchantConfirmation';
import DeleteMerchantMoveModal from 'components/settings/merchants/DeleteMerchantMoveModal';
import MerchantRecurringFields from 'components/settings/merchants/MerchantRecurringFields';

import { PAYLOAD_ERRORS_FRAGMENT } from 'common/lib/graphQl/errors';
import useQuery from 'common/lib/hooks/useQuery';
import { getEditMerchantInitialValues, transformFormValuesToInput } from 'common/lib/merchant/form';
import { useModalContext } from 'lib/contexts/ModalContext';
import useModal from 'lib/hooks/useModal';
import toast, { errorToast } from 'lib/ui/toast';

import type {
  UpdateMerchant,
  UpdateMerchantVariables,
} from 'common/generated/graphQlTypes/UpdateMerchant';
import type {
  Web_DeleteMerchant,
  Web_DeleteMerchantVariables,
} from 'common/generated/graphQlTypes/Web_DeleteMerchant';
import type {
  Web_GetEditMerchant,
  Web_GetEditMerchantVariables,
} from 'common/generated/graphQlTypes/Web_GetEditMerchant';
import type { UpdateMerchantInput } from 'common/generated/graphQlTypes/globalTypes';

const SpinnerContainer = styled(FlexContainer).attrs({ center: true })`
  margin: ${({ theme }) => theme.spacing.xxxlarge} 0;
`;

const StyledTextField = styled(TextField)`
  label {
    font-size: ${({ theme }) => theme.fontSize.small};
  }
`;

const SubmitButton = styled(FormSubmitButton)`
  font-size: ${({ theme }) => theme.fontSize.small};
`;

export type InitialValues = Partial<UpdateMerchantInput['recurrence']>;

export type Props = {
  merchantId: string;
  title?: string;
  initialValues?: InitialValues;
  goBack?: () => void;
  afterDelete?: () => void;
  afterUpdate?: () => void;
  next?: () => void;
};

const EditMerchantModal = ({
  merchantId,
  title = 'Edit merchant',
  initialValues: overrideInitialValues,
  goBack,
  afterDelete,
  afterUpdate,
  next,
}: Props) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const { close } = useModalContext();

  const { data, isLoadingInitialData } = useQuery<
    Web_GetEditMerchant,
    Web_GetEditMerchantVariables
  >(GET_MERCHANT_QUERY, {
    variables: { merchantId },
  });
  const {
    id,
    name,
    transactionCount,
    ruleCount,
    canBeDeleted,
    logoUrl,
    recurringTransactionStream,
  } = data?.merchant ?? {};

  const [
    DeleteConfirmationModal,
    { open: openDeleteConfirmationModal, close: closeDeleteConfirmationModal },
  ] = useModal();
  const [
    DeleteMoveRelationsModal,
    { open: openMoveRelationsModal, close: closeMoveRelationsModal },
  ] = useModal();
  const [deleteMerchantMutation] = useMutation<Web_DeleteMerchant, Web_DeleteMerchantVariables>(
    DELETE_MERCHANT_MUTATION,
  );

  const deleteMerchant = useCallback(
    async (moveToMerchantId?: string) => {
      setIsDeleting(true);
      try {
        await deleteMerchantMutation({ variables: { merchantId, moveToId: moveToMerchantId } });
        toast({ title: 'Merchant deleted successfully' });
        afterDelete?.();
      } catch (e) {
        errorToast();
        setIsDeleting(false);
      } finally {
        close();
      }
    },
    [afterDelete, close, deleteMerchantMutation, merchantId],
  );

  const [updateMerchant] = useMutation<UpdateMerchant, UpdateMerchantVariables>(UPDATE_MERCHANT);

  const formInitialValues = useMemo(
    () => getEditMerchantInitialValues(merchantId, name, recurringTransactionStream),
    [data?.merchant], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <>
      <ModalCard title={title} onClickBackButton={goBack}>
        {isLoadingInitialData ? (
          <SpinnerContainer>
            <LoadingSpinner />
          </SpinnerContainer>
        ) : (
          <Form
            // @ts-ignore mutation is not picking up types from transformValuesBeforeSubmit
            initialValues={{
              ...formInitialValues,
              ...overrideInitialValues,
            }}
            onSubmitSuccess={() => {
              close();
              afterUpdate?.();
              next?.();
            }}
            // @ts-ignore mutation is not picking up types from transformValuesBeforeSubmit
            mutation={updateMerchant}
            // @ts-ignore mutation doesn't have recurring field
            transformValuesBeforeSubmit={transformFormValuesToInput}
          >
            <CardBody>
              {!!id && <MerchantLogoSettings merchantId={id} merchantLogo={logoUrl} />}
              <StyledTextField name="name" label="Merchant name" required />
              <MerchantRecurringFields />
            </CardBody>
            <CardFooter>
              <DangerButton
                onClick={canBeDeleted ? openDeleteConfirmationModal : openMoveRelationsModal}
              >
                Delete
              </DangerButton>
              <DefaultButton onClick={close}>Cancel</DefaultButton>
              <SubmitButton disableWhenValuesUnchanged={false}>Save</SubmitButton>
            </CardFooter>
          </Form>
        )}
      </ModalCard>
      <DeleteConfirmationModal>
        <DeleteMerchantConfirmation
          name={name ?? ''}
          onCancel={closeDeleteConfirmationModal}
          onConfirm={() => deleteMerchant()}
          isLoading={isDeleting}
        />
      </DeleteConfirmationModal>
      <DeleteMoveRelationsModal>
        <DeleteMerchantMoveModal
          merchantId={id ?? ''}
          transactionCount={transactionCount ?? 0}
          ruleCount={ruleCount ?? 0}
          isLoading={isDeleting}
          onConfirm={(moveToId: string) => {
            deleteMerchant(moveToId);
            closeMoveRelationsModal();
          }}
          onCancel={closeMoveRelationsModal}
        />
      </DeleteMoveRelationsModal>
    </>
  );
};

const GET_MERCHANT_QUERY = gql`
  query Web_GetEditMerchant($merchantId: ID!) {
    merchant(id: $merchantId) {
      id
      name
      logoUrl
      transactionCount
      ruleCount
      canBeDeleted
      recurringTransactionStream {
        id
        frequency
        amount
        baseDate
        isActive
      }
    }
  }
`;

const DELETE_MERCHANT_MUTATION = gql`
  mutation Web_DeleteMerchant($merchantId: ID!, $moveToId: ID) {
    deleteMerchant(id: $merchantId, moveRelationsToMerchantId: $moveToId) {
      success
    }
  }
`;

const UPDATE_MERCHANT = gql`
  mutation Web_UpdateMerchant($input: UpdateMerchantInput!) {
    updateMerchant(input: $input) {
      merchant {
        id
        name
        logoUrl
        recurringTransactionStream {
          id
          frequency
          baseDate
          amount
          isActive
        }
      }

      errors {
        ...PayloadErrorFields
      }
    }
  }
  ${PAYLOAD_ERRORS_FRAGMENT}
`;

export default EditMerchantModal;
