import React from 'react';

import { useFormContext } from 'common/components/form/FormContext';
import OnboardingGroupFooter from 'components/plan/onboarding/OnboardingGroupFooter';
import type { FormValues } from 'components/routes/budget/OnboardingNonMonthly';

type Props = {
  title: string;
  isLoading?: boolean;
};

const OnboardingNonMonthlyGroupFooter = ({ title, isLoading }: Props) => {
  const { values } = useFormContext<FormValues>();
  const total = Object.values(values).reduce(
    (acc, { monthlyBudget }) => acc + (monthlyBudget || 0),
    0,
  );

  return <OnboardingGroupFooter title={title} value={total} isLoading={isLoading} />;
};

export default OnboardingNonMonthlyGroupFooter;
