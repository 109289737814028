import React, { useMemo } from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import type { Tick } from 'components/lib/ui/ProgressBar';
import ProgressBar from 'components/lib/ui/ProgressBar';
import Skeleton, { WithLoadingPlaceholder } from 'components/lib/ui/Skeleton';
import Text from 'components/lib/ui/Text';
import Currency from 'components/lib/ui/currency/Currency';

import { getProgressBarColorForBudget } from 'common/lib/budget/Adapters';
import type { ExtendedCategoryGroupType } from 'common/lib/budget/Amounts';
import {
  getAvailableDisplayAmount,
  getBudgetedAmountWithRollover,
  getGroupTypeActualAmountLabel,
  getRemainingTextColor,
} from 'common/lib/budget/Amounts';
import { color, spacing } from 'common/lib/theme/dynamic';
import { getSummaryWidgetProgressBarMax } from 'lib/budget/componentUtils';

import { BudgetVariability } from 'common/generated/graphql';
import type { KeyOfThemeProp } from 'common/types/Styles';

const Root = styled(FlexContainer).attrs({
  column: true,
})`
  margin: 0 calc(var(--inner-padding) * -1); /* this variable is set in the parent component */
  padding: ${spacing.default} ${spacing.large};
  gap: ${spacing.xsmall};

  &:last-child {
    padding-bottom: 0;
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${color.grayBackground};
  }
`;

const RemainingRowText = styled(Text).attrs({
  size: 'small',
  color: 'textLight',
  weight: 'medium',
})`
  display: inline-flex;
  align-items: center;
  gap: ${spacing.xxsmall};
`;

const RemainingValue = styled(Text)`
  display: inline-flex;
  gap: ${spacing.xxsmall};
  align-items: center;
`;

const RolloverIcon = styled(Icon).attrs({
  name: 'rotate-cw',
  size: 14,
})`
  display: inline-block;
  margin-top: 1px;
`;

type Props = {
  id?: string | BudgetVariability;
  title: string;
  actual: number;
  budgeted: number;
  remaining: Maybe<number>;
  rollover: number;
  isLoading: boolean;
  type: string;
  ticks?: Tick[];
  titleSize?: Extract<KeyOfThemeProp<'fontSize'>, 'small' | 'base'>;
};

const PlanSummaryWidgetRow = ({
  id,
  title,
  actual,
  budgeted,
  remaining,
  rollover,
  isLoading,
  type,
  ticks,
  titleSize = 'small',
}: Props) => {
  const budgetedWithRollover = getBudgetedAmountWithRollover(budgeted, rollover);
  const isFlexibleExpense = useMemo(() => type === BudgetVariability.FLEXIBLE, [type]);
  const typeOrBudgetVariability = type as ExtendedCategoryGroupType | BudgetVariability;

  const remainingDisplayAmount = getAvailableDisplayAmount(
    remaining,
    type as ExtendedCategoryGroupType,
  );

  const [firstTick] = ticks || []; // We only care about the first tick

  // This condition identifies flexible expenses with a defined threshold.
  // For such groups, we calculate the progress bar differently:
  // - The primary bar is capped at the first tick's percentage.
  // - Any spending beyond this threshold is represented by an extra bar.
  // This allows for visual distinction between normal and excess spending in the summary widget.
  const isThresholdedFlexibleExpense = isFlexibleExpense && !!firstTick;

  const primaryBarValue = isThresholdedFlexibleExpense
    ? Math.min(actual, firstTick.percent * budgetedWithRollover)
    : actual;
  const extraBarValue = isThresholdedFlexibleExpense ? Math.max(0, actual - primaryBarValue) : 0;

  return (
    <Root>
      <WithLoadingPlaceholder isLoading={isLoading} placeholderComponent={<LoadingSkeleton />}>
        <FlexContainer justifyBetween>
          <Text size={titleSize}>{title}</Text>
          <Text size="small" color="textLight">
            <Currency value={budgeted ?? undefined} round /> budget
          </Text>
        </FlexContainer>
        <ProgressBar
          value={primaryBarValue}
          max={getSummaryWidgetProgressBarMax(actual, budgetedWithRollover)}
          color={getProgressBarColorForBudget(
            remainingDisplayAmount,
            typeOrBudgetVariability,
            false,
          )}
          ticks={isThresholdedFlexibleExpense ? ticks : undefined}
          outlineTick={isThresholdedFlexibleExpense}
          extraBar={
            extraBarValue > 0
              ? {
                  color: getProgressBarColorForBudget(
                    remainingDisplayAmount,
                    typeOrBudgetVariability,
                    true,
                  ),
                  value: extraBarValue,
                }
              : undefined
          }
          roundInnerBar={false}
          roundAppearance
          animated
        />
        <FlexContainer justifyBetween>
          <Text weight="medium" size="small">
            <Currency value={actual ?? undefined} round /> {getGroupTypeActualAmountLabel(type)}
          </Text>
          <RemainingRow
            remaining={remainingDisplayAmount}
            rollover={rollover}
            type={typeOrBudgetVariability}
            exceedsMonthlyProgress={extraBarValue > 0}
          />
        </FlexContainer>
      </WithLoadingPlaceholder>
    </Root>
  );
};

const LoadingSkeleton = () => (
  <FlexContainer column>
    <FlexContainer marginVertical="xsmall" justifyBetween>
      <Skeleton $width="24%" $height="1.2em" />
      <Skeleton $width="15%" $height="1.2em" />
    </FlexContainer>
    <Skeleton $width="100%" $height="0.5em" />
    <FlexContainer marginVertical="xsmall" justifyBetween>
      <Skeleton $width="15%" $height="1.2em" />
      <Skeleton $width="15%" $height="1.2em" />
    </FlexContainer>
  </FlexContainer>
);

const RemainingRow = ({
  remaining,
  rollover,
  type,
  exceedsMonthlyProgress,
}: Pick<Props, 'remaining' | 'rollover' | 'type'> & { exceedsMonthlyProgress: boolean }) => {
  const isOverBudget = (remaining || 0) < 0;

  return (
    <RemainingRowText>
      <RemainingValue
        color={getRemainingTextColor(type, remaining, exceedsMonthlyProgress || isOverBudget)}
      >
        {rollover !== 0 && <RolloverIcon />}
        <Currency value={remaining ?? undefined} round />
      </RemainingValue>
      <span>remaining</span>
    </RemainingRowText>
  );
};

export default PlanSummaryWidgetRow;
