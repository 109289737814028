import { Interval } from 'luxon';
import * as R from 'ramda';
import { isNotNil, isPositive } from 'ramda-adjunct';

import type { PlanAmounts, PlanningAmountsByIdDate } from 'common/lib/planning';
import { getGoalsV2Amounts } from 'common/lib/planning';
import type { GridAmountsForGroup } from 'lib/plan/Adapters';

import type {
  Common_GetJointPlanningDataQuery,
  Common_GetJointPlanningDataQueryVariables,
} from 'common/generated/graphql';
import { BudgetVariability, CategoryGroupType } from 'common/generated/graphql';

const FETCH_EXTRA_COLUMNS = 1;

const indexMonthlyAmountsByIdAndDate = (
  monthlyAmountsByCategory: any[],
  groupBy: 'category' | 'categoryGroup' = 'category',
  categoryGroups: Common_GetJointPlanningDataQuery['categoryGroups'] = [],
): PlanningAmountsByIdDate => {
  const allCategories = R.flatten(R.map(R.prop('categories'), categoryGroups));
  const groupedById = R.groupBy((amounts) => amounts[groupBy]?.id, monthlyAmountsByCategory);

  return R.mapObjIndexed((groupedAmounts, key) => {
    const flatMapped = R.flatten(R.map(({ monthlyAmounts }) => monthlyAmounts, groupedAmounts));
    const indexedByMonth = R.indexBy(({ month }) => month, flatMapped);
    const groupedByObject =
      groupBy === 'category'
        ? R.find(R.propEq('id', key), allCategories)
        : R.find(R.propEq('id', key), categoryGroups);
    const startingBalance = groupedByObject?.rolloverPeriod?.startingBalance;

    return R.mapObjIndexed(
      ({
        plannedCashFlowAmount,
        actualAmount,
        remainingAmount,
        previousMonthRolloverAmount,
        rolloverType,
        rolloverTargetAmount,
        cumulativeActualAmount,
      }) => ({
        budgeted: plannedCashFlowAmount,
        actual: actualAmount,
        remaining: remainingAmount,
        rollover: previousMonthRolloverAmount,
        rolloverType: rolloverType ?? undefined,
        rolloverStartingBalance: startingBalance,
        rolloverTargetAmount,
        cumulativeActualAmount,
      }),
      indexedByMonth,
    );
  }, groupedById);
};

// Return a map from [itemId][category] to cell data, merging the pending data with the original
// data (and taking into account things like "apply to future").
// We return one map with goal and budget data for simplicity, since their ids shouldn't collide.
export const getMergedPlanningAmountsFromQueryData = (
  fetchedDateRange?: Interval,
  data?: Common_GetJointPlanningDataQuery,
): PlanningAmountsByIdDate => {
  if (data && fetchedDateRange) {
    const { budgetData, goalsV2, categoryGroups } = data;
    const originalBudgetAmounts = indexMonthlyAmountsByIdAndDate(
      budgetData.monthlyAmountsByCategory,
      'category',
      categoryGroups,
    );
    const categoryGroupBudgetAmounts = indexMonthlyAmountsByIdAndDate(
      budgetData.monthlyAmountsByCategoryGroup,
      'categoryGroup',
      categoryGroups,
    );
    const flexBudgetAmounts: PlanningAmountsByIdDate = {
      [BudgetVariability.FLEXIBLE]: R.mapObjIndexed(
        ({
          plannedCashFlowAmount,
          actualAmount,
          remainingAmount,
          previousMonthRolloverAmount,
          rolloverType,
          cumulativeActualAmount,
          rolloverTargetAmount,
        }) => ({
          budgeted: plannedCashFlowAmount,
          actual: actualAmount,
          remaining: remainingAmount,
          rollover: previousMonthRolloverAmount,
          rolloverType: rolloverType ?? undefined,
          rolloverTargetAmount,
          cumulativeActualAmount,
        }),
        // @ts-expect-error - TODO: fix this
        R.indexBy(({ month }) => month, budgetData.monthlyAmountsForFlexExpense.monthlyAmounts),
      ),
    };
    const originalGoalAmounts = getGoalsV2Amounts(goalsV2 ?? []);

    return R.mergeAll([
      originalBudgetAmounts,
      originalGoalAmounts,
      categoryGroupBudgetAmounts,
      flexBudgetAmounts,
    ]);
  }

  return {};
};

export const getDateRangeToFetch = (
  visibleDateRange: Interval,
  isTimeframeMonthly: boolean,
): Interval => {
  const columnInterval = isTimeframeMonthly ? 'months' : 'years';
  return Interval.fromDateTimes(
    visibleDateRange.start.minus({
      [columnInterval]: FETCH_EXTRA_COLUMNS,
    }),
    visibleDateRange.end.plus({
      [columnInterval]: FETCH_EXTRA_COLUMNS,
    }),
  );
};

// Exclude previously a fetched range from a needed date range
const excludePreviouslyFetchedFromDateRange = (
  neededDateRange: Interval,
  previouslyFetchedDateRange: Interval,
): Interval | null => {
  if (previouslyFetchedDateRange.engulfs(neededDateRange)) {
    // we've already fetched everything we need, just return
    return null;
  } else if (neededDateRange.engulfs(previouslyFetchedDateRange)) {
    // There are dates to fetch on both sides of previously fetched
    // Just naively fetch the entire range
    return neededDateRange;
  } else if (previouslyFetchedDateRange.contains(neededDateRange.start)) {
    // just fetch the new dates on the right
    return neededDateRange.difference(previouslyFetchedDateRange)[0];
  } else if (previouslyFetchedDateRange.contains(neededDateRange.end)) {
    // just fetch the new dates on the left
    return neededDateRange.difference(previouslyFetchedDateRange)[0];
  }
  return neededDateRange;
};

export const getDataQueryParameters = (
  neededDateRange: Interval,
  previouslyFetchedDateRange?: Interval,
): Common_GetJointPlanningDataQueryVariables | undefined => {
  const dateRangeToFetch = previouslyFetchedDateRange
    ? excludePreviouslyFetchedFromDateRange(neededDateRange, previouslyFetchedDateRange)
    : neededDateRange;

  if (!dateRangeToFetch) {
    // Don't need to fetch any new dates
    return undefined;
  }

  const fetchStartDate = dateRangeToFetch.start;
  const fetchEndDate = dateRangeToFetch.end;

  // We want to always fetch actuals and planned for the whole fetch range
  // so we can render the single month view
  return {
    startDate: fetchStartDate.startOf('month').toISODate(),
    endDate: fetchEndDate.plus({ months: 1 }).startOf('month').toISODate(),
  };
};

export const getUnallocatedFlexibleBudgetAmount = (
  amounts: GridAmountsForGroup,
  firstColumnDateIsoDate: string,
  isGroupFlexibleAndSingleMonth: boolean,
  sectionType: CategoryGroupType | 'savings',
) => {
  const rowsAggregateForFirstColumn = amounts.rowsAggregate[firstColumnDateIsoDate] ?? {};
  const aggregateForFirstColumn = amounts.aggregate[firstColumnDateIsoDate] ?? {};

  // We don't want to show the Available Flexible Budget column if there are no values defined
  const hasAtLeastOneValueDefined = getAtLeastOneValueDefined(rowsAggregateForFirstColumn);

  // We don't want to show the Available Flexible Budget column if there are no budgeted categories
  const hasBudgetedCategories = getHasBudgetedCategories(amounts, firstColumnDateIsoDate);

  if (
    !hasAtLeastOneValueDefined ||
    !hasBudgetedCategories ||
    !isGroupFlexibleAndSingleMonth ||
    sectionType !== CategoryGroupType.EXPENSE
  ) {
    return null;
  }

  const totalBudgetedAmountForRows = rowsAggregateForFirstColumn.budgeted ?? 0;
  const totalFlexibleBudgetAmount = aggregateForFirstColumn.budgeted ?? 0;
  return totalFlexibleBudgetAmount - totalBudgetedAmountForRows;
};

const getAtLeastOneValueDefined = (rowsAggregate: PlanAmounts) =>
  Object.values(rowsAggregate).some((value) => isNotNil(value) && isPositive(value));

const getHasBudgetedCategories = (amounts: GridAmountsForGroup, date: string) => {
  const rowsAmountsById = R.omit(['rowsAggregate', 'aggregate', 'aggregateUnplanned'], amounts);
  const rowsAmounts = Object.values(rowsAmountsById).map((amountsByDate) => amountsByDate[date]);
  return rowsAmounts.some((amounts) => !!amounts?.budgeted);
};
