export const TIMEFRAME_YEAR = 'year';
export const TIMEFRAME_MONTH = 'month';
export const TIMEFRAME_QUARTER = 'quarter';
export const TIMEFRAME_DAY = 'day';
export const TIMEFRAME_WEEK = 'week';

export const DEFAULT_TIMEFRAME = TIMEFRAME_MONTH;

export type TimeframeBase =
  | typeof TIMEFRAME_YEAR
  | typeof TIMEFRAME_MONTH
  | typeof TIMEFRAME_QUARTER;

export type Timeframe = TimeframeBase | typeof TIMEFRAME_DAY | typeof TIMEFRAME_WEEK;

export const TIMEFRAME_OPTIONS: { value: TimeframeBase; label: string }[] = [
  { value: 'month', label: 'Monthly' },
  { value: 'quarter', label: 'Quarterly' },
  { value: 'year', label: 'Yearly' },
];
