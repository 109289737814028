import { DateTime } from 'luxon';
import * as R from 'ramda';

import { getOrderOfMagnitude } from 'common/utils/Math';
import { getQuarterForDate, isoDateToMonthAbbreviation } from 'common/utils/date';

import type { Timeframe } from 'common/constants/timeframes';

type ChartDateOptions = {
  showFirstQuarterWithYear?: boolean;
};

export const objectToColorDataKeys = (obj: Record<string, number | string>) =>
  Object.entries(obj).map(([key, val]) => ({ name: key, color: val }));

export const getChartDateFromTimeframe =
  (timeframe: Timeframe, options: ChartDateOptions = {}) =>
  (date: string) => {
    const parsedDate = DateTime.fromISO(date);
    const { showFirstQuarterWithYear } = options;
    const q = `Q${getQuarterForDate(parsedDate.startOf('quarter'))}`;

    switch (timeframe) {
      case 'year':
        return parsedDate.toFormat('yyyy');
      case 'month':
        return isoDateToMonthAbbreviation(date);
      case 'quarter':
        if (q === 'Q1' && showFirstQuarterWithYear) {
          return `${q} ${parsedDate.toFormat('yyyy')}`;
        } else {
          return q;
        }
      case 'day':
        return parsedDate.toFormat('MMM d');
      default:
        return date;
    }
  };

/**
 * Calculates the lower and upper domain bounds for the Y axis, handling padding values,
 * aligning to the necessary order of magnitude, and ensuring the domain is not too small.
 */
export const getYAxisDomainBounds = (
  values: number[] = [],
  domainMultiplier = 1.0,
  ignoreOutliersCount = 0,
  minimumOrderOfMagnitude = 10,
): [number, number] => {
  const sortedValues = R.filter(
    R.is(Number),
    R.sort((a, b) => a - b, values),
  );

  // Calculate candidate domain bounds as direct min / max checks
  const minValue = sortedValues.at(ignoreOutliersCount) || 0;
  const maxValue = sortedValues.at(-(1 + ignoreOutliersCount)) || 0;

  // Pad the min and max values to give us a little wiggle room for the domain.
  // Ensure the right directionality (padded min should go down and padded max goes up) and that 0
  // is always within the domain. Otherwise, negative max's and positive min's would exclude it.
  const minValuePadded = Math.min(minValue * domainMultiplier, minValue, 0);
  const maxValuePadded = Math.max(maxValue * domainMultiplier, maxValue, 0);

  // Gets the next lower order of magnitude, e.g. $65,321 would return 1,000. Capped at 100.
  // Then it aligns the magnitudes between the top and the bottom of the domain for consistency.
  const minOrderOfMagnitude = Math.max(getOrderOfMagnitude(minValue) / 10, minimumOrderOfMagnitude);
  const maxOrderOfMagnitude = Math.max(getOrderOfMagnitude(maxValue) / 10, minimumOrderOfMagnitude);
  const alignedOrderOfMagnitude = Math.max(minOrderOfMagnitude, maxOrderOfMagnitude);

  // Finally, determine the domain bounds by truncating the padded min and max values to the nearest
  // aligned order of magnitude, ensuring the right direction of truncation for each. When the value
  // is 0, we want to prevent division by 0.
  let lowerBound = 0;
  let upperBound = 0;
  if (minValuePadded !== 0) {
    lowerBound = Math.floor(minValuePadded / alignedOrderOfMagnitude) * alignedOrderOfMagnitude;
  }
  if (maxValuePadded !== 0) {
    upperBound = Math.ceil(maxValuePadded / alignedOrderOfMagnitude) * alignedOrderOfMagnitude;
  }

  return [lowerBound, upperBound];
};
