import { match } from 'path-to-regexp';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getOffsetForKey, getRouteHistory } from 'selectors';
import type { RouteHistoryEntry } from 'state/navigation/reducer';

import type { SidebarRouteName } from 'constants/sidebar';
import { SIDEBAR_ROUTES } from 'constants/sidebar';

type Props = {
  defaultParentRoute: SidebarRouteName | null;
};

/**
 * Hook to manage breadcrumbs for page header.
 *
 * The breadcrumb shown is the most recently visited route that is can be a "parent" route (any
 * route that shows up in the sidebar).
 *
 * Otherwise, the default parent route for the page is used (for cases where the url is navigated to directly).
 *
 * This hook also restores scroll position when navigating back to the parent route.
 */
const usePageBreadcrumbs = ({ defaultParentRoute }: Props) => {
  const history = useHistory();
  const routeHistory = useSelector(getRouteHistory);

  // find most recently visited sidebar route
  const mostRecentParentRoute = findMostRecentParentRoute(routeHistory);
  const parentRoute = mostRecentParentRoute?.sidebarRouteName ?? defaultParentRoute;

  const parentScrollOffset = useSelector(getOffsetForKey(mostRecentParentRoute?.key ?? ''));

  if (!parentRoute || !defaultParentRoute) {
    // don't show breadcrumbs if there's no parent route OR if this is already a sidebar route
    return [];
  }

  const breadcrumbs = [
    {
      label: parentRoute,
      onClick: () => {
        const { route } = SIDEBAR_ROUTES[parentRoute];
        history.push({
          pathname: mostRecentParentRoute?.path ?? route,
          search: mostRecentParentRoute?.search,
          state: {
            // restore scroll position when navigating back to the parent route
            initialScrollOffset: mostRecentParentRoute && parentScrollOffset,
          },
        });
      },
    },
  ];

  return breadcrumbs;
};

const findMostRecentParentRoute = (routeHistory: RouteHistoryEntry[]) => {
  let sidebarRouteName: SidebarRouteName | undefined;

  const mostRecentRouteHistory = R.findLast((pastRoute) => {
    const sidebarRouteMatch = R.toPairs(SIDEBAR_ROUTES).find(([, { route }]) => {
      const matcher = match(route);
      return !!matcher(pastRoute.path);
    });

    sidebarRouteName = sidebarRouteMatch?.[0] as SidebarRouteName;

    return !!sidebarRouteMatch;
  }, routeHistory);

  if (sidebarRouteName && mostRecentRouteHistory) {
    return {
      sidebarRouteName,
      ...mostRecentRouteHistory,
    };
  }
};

export default usePageBreadcrumbs;
