import pluralize from 'pluralize';
import * as R from 'ramda';
import { isEmpty } from 'ramda';
import { isNotEmpty } from 'ramda-adjunct';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import Banner from 'components/lib/ui/Banner';
import FlexContainer from 'components/lib/ui/FlexContainer';
import SearchBar, { Input } from 'components/lib/ui/SearchBar';
import Skeleton from 'components/lib/ui/Skeleton';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import Menu, { MenuItem } from 'components/lib/ui/menu/Menu';
import FilterMenuColumn from 'components/reports/filters/FilterMenuColumn';
import FilterMenuEmpty from 'components/reports/filters/FilterMenuEmpty';
import FilterMenuOptionRow from 'components/reports/filters/FilterMenuOptionRow';
import FilterMenuOptionsList from 'components/reports/filters/FilterMenuOptionsList';
import FilterMenuSelectedList from 'components/reports/filters/FilterMenuSelectedList';
import type { FilterMenuOptionInput, FilterMenuSection } from 'components/reports/filters/types';
import {
  addPathToOptions,
  areAllChildrenSelected,
  filterOptions,
  getAllLeavesForOptions,
  getLeafOptionsForPath,
} from 'components/reports/filters/utils';

import useDebounce from 'common/lib/hooks/useDebounce';
import useEventCallback from 'common/lib/hooks/useEventCallback';
import { color, fontSize, spacing } from 'common/lib/theme/dynamic';
import HighlightTextContext from 'lib/contexts/HighlightTextContext';

const EMPTY_SECTION: FilterMenuSection = { id: '', name: '', options: [] };

const Root = styled(FlexContainer).attrs({ column: true })`
  width: 660px;
  height: 440px;
  overflow: hidden;
`;

const ColumnsContainer = styled(FlexContainer)`
  flex: 1;
  overflow: hidden;
`;

const Footer = styled(FlexContainer).attrs({ justifyEnd: true })`
  padding: ${spacing.small};
  border-top: 1px solid ${color.grayBackground};
  flex-shrink: 0;
`;

const ButtonGroup = styled(FlexContainer)`
  gap: ${spacing.xsmall};
  flex: 1;

  ${DefaultButton}:first-child {
    margin-right: auto;
  }
`;

const OptionsListContainer = styled(FlexContainer).attrs({ column: true })`
  padding: ${spacing.xsmall} ${spacing.small};
  gap: ${spacing.xxxsmall};
`;

const GrayHeaderText = styled.div`
  padding: ${spacing.xsmall} ${spacing.small};
  color: ${color.textLight};
`;

const StyledSearchBar = styled(SearchBar)`
  width: 100%;

  ${Input} {
    border: none !important;
    box-shadow: none !important;
    font-size: ${fontSize.small};
    outline: none;

    :disabled {
      background-color: ${color.white};
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  padding: 5.5px ${spacing.xsmall};
  margin: ${spacing.xxxsmall} ${spacing.xsmall};
  font-size: ${fontSize.small};
`;

const SkeletonContainer = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column;
  padding: ${spacing.xsmall} ${spacing.small};
  padding-top: ${spacing.small};
`;

const OptionListSkeletonContainer = styled(SkeletonContainer)`
  padding-left: 0;
  padding-top: 0;
  padding-right: ${spacing.small};
`;

const BaseSkeleton = styled(Skeleton)<{ $indent?: boolean }>`
  height: ${fontSize.small};

  &:not(:last-child) {
    margin-bottom: ${spacing.small};
  }

  ${({ $indent }) =>
    $indent &&
    css`
      margin-left: ${spacing.default};
    `}
`;

const SmallBanner = styled(Banner)`
  padding: ${spacing.xxsmall} ${spacing.xsmall};
  font-size: ${fontSize.small};
`;

export type OnClickApplyOptions = Partial<{ reset: boolean }>;

type Options = {
  filtersColumnWidth?: number;
};

export type Props = {
  sections: FilterMenuSection[];
  isLoading?: boolean;
  isSearchLoading?: boolean;
  onSearchChange?: (search: string) => void;
  onChange: (sections: FilterMenuSection[]) => void;
  onClickApply: (options?: OnClickApplyOptions) => void;
  onClickCancel: () => void;
  options?: Options;
};

const FilterMenu = ({
  sections: sectionsProp,
  isLoading,
  isSearchLoading,
  onSearchChange,
  onChange,
  onClickApply,
  onClickCancel,
  options,
}: Props) => {
  const { filtersColumnWidth } = options ?? {};
  // add path list to options so we know where they are in the tree
  const sections = useMemo(() => addPathToOptions(sectionsProp), [sectionsProp]);
  const [activeSectionIndex, setActiveSectionIndex] = useState(0);
  const activeSection = useMemo(
    () => sections[activeSectionIndex] ?? EMPTY_SECTION,
    [sections, activeSectionIndex],
  );

  // search state
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 200);
  const searchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    onSearchChange?.(debouncedSearch);
  }, [debouncedSearch, onSearchChange]);

  const activeSections = useMemo(
    () =>
      sections.flatMap((section) => {
        const selectedOptions = getAllLeavesForOptions(section.options).filter(
          ({ isSelected }) => isSelected,
        );

        if (!selectedOptions.length) {
          return [];
        }

        return [
          {
            ...section,
            selectedOptions,
          },
        ];
      }),
    [sections],
  );

  const totalSelectedOptions = useMemo(
    () => activeSections.reduce((acc, { selectedOptions }) => acc + selectedOptions.length, 0),
    [activeSections],
  );

  const filteredOptions = useMemo(
    () => (search ? filterOptions(activeSection.options, search) : activeSection.options),
    [search, activeSection],
  );

  // empty path means select/deselect all
  const changeSelected = useEventCallback((path: string[], selected: boolean) => {
    const newSections = R.clone(sections);
    const leafOptions = getLeafOptionsForPath(path, newSections);

    leafOptions.forEach((option) => {
      option.isSelected = selected;
    });

    onChange(newSections);
    setValidationErrors([]);
  });

  const sectionHasSubOptions = useMemo(
    () => activeSection.options.some((o) => o.options),
    [activeSection],
  );

  const sectionHasRadio = useMemo(
    () => activeSection.options.some(({ type }) => type === 'radio'),
    [activeSection],
  );

  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const handleInputChange = useEventCallback(
    (path: string[], optionId: string, input: FilterMenuOptionInput, value: unknown) => {
      const newSections = R.clone(sections);
      const leafOptions = getLeafOptionsForPath(path, newSections);

      leafOptions.forEach((option) => {
        if (option.id === optionId) {
          option.inputValues = R.assocPath([input.name], value, option.inputValues);
        }
      });

      onChange(newSections);
    },
  );

  const validateForm = useEventCallback(() => {
    let errors: string[] = [];

    sections.forEach((section) => {
      const selectedOptions = getAllLeavesForOptions(section.options).filter(
        ({ isSelected }) => isSelected,
      );

      if (section.validate) {
        const sectionInputValues = selectedOptions.reduce(
          (acc, { inputValues }) => ({ ...acc, ...inputValues }),
          {} as Record<string, any>,
        );

        const sectionErrors = section.validate(sectionInputValues);
        if (sectionErrors) {
          errors = errors.concat(sectionErrors);
        }
      }
    });

    return errors;
  });

  const handleApply = useEventCallback(() => {
    setValidationErrors([]);
    const errors = validateForm();

    if (isNotEmpty(errors)) {
      setValidationErrors(errors);
      return;
    }

    onClickApply();
  });

  const handleClear = useEventCallback(() => {
    const newSections = R.clone(sections);

    newSections.forEach((section) => {
      section.options.forEach((option) => {
        option.isSelected = false;
      });
    });

    onChange(newSections);
    onClickApply({ reset: true });
  });

  return (
    <Root>
      <ColumnsContainer>
        <FilterMenuColumn header="Filters" width={filtersColumnWidth ?? 140}>
          <Menu>
            {sections.map(({ name }, sectionIndex) => (
              <StyledMenuItem
                key={sectionIndex}
                selected={sectionIndex === activeSectionIndex}
                onClick={() => {
                  setActiveSectionIndex(sectionIndex);
                  setSearch(''); // Reset search when switching sections
                  searchRef.current?.focus();
                }}
              >
                {name}
              </StyledMenuItem>
            ))}
          </Menu>
        </FilterMenuColumn>
        <FilterMenuColumn
          header={
            !sectionHasRadio && (
              <StyledSearchBar
                name="search"
                value={search}
                onChange={setSearch}
                placeholder={`Search ${activeSection.name.toLowerCase()}...`}
                disabled={isLoading || (isEmpty(search) && isEmpty(filteredOptions))}
                ref={searchRef}
                autoFocus
                small
              />
            )
          }
        >
          <OptionsListContainer>
            {isLoading || (isSearchLoading && search.length > 0 && isEmpty(filteredOptions)) ? (
              <OptionListSkeletonContainer>
                {R.range(0, 6).map((i) => (
                  <BaseSkeleton key={i} $indent={i > 0 && i % 2 === 0} />
                ))}
              </OptionListSkeletonContainer>
            ) : (
              <>
                {isEmpty(search) &&
                  isNotEmpty(filteredOptions) &&
                  !sectionHasRadio &&
                  !activeSection.hideSelectAll && (
                    <FilterMenuOptionRow
                      name="Select all"
                      isGroup
                      isSelected={areAllChildrenSelected(activeSection.options)}
                      onChangeSelected={(selected) => changeSelected([activeSection.id], selected)}
                    />
                  )}

                {isEmpty(filteredOptions) ? (
                  <FilterMenuEmpty
                    subtitle={`There are no ${activeSection.name.toLowerCase()} to show.`}
                  />
                ) : (
                  <>
                    {validationErrors.length > 0 && (
                      <SmallBanner type="error">{validationErrors.join('\n')}</SmallBanner>
                    )}
                    <HighlightTextContext.Provider
                      value={{
                        searchWords: search.split(' '),
                      }}
                    >
                      <FilterMenuOptionsList
                        flat={!!search || !sectionHasSubOptions}
                        onChangeSelected={changeSelected}
                        onInputChange={handleInputChange}
                        options={filteredOptions}
                      />
                    </HighlightTextContext.Provider>
                  </>
                )}
              </>
            )}
          </OptionsListContainer>
        </FilterMenuColumn>
        <FilterMenuColumn
          header={
            <>
              {isLoading ? (
                <SkeletonContainer>
                  <BaseSkeleton $width="50%" />
                </SkeletonContainer>
              ) : (
                <GrayHeaderText>
                  {`${pluralize('filter', totalSelectedOptions, true)} selected`}
                </GrayHeaderText>
              )}
            </>
          }
        >
          {isLoading ? (
            <SkeletonContainer>
              {R.range(0, 4).map((i) => (
                <BaseSkeleton key={i} />
              ))}
            </SkeletonContainer>
          ) : (
            <FilterMenuSelectedList
              activeSections={activeSections}
              onChangeSelected={changeSelected}
            />
          )}
        </FilterMenuColumn>
      </ColumnsContainer>
      <Footer>
        <ButtonGroup>
          <DefaultButton size="xsmall" disabled={totalSelectedOptions <= 0} onClick={handleClear}>
            Clear
          </DefaultButton>
          <DefaultButton size="xsmall" onClick={onClickCancel}>
            Cancel
          </DefaultButton>
          <PrimaryButton size="xsmall" onClick={handleApply}>
            Apply
          </PrimaryButton>
        </ButtonGroup>
      </Footer>
    </Root>
  );
};

export default FilterMenu;
