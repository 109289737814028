import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import Flex from 'components/lib/ui/Flex';
import LeftRightSwitcher from 'components/lib/ui/LeftRightSwitcher';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import PlanHeaderTimeframeToggle from 'components/plan/PlanHeaderTimeframeToggle';

import { PlanTimeframe } from 'lib/hooks/plan/usePlanState';

import routes from 'constants/routes';

const Root = styled(Flex).attrs({ alignCenter: true })``;

const Switcher = styled(LeftRightSwitcher)`
  margin: 0 ${({ theme }) => theme.spacing.default};
`;

const Divider = styled.div`
  height: 16px;
  width: 1px;
  background-color: ${({ theme }) => theme.color.gray};
  margin: 0 ${({ theme }) => theme.spacing.default};
`;

const SettingsButton = styled(DefaultButton)`
  display: flex;
  align-items: baseline;
`;

type Props = {
  className?: string;
  timeframe: PlanTimeframe;
  onLeft: () => void;
  onRight: () => void;
  onClickToday: () => void;
  onChangeTimeframe: (timeframe: PlanTimeframe) => void;
};

const PlanHeaderControls = ({ onClickToday, timeframe, onChangeTimeframe, ...props }: Props) => {
  const { push } = useHistory();

  return (
    <Root>
      <Switcher {...props} />
      <DefaultButton onClick={onClickToday}>Today</DefaultButton>
      <Divider />
      {timeframe !== PlanTimeframe.SingleMonth && (
        <>
          <PlanHeaderTimeframeToggle timeframe={timeframe} onChangeTimeframe={onChangeTimeframe} />
          <Divider />
        </>
      )}
      <SettingsButton onClick={() => push(routes.plan.settings())}>
        <ButtonIcon name="settings" />
        <span>Settings</span>
      </SettingsButton>
    </Root>
  );
};

export default PlanHeaderControls;
