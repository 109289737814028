import React from 'react';
import styled from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import ContentLoader from 'components/lib/ui/ContentLoader';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import ProgressBar from 'components/lib/ui/ProgressBar';
import Text from 'components/lib/ui/Text';
import TextButton from 'components/lib/ui/TextButton';
import Tooltip from 'components/lib/ui/Tooltip';

import { getPendingStatementsLabel, getStatementsPendingInfo } from 'common/lib/recurring/summary';
import type { AggregatedSummary as AggregatedSummaryType } from 'common/lib/recurring/types';
import { spacing } from 'common/lib/theme/dynamic';
import { formatCurrency } from 'common/utils/Currency';

const TOOLTIP_MAX_WIDTH = 250;

const StyledContentLoader = styled(ContentLoader)`
  height: 20px;
`;

const EmptyTextButton = styled(TextButton)`
  text-align: left;
  justify-content: flex-start;
  padding: 0;
  margin-top: ${spacing.xxsmall};
`;

const SummaryProgressBar = styled(ProgressBar).attrs({
  roundInnerBar: false,
  roundAppearance: true,
})`
  margin-top: ${({ theme }) => theme.spacing.xsmall};
`;

const Title = styled(Text)`
  font-size: ${({ theme }) => theme.fontSize.base};
  margin-right: ${({ theme }) => theme.spacing.xxsmall};
`;

const InfoIcon = styled(Icon).attrs({ name: 'info', size: 12 })`
  cursor: default;
`;

export const AggregatedSummary = styled(FlexContainer)`
  border-top: 1px solid ${({ theme }) => theme.color.grayBackground};

  @media (max-width: ${({ theme }) => theme.breakPoints.lg}px) {
    flex-direction: column;
  }
`;

const AggregatedSummaryBox = styled(FlexContainer)`
  display: flex;
  padding: 24px;
  flex-direction: column;
  justify-content: flex-start;
  gap: 12px;
  flex: 1 0 0;
  border-left: 1px solid ${({ theme }) => theme.color.grayBackground};

  &:first-child {
    border-right: none;
  }

  @media (max-width: ${({ theme }) => theme.breakPoints.lg}px) {
    border-left: none;
    border-top: 1px solid ${({ theme }) => theme.color.grayBackground};

    &:first-child {
      border-top: none;
    }
  }
`;

type TitleProps = { title: string; tooltipText: string };

const AggregatedSummaryItemTitle = ({ title, tooltipText }: TitleProps) => (
  <div>
    <Title>{title}</Title>

    <Tooltip maxWidth={TOOLTIP_MAX_WIDTH} content={tooltipText}>
      <InfoIcon />
    </Tooltip>
  </div>
);

export const AggregateSummaryItem = ({
  title,
  tooltipText,
  completedText = 'paid',
  aggregatedSummary,
  isLoading,
  isEmpty,
  emptyText,
  onEmptyAction,
}: {
  completedText?: string;
  isLoading: boolean;
  isEmpty: boolean;
  emptyText: string;
  onEmptyAction: () => void;
  aggregatedSummary?: Maybe<Partial<AggregatedSummaryType>>;
} & TitleProps) => {
  const { total, completed, remaining } = aggregatedSummary ?? {};
  const { isAllPending } = getStatementsPendingInfo(aggregatedSummary);

  if (isLoading) {
    return <AggregateSummaryItemLoading />;
  }

  if (isEmpty) {
    return (
      <AggregatedSummaryBox>
        <div>
          <AggregatedSummaryItemTitle title={title} tooltipText={tooltipText} />
          <EmptyTextButton onClick={onEmptyAction}>{emptyText}</EmptyTextButton>
        </div>
      </AggregatedSummaryBox>
    );
  }

  return (
    <AggregatedSummaryBox>
      <div>
        <FlexContainer justifyBetween alignCenter gap="xsmall">
          <AggregatedSummaryItemTitle title={title} tooltipText={tooltipText} />

          <Text color="textLight" size="small">
            {getPendingStatementsLabel(aggregatedSummary)}
          </Text>
        </FlexContainer>

        <SummaryProgressBar value={(completed ?? 0) / (total || 1)} color="blue" />
      </div>

      <FlexContainer justifyBetween>
        <Text weight="medium" size="small">
          {formatCurrency(Math.abs(completed ?? 0))} {completedText}
        </Text>
        <Text weight="medium" size="small">
          <Switch>
            <Case when={isAllPending}>Remaining pending</Case>
            <Case default>{formatCurrency(Math.abs(remaining ?? 0))} remaining</Case>
          </Switch>
        </Text>
      </FlexContainer>
    </AggregatedSummaryBox>
  );
};

const AggregateSummaryItemLoading = () => (
  <AggregatedSummaryBox>
    <FlexContainer justifyBetween>
      <StyledContentLoader>
        <rect x="0%" y="5" rx="5" ry="5" width="80" height="15" />
        <rect x="70%" y="5" rx="5" ry="5" width="80" height="15" />
      </StyledContentLoader>
    </FlexContainer>

    <StyledContentLoader>
      <rect x="0" y="10" rx="5" ry="5" width="100%" height="10" />
    </StyledContentLoader>

    <FlexContainer justifyBetween>
      <StyledContentLoader>
        <rect x="0" y="0" rx="5" ry="5" width="80" height="15" />
        <rect x="70%" y="0" rx="5" ry="5" width="80" height="15" />
      </StyledContentLoader>
    </FlexContainer>
  </AggregatedSummaryBox>
);
