import { rgba } from 'polished';
import * as RA from 'ramda-adjunct';
import React from 'react';
import styled from 'styled-components';

import variables from 'common/lib/theme/variables';

export type TooltipPayload<DataT> = {
  // Subset of real values passed
  dataKey: string;
  value: number;
  payload: DataT;
};

// eslint-disable-next-line
export type TooltipProps<XAxisT, YAxisT, DataT extends object> = {
  active: boolean;
  payload: TooltipPayload<DataT>[];
  label: string;
};

// eslint-disable-next-line
export type TooltipComponentProps<XAxisT, YAxisT, DataT extends object> =
  | React.ReactElement<TooltipProps<XAxisT, YAxisT, DataT>>
  | React.FunctionComponent<TooltipProps<XAxisT, YAxisT, DataT>>;

const TooltipRoot = styled.div<{ $opacity: number }>`
  background-color: ${({ theme, $opacity }) =>
    rgba(theme.uiTheme === 'dark' ? theme.color.trueBlack : theme.color.black, $opacity)};
  border-radius: ${({ theme }) => theme.radius.small};
  color: ${({ theme }) => theme.color.textWhite};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  text-align: left;
  backdrop-filter: blur(10px);
`;

export const TooltipHeader = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  text-align: left;
  padding: ${({ theme }) => theme.spacing.xsmall} ${({ theme }) => theme.spacing.default};
  border: 1px solid ${variables.color.border.tooltip};
  border-width: 0;
  border-bottom-width: 1px;
`;

const TooltipFooter = styled(TooltipHeader)`
  border-top-width: 1px;
  border-bottom-width: 0;
`;

export const TooltipValue = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  padding: ${({ theme }) => theme.spacing.xsmall} ${({ theme }) => theme.spacing.default};
`;

type Props = {
  header?: React.ReactNode;
  footer?: React.ReactNode;
  children: React.ReactNode;
  active: boolean;
  opacity?: number;
  className?: string;
};

const ChartTooltip = ({ header, children, active, className, footer, opacity = 0.9 }: Props) =>
  active ? (
    <TooltipRoot $opacity={opacity} className={className}>
      {!!header && <TooltipHeader>{header}</TooltipHeader>}
      <TooltipValue>{children}</TooltipValue>
      {RA.isNotNil(footer) && <TooltipFooter>{footer}</TooltipFooter>}
    </TooltipRoot>
  ) : null;

export default ChartTooltip;
