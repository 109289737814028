import * as React from 'react';

import FeatureEntitlementGate from 'common/components/premium/FeatureEntitlementGate';
import CardLink from 'components/lib/ui/CardLink';
import ModalCard from 'components/lib/ui/ModalCard';

import { ProductFeature } from 'common/constants/premium';

type Props = {
  onClickHelpCenter: () => void;
  onClickFeatureRequest: () => void;
  onClickContactSupport: () => void;
  onClickWhatsNew: () => void;
};

const HelpCenterModalCard = ({
  onClickContactSupport,
  onClickFeatureRequest,
  onClickHelpCenter,
  onClickWhatsNew,
}: Props) => (
  <ModalCard title="Help & Support">
    <CardLink
      title="Help Center"
      subtitle="Read more about how to fully utilize Monarch"
      onClick={onClickHelpCenter}
      icon="book"
    />
    <CardLink
      title="Feature Request"
      subtitle="View our product roadmap and vote on features"
      onClick={onClickFeatureRequest}
      icon="map"
    />
    <FeatureEntitlementGate feature={ProductFeature.customer_support}>
      <CardLink
        title="Contact Support"
        subtitle="Get in touch with our customer success team"
        onClick={onClickContactSupport}
        icon="MessageCircle"
      />
    </FeatureEntitlementGate>
    <CardLink
      title="What's New"
      subtitle="Catch up on the latest product improvements"
      onClick={onClickWhatsNew}
      icon="zap"
    />
  </ModalCard>
);

export default HelpCenterModalCard;
