import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import GoalTypeIncrementCard from 'components/goalsV2/GoalTypeIncrementCard';
import GoalsSetupHeader from 'components/goalsV2/GoalsSetupHeader';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Page from 'components/lib/ui/Page';
import SetupContentHeader from 'components/lib/ui/SetupContentHeader';
import Text from 'components/lib/ui/Text';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import { setCreatedByType } from 'actions';
import useSelectGoals from 'common/lib/hooks/goalsV2/useSelectGoals';
import variables from 'common/lib/theme/variables';
import { getProgressPercent } from 'lib/goals/progress';
import toast, { errorToast } from 'lib/ui/toast';
import type { GoalByType } from 'state/goals/reducer';
import { getCreatedByType } from 'state/goals/selectors';

import * as COPY from 'common/constants/copy';
import { COPY_FOR_GOAL_TYPE } from 'common/constants/goals';
import routes from 'constants/routes';

import type { GoalOptionsQuery } from 'common/generated/graphql';
import type { ElementOf } from 'common/types/utility';

const StyledPage = styled(Page)`
  height: 100vh;
  background-color: ${variables.color.background.fullscreenHeader};
`;

const List = styled(FlexContainer)`
  display: grid;
  grid-gap: 27px;
  margin-top: ${({ theme }) => theme.spacing.default};
  margin-bottom: ${({ theme }) => theme.spacing.xxxlarge};
  grid-template-columns: repeat(2, 1fr);

  @media (min-width: ${({ theme }) => theme.breakPoints.sm}px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: ${({ theme }) => theme.breakPoints.md}px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const NextButton = styled(PrimaryButton)`
  width: 100%;
  max-width: 438px;
  margin-top: ${({ theme }) => theme.spacing.xxxlarge};
  margin-bottom: ${({ theme }) => theme.spacing.xxxlarge};
`;

const GoalTypeTitle = styled(Text).attrs({ size: 'small', weight: 'medium' })`
  text-transform: capitalize;
  margin-top: ${({ theme }) => theme.spacing.small};
  display: block;
`;

const GoalOptionsListHeader = styled(FlexContainer).attrs({ column: true })`
  max-width: 690px;
`;

const StyledLoadingSpinner = styled(LoadingSpinner)`
  margin-top: ${({ theme }) => theme.spacing.xlarge};
`;

type GoalOption = ElementOf<GoalOptionsQuery, 'goalOptions'>;

type GoalOptionsListProps = {
  title: string;
  subtitle: string;
  goalOptions: GoalOption[];
  getCountForItem: (item: GoalOption) => number;
  updateCountForItem: (item: GoalOption) => (value: number) => void;
};

const GoalOptionsList = ({
  title,
  subtitle,
  goalOptions,
  getCountForItem,
  updateCountForItem,
}: GoalOptionsListProps) => (
  <FlexContainer column>
    <GoalOptionsListHeader>
      <Text weight="medium">{title}</Text>
      <Text size="small">{subtitle}</Text>
    </GoalOptionsListHeader>
    <List>
      {goalOptions.map((item) => (
        <div key={item.defaultName}>
          <GoalTypeIncrementCard
            data={item}
            value={getCountForItem(item)}
            onChangeValue={updateCountForItem(item)}
            onClick={() => {
              if (!getCountForItem(item)) {
                updateCountForItem(item)(1);
              } else {
                updateCountForItem(item)(0);
              }
            }}
          />
          <GoalTypeTitle>{item.defaultName}</GoalTypeTitle>
        </div>
      ))}
    </List>
  </FlexContainer>
);

const SelectGoals = () => {
  const { push, goBack } = useHistory();
  const dispatch = useDispatch();
  const createdByType = useSelector(getCreatedByType);

  const onSelectedGoalsChange = (createdByType: GoalByType) => {
    dispatch(setCreatedByType(createdByType));
  };

  const {
    sectionsData,
    isLoadingInitialData,
    updateCountForItem,
    getCountForItem,
    areAnySelected,
    createGoalsForSelectedObjectives,
  } = useSelectGoals({
    onChange: onSelectedGoalsChange,
    initialCreatedByType: createdByType,
    onDeleteFailed: () => errorToast(COPY.SELECTED_GOALS.DELETE_ERROR),
  });

  return (
    <StyledPage
      name="Select goals"
      header={
        <GoalsSetupHeader
          onClickBackButton={() => {
            dispatch(setCreatedByType({}));
            goBack();
          }}
          progress={getProgressPercent(1)}
        />
      }
    >
      <FlexContainer center column>
        <SetupContentHeader
          title={COPY.GOALS.SELECT_GOALS.TITLE}
          description={COPY.GOALS.SELECT_GOALS.SUBTITLE}
        />
        {isLoadingInitialData ? (
          <StyledLoadingSpinner />
        ) : (
          <>
            {sectionsData.map(({ type, goalOptions }) => (
              <GoalOptionsList
                key={type}
                title={COPY_FOR_GOAL_TYPE[type].title}
                subtitle={COPY_FOR_GOAL_TYPE[type].subtitle}
                goalOptions={goalOptions}
                getCountForItem={getCountForItem}
                updateCountForItem={updateCountForItem}
              />
            ))}

            <NextButton
              size="large"
              disabled={!areAnySelected}
              onClick={async () => {
                try {
                  await createGoalsForSelectedObjectives();
                  push(routes.goalsV2.rank());
                } catch (e) {
                  toast({ title: COPY.DEFAULT_ERROR_MESSAGE });
                }
              }}
            >
              Next
            </NextButton>
          </>
        )}
      </FlexContainer>
    </StyledPage>
  );
};

export default SelectGoals;
