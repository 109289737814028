import * as React from 'react';
import { FiCircle } from 'react-icons/fi';
import styled from 'styled-components';

import Flex from 'components/lib/ui/Flex';

type Props = {
  dotFillColor?: string;
  strokeColor?: string;
  label?: string;
  value: React.ReactNode;
  className?: string;
};

const Row = styled(Flex)`
  justify-content: space-between;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
`;

const RowIcon = styled.span`
  margin-right: 8px;
  position: relative;
  top: 2px;
  font-size: ${({ theme }) => theme.fontSize.xxsmall};
`;

const RowLabel = styled.span`
  flex-grow: 1;
  text-align: left;
  margin-right: 40px;
`;

const ChartTooltipDataRow = ({ dotFillColor, label, value, strokeColor, className }: Props) => {
  const hasDot = dotFillColor || strokeColor;
  return (
    <Row className={className}>
      {!!hasDot && (
        <RowIcon>
          <FiCircle fill={dotFillColor} fillOpacity={dotFillColor ? 1 : 0} stroke={strokeColor} />
        </RowIcon>
      )}
      {!!label && <RowLabel>{label}:</RowLabel>}
      {value}
    </Row>
  );
};

export default ChartTooltipDataRow;
