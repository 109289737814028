import { keys, omit, pipe } from 'ramda';
import { lengthGt } from 'ramda-adjunct';
import { createSelector } from 'reselect';

import type { SankeyGroupMode } from 'lib/cashFlow/sankey';
import type { ReportsTab } from 'state/reports/types';
import type { RootState } from 'state/types';

export const selectReportsState = (state: RootState) => state.reports;
export const selectReportsFilters = createSelector(selectReportsState, (state) => state.filters);
export const selectReportsSortBy = createSelector(selectReportsState, (state) => state.sortBy);
export const selectReportsGroupBy = createSelector(selectReportsState, (state) => state.groupBy);
export const selectReportsGroupByTimeframe = createSelector(
  selectReportsState,
  (state) => state.groupByTimeframe,
);

const selectActiveTab = (_: RootState, tab: ReportsTab) => tab;

export const selectDisplayPropertiesForTab = createSelector(
  [selectReportsState, selectActiveTab],
  (state, tab) => state[tab],
);

export const selectReportsHasFilters = createSelector(
  selectReportsFilters,
  pipe(omit(['startDate', 'endDate', 'timeframePeriod']), keys, lengthGt(0)),
);

export const selectReportsStartEndDate = createSelector(
  selectReportsFilters,
  ({ startDate, endDate }) => ({ startDate, endDate }),
);

export const selectReportsSankeyGroupMode = createSelector(
  selectReportsState,
  (state): SankeyGroupMode => state.cashFlow.groupMode ?? 'category',
);
