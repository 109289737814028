import * as React from 'react';

import AmountPill from 'components/lib/ui/AmountPill';

import { compare } from 'common/utils/Math';
import useTheme from 'lib/hooks/useTheme';

import {
  EQUAL_AMOUNT_INDICATOR_ICON,
  NEGATIVE_AMOUNT_INDICATOR_ICON,
  POSITIVE_AMOUNT_INDICATOR_ICON,
} from 'common/constants/amounts';

type Props = {
  returnPercent: number | null;
  className?: string;
};

const ReturnPercentPill = ({ returnPercent, className }: Props) => {
  const theme = useTheme();

  return (
    <AmountPill
      className={className}
      value={returnPercent}
      formatter={(value) =>
        `${
          value
            ? // don't show negative sign because it's redundant with the arrow icon
              Math.abs(value).toFixed(2)
            : '--'
        }%`
      }
      icon={compare(returnPercent ?? 0, 0, {
        equal: EQUAL_AMOUNT_INDICATOR_ICON({ theme }),
        lessThan: NEGATIVE_AMOUNT_INDICATOR_ICON({ theme }),
        greaterThan: POSITIVE_AMOUNT_INDICATOR_ICON({ theme }),
      })}
    />
  );
};

export default ReturnPercentPill;
