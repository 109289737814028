import { useMutation, gql } from '@apollo/client';
import * as R from 'ramda';
import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import TextField from 'components/lib/form/TextField';
import { sensitiveClassProps } from 'components/lib/higherOrder/withSensitiveData';
import SettingsCard from 'components/lib/layouts/SettingsCard';
import Flex from 'components/lib/ui/Flex';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Page from 'components/lib/ui/Page';
import Text from 'components/lib/ui/Text';

import { PAYLOAD_ERRORS_FRAGMENT } from 'common/lib/graphQl/errors';
import useQuery from 'common/lib/hooks/useQuery';
import { errorToast } from 'lib/ui/toast';

import type { PayloadErrorFields } from 'common/generated/graphQlTypes/PayloadErrorFields';
import type { Web_GetAdvisorProfilePage } from 'common/generated/graphQlTypes/Web_GetAdvisorProfilePage';
import type {
  Web_UpdateSubscriptionSponsor,
  Web_UpdateSubscriptionSponsorVariables,
} from 'common/generated/graphQlTypes/Web_UpdateSubscriptionSponsor';

const Container = styled(Flex)`
  flex-direction: column;
  align-items: center;
  padding: ${(props) => props.theme.spacing.xxlarge};
  padding-bottom: 0;
`;

const FormContainer = styled.div`
  width: 406px;
  margin: ${({ theme }) => theme.spacing.xlarge};
`;

const StyledText = styled(Text).attrs({ size: 'xsmall', color: 'textLight' })`
  display: block;
`;

const AdvisorProfile = () => {
  const { data, isLoadingInitialData } = useQuery<Web_GetAdvisorProfilePage>(
    GET_ADVISOR_PROFILE_PAGE_QUERY,
  );

  const [updateSponsor] = useMutation<
    Web_UpdateSubscriptionSponsor,
    Web_UpdateSubscriptionSponsorVariables
  >(UPDATE_SPONSOR_MUTATION);

  const handleFormSubmit = async (values: { name: string }) => {
    const { name } = values;
    const { data: responseData } = await updateSponsor({
      variables: {
        input: {
          name,
        },
      },
    });
    const errors: PayloadErrorFields | undefined = R.path(
      ['updateSponsor', 'errors'],
      responseData,
    );
    if (errors) {
      errorToast(errors.message);
    }
  };

  return (
    <Page name="Profile" parentRoute={null}>
      <Helmet>
        <title>Professional Portal | Profile</title>
      </Helmet>
      <Container>
        {isLoadingInitialData ? (
          <FlexContainer full center marginVertical="large">
            <LoadingSpinner />
          </FlexContainer>
        ) : (
          <SettingsCard title="Profile">
            <FormContainer {...sensitiveClassProps}>
              <Form
                initialValues={{
                  name: data?.subscriptionSponsor?.name,
                }}
                onSubmit={handleFormSubmit}
              >
                <TextField name="name" label="Name" />
                <StyledText>
                  Your clients will see this when they redeem an invite code you send them. It
                  should be either your personal name or your business name.
                </StyledText>
                <FormSubmitButton>Update Profile</FormSubmitButton>
              </Form>
            </FormContainer>
          </SettingsCard>
        )}
      </Container>
    </Page>
  );
};

const GET_ADVISOR_PROFILE_PAGE_QUERY = gql`
  query Web_GetAdvisorProfilePage {
    subscriptionSponsor {
      id
      name
    }
  }
`;

const UPDATE_SPONSOR_MUTATION = gql`
  mutation Web_UpdateSubscriptionSponsor($input: UpdateSponsorInput!) {
    updateSubscriptionSponsor(input: $input) {
      sponsor {
        id
        name
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
  ${PAYLOAD_ERRORS_FRAGMENT}
`;

export default AdvisorProfile;
