import { useGoogleLogin } from '@react-oauth/google';
import React, { useEffect } from 'react';

import AuthButton from 'components/lib/ui/button/AuthButton';

import type { AuthProviderResponse } from 'lib/hooks/auth/useAuthProvider';
import useAuthProvider from 'lib/hooks/auth/useAuthProvider';
import useToast from 'lib/hooks/useToast';
import { onOpenReportEmail } from 'lib/support';

import type { GoogleAuthExpectedResponse } from 'types/auth';

const DEFAULT_REPORT_EMAIL_SUBJECT = 'Issue signing in with Google';

type Props = {
  skipConfirmation?: boolean;
  staySignedIn?: boolean;
  extraData?: Record<string, unknown>;
  onConfirmedAuthentication?: (response: AuthProviderResponse) => void;
  onError?: (error?: { detail: string; code: string }) => void;
  setIsPending?: (isPending: boolean) => void;
};

const GoogleSignInButton = ({
  skipConfirmation,
  staySignedIn,
  onConfirmedAuthentication,
  onError,
  setIsPending,
  extraData,
}: Props) => {
  const { openErrorToast } = useToast();
  const { isLoading, authenticate } = useAuthProvider<GoogleAuthExpectedResponse>({
    provider: 'google',
    onConfirmedAuthentication,
    onError: (error) => {
      openErrorToast({
        onReport: () => onOpenReportEmail(DEFAULT_REPORT_EMAIL_SUBJECT),
        description: error?.detail,
      });
      setIsPending?.(false);
      onError?.(error);
    },
    staySignedIn,
    extraData,
  });

  useEffect(() => {
    setIsPending?.(isLoading);
  }, [isLoading, setIsPending]);

  const signInWithGoogle = useGoogleLogin({
    onSuccess: (response) => authenticate({ ...response, skipConfirmation }),
    onError: () =>
      openErrorToast({ onReport: () => onOpenReportEmail(DEFAULT_REPORT_EMAIL_SUBJECT) }),
    flow: 'implicit',
  });

  return (
    <AuthButton
      provider="google"
      text="Continue with Google"
      onClick={() => signInWithGoogle()}
      pending={!setIsPending && isLoading}
    />
  );
};

export default GoogleSignInButton;
