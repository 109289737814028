import { capitalize } from 'lodash';
import { rgba } from 'polished';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React from 'react';
import styled, { css, useTheme } from 'styled-components';

import { maskClassProps } from 'components/lib/higherOrder/withSensitiveData';
import CardTitle from 'components/lib/ui/CardTitle';
import Flex from 'components/lib/ui/Flex';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Icon from 'components/lib/ui/Icon';
import Text from 'components/lib/ui/Text';
import { TooltipV2 } from 'components/lib/ui/TooltipV2';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import MerchantLogo from 'components/merchants/MerchantLogo';

import useCreditReportLiabilityStatementsOperations from 'common/lib/hooks/recurring/useCreditReportLiabilityStatementsOperations';
import { splitTransactionsBetweenPaymentsAndCredits } from 'common/lib/recurring';
import type { PaymentTransactions } from 'common/lib/recurring/types';
import variables from 'common/lib/theme/variables';
import { formatCurrency } from 'common/utils/Currency';
import { isoDateToAbbreviatedMonthDayAndYear } from 'common/utils/date';
import formatTransactionAmount from 'common/utils/formatTransactionAmount';
import useToast from 'lib/hooks/useToast';

import { RECURRING } from 'common/constants/copy';

import { LiabilityStatementPaymentStatus } from 'common/generated/graphql';

const ACCOUNT_LOGO_SIZE = 16;

const TooltipRow = styled.div<{ $verticalPaddingBigger?: boolean }>`
  --border-color: ${({ theme }) =>
    theme.uiTheme === 'dark' ? variables.color.divider.primary : rgba(0, 0, 0, 0.2)};

  border-bottom: 1px solid var(--border-color);
  font-weight: ${({ theme }) => theme.fontWeight.book};
  padding: ${({ theme }) => theme.spacing.small} ${({ theme }) => theme.spacing.default};

  ${({ $verticalPaddingBigger }) =>
    $verticalPaddingBigger &&
    css`
      padding: ${({ theme }) => theme.spacing.default};
    `}
`;

const CreditCardNote = styled.div`
  color: ${({ theme }) => theme.color.textLight};
  font-style: italic;
  margin-top: ${({ theme }) => theme.spacing.xsmall};
  line-height: ${({ theme }) => theme.fontSize.large};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
`;

const TooltipContent = styled.div<{ $opacity: number }>`
  background-color: ${({ theme, $opacity }) =>
    rgba(theme.uiTheme === 'dark' ? theme.color.trueBlack : theme.color.black, $opacity)};
  border-radius: ${({ theme }) => theme.radius.small};
  color: ${({ theme }) => theme.color.textWhite};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  text-align: left;
  backdrop-filter: blur(10px);
  font-size: ${({ theme }) => theme.fontSize.small};
`;

const PaymentsTitle = styled(CardTitle)`
  display: block;
  font-size: 11px;
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};

  margin-top: ${({ theme }) => theme.spacing.large};

  &:first-child {
    margin-top: 0;
  }
`;

const NoPaymentsYetLabel = styled(Text).attrs({
  weight: 'book',
})`
  color: ${({ theme }) => theme.color.textLight};
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
  font-style: italic;
`;

const Amount = styled(Text).attrs({ weight: 'medium', ...maskClassProps })<{ $isIncome: boolean }>`
  color: ${({ $isIncome, theme }) => ($isIncome ? theme.color.green : theme.color.text)};
  margin-right: ${({ theme }) => theme.spacing.default};
`;

const TransactionAmount = styled(Amount)`
  margin-right: 0;
`;

const StatusContainer = styled(Flex).attrs({
  justifyBetween: true,
})`
  margin-top: ${({ theme }) => theme.spacing.small};
`;

const MinimumPaymentText = styled(Text)`
  display: block;
  color: ${({ theme }) => theme.color.textLight};
  text-align: center;
`;

const MinimumPaymentAmount = styled(Text).attrs({
  weight: 'medium',
})`
  color: ${({ theme }) => theme.color.textLight};
`;

const DateText = styled(Text)`
  color: ${({ theme }) => theme.color.textLight};
  margin-left: ${({ theme }) => theme.spacing.small};
  text-align: right;
  text-wrap: nowrap;
  flex-grow: 1;
`;

const AccountName = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 2;
`;

const ItemNameAndLogoWrapper = styled(Flex).attrs({ alignCenter: true })`
  gap: ${({ theme }) => theme.spacing.xsmall};
  flex-grow: 1;
  max-width: 180px;
`;

const PaymentTransaction = styled(Flex).attrs({ justifyBetween: true })`
  margin-top: ${({ theme }) => theme.spacing.xsmall};

  &:first-child {
    margin-top: 0;
  }
`;

const MarkAsPaidButton = styled(PrimaryButton).attrs({ size: 'small' })`
  width: 100%;
`;

const MarkAsUnpaidButton = styled(DefaultButton).attrs({ size: 'small' })`
  width: 100%;
`;

const IconWrapper = styled.div<{
  $backgroundColor: string;
  $color: string;
  $small?: boolean;
  $noMargin?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.radius.round};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  color: ${({ $color }) => $color};
  width: ${({ theme, $small }) => ($small ? theme.spacing.small : theme.spacing.default)};
  height: ${({ theme, $small }) => ($small ? theme.spacing.small : theme.spacing.default)};
  margin-right: ${({ theme, $noMargin }) => ($noMargin ? 0 : theme.spacing.xsmall)};
`;

export const PaidIcon = ({
  showCheckmark,
  isPartiallyPaid,
  small,
  darkBackground,
}: {
  showCheckmark: boolean;
  isPartiallyPaid?: boolean;
  small?: boolean;
  darkBackground?: boolean;
}) => {
  const theme = useTheme();

  if (!showCheckmark && !isPartiallyPaid) {
    return null;
  }

  const colorMapping = {
    darkBackground: {
      partiallyPaid: {
        backgroundColor: rgba(theme.color.blueBackground, 0.2),
        color: theme.color.blueText,
      },

      paid: {
        backgroundColor: rgba(theme.color.greenBackground, 0.2),
        color: theme.color.greenText,
      },
    },

    lightBackground: {
      partiallyPaid: {
        backgroundColor: theme.color.blueFocus,
        color: theme.color.blueText,
      },

      paid: {
        backgroundColor: theme.color.greenFocus,
        color: theme.color.greenText,
      },
    },
  };

  const colors = darkBackground ? colorMapping.darkBackground : colorMapping.lightBackground;

  return (
    <IconWrapper
      $backgroundColor={
        isPartiallyPaid ? colors.partiallyPaid.backgroundColor : colors.paid.backgroundColor
      }
      $color={isPartiallyPaid ? colors.partiallyPaid.color : colors.paid.color}
      $small={small}
      $noMargin={small}
    >
      <Icon name="check" size={small ? 8 : 10} />
    </IconWrapper>
  );
};

const PaymentStatusTooltip = ({
  children,
  logoUrl,
  name,
  itemDate,
  updatedAmount,
  paymentTransactions,
  paymentStatus,
  liabilityType,
  remainingBalance,
  showCheckmark,
  minimumPaymentAmount,
  creditReportLiabilityStatementId,
  isPaid,
  refetch,
}: {
  children: React.ReactNode;
  logoUrl?: string | null;
  name?: string | null;
  itemDate: string;
  updatedAmount?: number;
  paymentTransactions: PaymentTransactions;
  paymentStatus?: string | null;
  liabilityType?: string | null;
  remainingBalance?: number | null;
  showCheckmark: boolean;
  minimumPaymentAmount?: number | null;
  creditReportLiabilityStatementId?: string | null;
  refetch?: () => void;
  isPaid?: boolean;
}) => {
  const theme = useTheme();
  const { openToast, openErrorToast } = useToast();

  const isCreditCard = liabilityType === 'credit_card';
  const isPartiallyPaid = paymentStatus === LiabilityStatementPaymentStatus.PARTIALLY_PAID;

  const { payments, credits } = splitTransactionsBetweenPaymentsAndCredits(paymentTransactions);

  const { updateLiabilityStatementPaymentStatus, isLoadingUpdatePaymentStatus } =
    useCreditReportLiabilityStatementsOperations();

  const togglePaymentStatus = async () => {
    if (!creditReportLiabilityStatementId) {
      return;
    }

    await updateLiabilityStatementPaymentStatus({
      creditReportLiabilityStatementId,
      isPaid: !isPaid,
      onSuccess: () => {
        openToast({
          title: isPaid ? 'Marked as unpaid' : 'Marked as paid',
          description: 'Statement payment status updated.',
        });
        refetch?.();
      },
      onError: () => openErrorToast(),
    });
  };

  const PaymentStatusButton = isPaid ? MarkAsUnpaidButton : MarkAsPaidButton;

  return (
    <TooltipV2
      opacity={1}
      clickable
      maxWidth={312}
      align="end"
      content={
        <TooltipContent $opacity={1}>
          <TooltipRow>
            <Flex justifyBetween>
              <ItemNameAndLogoWrapper>
                <MerchantLogo url={logoUrl} size={ACCOUNT_LOGO_SIZE} />
                <AccountName weight="medium">{name}</AccountName>
              </ItemNameAndLogoWrapper>

              <DateText>{isoDateToAbbreviatedMonthDayAndYear(itemDate)}</DateText>
            </Flex>
          </TooltipRow>

          <TooltipRow>
            <Flex justifyBetween>
              <div>Amount due</div>
              <Text weight="medium">{formatCurrency((updatedAmount ?? 0) * -1)}</Text>
            </Flex>
            <CreditCardNote>
              {isCreditCard ? RECURRING.CREDIT_CARD_NO_CENTS : undefined}
            </CreditCardNote>
          </TooltipRow>

          <TooltipRow $verticalPaddingBigger>
            <PaymentsTitle>Payments</PaymentsTitle>

            <div>
              {RA.compact(payments).map((transaction) => (
                <PaymentTransaction key={transaction.id}>
                  <div>
                    {transaction.category.icon}{' '}
                    {isoDateToAbbreviatedMonthDayAndYear(transaction.date)}
                  </div>

                  <TransactionAmount $isIncome={transaction.amount > 0}>
                    {formatTransactionAmount(transaction?.amount)}
                  </TransactionAmount>
                </PaymentTransaction>
              ))}
            </div>

            {R.isEmpty(payments) && <NoPaymentsYetLabel>No payments yet</NoPaymentsYetLabel>}

            <PaymentsTitle>Credits</PaymentsTitle>

            <div>
              {RA.compact(credits).map((transaction) => (
                <PaymentTransaction key={transaction.id}>
                  <div>
                    {transaction.category.icon}{' '}
                    {isoDateToAbbreviatedMonthDayAndYear(transaction.date)}
                  </div>

                  <TransactionAmount $isIncome={transaction.amount > 0}>
                    {formatTransactionAmount(transaction?.amount)}
                  </TransactionAmount>
                </PaymentTransaction>
              ))}
            </div>

            {R.isEmpty(credits) && <NoPaymentsYetLabel>No credits</NoPaymentsYetLabel>}
          </TooltipRow>

          <TooltipRow>
            <Flex justifyBetween>
              <Text weight="medium">Remaining Balance</Text>
              <Text weight="medium">{formatCurrency((remainingBalance ?? 0) * -1)}</Text>
            </Flex>

            <StatusContainer>
              <div>Status</div>
              <Flex alignCenter justifyEnd gap={theme.spacing.xxsmall}>
                <PaidIcon
                  showCheckmark={showCheckmark}
                  isPartiallyPaid={isPartiallyPaid}
                  small
                  darkBackground
                />

                <Text weight="medium">{capitalize(paymentStatus?.replace('_', ' ') ?? '')}</Text>
              </Flex>
            </StatusContainer>
          </TooltipRow>

          {isCreditCard ? (
            <TooltipRow>
              <MinimumPaymentText>
                Minimum payment due:{' '}
                <MinimumPaymentAmount>
                  {formatCurrency(minimumPaymentAmount ?? 0)}
                </MinimumPaymentAmount>
              </MinimumPaymentText>
            </TooltipRow>
          ) : null}

          <TooltipRow $verticalPaddingBigger>
            <PaymentStatusButton
              onClick={togglePaymentStatus}
              disabled={isLoadingUpdatePaymentStatus}
            >
              <FlexContainer alignCenter gap="xsmall">
                <Icon name="check-circle" size={12} />
                <div>Mark as {isPaid ? 'unpaid' : 'paid'}</div>
              </FlexContainer>
            </PaymentStatusButton>
          </TooltipRow>
        </TooltipContent>
      }
    >
      {children}
    </TooltipV2>
  );
};

export default PaymentStatusTooltip;
