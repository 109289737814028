import * as R from 'ramda';
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import ExpandMoreIconButton from 'components/lib/ui/ExpandMoreIconButton';
import FlexContainer from 'components/lib/ui/FlexContainer';
import PlanColumns from 'components/plan/PlanColumns';
import {
  LEFT_WIDTH_PX,
  LeftSticky,
  PlanGridColumn,
  SECTION_HEADER_HEIGHT_PX,
  SECTION_HEADER_Z_INDEX,
} from 'components/plan/PlanGrid';
import PlanHeaderPastSeparator from 'components/plan/PlanHeaderPastSeparator';

import useEventCallback from 'common/lib/hooks/useEventCallback';
import { fontSize, spacing } from 'common/lib/theme/dynamic';
import isV2Theme from 'common/lib/theme/isV2Theme';
import variables from 'common/lib/theme/variables';
import { usePlanContext } from 'lib/contexts/PlanContext';
import { PlanTimeframe } from 'lib/hooks/plan/usePlanState';
import useIsV2Theme from 'lib/hooks/useIsV2Theme';
import { getDisplayNameForAmountType } from 'lib/plan';
import type { PlanSectionType } from 'lib/plan/Adapters';
import fakeCornersMixin from 'lib/styles/fakeCornersMixin';

const Root = styled(FlexContainer)`
  align-items: ${isV2Theme('flex-start', 'flex-end')};
  position: sticky;
  top: 0;
  width: 100%;
  height: ${SECTION_HEADER_HEIGHT_PX}px;
  padding-right: ${({ theme }) => theme.spacing.xlarge};
  background: ${variables.color.background.page};
  z-index: ${SECTION_HEADER_Z_INDEX}; /* stylelint-disable-line plugin/no-z-index */
`;

const V2Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: calc(${SECTION_HEADER_HEIGHT_PX}px - ${spacing.small});
  z-index: -1; /* stylelint-disable-line plugin/no-z-index */
  display: ${isV2Theme('block', 'none')};
`;

const V2BackgroundLeft = styled(V2Background)`
  ${({ theme }) =>
    fakeCornersMixin({
      theme,
      backgroundColor: variables.color.background.page({ theme }),
      fillColor: theme.color.gray4,
      topLeft: true,
      bottomLeft: true,
    })}
`;

const V2BackgroundRight = styled(V2Background)`
  ${({ theme }) =>
    fakeCornersMixin({
      theme,
      backgroundColor: variables.color.background.page({ theme }),
      fillColor: theme.color.gray4,
      topRight: true,
      bottomRight: true,
    })}
`;

const BottomPadding = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing.default};
`;

const StyledLeftSticky = styled(LeftSticky)`
  ${isV2Theme(css`
    background: none;
  `)}
`;

const Left = styled(BottomPadding)`
  display: flex;
  align-items: ${isV2Theme('center', 'flex-end')};
  padding-right: ${({ theme }) => theme.spacing.xlarge};

  ${isV2Theme(css`
    height: calc(${SECTION_HEADER_HEIGHT_PX}px - ${spacing.small});
    padding-bottom: 0;
  `)}
`;

const Title = styled.div`
  font-size: ${isV2Theme(fontSize.small, '20px')};
  color: ${isV2Theme(variables.color.content.secondary, variables.color.content.primary)};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-left: ${({ theme }) => theme.spacing.xsmall};
`;

const ColumnTitle = styled(BottomPadding)<{ isActive?: boolean; clickable?: boolean }>`
  font-size: ${isV2Theme(fontSize.small, fontSize.base)};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme, isActive }) => (isActive ? theme.color.text : theme.color.textLight)};
  text-align: right;
  transition: ${({ theme }) => theme.transition.default};
  padding-top: ${isV2Theme('6px', 0)};

  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
      :hover {
        color: ${({ theme }) => theme.color.blue};
      }
    `}
`;

const StickyPlanHeaderPastSeparator = styled(PlanHeaderPastSeparator)`
  position: sticky;
  left: ${LEFT_WIDTH_PX + 5}px;
  right: -5px;
  margin-left: 30px;
  margin-right: -30px;
  display: ${isV2Theme('none', 'block')};
`;

const StyledExpandMoreIconButton = styled(ExpandMoreIconButton)`
  color: ${variables.color.content.secondary};
  margin-left: ${spacing.xsmall};
`;

type Props = {
  name: string;
  sectionType: PlanSectionType;
  isCollapsed: boolean;
  timeframe: PlanTimeframe;
  numColumns: number;
  onClickColumn: (column: number) => void;
};

const PlanSectionHeader = ({
  name,
  sectionType,
  isCollapsed,
  timeframe,
  numColumns,
  onClickColumn,
}: Props) => {
  const isV2ThemeEnabled = useIsV2Theme();
  const { getDateForColumn, getAmountTypeForColumn, actions } = usePlanContext();

  const amountTypeSameForAllColumns = useMemo(
    () =>
      R.all(
        (column) => getAmountTypeForColumn(column) === getAmountTypeForColumn(0),
        R.range(0, numColumns),
      ),
    [numColumns, getAmountTypeForColumn],
  );

  const toggleCollapsed = useEventCallback(() => {
    actions.toggleIsSectionCollapsed(sectionType);
  });

  return (
    <Root>
      <V2BackgroundRight />
      <StyledLeftSticky>
        <V2BackgroundLeft />
        <Left>
          {isV2ThemeEnabled && (
            <StyledExpandMoreIconButton expanded={!isCollapsed} onClick={toggleCollapsed} />
          )}
          <Title>{name}</Title>
        </Left>
      </StyledLeftSticky>
      <PlanColumns numColumns={numColumns} getDateForColumn={getDateForColumn}>
        {(column, date) => (
          <>
            {
              // All columns are planned, so we want to put the separator at the beginning
              column === 0 &&
                amountTypeSameForAllColumns &&
                getAmountTypeForColumn(0) === 'budgeted' && <StickyPlanHeaderPastSeparator />
            }
            <PlanGridColumn>
              <ColumnTitle
                onClick={() => onClickColumn(column)}
                clickable={timeframe !== PlanTimeframe.SingleMonth}
              >
                {timeframe === PlanTimeframe.SingleMonth
                  ? getDisplayNameForAmountType(getAmountTypeForColumn(column))
                  : date.toFormat(timeframe === PlanTimeframe.Yearly ? 'y' : 'MMM y')}
              </ColumnTitle>
            </PlanGridColumn>
            {
              // Columns transitioned from actual to budgeted
              ((getAmountTypeForColumn(column) === 'actual' &&
                getAmountTypeForColumn(column + 1) === 'budgeted') ||
                // All columns are actual, so we want to put the separator at the end
                (column === numColumns - 1 &&
                  amountTypeSameForAllColumns &&
                  getAmountTypeForColumn(column) === 'actual')) && <StickyPlanHeaderPastSeparator />
            }
          </>
        )}
      </PlanColumns>
    </Root>
  );
};

export default React.memo(PlanSectionHeader);
