import _ from 'lodash';
import pluralize from 'pluralize';
import type { KeyValuePair } from 'ramda';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import * as Yup from 'yup';

import formatCategory from 'common/lib/categories/formatCategory';
import { ANYONE_ID } from 'common/lib/hooks/household/useHouseholdUsers';
import {
  getAmountLeftToSplit,
  getIsSplitByAbsoluteAmount,
  getSplitsAmountTotal,
  invertAmounts,
} from 'common/lib/transactions/Splits';
import { getDescriptionForReviewStatus } from 'common/lib/transactions/review';
import { formatCurrency } from 'common/utils/Currency';
import { omitDeep } from 'common/utils/Object';
import { includesIgnoreCase } from 'common/utils/String';
import 'common/utils/yupAddons';

import type {
  Maybe,
  PreviewTransactionRuleQuery,
  SplitTransactionsAction,
  SplitTransactionsActionInfoInput,
  SplitTransactionsActionInput,
  TransactionRuleFieldsFragment,
  TransactionRuleFieldsMobileFragment,
  UpdateTransactionRuleInput,
} from 'common/generated/graphql';
import { SplitAmountType } from 'common/generated/graphql';

type PreviewTransactionRule_transactionRulePreview =
  PreviewTransactionRuleQuery['transactionRulePreview'];

export type DefaultRuleFields = Omit<UpdateTransactionRuleInput, 'id'> & { id?: string };

export enum MerchantOption {
  MerchantName = 'Merchant-Name',
  OriginalStatement = 'Original-Statement',
}

export enum StringOperator {
  Equals = 'eq',
  Contains = 'contains',
}

export enum AmountOperator {
  Equals = 'eq',
  GreaterThan = 'gt',
  LessThan = 'lt',
  Between = 'between',
}

export const getDescriptionForStringOperator = (operator: string) => {
  switch (operator) {
    case StringOperator.Equals:
      return 'exactly matches';
    case StringOperator.Contains:
      return 'contains';
    default:
      return '';
  }
};

export const getDescriptionForAmountCriteriaType = (isExpense: boolean) =>
  isExpense ? 'expense' : 'income';

export const getDescriptionForAmountOperator = (operator: string) => {
  switch (operator) {
    case AmountOperator.Equals:
      return 'equals';
    case AmountOperator.GreaterThan:
      return 'greater than';
    case AmountOperator.LessThan:
      return 'less than';
    case AmountOperator.Between:
      return 'between';
  }
  return '';
};

export const splitAmountTypeToString = (amountType: Maybe<string>) => {
  switch (amountType) {
    case SplitAmountType.ABSOLUTE:
      return 'dollar amounts';
    case SplitAmountType.PERCENTAGE:
      return 'percentages';
    default:
      return undefined;
  }
};

export const getDescriptionForSplitTransactionsAmountType = (amountType: SplitAmountType) => {
  const amountTypeLabel = splitAmountTypeToString(amountType);

  if (!amountTypeLabel) {
    return '';
  }

  return `By ${amountTypeLabel}`;
};

export const getTitleForSplitTransactions = (amountType: Maybe<string>) =>
  !amountType ? 'Split transaction' : `Split by ${splitAmountTypeToString(amountType)}`;

export const getDescriptionForSplitTransactions = (split: Maybe<SplitTransactionsActionInput>) => {
  if (!split) {
    return undefined;
  }

  const splitsCount = split.splitsInfo.length;

  return `${splitsCount} split ${pluralize('transaction', splitsCount)}`;
};

export const STRING_OPERATOR_OPTIONS = Object.values(StringOperator).map((value) => ({
  value,
  label: _.capitalize(getDescriptionForStringOperator(value)),
}));

export const AMOUNT_OPERATOR_OPTIONS = Object.values(AmountOperator).map((value) => ({
  value,
  label: _.capitalize(getDescriptionForAmountOperator(value)),
}));

export const MERCHANT_ORIGINAL_STATEMENT_OPTIONS = [
  { value: false, label: 'Merchant name' },
  { value: true, label: 'Original statement' },
];

// The mobile picker doesn't support boolean values, so we use enums
export const MERCHANT_ORIGINAL_STATEMENT_OPTIONS_MOBILE = MERCHANT_ORIGINAL_STATEMENT_OPTIONS.map(
  ({ label, value }) => ({
    label,
    value: value ? MerchantOption.OriginalStatement : MerchantOption.MerchantName,
  }),
);

/** Strips out __typename and lifts some values to match input schema */
export const convertRuleToFormValues = (
  rule: TransactionRuleFieldsMobileFragment | TransactionRuleFieldsFragment,
) =>
  R.pipe(
    omitDeep(['__typename']),
    R.omit([
      'order',
      'categories',
      'accounts',
      'lastAppliedAt',
      'recentApplicationCount',
      'unassignNeedsReviewByUserAction',
    ]),
    R.evolve({
      setMerchantAction: (value) => value?.name,
      setCategoryAction: (value) => value?.id,
      needsReviewByUserAction: (value) =>
        rule?.unassignNeedsReviewByUserAction ? ANYONE_ID : value?.id,
      sendNotificationAction: (enabled) => enabled || undefined,
      setHideFromReportsAction: (enabled) => enabled || undefined,
      addTagsAction: (value) => value?.map(({ id }: { id: string }) => id),
      categoryIds: (value) => (!value?.length ? null : value),
      accountIds: (value) => (!value?.length ? null : value),
      linkGoalAction: (value) => value?.id,
      splitTransactionsAction: (value) => {
        if (!value) {
          return null;
        }

        const { amountType, splitsInfo } = value;

        // Must invert splits on entry if the amount is an expense; users should
        // only see unsigned values in the UI. This keeps parity with splits form.
        let processedSplits = splitsInfo;
        if (amountType === SplitAmountType.ABSOLUTE && rule.amountCriteria?.isExpense) {
          processedSplits = invertAmounts(splitsInfo);
        }

        return {
          amountType,
          splitsInfo: processedSplits,
        };
      },
    }),
    // @ts-ignore we have unit tests for this
  )(rule);

// Nested objects need an initial value of null for validation to work correctly
export const EMPTY_RULE: Partial<DefaultRuleFields> = {
  categoryIds: null,
  accountIds: null,
  merchantCriteria: null,
  amountCriteria: null,
  merchantCriteriaUseOriginalStatement: false,
  addTagsAction: null,
  splitTransactionsAction: null,
};

const CRITERIA_FIELD_NAMES = [
  'merchantCriteria',
  'amountCriteria',
  'categoryIds',
  'accountIds',
] as const;

export const ACTION_FIELD_NAMES = [
  'setMerchantAction',
  'setCategoryAction',
  'sendNotificationAction',
  'setHideFromReportsAction',
  'addTagsAction',
  'reviewStatusAction',
  'linkGoalAction',
  'needsReviewByUserAction',
  'splitTransactionsAction',
] as const;

type CriteriaFieldName = (typeof CRITERIA_FIELD_NAMES)[number];
type ActionFieldName = (typeof ACTION_FIELD_NAMES)[number];
export type RuleFieldName = CriteriaFieldName | ActionFieldName;

export const DEFAULT_SPLIT_INFO: Partial<SplitTransactionsActionInfoInput> = {
  merchantName: undefined,
  categoryId: undefined,
  amount: undefined,
  goalId: undefined,
  hideFromReports: false,
  reviewStatus: undefined,
  needsReviewByUserId: undefined,
  tags: undefined,
};

export const INITIAL_ENABLED_VALUES = {
  merchantCriteria: [
    {
      operator: StringOperator.Contains,
      value: '',
    },
  ],
  amountCriteria: {
    operator: AmountOperator.GreaterThan,
    isExpense: true,
    valueRange: null,
    value: null,
  },
  categoryIds: [],
  accountIds: [],
  setMerchantAction: '',
  setCategoryAction: '',
  sendNotificationAction: true,
  setHideFromReportsAction: true,
  addTagsAction: [],
  reviewStatusAction: '',
  linkGoalAction: '',
  needsReviewByUserAction: '',
  applyToExistingTransactions: false,
  splitTransactionsAction: {
    amountType: SplitAmountType.PERCENTAGE,
    splitsInfo: [],
  },
};

export const validationSchema: Yup.ObjectSchema<any> = Yup.object()
  .shape({
    merchantCriteria: Yup.array()
      .nullable()
      .min(1)
      .of(
        Yup.object().shape({
          operator: Yup.string().required(),
          value: Yup.string().required().label('Merchant Name'),
        }),
      ),
    amountCriteria: Yup.object()
      .nullable()
      .shape({
        operator: Yup.string().required(),
        isExpense: Yup.boolean().required(),
        value: Yup.number().nullable().label('Amount'),
        valueRange: Yup.object()
          .nullable()
          .shape({
            lower: Yup.number().required().label('Amount'),
            upper: Yup.number().required().moreThan(Yup.ref('lower')).label('Amount'),
          }),
      })
      .test('range-check', 'Must include valueRange if operator is between', (value) => {
        if (R.isNil(value)) {
          return true;
        } else if (value.operator === AmountOperator.Between) {
          return !R.isNil(value.valueRange);
        } else {
          return !R.isNil(value.value);
        }
      }),
    categoryIds: Yup.array().nullable().min(1).label('Categories'),
    accountIds: Yup.array().nullable().min(1).label('Accounts'),
    setMerchantAction: Yup.string().notOneOf([''], 'This field is required'),
    linkGoalAction: Yup.string().nullable().notOneOf([''], 'This field is required'),
    setCategoryAction: Yup.string().notOneOf([''], 'This field is required'),
    sendNotificationAction: Yup.boolean(),
    setHideFromReportsAction: Yup.boolean(),
    addTagsAction: Yup.array().nullable().min(1).label('Tags'),
    reviewStatusAction: Yup.string().nullable().notOneOf([''], 'This field is required'),
    needsReviewByUserAction: Yup.string().nullable().notOneOf([''], 'This field is required'),
    splitTransactionsAction: Yup.object()
      .nullable()
      .shape({
        amountType: Yup.string().required().oneOf(Object.values(SplitAmountType)),
        splitsInfo: Yup.array()
          .required()
          .min(2)
          .label('Splits')
          .of(
            Yup.object().shape({
              merchantName: Yup.string().required().label('Split merchant'),
              categoryId: Yup.string().required().label('Split category'),
              amount: Yup.number().required().label('Split amount'),
              goalId: Yup.string().nullable().label('Split goal'),
              hideFromReports: Yup.boolean().nullable().label('Split hide from reports'),
              reviewStatus: Yup.string().nullable().label('Split review status'),
              needsReviewByUserId: Yup.string().nullable().label('Split needs review by'),
              tags: Yup.array().nullable().label('Split tags'),
            }),
          ),
      })
      .test(
        'split-amounts-percentage-limits',
        'Split percentages must be 0% to 100%',
        function validate(splitTransactionsAction: SplitTransactionsAction) {
          if (
            R.isNil(splitTransactionsAction) ||
            splitTransactionsAction.amountType !== SplitAmountType.PERCENTAGE
          ) {
            return true;
          }

          // Need to disable eslint rule because we need to loop through the splits and actually
          // return an error if any of them are invalid. Using a `forEach` would not work here,
          // since the `this` scope changes and any returned error would be dropped.
          // eslint-disable-next-line fp/no-loops, no-restricted-syntax
          for (let i = 0; i < splitTransactionsAction.splitsInfo.length; i += 1) {
            const splitInfo = splitTransactionsAction.splitsInfo[i];
            if (splitInfo.amount < 0 || splitInfo.amount > 1) {
              return this.createError({ path: `splitTransactionsAction.splitsInfo[${i}].amount` });
            }
          }

          return true;
        },
      )
      .test(
        'split-amounts-percentage-total',
        'Split percentages must add up to 100%',
        (splitTransactionsAction: SplitTransactionsAction) => {
          if (
            R.isNil(splitTransactionsAction) ||
            splitTransactionsAction.amountType !== SplitAmountType.PERCENTAGE
          ) {
            return true;
          }

          const totalPercentage = getSplitsAmountTotal(splitTransactionsAction.splitsInfo);
          return Math.abs(totalPercentage - 1) < Number.EPSILON;
        },
      ),
  })
  .test(
    'split-amounts-absolute',
    'Split amounts must add up to amount criteria',
    // Because of https://github.com/jaredpalmer/formik/issues/2146, top level tests need to create
    // an error with a field path that exists within the form, rather than simply returning false.
    function validate(this: Yup.TestContext, values: UpdateTransactionRuleInput) {
      const { amountCriteria, splitTransactionsAction } = values;

      if (
        R.isNil(amountCriteria) ||
        R.isNil(splitTransactionsAction) ||
        splitTransactionsAction.amountType !== SplitAmountType.ABSOLUTE
      ) {
        return true;
      }

      if (amountCriteria.operator !== AmountOperator.Equals) {
        return this.createError({
          path: 'splitTransactionsAction',
          message: "Splits using absolute amount must use amount criteria operator 'Equals'",
        });
      }

      // May eventually need to invert amount based on amountCriteria.isExpense
      const totalAmount = amountCriteria.value ?? 0;
      const amountLeftToSplit = getAmountLeftToSplit(
        splitTransactionsAction.splitsInfo,
        totalAmount,
      );

      if (amountLeftToSplit >= Number.EPSILON) {
        return this.createError({ path: 'splitTransactionsAction' });
      }

      return true;
    },
  )
  .requireOneOf(CRITERIA_FIELD_NAMES as unknown as string[], 'criteria')
  .requireOneOf(ACTION_FIELD_NAMES as unknown as string[], 'actions');

export const getSortedRules = R.sortBy(R.prop('order'));

export const getFilteredRulesForSearch = <
  T extends TransactionRuleFieldsMobileFragment | TransactionRuleFieldsFragment,
>(
  search: string,
) =>
  R.filter<T>(
    ({
      merchantCriteria,
      amountCriteria,
      categories,
      accounts,
      setMerchantAction,
      setCategoryAction,
    }) => {
      const searchFields = [
        ...(merchantCriteria?.map(R.prop('value')) ?? []),
        amountCriteria?.value,
        ...(categories?.map(R.prop('name')) ?? []),
        ...(accounts?.map(R.prop('displayName')) ?? []),
        setMerchantAction?.name,
        setCategoryAction?.name,
        setCategoryAction?.icon,
      ];
      return R.any(
        (field) => (field ? includesIgnoreCase(`${field}`, search) : false),
        searchFields,
      );
    },
  );

export const getDescriptionForAmountCriteria = ({
  isExpense,
  operator,
  valueRange,
  value,
}: {
  isExpense: boolean;
  operator: string;
  value?: number | null | undefined;
  valueRange?:
    | null
    | {
        upper: number | null | undefined;
        lower: number | null | undefined;
      }
    | undefined;
}) =>
  `${getDescriptionForAmountCriteriaType(isExpense)} ${getDescriptionForAmountOperator(operator)} ${
    !R.isNil(valueRange)
      ? `${formatCurrency(valueRange.lower ?? 0)} and ${formatCurrency(valueRange.upper ?? 0)}`
      : formatCurrency(value ?? 0)
  }`;

export const getDescriptionForMerchantCriteria = (
  criteria:
    | (
        | {
            operator: string;
            value: string;
          }
        | null
        | undefined
      )[] // TODO: fix this type, `null` should not be allowed in the array
    | null
    | undefined,
  useOriginalStatement: boolean | null | undefined,
) =>
  (criteria ?? [])
    .filter(RA.isNotNil)
    .map(({ operator, value }, index) =>
      [
        formatMerchantCriteriaFirstPart(!!useOriginalStatement, index === 0),
        getDescriptionForStringOperator(operator),
        value ? `"${value.trim()}"` : undefined,
      ]
        .filter(Boolean)
        .join(' '),
    )
    .join(' or ');

const formatMerchantCriteriaFirstPart = (useOriginalStatement: boolean, isFirst: boolean) => {
  if (!isFirst) {
    return undefined;
  }
  return useOriginalStatement ? 'original statement' : 'name';
};

export const getDescriptionForCategoryCriteria = (categories: { icon: string; name: string }[]) =>
  categories.map(formatCategory).join(', ');

export const getDescriptionForAccountCriteria = (accounts: { displayName: string }[]) =>
  accounts.map(R.prop('displayName')).join(', ');

export const getEmptyListMessage = (
  data: PreviewTransactionRule_transactionRulePreview | undefined,
  applyToExisting: boolean,
) => {
  if (!applyToExisting) {
    return 'This rule will not be applied to existing transactions.';
  } else if (!data) {
    return 'Modify rule settings to see how it will affect your existing transactions.';
  } else if (data?.totalCount === 0) {
    return 'This rule will not change any existing transactions.';
  }
};

type Category = {
  name: string;
  icon: string;
};

type Goal = {
  name: string;
};

export type Tag = {
  id: string;
  name: string;
};

type FormValues = {
  [key in (typeof ACTION_FIELD_NAMES)[number]]: string | boolean | unknown[] | undefined;
};

const FIELD_TO_LABEL: Record<keyof FormValues, string> = {
  setMerchantAction: 'Merchant',
  setCategoryAction: 'Category',
  linkGoalAction: 'Goal',
  addTagsAction: 'Tags',
  reviewStatusAction: 'Review status',
  setHideFromReportsAction: 'Hide from reports',
  sendNotificationAction: 'Send notification',
  needsReviewByUserAction: 'Review by',
  splitTransactionsAction: 'Split transaction',
};

const formFieldToDisplay = <TCategory extends Category, TTag extends Tag, TGoal extends Goal>(
  key: keyof FormValues,
  val: string | boolean | unknown[] | undefined,
  category: TCategory | undefined,
  tags: TTag[] | undefined,
  goal: TGoal | undefined,
  users: { id: string; name: string }[],
): KeyValuePair<string, typeof val> => {
  const label = FIELD_TO_LABEL[key];
  let value = val;

  if (key === 'setCategoryAction') {
    value = category ? `${category.icon} ${category.name}` : 'Loading...';
  } else if (key === 'setHideFromReportsAction') {
    value = val;
  } else if (key === 'addTagsAction') {
    value = tags ? R.map(R.prop('id'), tags) : 'Loading...';
  } else if (key === 'reviewStatusAction') {
    value = getDescriptionForReviewStatus(String(val));
  } else if (key === 'linkGoalAction') {
    value = goal?.name ?? (val === null ? 'Remove goal' : val);
  } else if (key === 'setMerchantAction') {
    value = val;
  } else if (key === 'sendNotificationAction') {
    value = val;
  } else if (key === 'needsReviewByUserAction') {
    if (val === ANYONE_ID) {
      value = 'Anyone';
    } else {
      value = val ? users.find(R.propEq('id', val))?.name : '';
    }
  }

  return R.pair(label, value);
};

export const getValidActionValues = (values: FormValues): FormValues =>
  R.pickBy(
    (val, key) =>
      val !== '' &&
      RA.isNotUndefined(val) && // null means the field should be cleared so we can't use isNotNil
      ACTION_FIELD_NAMES.includes(key) && // filter out the criteria fields since they aren't displayed in the confirmation modal
      key !== 'splitTransactionsAction', // this field should not be displayed in the confirmation modal
    values,
  );

export const confirmationModalValuesAdapter = <
  TCategory extends Category,
  TTag extends Tag,
  TGoal extends Goal,
>(
  values: FormValues,
  category: TCategory | undefined,
  tags: TTag[] | undefined,
  goal: TGoal | undefined,
  users: { id: string; name: string }[],
) => {
  const pairs = R.pipe(
    getValidActionValues,
    R.toPairs,
    R.map(([key, val]) =>
      formFieldToDisplay(key as keyof FormValues, val, category, tags, goal, users),
    ),
    R.filter(([, val]) => RA.isNotEmpty(val)),
    R.sortBy(R.prop('key')),
  )(values);
  return R.fromPairs(pairs);
};

export const transformRuleFormValuesBeforeSubmit = (
  values: Omit<UpdateTransactionRuleInput, 'id'>,
) => {
  // A deep copy is made to avoid modifying the original form object,
  // which might otherwise cause visual side effects, e.g., flipping the
  // amount field sign and animating the amount left number during the query.
  const transformedValues = R.clone(values);

  // API expects null (not 'anyone') for the user when anyone is allowed to review
  transformedValues.needsReviewByUserAction =
    transformedValues.needsReviewByUserAction === 'anyone'
      ? null
      : transformedValues.needsReviewByUserAction;

  // Inverting the amounts is necessary because the backend expects the
  // amounts to be negative for expense splits, even though we visually
  // show the numbers as unsigned.
  if (
    transformedValues.amountCriteria?.isExpense &&
    !R.isNil(transformedValues.splitTransactionsAction) &&
    getIsSplitByAbsoluteAmount(transformedValues.splitTransactionsAction.amountType ?? '')
  ) {
    transformedValues.splitTransactionsAction.splitsInfo = invertAmounts(
      transformedValues.splitTransactionsAction.splitsInfo,
    );
  }

  return transformedValues;
};
