import * as React from 'react';
import styled from 'styled-components';

import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';

import { formatCurrency } from 'common/utils/Currency';
import { formatThousands } from 'common/utils/Number';

import type { Web_GetReferralSettingsQuery } from 'common/generated/graphql';

const Root = styled(FlexContainer).attrs({ justifyBetween: true, alignCenter: true })`
  margin: ${({ theme }) => theme.spacing.default} ${({ theme }) => theme.spacing.xlarge};
  gap: ${({ theme }) => theme.spacing.default};

  @media (max-width: ${({ theme }) => theme.breakPoints.sm}px) {
    flex-flow: column nowrap;
  }
`;

const Box = styled.div`
  background-color: ${({ theme }) => theme.color.grayBackground};
  border-radius: ${({ theme }) => theme.radius.medium};
  padding: ${({ theme }) => theme.spacing.xlarge};
  text-align: center;
  flex-grow: 1;
  width: 100%;
`;

const Label = styled(Text).attrs({ weight: 'medium', size: 'xsmall', color: 'textLight' })`
  display: block;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  margin-top: ${({ theme }) => theme.spacing.xsmall};
`;

type Props = {
  statistics: Web_GetReferralSettingsQuery['referralStatistics'];
};

const ReferralTracker = ({ statistics }: Props) => (
  <Root>
    <Box>
      <Text size="xxlarge" weight="medium">
        {formatThousands(statistics.signedUp)}
      </Text>
      <Label>Signed up</Label>
    </Box>
    <Box>
      <Text size="xxlarge" weight="medium">
        {formatThousands(statistics.subscribed)}
      </Text>
      <Label>Subscribed</Label>
    </Box>
    <Box>
      <Text size="xxlarge" weight="medium" color="greenText">
        {formatCurrency(statistics.creditsEarned)}
      </Text>
      <Label>Credits earned</Label>
    </Box>
  </Root>
);

export default ReferralTracker;
