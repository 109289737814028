import { useQuery } from '@apollo/client';

import { gql } from 'common/generated/gql';
import type { TransactionFilterInput } from 'common/generated/graphql';

/**
 * Fetches metadata for entities used in transaction filters, such as categories, accounts, merchants, tags, and goals.
 */
const useTransactionFiltersMetadata = (filters: TransactionFilterInput) => {
  const { data } = useQuery(GET_TRANSACTION_FILTERS_METADATA, { variables: { input: filters } });
  return { transactionFiltersMetadata: data?.transactionFiltersMetadata };
};

const GET_TRANSACTION_FILTERS_METADATA = gql(/* GraphQL */ `
  query Web_GetTransactionFiltersMetadata($input: TransactionFilterInput!) {
    transactionFiltersMetadata(input: $input) {
      categories {
        id
        name
        icon
      }
      categoryGroups {
        id
        name
      }
      accounts {
        id
        displayName
        logoUrl
        icon
      }
      merchants {
        id
        name
        logoUrl
      }
      tags {
        id
        name
        color
      }
      goal {
        id
        name
        imageStorageProvider
        imageStorageProviderId
      }
      needsReviewByUser {
        id
        name
      }
    }
  }
`);

export default useTransactionFiltersMetadata;
