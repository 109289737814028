import React from 'react';
import styled from 'styled-components';

import ChartTooltip from 'components/lib/charts/ChartTooltip';
import ChartTooltipDataRow from 'components/lib/charts/ChartTooltipDataRow';

type Props = {
  title: string;
  active: boolean;
  allocationPercent: number;
  label: string;
  color: string;
};

const Header = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  text-align: left;
`;

const InvestmentsAllocationChartTooltip = ({
  allocationPercent,
  title,
  active,
  label,
  color,
}: Props) => (
  <ChartTooltip active={active} header={<Header>{title}</Header>}>
    <ChartTooltipDataRow label={label} value={`${allocationPercent}%`} dotFillColor={color} />
  </ChartTooltip>
);

export default InvestmentsAllocationChartTooltip;
