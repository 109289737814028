import type { Color } from 'common/types/Styles';

export type TransactionType = 'income' | 'expense';

const BAR_COLORS: Record<TransactionType, Color> = {
  income: 'green',
  expense: 'red',
};

const getInvertedType = (type: TransactionType): TransactionType => {
  if (type === 'income') {
    return 'expense';
  } else if (type === 'expense') {
    return 'income';
  }

  throw new Error(`Invalid transaction type: ${type}`);
};

/** Returns the color name (not hex code) of the bar according to transaction type. **/
export const getBarColorName = <T extends TransactionType>(type: T, invert = false) =>
  BAR_COLORS[invert ? getInvertedType(type) : type];

/**
 * Merchants don't have an explicit income or expense type like categories do, so to determine
 * which direction the bar charts should go we infer it from the transaction data.
 *
 * If the total sum of all transactions is negative or zero, we assume the merchant is an expense.
 * If the total sum of all transactions is positive, we assume the merchant is an income.
 */
export const inferTransactionTypeFromSummaryData = <T extends { summary: { sum: number } }>(
  data: T[],
): TransactionType => {
  const total = data.reduce((acc, { summary }) => acc + summary.sum, 0);
  return total > 0 ? 'income' : 'expense';
};
