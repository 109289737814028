import { DateTime } from 'luxon';
import type { KeyValuePair } from 'ramda';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

import type { BulkUpdateState } from 'common/lib/hooks/transactions/useBulkUpdateTransactionState';
import { getDescriptionForReviewStatus } from 'common/lib/transactions/review';

import type { BulkTransactionDataParams } from 'common/generated/graphql';

const MAX_ACCOUNTS_LOGOS_TO_SHOW = 3;

type Category = {
  name: string;
  icon: string;
};

type Goal = {
  name: string;
};

export enum RecurringStatus {
  Recurring = 'Recurring',
  NotRecurring = 'Not-Recurring',
}

export type Tag = {
  id: string;
  name: string;
};

export type TagConfirmationLabel = {
  name: string;
  isRemoved: boolean;
};

export type FormValues = {
  date: string | undefined;
  notes: string | undefined;
  merchantName: string | undefined;
  categoryId: string | undefined;
  goalId: string | undefined;
  hide: boolean;
  tags: string[] | undefined;
  reviewStatus: string;
  isRecurring: boolean | undefined;
  needsReviewByUserId: string | undefined;
};

export type ConfirmationData = Partial<{
  Date: string;
  Notes: string;
  Merchant: string;
  Category: string;
  Goal: string;
  Hide: string;
  Tags: string[];
  Recurring: string;
  'Review status': string;
  'Needs review by': string;
}>;

export const HIDE_SELECT_OPTIONS = [
  { value: true, label: 'Hide' },
  { value: false, label: 'Show' },
];

export const RECURRING_SELECT_OPTIONS = [
  { value: true, label: 'Mark as recurring' },
  { value: false, label: 'Mark transaction as not recurring' },
];

// The mobile picker doesn't support boolean values, so we use enums
export const RECURRING_SELECT_OPTIONS_MOBILE = RECURRING_SELECT_OPTIONS.map(({ label, value }) => ({
  label,
  value: value ? RecurringStatus.Recurring : RecurringStatus.NotRecurring,
}));

const FIELD_TO_LABEL: Record<keyof FormValues, string> = {
  merchantName: 'Merchant',
  categoryId: 'Category',
  goalId: 'Goal',
  date: 'Date',
  hide: 'Hide',
  notes: 'Notes',
  tags: 'Tags',
  reviewStatus: 'Review status',
  needsReviewByUserId: 'Needs review by',
  isRecurring: 'Recurring',
};

export const getValidValues = (values: FormValues): FormValues =>
  // null means the field should be cleared so we can't use isNotNil
  R.pickBy((val) => RA.isNotUndefined(val) && val !== '', values);

export const validateForm = (values: FormValues) => {
  const valid = getValidValues(values);
  return R.keys(valid).length <= 0 ? { error: 'No values' } : undefined;
};

const formFieldToDisplay = <TCategory extends Category, TTag extends Tag, TGoal extends Goal>(
  key: keyof FormValues,
  val: string | boolean | unknown[] | undefined,
  category: TCategory | undefined,
  tags: TTag[] | undefined,
  goal: TGoal | undefined,
  users: { id: string; name: string }[],
): KeyValuePair<string, typeof val> => {
  const label = FIELD_TO_LABEL[key];
  let value = val;

  if (key === 'categoryId') {
    value = category ? `${category.icon} ${category.name}` : 'Loading...';
  } else if (key === 'date') {
    value = DateTime.fromISO(String(val)).toFormat('MMMM d, yyyy');
  } else if (key === 'hide') {
    value = HIDE_SELECT_OPTIONS.find(({ value }) => val === value)?.label;
  } else if (key === 'tags') {
    value = tags ? R.map(R.prop('id'), tags) : 'Loading...';
  } else if (key === 'notes') {
    value = val === null ? 'Remove notes' : val;
  } else if (key === 'reviewStatus') {
    value = getDescriptionForReviewStatus(String(val));
  } else if (key === 'goalId') {
    value = goal?.name ?? (val === null ? 'Remove goal' : val);
  } else if (key === 'isRecurring') {
    value = RECURRING_SELECT_OPTIONS.find(({ value }) => val === value)?.label;
  } else if (key === 'needsReviewByUserId') {
    const user = users.find(({ id }) => id === val);
    value = val === 'anyone' ? 'Unassign review' : user?.name ?? 'Loading...';
  }

  return R.pair(label, value);
};

export const confirmationModalValuesAdapter = <
  TCategory extends Category,
  TTag extends Tag,
  TGoal extends Goal,
>(
  values: FormValues,
  category: TCategory | undefined,
  tags: TTag[] | undefined,
  goal: TGoal | undefined,
  users: { id: string; name: string }[],
): ConfirmationData => {
  const pairs = R.pipe(
    getValidValues,
    R.toPairs,
    R.map(([key, val]) =>
      formFieldToDisplay(key as keyof FormValues, val, category, tags, goal, users),
    ),
    R.filter(([, val]) => RA.isNotEmpty(val)),
    R.sortBy(R.prop('key')),
  )(values);
  return R.fromPairs(pairs);
};

export const getAccountsFromTransactionsOverflow = (totalCount: number) => {
  const overflow =
    totalCount > MAX_ACCOUNTS_LOGOS_TO_SHOW ? totalCount - MAX_ACCOUNTS_LOGOS_TO_SHOW : 0;
  return [MAX_ACCOUNTS_LOGOS_TO_SHOW, overflow] as const;
};

export const transactionTagsAdapter = (
  tags: string[] | undefined = [],
  initial: string[] | undefined = [],
) => {
  const added = R.difference(tags, initial);
  const removed = R.without(tags, initial);
  return [added, removed] as const;
};

export const isTagsField = (label: string, value: unknown): value is string[] => label === 'Tags';

export const tagConfirmationLabelsAdapter = <TTag extends Tag>(
  tags: TTag[],
  removedIds: string[],
) => tags.map(({ id, name }) => ({ name, isRemoved: removedIds.includes(id) }));

/**
 * Transforms the bulk update state into the format expected by any mutation which uses
 * BulkTransactionDataParams.
 */
export const convertBulkUpdateStateToMutationInput = ({
  selectedTransactions,
  excludedTransactions,
  isAllSelected,
}: Partial<BulkUpdateState> | undefined = {}): Omit<BulkTransactionDataParams, 'filters'> => ({
  isAllSelected,
  selectedTransactionIds: selectedTransactions,
  excludedTransactionIds: excludedTransactions,
  expectedAffectedTransactionCount: undefined,
});
