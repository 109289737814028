import { useMutation } from '@apollo/client';
import { isNotNil } from 'ramda-adjunct';
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import CurrencyField from 'components/lib/form/CurrencyField';
import FieldCellWithToggle from 'components/lib/form/FieldCellWithToggle';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import SelectField from 'components/lib/form/SelectField';
import CardFooter from 'components/lib/ui/CardFooter';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Link from 'components/lib/ui/Link';
import LoadingSpinnerContainer from 'components/lib/ui/LoadingSpinnerContainer';
import ModalCard from 'components/lib/ui/ModalCard';
import Text from 'components/lib/ui/Text';
import DefaultButton from 'components/lib/ui/button/DefaultButton';

import { getRolloverStartMonthOptions } from 'common/lib/budget/Rollovers';
import useQuery from 'common/lib/hooks/useQuery';
import { getStartOfCurrentMonthISO } from 'common/utils/date';
import { usePlanContext } from 'lib/contexts/PlanContext';

import { BUDGET } from 'common/constants/copy';
import { HELP_CENTER_ROLLOVER_ARTICLE_URL } from 'common/constants/externalUrls';

import { gql } from 'common/generated';

const Root = styled(FlexContainer)`
  padding: ${({ theme }) => theme.spacing.xlarge};
  padding-top: 0;
`;

type Props = {
  onClose: () => void;
};

const EditFlexibleGroupModalCard = ({ onClose }: Props) => {
  const { refetch } = usePlanContext();
  const rolloverStartMonthOptions = useMemo(() => getRolloverStartMonthOptions(), []);

  const startOfCurrentMonth = useMemo(() => getStartOfCurrentMonthISO(), []);
  const { data, isLoadingInitialData } = useQuery(QUERY, {
    fetchPolicy: 'cache-first',
  });
  const { budgetSystem, flexExpenseRolloverPeriod } = data ?? {};
  const { startMonth, startingBalance } = flexExpenseRolloverPeriod ?? {};

  const [updateRolloverSettings, { loading: isUpdatingRolloverSettings }] = useMutation(MUTATION);

  const initialValues = useMemo(
    () => ({
      rolloverEnabled: isNotNil(flexExpenseRolloverPeriod),
      rolloverStartMonth: startMonth ?? startOfCurrentMonth,
      rolloverStartingBalance: startingBalance ?? 0,
    }),
    [flexExpenseRolloverPeriod, startMonth, startingBalance, startOfCurrentMonth],
  );

  const handleSubmit = useCallback(
    async (values: typeof initialValues) => {
      await updateRolloverSettings({
        variables: {
          // @ts-ignore Schema change breaks this, but it should be fixed later.
          input: {
            budgetSystem,
            budgetApplyToFutureMonthsDefault: undefined, // this is required but passing undefined will not update the value
            rolloverEnabled: values.rolloverEnabled,
            rolloverStartMonth: values.rolloverStartMonth,
            rolloverStartingBalance: values.rolloverStartingBalance,
          },
        },
        updateQueries: {
          // Update the cache manually since the object names are different (flexExpenseRolloverPeriod != budgetRolloverPeriod)
          Web_GetFlexibleGroupRolloverSettings: (prev, { mutationResult }) => ({
            ...prev,
            flexExpenseRolloverPeriod:
              mutationResult.data?.updateBudgetSettings?.budgetRolloverPeriod,
          }),
        },
        onCompleted: () => {
          // Refetch all planning data
          refetch();
          onClose();
        },
      });
    },
    [budgetSystem, updateRolloverSettings, onClose, refetch],
  );

  return (
    <ModalCard title="Flexible budget" hideBottomBorder>
      {isLoadingInitialData ? (
        <LoadingSpinnerContainer padding="xxxxlarge" />
      ) : (
        <Form initialValues={initialValues} onSubmit={handleSubmit} preventSubmitWhileSubmitting>
          <Root>
            <FieldCellWithToggle
              toggleFieldProps={{ name: 'rolloverEnabled' }}
              fieldCellProps={{
                title: BUDGET.ROLLOVER.TOGGLE.LABEL,
                subtitle: (
                  <Text>
                    {BUDGET.ROLLOVER.TOGGLE.DESCRIPTION}{' '}
                    <Link href={HELP_CENTER_ROLLOVER_ARTICLE_URL} target="_blank">
                      Learn more
                    </Link>
                    .
                  </Text>
                ),
              }}
            >
              <SelectField
                name="rolloverStartMonth"
                label={BUDGET.ROLLOVER.STARTING_MONTH.LABEL}
                description={BUDGET.ROLLOVER.STARTING_MONTH.DESCRIPTION}
                options={rolloverStartMonthOptions}
              />

              <CurrencyField
                name="rolloverStartingBalance"
                label={BUDGET.ROLLOVER.STARTING_BALANCE.LABEL}
                description={BUDGET.ROLLOVER.STARTING_BALANCE.DESCRIPTION}
                placeholder="$0.00"
                maskOptions={{
                  prefix: '$',
                  allowDecimal: true,
                  decimalLimit: 2,
                  allowNegative: false,
                }}
              />
            </FieldCellWithToggle>
          </Root>
          <CardFooter>
            <DefaultButton onClick={onClose}>Cancel</DefaultButton>
            <FormSubmitButton size="small" pending={isUpdatingRolloverSettings}>
              Save
            </FormSubmitButton>
          </CardFooter>
        </Form>
      )}
    </ModalCard>
  );
};

const QUERY = gql(/* GraphQL */ `
  query Web_GetFlexibleGroupRolloverSettings {
    budgetSystem
    flexExpenseRolloverPeriod {
      id
      startMonth
      startingBalance
    }
  }
`);

const MUTATION = gql(/* GraphQL */ `
  mutation Web_UpdateFlexibleGroupRolloverSettings($input: UpdateBudgetSettingsMutationInput!) {
    updateBudgetSettings(input: $input) {
      budgetRolloverPeriod {
        id
        startMonth
        startingBalance
      }
    }
  }
`);

export default EditFlexibleGroupModalCard;
