import type { DurationLikeObject } from 'luxon';
import { DateTime, Duration } from 'luxon';
import * as R from 'ramda';

import isV2Theme from 'common/lib/theme/isV2Theme';

import {
  EQUAL_AMOUNT_INDICATOR_ICON,
  NEGATIVE_AMOUNT_INDICATOR_ICON,
  POSITIVE_AMOUNT_INDICATOR_ICON,
} from 'common/constants/amounts';

import type { ThemeType } from 'common/types/Styles';

export type DateRange = {
  display: string;
  longDisplay: string;
  longDisplaySingular?: string;
  duration: DurationLikeObject;
};

const getYtdDuration = () => ({
  days: Math.abs(DateTime.local().startOf('year').diffNow('days').days),
});

export const getDateRanges = (): DateRange[] => [
  { display: '1M', longDisplay: '1 month', duration: { months: 1 } },
  {
    display: '3M',
    longDisplay: '3 months',
    duration: { months: 3 },
    longDisplaySingular: '3 month',
  },
  {
    display: '6M',
    longDisplay: '6 months',
    duration: { months: 6 },
    longDisplaySingular: '6 month',
  },
  {
    display: 'YTD',
    longDisplay: 'Year to date',
    duration: getYtdDuration(),
  },
  { display: '1Y', longDisplay: '1 year', duration: { years: 1 } },
  { display: 'ALL', longDisplay: 'All time', duration: { years: 150 } },
];

export const getBreakdownDateRanges = (): DateRange[] => [
  { display: '1M', longDisplay: 'Past Month', duration: { months: 1 } },
  { display: '3M', longDisplay: 'Past 3 Months', duration: { months: 3 } },
  { display: '6M', longDisplay: 'Past 6 Months', duration: { months: 6 } },
  {
    display: 'YTD',
    longDisplay: 'Year to Date',
    duration: getYtdDuration(),
  },
  { display: '1Y', longDisplay: 'Past Year', duration: { years: 1 } },
  { display: 'ALL', longDisplay: 'All Time', duration: { years: 150 } },
];

export const getDateRangesForChartType = (chartType: string) =>
  chartType === 'breakdown' ? getBreakdownDateRanges() : getDateRanges();

export const NET_WORTH_CHART_TYPES = ['performance', 'breakdown'] as const;

export type NetWorthChartType = (typeof NET_WORTH_CHART_TYPES)[number];

export const getSnapshotDataForTimePeriod = <T extends SnapshotEntry>(
  duration: DurationLikeObject,
  data: T[],
  offset = 0,
): { data: T[]; hasOlderData: boolean } => {
  const oldestDate = R.reduce(
    R.minBy((date: DateTime) => date.valueOf()),
    DateTime.local(),
    data.map(({ date }) => DateTime.fromISO(date)),
  );

  const startDate = DateTime.max(
    DateTime.local().minus(
      // multiply duration by offset
      Duration.fromObject(duration).mapUnits((value) => value * (offset + 1)),
    ),
    oldestDate,
  ).startOf('day');

  const endDate = startDate.plus(duration);

  const dateIsInTimeFrame = (dateISO: string) =>
    startDate <= DateTime.fromISO(dateISO) && DateTime.fromISO(dateISO) <= endDate;

  return {
    hasOlderData: oldestDate < startDate,
    data: data.filter(({ date }) => dateIsInTimeFrame(date)),
  };
};

export type SnapshotEntry = {
  balance: number;
  date: string;
  assetsBalance?: number | null;
  liabilitiesBalance?: number | null;
};

/**
 * Only show the longest date range choice that has an affect on the data. I.e. if we only have
 * 1 week of data, don't give users the option to specify 1 month etc.
 *
 * We do include a timeframe if we have data in between it and the previous timeframe
 * i.e. if we have 2 months of data, we will include 1 Week, 1 Month, 3 Months even though we don't
 * have the full 3 months. See earliestDateInBounds
 *
 * We also exclude recent timeframes that don't have data associated with them. For example, if our
 * latest data point is 12 days ago, we exclude the 1 Month time frame. See latestDateInBounds
 *
 * We always include the ALL option if users want a complete view of their data.
 */
export const getAvailableTimePeriodsForDateRanges =
  <T extends DateRange>(dateRanges: T[]) =>
  (data: SnapshotEntry[]) => {
    const now = DateTime.local();
    if (!(data.length > 0)) {
      return dateRanges.filter(({ display }) => display === 'ALL');
    }
    const [earliestDate, latestDate] = [data?.[0], data?.[data.length - 1]].map(({ date }) =>
      DateTime.fromISO(date),
    );

    const earliestDateInBounds = (prevDuration: DurationLikeObject) =>
      earliestDate < now.minus(prevDuration);
    const latestDateInBounds = (duration: DurationLikeObject) => latestDate > now.minus(duration);

    return dateRanges.filter(
      ({ display, duration }, i) =>
        (earliestDateInBounds(dateRanges[Math.max(0, i - 1)].duration) &&
          latestDateInBounds(duration)) ||
        display === 'ALL',
    );
  };

type GetTrendIndicatorLineColorArgs = {
  isAsset?: boolean;
  balance: number;
  theme: ThemeType;
};

export const getTrendIndicatorLineColor = ({
  isAsset,
  balance,
  theme,
}: GetTrendIndicatorLineColorArgs) => {
  if (balance === 0) {
    return theme.color.grayDark;
  }

  const negativeColor = isV2Theme(theme.color.redText, theme.color.red)({ theme });
  const positiveColor = isV2Theme(theme.color.greenText, theme.color.green)({ theme });

  if (isAsset === undefined) {
    return balance < 0 ? negativeColor : positiveColor;
  }

  if (isAsset) {
    return balance >= 0 ? positiveColor : negativeColor;
  } else {
    return balance <= 0 ? positiveColor : negativeColor;
  }
};

export const getTrendIndicatorIconGivenChange = (change: number, theme: ThemeType) => {
  if (change === 0) {
    return EQUAL_AMOUNT_INDICATOR_ICON({ theme });
  }

  return change < 0
    ? NEGATIVE_AMOUNT_INDICATOR_ICON({ theme })
    : POSITIVE_AMOUNT_INDICATOR_ICON({ theme });
};

export const getSnapshotDataForSnapshotGraph = <T extends SnapshotEntry[]>(
  signedSnapshots: T,
  isAsset: boolean,
) => {
  const snapshots = signedSnapshots.map(
    R.evolve({
      // invert display balance for liabilities
      balance: R.multiply(isAsset ? 1 : -1),
      // invert total liabilities balance
      liabilitiesBalance: (l) => R.multiply(-1, l),
    }),
  );
  return {
    snapshots,
    displayBalance: R.last(snapshots)?.balance ?? 0.0,
  };
};
