import { useMutation, useQuery } from '@apollo/client';
import { useCallback } from 'react';

import { track } from 'lib/analytics/segment';

import { ReportsEventNames } from 'common/constants/analytics';

import { gql } from 'common/generated/gql';
import type { ReportConfiguration, TransactionFilterInput } from 'common/generated/graphql';

/**
 * Performs operations related to report configurations, such as fetching, creating, and deleting them.
 */
const useReportConfigurations = () => {
  const { data, refetch } = useQuery(GET_REPORT_CONFIGURATIONS);
  const reportConfigurations = (data?.reportConfigurations ?? []) as ReportConfiguration[];

  const [createReportConfigurationMutation] = useMutation(CREATE_REPORT_CONFIGURATION);
  const [updateReportConfigurationMutation] = useMutation(UPDATE_REPORT_CONFIGURATION);
  const [deleteReportConfigurationMutation] = useMutation(DELETE_REPORT_CONFIGURATION);

  const createReportConfiguration = useCallback(
    async (name: string, filters: TransactionFilterInput) => {
      await createReportConfigurationMutation({
        variables: {
          input: {
            displayName: name,
            transactionFilters: filters,
          },
        },
      });

      track(ReportsEventNames.ReportConfigurationCreated);

      refetch();
    },
    [createReportConfigurationMutation, refetch],
  );

  const updateReportConfiguration = useCallback(
    async (id: string, displayName: string) => {
      await updateReportConfigurationMutation({
        variables: { input: { id, displayName } },
      });

      track(ReportsEventNames.ReportConfigurationUpdated);

      refetch();
    },
    [updateReportConfigurationMutation, refetch],
  );

  const deleteReportConfiguration = useCallback(
    async (id: string) => {
      await deleteReportConfigurationMutation({
        variables: { id },
      });

      track(ReportsEventNames.ReportConfigurationDeleted);

      refetch();
    },
    [deleteReportConfigurationMutation, refetch],
  );

  return {
    reportConfigurations,
    createReportConfiguration,
    updateReportConfiguration,
    deleteReportConfiguration,
  };
};

const GET_REPORT_CONFIGURATIONS = gql(/* GraphQL */ `
  query Web_GetReportConfigurations {
    reportConfigurations {
      ...ReportConfigurationFields
    }
  }
`);

const CREATE_REPORT_CONFIGURATION = gql(/* GraphQL */ `
  mutation Web_CreateReportConfiguration($input: CreateReportConfigurationInput!) {
    createReportConfiguration(input: $input) {
      reportConfiguration {
        ...ReportConfigurationFields
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);

const UPDATE_REPORT_CONFIGURATION = gql(/* GraphQL */ `
  mutation Web_UpdateReportConfiguration($input: UpdateReportConfigurationInput!) {
    updateReportConfiguration(input: $input) {
      reportConfiguration {
        ...ReportConfigurationFields
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);

const DELETE_REPORT_CONFIGURATION = gql(/* GraphQL */ `
  mutation Web_DeleteReportConfiguration($id: ID!) {
    deleteReportConfiguration(id: $id) {
      deleted
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);

export const REPORT_CONFIGURATION_FIELDS = gql(/* GraphQL */ `
  fragment ReportConfigurationFields on ReportConfiguration {
    id
    displayName
    transactionFilterSet {
      ...TransactionFilterSetFields
    }
  }
`);

export const TRANSACTION_FILTER_SET_FIELDS = gql(/* GraphQL */ `
  fragment TransactionFilterSetFields on TransactionFilterSet {
    id
    displayName
    categories {
      id
      name
      icon
    }
    categoryGroups {
      id
      name
      type
    }
    accounts {
      id
      displayName
      logoUrl
      icon
    }
    merchants {
      id
      name
      logoUrl
    }
    tags {
      id
      name
      color
    }
    goal {
      id
      name
      imageStorageProvider
      imageStorageProviderId
    }
    searchQuery
    systemCategories
    systemCategoryGroups
    categoryType
    isUncategorized
    budgetVariability
    isFlexSpending
    startDate
    endDate
    timeframePeriod {
      unit
      value
      includeCurrent
    }
    createdBefore
    createdAfter
    absAmountGte
    absAmountLte
    isSplit
    isRecurring
    isInvestmentAccount
    isPending
    creditsOnly
    debitsOnly
    hasNotes
    hasAttachments
    hiddenFromReports
    importedFromMint
    syncedFromInstitution
    needsReview
    needsReviewUnassigned
    needsReviewByUser {
      id
      name
    }
  }
`);

export default useReportConfigurations;
