import React from 'react';
import styled from 'styled-components';

import Badge from 'components/lib/ui/Badge';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Link from 'components/lib/ui/Link';
import Text from 'components/lib/ui/Text';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';
import BudgetOnboardingPage from 'components/plan/onboarding/BudgetOnboardingPage';
import { CardWithHeavyShadow } from 'components/plan/onboarding/OnboardingCard';
import type { OnboardingSharedPageProps } from 'components/routes/budget/BudgetOnboardingFlow';

import useBudgetOnboardingState from 'common/lib/hooks/budget/useBudgetOnboardingState';
import useBudgetSettings from 'common/lib/hooks/budget/useBudgetSettings';

import {
  BLOG_FLEX_BUDGET_URL,
  BLOG_FLEX_VS_CATEGORY_BUDGET_URL,
} from 'common/constants/externalUrls';

import { BudgetSystem } from 'common/generated/graphql';

const Column = styled(FlexContainer).attrs({ full: true, column: true })`
  max-width: 704px;
`;

const ChoiceContainer = styled(FlexContainer).attrs({
  alignCenter: true,
  justifyBetween: true,
  full: true,
})`
  padding: ${({ theme }) => theme.spacing.default};

  &:first-child {
    border-bottom: ${({ theme }) => theme.color.grayBackground} 1px solid;
  }
`;

const ChoiceTitle = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.xxxsmall};
`;

const ChoiceDescription = styled.div`
  max-width: 528px;
`;

const StyledBadge = styled(Badge)`
  text-transform: none;
  letter-spacing: unset;
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin-left: ${({ theme }) => theme.spacing.small};
`;

const ContinueButton = styled(PrimaryButton)`
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

const CurrentBadge = styled(StyledBadge).attrs({
  color: 'grayLight',
  children: 'Current',
})``;

type Props = {
  onSelectFixFlexBudget: () => void;
  onSelectCategoryBudget: () => void;
  onBack: () => void;
  onCancel: () => void;
} & OnboardingSharedPageProps;

const OnboardingMethodChoice = ({
  title,
  description,
  progress,
  onSelectFixFlexBudget,
  onSelectCategoryBudget,
  onBack,
  onCancel,
}: Props) => {
  const { isExistingUser } = useBudgetOnboardingState();
  const {
    budgetSystem: currentBudgetSystem,
    updateBudgetSystem,
    updateLoading,
  } = useBudgetSettings();
  const usesFlexBudgeting = currentBudgetSystem === BudgetSystem.FIXED_AND_FLEX;

  const handleSelectFixFlexBudget = async () => {
    await updateBudgetSystem(BudgetSystem.FIXED_AND_FLEX, {
      onDone: onSelectFixFlexBudget,
    });
  };

  const handleSelectCategoryBudget = async () => {
    await updateBudgetSystem(BudgetSystem.GROUPS_AND_CATEGORIES, {
      onDone: onSelectCategoryBudget,
    });
  };

  return (
    <BudgetOnboardingPage
      pageName="Budget Onboarding Method Choice"
      title={title}
      description={description}
      descriptionMaxWidth={624}
      hideNextButton
      progress={progress}
      onClickBack={onBack}
      onClickCancel={onCancel}
    >
      <Column>
        <CardWithHeavyShadow>
          <ChoiceContainer>
            <div>
              <ChoiceTitle>
                <Text weight="medium">Flex Budget</Text>
                <StyledBadge color="orange">Recommended</StyledBadge>
                {usesFlexBudgeting && isExistingUser && <CurrentBadge />}
              </ChoiceTitle>
              <ChoiceDescription>
                <Text size="small">
                  Organize your expenses into <strong>Fixed</strong>, <strong>Non-Monthly</strong>,{' '}
                  <strong>Flexible</strong> buckets to get one number to focus on. This makes
                  sticking to a budget easier — and you don&apos;t have to itemize everything.{' '}
                  <Link href={BLOG_FLEX_BUDGET_URL} target="_blank" rel="noopener noreferrer">
                    Learn more
                  </Link>
                </Text>
              </ChoiceDescription>
            </div>
            <div>
              <ContinueButton disabled={updateLoading} onClick={handleSelectFixFlexBudget}>
                {usesFlexBudgeting && isExistingUser ? 'Keep' : 'Continue'}
              </ContinueButton>
            </div>
          </ChoiceContainer>

          <ChoiceContainer>
            <div>
              <ChoiceTitle>
                <Text weight="medium">Category Budget</Text>
                {!usesFlexBudgeting && isExistingUser && <CurrentBadge />}
              </ChoiceTitle>
              <ChoiceDescription>
                <Text size="small">
                  Organize your income and spending categories by type, and create a budget for each
                  category. This is great if you like to track your spending closely and learn more
                  about how you spend.{' '}
                  <Link
                    href={BLOG_FLEX_VS_CATEGORY_BUDGET_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn more
                  </Link>
                </Text>
              </ChoiceDescription>
            </div>
            <div>
              <ContinueButton disabled={updateLoading} onClick={handleSelectCategoryBudget}>
                {!usesFlexBudgeting && isExistingUser ? 'Keep' : 'Continue'}
              </ContinueButton>
            </div>
          </ChoiceContainer>
        </CardWithHeavyShadow>
      </Column>
    </BudgetOnboardingPage>
  );
};

export default OnboardingMethodChoice;
