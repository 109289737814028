import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { setOffsetForKey } from 'actions';
import useInitialValue from 'common/lib/hooks/useInitialValue';
import type { ScrollOffset } from 'state/scroll/reducer';
import { store } from 'state/store';

/**
 * Hook for managing scroll position restoration.
 *
 * By default, the scroll position is restored only when using the browser
 * back/forward buttons.
 *
 * You can override the initial scroll position for a route by passing an `initialScrollOffset` object
 * to the location state when navigating to a route.
 *
 * i.e.
 * const history = useHistory();
 * history.push('/some-route', { initialScrollOffset: { scrollTop: 100, scrollLeft: 0 } });
 */
const useRestoreScrollPosition = () => {
  const {
    action,
    location: { key = '', state },
  } = useHistory<{ initialScrollOffset?: ScrollOffset }>();

  const initialState = useInitialValue(state);

  const dispatch = useDispatch();

  // Only update initialScrollOffset once on mount IF action is "POP" (went forward/back using browser buttons)
  const initialScrollOffset = useMemo(() => {
    if (action === 'POP') {
      const restoreScrollPosition = store.getState().scroll.offsetByKey[key];
      return restoreScrollPosition;
    }

    // otherwise, default to the initial scroll offset from the location state
    return initialState?.initialScrollOffset;
  }, [action]); // eslint-disable-line react-hooks/exhaustive-deps

  const onScroll = useCallback(
    (scrollTop: number, scrollLeft: number) =>
      dispatch(setOffsetForKey({ key, offset: { scrollLeft, scrollTop } })),
    [dispatch, key],
  );

  return [initialScrollOffset, onScroll] as const;
};

export default useRestoreScrollPosition;
