import React from 'react';
import styled, { css } from 'styled-components';

import type { CardProps } from 'components/lib/ui/Card';
import Card from 'components/lib/ui/Card';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Tooltip from 'components/lib/ui/Tooltip';

import { DEMO_HOUSEHOLD } from 'common/constants/copy';

const LoadingContainer = styled(FlexContainer).attrs({ center: true })`
  padding: ${({ theme }) => theme.spacing.xxxlarge};
`;

const StyledCard = styled(Card)<{ $disabled: boolean }>`
  ${({ $disabled }) =>
    $disabled
      ? css`
          opacity: 50%;
          pointer-events: none;
          user-select: none;
        `
      : ''}

  @media (max-width: ${({ theme }) => theme.breakPoints.xs}px) {
    margin-top: ${({ theme }) => theme.spacing.default};
  }
`;

const Placeholder = styled.div<{ $disabled: boolean }>`
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'inherit')};
`;

type Props = {
  loading?: boolean;
  isDemo?: boolean;
} & CardProps;

const SettingsCard = ({ loading, isDemo = false, ...props }: Props) =>
  loading ? (
    <LoadingContainer>
      <LoadingSpinner />
    </LoadingContainer>
  ) : (
    <Tooltip content={DEMO_HOUSEHOLD.PAGE_DISABLED} disable={!isDemo}>
      <Placeholder $disabled={isDemo}>
        <StyledCard $disabled={isDemo} {...props} />
      </Placeholder>
    </Tooltip>
  );

export default SettingsCard;
