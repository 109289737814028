import { sentenceCase } from 'change-case';

import { ENTITY_TO_LABEL } from 'common/lib/reports';
import { generateAmountFilterFromRange } from 'lib/filters/utils';
import type { ReportsTab } from 'state/reports/types';

import { ReportsGroupByTimeframe } from 'common/generated/graphql';
import type { ReportsGroupByEntity, TransactionFilterSet } from 'common/generated/graphql';
import type { TransactionFilters } from 'types/filters';

export const makeChartTitle = (groupBy: Maybe<ReportsGroupByEntity>, tab: ReportsTab) => {
  if (!groupBy) {
    return undefined;
  } else if (tab === 'cashFlow') {
    return 'Cash Flow';
  } else {
    const groupLabel = ENTITY_TO_LABEL[groupBy];
    return sentenceCase(`${tab} by ${groupLabel}`);
  }
};

export const formatReportsValue = (value: Maybe<number>) => (value ? Math.abs(value) : 0);

export const getTimeframeLabel = (timeframe: ReportsGroupByTimeframe) => {
  switch (timeframe) {
    case ReportsGroupByTimeframe.DAY:
      return 'Daily';
    case ReportsGroupByTimeframe.WEEK:
      return 'Weekly';
    case ReportsGroupByTimeframe.MONTH:
      return 'Monthly';
    case ReportsGroupByTimeframe.QUARTER:
      return 'Quarterly';
    case ReportsGroupByTimeframe.YEAR:
      return 'Yearly';
    default:
      return '';
  }
};

export const getReportFiltersForTransactionFilterSet = (
  transactionFilterSet: TransactionFilterSet,
  startDate?: Maybe<ISODate>,
  endDate?: Maybe<ISODate>,
): Partial<TransactionFilters> => {
  const amountFilter = generateAmountFilterFromRange(
    transactionFilterSet.absAmountLte,
    transactionFilterSet.absAmountGte,
  );

  return {
    accounts: transactionFilterSet.accounts?.map((account) => account.id),
    categories: transactionFilterSet.categories?.map((category) => category.id),
    categoryGroups: transactionFilterSet.categoryGroups?.map((group) => group.id),
    merchants: transactionFilterSet.merchants?.map((merchant) => merchant.id),
    tags: transactionFilterSet.tags?.map((tag) => tag.id),
    goalId: transactionFilterSet.goal?.id,
    uploadedStatement: transactionFilterSet.uploadedStatement?.id,
    amountFilter,
    absAmountGte: transactionFilterSet.absAmountGte,
    absAmountLte: transactionFilterSet.absAmountLte,
    categoryType: transactionFilterSet.categoryType,
    creditsOnly: transactionFilterSet.creditsOnly,
    debitsOnly: transactionFilterSet.debitsOnly,
    hasAttachments: transactionFilterSet.hasAttachments,
    hasNotes: transactionFilterSet.hasNotes,
    hideFromReports: transactionFilterSet.hiddenFromReports,
    importedFromMint: transactionFilterSet.importedFromMint,
    isFlexSpending: transactionFilterSet.isFlexSpending,
    isInvestmentAccount: transactionFilterSet.isInvestmentAccount,
    isPending: transactionFilterSet.isPending,
    isRecurring: transactionFilterSet.isRecurring,
    isSplit: transactionFilterSet.isSplit,
    isUncategorized: transactionFilterSet.isUncategorized,
    needsReview: transactionFilterSet.needsReview,
    needsReviewByUser: transactionFilterSet.needsReviewByUser?.id,
    needsReviewUnassigned: transactionFilterSet.needsReviewUnassigned,
    search: transactionFilterSet.searchQuery,
    syncedFromInstitution: transactionFilterSet.syncedFromInstitution,
    // TODO(ENG-10601): Apply what's already there until the dates requirement is removed
    startDate,
    endDate,
  };
};
