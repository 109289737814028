import { gql, useMutation } from '@apollo/client';
import * as R from 'ramda';
import React, { useMemo, useState } from 'react';
import type { DropResult } from 'react-beautiful-dnd';
import styled from 'styled-components';

import GoalCustomizeModal from 'components/goalsV2/GoalCustomizeModal';
import GoalRankCard from 'components/goalsV2/GoalRankCard';
import DragDropContext from 'components/lib/dnd/DragDropContext';
import Draggable from 'components/lib/dnd/Draggable';
import Droppable from 'components/lib/dnd/Droppable';
import FlexContainer from 'components/lib/ui/FlexContainer';

import { filterOutArchivedGoals, sortGoalsByPriority } from 'common/lib/goalsV2/adapters';
import { PAYLOAD_ERRORS_FRAGMENT } from 'common/lib/graphQl/errors';
import useQuery from 'common/lib/hooks/useQuery';
import useModal from 'lib/hooks/useModal';

import type {
  Web_RankGoalsList,
  Web_RankGoalsList_goalsV2,
} from 'common/generated/graphQlTypes/Web_RankGoalsList';
import type {
  Web_UpdateGoalsPriorities,
  Web_UpdateGoalsPrioritiesVariables,
} from 'common/generated/graphQlTypes/Web_UpdateGoalsPriorities';

const List = styled(FlexContainer).attrs({ column: true, alignCenter: true })``;

const StyledDraggable = styled(Draggable)`
  width: 100%;

  /* 
    having top and bottom margins makes the drop animation look better 
    than if the margin is only at one side or if using gap on parent  
  */
  margin-top: ${({ theme }) => theme.spacing.xsmall};
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
`;

const StyledDroppable = styled(Droppable)`
  width: 100%;
`;

type Props = {
  className?: string;
  onDoneLoading?: () => void;
};

const RankGoalsList = ({ className, onDoneLoading }: Props) => {
  const { data: goalsData } = useQuery<Web_RankGoalsList>(QUERY, {
    onCompleted: onDoneLoading,
  });

  const [goalIdBeingEdited, setGoalIdBeingEdited] = useState<string | null>();

  const [CustomizeModal, { open: openGoalCustomizeModal }] = useModal();

  const goals = useMemo(() => {
    let goals = filterOutArchivedGoals(goalsData?.goalsV2 ?? []);
    goals = sortGoalsByPriority(goals);

    return goals;
  }, [goalsData]);

  const selectedGoal = useMemo(
    () => R.find(({ id }) => id === goalIdBeingEdited, goals),
    [goals, goalIdBeingEdited],
  );

  const handleOnDragEnd = ({ source, destination }: DropResult) => {
    if (!destination) {
      return;
    }

    // Remove from source & insert into destination position
    const draggedGoal = goals[source.index];
    const newOrder = R.pipe(
      R.remove<Web_RankGoalsList_goalsV2>(source.index, 1),
      R.insert(destination.index, draggedGoal),
    )(goals);

    const newGoals = newOrder.map((goal, index) => ({
      id: goal.id,
      priority: index + 1,
    }));

    updateGoalPriorities({
      variables: {
        input: {
          goals: newGoals,
        },
      },
      optimisticResponse: {
        updateGoalPriorities: {
          __typename: 'UpdateGoalPriorities',
          goals: newGoals.map((goal) => ({
            ...goal,
            __typename: 'GoalV2',
          })),
          errors: null,
        },
      },
    });
  };

  const [updateGoalPriorities] = useMutation<
    Web_UpdateGoalsPriorities,
    Web_UpdateGoalsPrioritiesVariables
  >(MUTATION);

  return (
    <>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <StyledDroppable className={className} droppableId="goals-rank">
          <List>
            {goals.map((item, idx) => (
              <StyledDraggable key={item.id} draggableId={item.id} index={idx}>
                <GoalRankCard
                  goalData={item}
                  onClickEdit={() => {
                    setGoalIdBeingEdited(item.id);
                    openGoalCustomizeModal();
                  }}
                />
              </StyledDraggable>
            ))}
          </List>
        </StyledDroppable>
      </DragDropContext>

      {selectedGoal && (
        <CustomizeModal onClose={() => setGoalIdBeingEdited(null)}>
          <GoalCustomizeModal data={selectedGoal} />
        </CustomizeModal>
      )}
    </>
  );
};

const QUERY = gql`
  query Web_RankGoalsList {
    goalsV2 {
      id
      archivedAt
      ...GoalRankCardFields
      ...GoalCustomizeModalFields
    }
  }

  ${GoalRankCard.fragments.GoalRankCardFields}
  ${GoalCustomizeModal.fragments.GoalCustomizeModalFields}
`;

const MUTATION = gql`
  mutation Web_UpdateGoalsPriorities($input: UpdateGoalPrioritiesInput!) {
    updateGoalPriorities(input: $input) {
      goals {
        id
        priority
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
  ${PAYLOAD_ERRORS_FRAGMENT}
`;

export default RankGoalsList;
