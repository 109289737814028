import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import AccountLogo from 'components/accounts/AccountLogo';
import FilterMenu from 'components/reports/filters/FilterMenu';

import { setRecurringFilters } from 'actions';
import { spacing } from 'common/lib/theme/dynamic';
import useDispatch from 'lib/hooks/useDispatch';
import useRecurringFilterMenu from 'lib/hooks/useRecurringFilterMenu';
import { getFilters } from 'state/recurring/selectors';

const ACCOUNT_LOGO_SIZE_PX = 14;
const FILTER_MENU_COLUMN_WIDTH = 150;

const SmallAccountLogo = styled(AccountLogo).attrs({ size: ACCOUNT_LOGO_SIZE_PX })`
  margin-right: ${spacing.xxsmall};
`;

type Props = {
  toggleOpen: () => void;
};

const RecurringFilters = ({ toggleOpen }: Props) => {
  const dispatch = useDispatch();
  const existingFilters = useSelector(getFilters);

  const { sections, isLoading, onChangeSections, handleApply } = useRecurringFilterMenu({
    page: 'recurring',
    filters: existingFilters,
    onApplyFilters: (filters) => {
      dispatch(setRecurringFilters(filters));
      toggleOpen();
    },
    renderAccountLogo: (account) => (
      <SmallAccountLogo logoUrl={account.logoUrl} icon={account.icon} />
    ),
  });

  return (
    <FilterMenu
      isLoading={isLoading}
      onClickApply={handleApply}
      onClickCancel={toggleOpen}
      sections={sections}
      onChange={onChangeSections}
      options={{
        filtersColumnWidth: FILTER_MENU_COLUMN_WIDTH,
      }}
    />
  );
};

export default RecurringFilters;
