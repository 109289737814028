import * as R from 'ramda';
import React, { useMemo } from 'react';
import type { ContentRenderer, LineProps, PieLabelRenderProps } from 'recharts';
import {
  Cell,
  Curve,
  Pie,
  PieChart as RechartsPieChart,
  ResponsiveContainer,
  Sector,
  Tooltip,
} from 'recharts';

import type { TooltipComponentProps } from 'components/lib/charts/ChartTooltip';
import ChartTooltip from 'components/lib/charts/ChartTooltip';
import ChartTooltipDataRow from 'components/lib/charts/ChartTooltipDataRow';

import useTheme from 'lib/hooks/useTheme';

const MIN_LABEL_PERCENT = 0.02; // try to prevent label stacking
const ACTIVE_PIE_GROW_PX = 6;

const renderCustomLabel: ContentRenderer<PieLabelRenderProps> = ({
  name,
  labelColor,
  textAnchor,
  x,
  y,
  fill,
  percent,
}) => {
  if ((percent ?? 0) < MIN_LABEL_PERCENT) {
    return null;
  }
  return (
    <text
      x={x}
      y={y}
      fill={labelColor ?? fill}
      textAnchor={textAnchor}
      dominantBaseline="central"
      fontSize={12}
    >
      {name}
    </text>
  );
};

const renderCustomLabelLine: ContentRenderer<LineProps & any> = (props) => {
  const { percent } = props;

  if ((percent ?? 0) < MIN_LABEL_PERCENT) {
    return null;
  }

  return <Curve {...props} type="linear" />;
};

const TooltipContent: TooltipComponentProps<string, string, Datum> = ({ payload, active }) => {
  const { label, value, formattedValue, color } = payload[0]?.payload ?? {};
  return (
    <ChartTooltip active={active}>
      <ChartTooltipDataRow dotFillColor={color} label={label} value={formattedValue ?? value} />
    </ChartTooltip>
  );
};

const renderActiveShape = (props: any) => (
  <g>
    <Sector
      {...props}
      innerRadius={props.innerRadius - ACTIVE_PIE_GROW_PX}
      outerRadius={props.outerRadius + ACTIVE_PIE_GROW_PX}
    />
  </g>
);

export type Datum = {
  id?: string;
  label: string;
  value: number;
  color: string;
  labelColor?: string;
  formattedValue?: string;
  percent?: number;
  onClick?: () => void; // This is only defined for the everything else slice
};

export type Props<T extends Datum = Datum> = {
  data: T[];
  width?: string | number;
  height?: string | number;
  innerRadius?: number | string;
  outerRadius?: number | string;
  isAnimationActive?: boolean;
  /** Show lines with labels for each slice. Defaults to true. */
  showLabels?: boolean;
  startAngle?: number;
  endAngle?: number;
  tooltip?: TooltipComponentProps<string, string, T>;
  selectedDatumId?: Maybe<string>;
  activeDatumIds?: Maybe<string[]>;
  onChangeActiveDatumId?: (id: Maybe<string>) => void;
  onClickDatum?: (id: string) => void;
};

function generateDatumIdToIndexMap(data: Datum[]) {
  return data.reduce(
    (acc, datum, index) => {
      if (datum.id) {
        acc[datum.id] = index;
      }
      return acc;
    },
    {} as { [key: string]: number },
  );
}

/** Pie chart with single ring. */
const PieChart = ({
  data,
  width,
  height,
  innerRadius = '50%',
  outerRadius = '70%',
  isAnimationActive,
  showLabels = true,
  startAngle,
  endAngle,
  tooltip,
  activeDatumIds,
  onChangeActiveDatumId,
  onClickDatum,
}: Props) => {
  const theme = useTheme();

  // Recharts draws counter-clockwise, and we'd prefer the data to render the
  // other direction, so we reverse the data here
  const pieData = useMemo(() => R.reverse(data), [data]);
  // Memoize a map of datum ids to their indices, so we can use it to set the active index
  const datumIdToIndexMap = useMemo(() => generateDatumIdToIndexMap(pieData), [pieData]);

  return (
    <ResponsiveContainer width={width} height={height}>
      <RechartsPieChart
        margin={{ top: 0, bottom: 0, left: 0, right: 0 }}
        style={{ overflow: 'visible' }}
      >
        <Pie
          data={pieData}
          activeIndex={activeDatumIds?.map((id) => datumIdToIndexMap[id])}
          activeShape={renderActiveShape}
          startAngle={startAngle}
          endAngle={endAngle}
          dataKey="value"
          nameKey="label"
          cx="50%"
          cy="50%"
          stroke={theme.color.white}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          isAnimationActive={isAnimationActive}
          onMouseEnter={({ id }) => onChangeActiveDatumId?.(id)}
          onMouseLeave={({ id }) =>
            activeDatumIds?.includes(id) && onChangeActiveDatumId?.(undefined)
          }
          onClick={({ id }) => id !== undefined && id !== 'everything_else' && onClickDatum?.(id)}
          style={{ overflow: 'visible' }}
          {...(showLabels && {
            label: renderCustomLabel,
            labelLine: renderCustomLabelLine,
          })}
        >
          {pieData.map(({ color }, index) => (
            <Cell key={`cell-${index}`} fill={color} style={{ cursor: 'pointer' }} />
          ))}
        </Pie>
        <Tooltip cursor={false} content={tooltip ?? TooltipContent} />
      </RechartsPieChart>
    </ResponsiveContainer>
  );
};

export default PieChart;
