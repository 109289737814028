import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { DropdownMenuItem } from 'components/lib/ui/DropdownMenu';
import WithIndicatorContainer from 'components/lib/ui/WithIndicatorContainer';
import ButtonIcon from 'components/lib/ui/button/ButtonIcon';
import DefaultButton from 'components/lib/ui/button/DefaultButton';
import IconButton from 'components/lib/ui/button/IconButton';
import { OverlayTrigger, Popover } from 'components/lib/ui/popover';

import { spacing, transition } from 'common/lib/theme/dynamic';
import variables from 'common/lib/theme/variables';

import type { ReportConfiguration } from 'common/generated/graphql';

const StyledPopover = styled(Popover)`
  min-width: 260px;
`;

const DropdownMenuList = styled.div`
  max-height: 500px;
  overflow-y: auto;
`;

const Divider = styled.div`
  height: 1px;
  background-color: ${variables.color.divider.secondary};
  margin: ${spacing.xxsmall} ${spacing.xsmall};
`;

const StyledIconButton = styled(IconButton)`
  opacity: 0;
  transition: opacity ${transition.default};
`;

const ReportConfigurationDropdownMenuItem = styled(DropdownMenuItem)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: ${spacing.xxsmall};

  &:hover {
    ${StyledIconButton} {
      opacity: 1;
    }
  }
`;

const ActionDropdownMenuItem = styled(DropdownMenuItem)`
  color: ${variables.color.content.info};
`;

type Props = {
  reportConfigurations: ReportConfiguration[];
  selectedReportConfigurationId?: string;
  onSelectReportConfiguration?: (reportConfiguration: ReportConfiguration) => void;
  onSaveReportConfiguration?: () => void;
  onEditReportConfiguration?: (reportConfiguration: ReportConfiguration) => void;
  onResetToDefaultReportConfiguration?: () => void;
  showResetToDefaultReportConfiguration?: boolean;
};

const ReportsConfigurationsMenuButton = ({
  reportConfigurations,
  selectedReportConfigurationId,
  onSelectReportConfiguration,
  onSaveReportConfiguration,
  onEditReportConfiguration,
  onResetToDefaultReportConfiguration,
  showResetToDefaultReportConfiguration = false,
}: Props) => {
  const sortedReportConfigurations = useMemo(
    () =>
      R.sort(
        (a: ReportConfiguration, b: ReportConfiguration) =>
          a.displayName.localeCompare(b.displayName),
        reportConfigurations,
      ),
    [reportConfigurations],
  );

  const selectedReportConfiguration = useMemo(
    () =>
      RA.isNotNil(selectedReportConfigurationId)
        ? sortedReportConfigurations.find((config) => config.id === selectedReportConfigurationId)
        : undefined,
    [sortedReportConfigurations, selectedReportConfigurationId],
  );

  return (
    <OverlayTrigger
      placement="bottom-end"
      overlay={() => (
        <StyledPopover>
          <DropdownMenuList>
            {sortedReportConfigurations.map((reportConfiguration) => (
              <ReportConfigurationDropdownMenuItem
                key={reportConfiguration.id}
                onClick={() => onSelectReportConfiguration?.(reportConfiguration)}
                selected={reportConfiguration.id === selectedReportConfigurationId}
              >
                <span>{reportConfiguration.displayName}</span>
                <StyledIconButton
                  icon="edit"
                  size="xsmall"
                  onClick={(event) => {
                    onEditReportConfiguration?.(reportConfiguration);
                    event.stopPropagation();
                  }}
                />
              </ReportConfigurationDropdownMenuItem>
            ))}
          </DropdownMenuList>
          {reportConfigurations.length > 0 && <Divider />}
          {showResetToDefaultReportConfiguration && (
            <ActionDropdownMenuItem onClick={onResetToDefaultReportConfiguration}>
              Reset to default view
            </ActionDropdownMenuItem>
          )}
          <ActionDropdownMenuItem onClick={onSaveReportConfiguration}>
            Save current report filters
          </ActionDropdownMenuItem>
        </StyledPopover>
      )}
    >
      {({ toggleOpen, isOpen }) => (
        <WithIndicatorContainer show={!!selectedReportConfiguration}>
          <DefaultButton onClick={toggleOpen} active={isOpen}>
            <ButtonIcon name="bookmark" />
            <span>{selectedReportConfiguration?.displayName ?? 'Saved'}</span>
          </DefaultButton>
        </WithIndicatorContainer>
      )}
    </OverlayTrigger>
  );
};

export default ReportsConfigurationsMenuButton;
