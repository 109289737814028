import * as R from 'ramda';

import type { AccountType } from 'common/constants/accounts';
import { ACCOUNT_TYPE_TO_COLOR_MAP } from 'common/constants/accounts';

import type { Web_GetAccountsPageQuery } from 'common/generated/graphql';
import type { ThemeType } from 'common/types/Styles';
import type { ElementOf } from 'common/types/utility';

export type AccountTypeSummary = ElementOf<Web_GetAccountsPageQuery, 'accountTypeSummaries'>;

export type AccountSummaryCardItem = Pick<AccountTypeSummary, 'type'> & {
  amount: number;
  color: string;
  percentage: number;
};

export const getLineColorByAccountType = (accountType: string, theme: ThemeType) => {
  const color =
    ACCOUNT_TYPE_TO_COLOR_MAP(theme)[accountType.toLowerCase() as AccountType] ?? 'gray';
  return theme.color[color];
};

export const getAccountSummaryComposition = (
  summaries: AccountTypeSummary[],
  theme: ThemeType,
): AccountSummaryCardItem[] => {
  const total = summaries.reduce((acc, { totalDisplayBalance }) => acc + totalDisplayBalance, 0);
  const sortedAndReversedSummaries = R.pipe<
    AccountTypeSummary[],
    AccountTypeSummary[],
    AccountTypeSummary[],
    AccountTypeSummary[]
  >(
    R.filter((summary: AccountTypeSummary) => summary.totalDisplayBalance > 0),
    R.sortBy(R.prop('totalDisplayBalance')),
    R.reverse,
  )(summaries);

  return sortedAndReversedSummaries.map(({ type, totalDisplayBalance }) => ({
    type,
    amount: totalDisplayBalance,
    color: getLineColorByAccountType(type.name, theme),
    percentage: total > 0 ? totalDisplayBalance / total : 0,
  }));
};
