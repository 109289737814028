import type { DateTime } from 'luxon';
import React, { useContext } from 'react';

import type { PlanActions, PlanTimeframe } from 'lib/hooks/plan/usePlanState';
import type { PlanAmountType, PlanSectionType } from 'lib/plan';

export type GetDateForColumn = (column: number) => DateTime;

type PlanContextType = {
  timeframe: PlanTimeframe;
  refetch: () => Promise<any>;
  getIsColumnLoading: (column: number) => boolean;
  openEditCategoryGroup: (id: string) => void;
  getDateForColumn: GetDateForColumn;
  getAmountTypeForColumn: (column: number) => PlanAmountType;
  getCanEditColumn: (column: number) => boolean;
  updateCellValue: (
    itemId: string,
    date: DateTime,
    sectionType: PlanSectionType,
    isGroup?: boolean,
  ) => (amount: number, applyToFuture: boolean) => void;
  actions: PlanActions;
  budgetApplyToFutureMonthsDefault: boolean;
};

const PlanContext = React.createContext<PlanContextType>(undefined as any);

export const usePlanContext = () => {
  const context = useContext(PlanContext);
  if (!context) {
    throw new Error(
      'usePlanContext used outside of PlanContext. Ensure you have a <PlanContext.Provider> higher in the tree.',
    );
  }

  return context;
};

export default PlanContext;
