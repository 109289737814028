import routes from 'constants/routes';

/**
 * Defines routes that can be shown in the sidebar. Adding an
 * entry here will not automatically add it to the sidebar. You
 * must also add it to SideBarDefaultContent.
 *
 * This is also used in breadcrumbs to decide which routes can
 * be shown as a "parent" route.
 *
 * Key is the title that will be shown in the sidebar.
 */
export const SIDEBAR_ROUTES = {
  Dashboard: {
    route: routes.dashboard(),
    icon: 'home',
  },
  Accounts: {
    route: routes.accounts(),
    icon: 'layers',
  },
  Transactions: {
    route: routes.transactions(),
    icon: 'credit-card',
  },
  'Cash Flow': {
    route: routes.cashFlow(),
    icon: 'bar-chart',
  },
  Reports: {
    route: routes.reports(),
    icon: 'pie-chart',
  },
  Budget: {
    route: routes.plan(),
    icon: 'map',
  },
  Recurring: {
    route: routes.recurring(),
    icon: 'calendar',
  },
  Goals: {
    route: routes.goalsV2(),
    icon: 'target',
  },
  Investments: {
    route: routes.investments(),
    icon: 'trending-up',
  },
  Advice: {
    route: routes.advice({}),
    icon: 'thumbs-up',
  },
  Assistant: {
    route: routes.assistant({}),
    icon: 'stars',
  },
};

export type SidebarRouteName = keyof typeof SIDEBAR_ROUTES;
