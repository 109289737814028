import React, { useEffect } from 'react';

import PremiumPlanComparisonModal from 'components/premium/PremiumPlanComparisonModal';
import PremiumSubscribeModal from 'components/premium/PremiumSubscribeModal';
import Flow from 'components/utils/Flow';

import usePremiumStripeOfferings from 'common/lib/hooks/premium/usePremiumStripeOfferings';
import { trackPremiumScreenViewed } from 'lib/analytics/premium';

type Props = {
  analyticsName: string;
  onBack?: () => void;
  onComplete?: () => void;
};

const PremiumUpgradeFlow = ({ analyticsName, onBack, onComplete }: Props) => {
  usePremiumStripeOfferings({ prefetch: true }); // Prefetch plans to avoid loading spinner

  useEffect(() => {
    trackPremiumScreenViewed(analyticsName);
  }, [analyticsName]);

  return (
    <Flow
      steps={[PremiumPlanComparisonModal, PremiumSubscribeModal]}
      onBack={onBack}
      onComplete={onComplete}
    />
  );
};

export default PremiumUpgradeFlow;
