import * as R from 'ramda';

import type { SyncDisabledReason } from 'common/constants/credential';

type SortableCredential = {
  id: GraphQlUUID;
  institution: {
    name: string;
  };
};

export const sortCredentials = <TCredential extends SortableCredential>(
  credentials: TCredential[],
) => R.sortBy(({ institution }) => institution.name, credentials);

export const isSyncDisabledWithReason = (credential: any | null, reason: SyncDisabledReason) =>
  !!credential?.syncDisabledAt && credential?.syncDisabledReason === reason;
