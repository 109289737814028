import pluralize from 'pluralize';
import { indexBy, prop } from 'ramda';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import TabPanel from 'common/components/tabs/TabPanel';
import Tabs from 'common/components/tabs/Tabs';
import Switch, { Case } from 'common/components/utils/Switch';
import PlanSummaryWidgetHeader from 'components/budget/PlanSummaryWidgetHeader';
import PlanSummaryWidgetRow from 'components/budget/PlanSummaryWidgetRow';
import Card from 'components/lib/ui/Card';
import FlexContainer from 'components/lib/ui/FlexContainer';
import type { Tick } from 'components/lib/ui/ProgressBar';
import Text from 'components/lib/ui/Text';
import PillTab from 'components/lib/ui/tabs/PillTab';
import { CellLoadingSpinner } from 'components/planning/PlanningTableCell';

import type { SummaryDisplayBudgetType } from 'common/lib/budget/Adapters';
import { EXCESS_BUDGET_TYPE } from 'common/lib/budget/Adapters';
import useHouseholdPreferences from 'common/lib/hooks/household/useHouseholdPreferences';
import { color, fontSize, spacing } from 'common/lib/theme/dynamic';

import type { KeyOfThemeProp } from 'common/types/Styles';
import type { ObjectValuedKeys } from 'common/types/utility';

type SummaryDisplayBudgetTypeWithId = SummaryDisplayBudgetType & { id?: string };

const Root = styled.div`
  --inner-padding: ${spacing.default};

  margin-left: ${spacing.gutter};
  margin-bottom: ${spacing.xlarge};
`;

const Content = styled(FlexContainer).attrs({ column: true })`
  padding: var(--inner-padding) var(--inner-padding) 0;
`;

const TabsContainer = styled(FlexContainer).attrs({ justifyCenter: true, alignCenter: true })`
  gap: ${spacing.xsmall};
  padding: ${spacing.default} 0 ${spacing.small};
  border-bottom: 1px solid ${color.grayBackground};
  margin: 0 calc(-1 * var(--inner-padding)); /* negative margin for the border to be flush with the card */

  ${PillTab} {
    font-size: ${fontSize.xsmall};
  }
`;

const NoDataContainer = styled(FlexContainer).attrs({ center: true, marginTop: 'default' })`
  ${Text} {
    max-width: 20em;
    text-align: center;
  }
`;

type Props = {
  data: SummaryDisplayBudgetType[];
  isLoading: boolean;
  ticks?: Tick[];
  titleSize?: Extract<KeyOfThemeProp<'fontSize'>, 'small' | 'base'>;
};

const PlanSummaryWidget = ({ data, ticks, isLoading, titleSize }: Props) => {
  const { isFlexBudgeting } = useHouseholdPreferences();

  const budgetDataByType = useMemo(() => indexBy(prop('type'), data), [data]);
  const incomeBreakdown = budgetDataByType.income;
  const expenseBreakdown = budgetDataByType.expense;

  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    const newTabIndex = isFlexBudgeting ? 2 : 0;
    if (newTabIndex !== tabIndex) {
      setTabIndex(newTabIndex);
    }
  }, [isFlexBudgeting]);

  return (
    <Root>
      <Card>
        <Content>
          <PlanSummaryWidgetHeader data={data} isLoading={isLoading} />
          <Tabs onChangeIndex={setTabIndex} index={tabIndex}>
            <TabsContainer>
              <PillTab $background="card" index={0}>
                Summary
              </PillTab>
              <PillTab $background="card" index={1}>
                Income
              </PillTab>
              <PillTab $background="card" index={2}>
                Expenses
              </PillTab>
            </TabsContainer>
            <TabPanel index={0}>
              <PlanSummaryOverview data={data} ticks={ticks} isLoading={isLoading} />
            </TabPanel>
            <TabPanel index={1}>
              <BudgetTypeCategoryBreakdown
                parentGroup={incomeBreakdown}
                ticks={ticks}
                isLoading={isLoading}
                titleSize={titleSize}
                breakdownType="categoryGroups"
              />
            </TabPanel>
            <TabPanel index={2}>
              <BudgetTypeCategoryBreakdown
                parentGroup={expenseBreakdown}
                ticks={ticks}
                isLoading={isLoading}
                titleSize={titleSize}
                breakdownType={isFlexBudgeting ? 'budgetVariabilities' : 'categoryGroups'}
              />
            </TabPanel>
          </Tabs>
        </Content>
      </Card>
    </Root>
  );
};

export const PlanSummaryOverview = ({ data, isLoading, ticks, titleSize }: Props) => (
  <FlexContainer marginBottom="large" column>
    {Object.values(data)
      .filter((group) => group.type !== EXCESS_BUDGET_TYPE)
      .map((group) => renderBudgetRow({ item: group, isLoading, ticks, titleSize }))}
  </FlexContainer>
);

export const BudgetTypeCategoryBreakdown = ({
  parentGroup,
  isLoading,
  ticks,
  titleSize,
  breakdownType,
}: Pick<Props, 'ticks' | 'titleSize'> & {
  parentGroup: SummaryDisplayBudgetType | undefined;
  breakdownType: ObjectValuedKeys<SummaryDisplayBudgetType>;
  isLoading: boolean;
}) => {
  const isParentGroupStillLoading = useMemo(
    () =>
      isLoading ||
      parentGroup?.actual === null ||
      parentGroup?.budgeted === null ||
      parentGroup?.remaining === null,
    [isLoading, parentGroup],
  );
  const hasAnyData = (parentGroup?.budgeted ?? 0) > 0;
  const groupName = parentGroup?.type?.toLowerCase();

  const rows = useMemo(
    () => (parentGroup?.[breakdownType] ?? []) as SummaryDisplayBudgetType[],
    [parentGroup, breakdownType],
  );

  return (
    <FlexContainer marginBottom="large" column>
      <Switch>
        <Case when={hasAnyData && !isParentGroupStillLoading}>
          {Object.entries(rows)
            .map(([budgetCategoryKey, row]) => ({ ...row, id: budgetCategoryKey }))
            .filter(({ budgeted, actual }) => (budgeted || 0) + (actual || 0) > 0)
            .map((row) =>
              renderBudgetRow({
                item: {
                  ...row,
                  name: row.name,
                  type: row.type || parentGroup?.type,
                },
                isLoading,
                ticks,
                titleSize,
              }),
            )}
        </Case>
        <Case when={!hasAnyData && !isParentGroupStillLoading && !!groupName}>
          <NoDataContainer>
            {groupName ? (
              <Text size="small" color="textLight">
                You haven&apos;t added any {groupName} budgets. When you start budgeting your{' '}
                {pluralize(groupName, 2)}, a summary will appear here.
              </Text>
            ) : (
              <Text size="small" color="textLight">
                You haven&apos;t added any budgets yet.
              </Text>
            )}
          </NoDataContainer>
        </Case>
        <Case default>
          <FlexContainer marginTop="default" justifyCenter>
            <CellLoadingSpinner />
          </FlexContainer>
        </Case>
      </Switch>
    </FlexContainer>
  );
};

const renderBudgetRow = ({
  item,
  isLoading,
  ticks,
  titleSize,
}: Omit<Props, 'data'> & { item: SummaryDisplayBudgetTypeWithId }) => (
  <PlanSummaryWidgetRow
    id={item.id}
    title={item.name || ''}
    actual={item.actual ?? 0}
    budgeted={item.budgeted ?? 0}
    remaining={item.remaining ?? 0}
    rollover={item.rollover ?? 0}
    isLoading={isLoading}
    type={item.type as string}
    ticks={ticks}
    titleSize={titleSize}
  />
);

export default PlanSummaryWidget;
