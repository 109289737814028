import * as R from 'ramda';

import type { InstitutionRowFieldsFragment } from 'common/generated/graphql';
import { DataProviderLegacy } from 'common/generated/graphql';
import type { InstitutionDataProvider } from 'common/types/institutions';

export type InstitutionProviderFields = Pick<
  InstitutionRowFieldsFragment,
  | 'preferredDataProvider'
  | 'firstBackupDataProvider'
  | 'plaidInstitutionId'
  | 'finicityInstitutionId'
  | 'mxInstitutionId'
>;

export type InstitutionDataProviderFallbackMetadata = {
  dataProviderPriority0: number;
  fallbackInstitutionDataProvider: InstitutionDataProvider | undefined;
};

/**
 * The order of keys in this mapping also determines the default fallback order:
 * [plaid, finicity, mx]
 *  */
const DATA_PROVIDER_TO_ID_FIELD: {
  [dataProvider in DataProviderLegacy]: keyof InstitutionProviderFields | null;
} = {
  [DataProviderLegacy.PLAID]: 'plaidInstitutionId',
  [DataProviderLegacy.FINICITY]: 'finicityInstitutionId',
  [DataProviderLegacy.MX]: 'mxInstitutionId',
  [DataProviderLegacy.APPLE_CARD]: null,
  [DataProviderLegacy.COINBASE]: null,
  [DataProviderLegacy.ZILLOW]: null,
  [DataProviderLegacy.APPLE_FINANCE_KIT]: null,
  [DataProviderLegacy.VIN_AUDIT]: null,
};

const DEFAULT_DATA_PROVIDER_ORDER = R.toPairs(DATA_PROVIDER_TO_ID_FIELD)
  .filter(([, field]) => !!field)
  .map(([dataProvider]) => dataProvider as DataProviderLegacy);

export const getInstitutionDataProviders = <TInstitution extends InstitutionProviderFields>(
  institution: TInstitution | undefined,
  currentDataProvider: DataProviderLegacy,
): InstitutionDataProviderFallbackMetadata => {
  const orderedInstitutionDataProviders = institution
    ? getOrderedInstitutionDataProviders(institution)
    : [];

  const dataProviderPriority0 = orderedInstitutionDataProviders
    .map((item) => item.dataProvider)
    .indexOf(currentDataProvider);
  const isPrimaryDataProvider = dataProviderPriority0 === 0;
  const fallbackInstitutionDataProvider =
    isPrimaryDataProvider && orderedInstitutionDataProviders.length > 1
      ? orderedInstitutionDataProviders[1] // get second provider
      : undefined;

  return {
    dataProviderPriority0,
    fallbackInstitutionDataProvider,
  };
};

/**
 * Return a list of InstitutionDataProviders in priority order.
 */
export const getOrderedInstitutionDataProviders = <
  T extends {
    preferredDataProvider: DataProviderLegacy;
    firstBackupDataProvider?: DataProviderLegacy | null;
    plaidInstitutionId?: string | null;
    finicityInstitutionId?: string | null;
    mxInstitutionId?: string | null;
  },
>(
  institution: T,
): InstitutionDataProvider[] => {
  const defaultOrder = R.uniq([
    institution.preferredDataProvider,
    institution.firstBackupDataProvider,
    ...DEFAULT_DATA_PROVIDER_ORDER,
  ]);

  const institutionDataProviders = R.flatten(
    defaultOrder.map((dataProvider) => {
      const fieldName = dataProvider && DATA_PROVIDER_TO_ID_FIELD[dataProvider];
      if (dataProvider && fieldName) {
        const dataProviderInstitutionId = institution[fieldName];

        if (dataProviderInstitutionId) {
          return { dataProvider, dataProviderInstitutionId };
        }
      }

      return [];
    }),
  );

  if (institutionDataProviders.length === 0) {
    // this is important for the Coinbase case
    return [
      {
        dataProvider: institution.preferredDataProvider,
        dataProviderInstitutionId: null,
      },
    ];
  }

  return institutionDataProviders;
};
