import * as RA from 'ramda-adjunct';
import React, { useState, createContext, useMemo, useContext, useEffect } from 'react';
import Helmet from 'react-helmet';
import styled, { css } from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import Flex from 'components/lib/ui/Flex';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import PageHeader from 'components/lib/ui/PageHeader';
import type { ScrollHandles } from 'components/lib/ui/Scroll';
import Scroll from 'components/lib/ui/Scroll';
import { HEADER_HEIGHT } from 'components/routes/Header';

import variables from 'common/lib/theme/variables';
import noop from 'common/utils/noop';
import useNavigationTracking from 'lib/hooks/useNavigationTracking';
import usePageBreadcrumbs from 'lib/hooks/usePageBreadcrumbs';
import useRestoreScrollPosition from 'lib/hooks/useRestoreScrollPosition';
import type { ScrollOffset } from 'state/scroll/reducer';

import type { SidebarRouteName } from 'constants/sidebar';

const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${variables.color.background.page};
  overflow-x: auto;

  @media (max-width: ${({ theme }) => theme.breakPoints.xs}px) {
    min-width: 100vw;
  }
`;

const LoadingContainer = styled(Flex).attrs({ center: true })`
  flex: 1;
  height: 100%;
`;

const ScrollHeaderContainer = styled.div<{ $disabled: boolean }>`
  position: absolute;
  width: 100%;
  top: 0;

  ${({ $disabled }) =>
    $disabled &&
    css`
      @media (min-width: ${({ theme }) => theme.breakPoints.md}px) {
        opacity: 0.4;
        pointer-events: none;
      }
    `}
`;

const DisabledContainer = styled(ScrollHeaderContainer)`
  position: inherit;
`;

const ScrollHeaderSpacer = styled.div`
  width: 100%;
  height: ${HEADER_HEIGHT};
`;

const NonScrollContainer = styled.div<{ disabled: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `}
`;

type SidebarRoutePage = {
  name: SidebarRouteName;
};

type DetailPage = {
  /** The name of the page, used for event tracking. Should not change. */
  name: string;
  /** The default parent route, used for breadcrumbs. Pass null if there is no parent route. */
  parentRoute: SidebarRouteName | null;
};

export type Props = (SidebarRoutePage | DetailPage) & {
  icon?: React.ReactNode;
  /** Dynamic title to override the page. Used just for display. */
  overrideTitle?: string;
  /** Set just the html <title> element (will be concatenated with Monarch | ...). */
  overrideHtmlTitle?: string;
  controls?: React.ReactNode;
  /** Tabs component to show when page has sub routes. */
  tabs?: React.ReactNode;
  overlayEmptyComponent?: React.ReactNode;
  shouldScroll?: boolean;
  initialScrollOffset?: ScrollOffset;
  /** Use this to reset scroll to zero */
  scrollKey?: string;
  scrollRef?: React.RefObject<ScrollHandles>;
  showHeader?: boolean;
  /** Custom header component. If specified, will override default <Header> and related props. */
  header?: React.ReactNode;
  isLoading?: boolean;
  /** Custom component to show when loading. Defaults to loading spinner in center of page. */
  loadingComponent?: React.ReactNode;
  children: React.ReactNode;

  titleIsSensitive?: boolean;
  className?: string;
};

const Page: React.ForwardRefRenderFunction<HTMLDivElement, Props> = (
  {
    icon,
    name,
    overrideTitle,
    overrideHtmlTitle,
    controls,
    children,
    titleIsSensitive,
    overlayEmptyComponent,
    tabs,
    shouldScroll = true,
    initialScrollOffset: initialScrollProp,
    scrollKey,
    scrollRef,
    showHeader = true,
    header: customHeader,
    isLoading = false,
    loadingComponent,
    className,
    ...props
  }: Props,
  ref,
) => {
  const [contextTitle, setContextTitle] = useState<string | undefined>();
  const effectiveTitle = contextTitle || overrideHtmlTitle || overrideTitle || name;
  const pageTitle = `Monarch | ${effectiveTitle}`;

  useNavigationTracking(name, pageTitle);

  const [initialScrollOffset, onScroll] = useRestoreScrollPosition();

  const parentRoute = 'parentRoute' in props ? props.parentRoute : null;
  const breadcrumbs = usePageBreadcrumbs({ defaultParentRoute: parentRoute });

  const header = customHeader ?? (
    <PageHeader
      icon={icon}
      name={name}
      overrideTitle={overrideTitle}
      controls={controls}
      tabs={tabs}
      titleIsSensitive={titleIsSensitive}
      breadcrumbs={breadcrumbs}
    />
  );

  const isNotScrollPage = !shouldScroll;
  const isPageDisabled = RA.isNotNil(overlayEmptyComponent);

  const pageContext: React.ContextType<typeof PageContext> = useMemo(
    () => ({ setContextTitle }),
    [setContextTitle],
  );

  return (
    <PageContext.Provider value={pageContext}>
      <Root ref={ref} className={className}>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        {isNotScrollPage && showHeader && (
          <DisabledContainer $disabled={isPageDisabled}>{header}</DisabledContainer>
        )}
        <Switch>
          <Case when={isLoading}>
            {loadingComponent ?? (
              <LoadingContainer>
                <LoadingSpinner />
              </LoadingContainer>
            )}
          </Case>
          <Case when={shouldScroll}>
            {showHeader && <ScrollHeaderSpacer />}

            <Scroll
              ref={scrollRef}
              disabled={isPageDisabled}
              onScroll={onScroll}
              initialOffset={initialScrollProp || initialScrollOffset}
              key={scrollKey}
            >
              {children}
            </Scroll>

            {showHeader && (
              <ScrollHeaderContainer $disabled={isPageDisabled}>{header}</ScrollHeaderContainer>
            )}
          </Case>
          <Case default>
            <NonScrollContainer disabled={isPageDisabled}>{children}</NonScrollContainer>
          </Case>
        </Switch>
        {overlayEmptyComponent}
      </Root>
    </PageContext.Provider>
  );
};

const PageContext = createContext<{ setContextTitle: (title: string) => void }>({
  setContextTitle: noop,
});

/**
 * Dynamically overrides the page title through context without requiring direct Page component prop manipulation.
 * Updates automatically when the title changes. Designed to be used anywhere within the page component tree,
 * eliminating the need for prop drilling or manual title management.
 *
 * @example
 * <Root>
 *   <DynamicPageTitle>New Page Title</DynamicPageTitle>
 *   <MyComponent />
 * </Root>
 */
export const DynamicPageTitle = ({ children }: { children: string }) => {
  const { setContextTitle } = useContext(PageContext);

  useEffect(() => {
    setContextTitle(children);
  }, [children, setContextTitle]);

  return null;
};

export default React.forwardRef(Page);
