/**
 * Check if the bottom of an element is below the viewport.
 * @param element - The element to check.
 * @param includeScrollMargin - Whether to include the scroll margin in the calculation.
 * @returns True if the bottom of the element is below the viewport, false otherwise.
 */
export function isElementBottomBelowViewport(element: HTMLElement, includeScrollMargin = true) {
  // Get the computed style to read scroll margins
  const computedStyle = window.getComputedStyle(element);

  // Parse scroll margins (converting string values like '20px' to numbers)
  const scrollMarginBottom = parseFloat(computedStyle.scrollMarginBottom) || 0;

  // Get the bounding rectangle of the element
  const rect = element.getBoundingClientRect();

  // Get viewport height
  const windowHeight = window.innerHeight || document.documentElement.clientHeight;

  // Check if the bottom of the element (including its scroll margin) is below the viewport
  return rect.bottom + (includeScrollMargin ? scrollMarginBottom : 0) > windowHeight;
}
