import type { DateTime } from 'luxon';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { useCallback, useMemo, useState } from 'react';

import ExpandMoreIconButton from 'components/lib/ui/ExpandMoreIconButton';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import NotSelectedHelp from 'components/recurring/NotSelectedHelp';
import RecurringAccount from 'components/recurring/RecurringAccount';
import RecurringAmountCell from 'components/recurring/RecurringAmountCell';
import RecurringCategory from 'components/recurring/RecurringCategory';
import RecurringMenu from 'components/recurring/RecurringMenu';
import RecurringStream from 'components/recurring/RecurringStream';
import {
  RecurringList,
  RecurringListHeader,
  RecurringListCell,
  RecurringHeaderTitle,
  RecurringHeaderText,
  RecurringListRows,
  RecurringListRow,
  PaymentDateLabel,
  SortDirection,
  DirectionIcon,
  DateText,
} from 'components/recurring/list/RecurringList';
import RecurringListLoading from 'components/recurring/list/RecurringListLoading';

import { formatNextPaymentDateWithDaysAgo, getFrequencyLabel } from 'common/lib/recurring';
import type { AllRecurringStream } from 'common/lib/recurring/types';

const AMOUNT_LABEL_MARGIN_RIGHT = 52;

type Props = {
  title: string;
  items?: AllRecurringStream[];
  startDate: DateTime;
  isLoading: boolean;
  refetch: () => void;
  isCollapsed?: boolean;
  toggleCollapsed?: () => void;
};

type SortedBy = {
  property: 'name' | 'date' | 'account' | 'category' | 'amount';
  direction: SortDirection;
};

const columnToPathMapping = {
  name: ['stream', 'name'],
  date: ['nextForecastedTransaction', 'date'],
  account: ['account', 'displayName'],
  category: ['category', 'name'],
  amount: ['nextForecastedTransaction', 'amount'],
};

const RecurringAllList = (props: Props) => {
  const {
    startDate,
    items,
    title,
    refetch,
    isLoading = false,
    isCollapsed,
    toggleCollapsed,
  } = props;
  const [sortedBy, setSortedBy] = useState<SortedBy | null>(null);

  const sortedItems = useMemo(() => {
    if (!sortedBy) {
      return items ?? [];
    }

    const directionFunc = sortedBy?.direction === SortDirection.ASC ? R.ascend : R.descend;
    const pathFunction = R.pathOr('', columnToPathMapping[sortedBy.property]);

    const sortByFunction = R.sortWith([
      directionFunc(R.compose(R.ifElse(RA.isNumber, Math.abs, R.trim), pathFunction)),
    ]);
    return sortByFunction(items ?? []);
  }, [items, sortedBy]) as AllRecurringStream[];

  const handleColumnNameClick = useCallback(
    R.curry((property: SortedBy['property'], e: any) => {
      if (sortedBy?.property === property) {
        if (sortedBy?.direction === SortDirection.DESC) {
          setSortedBy(null);
          return;
        }
      }

      setSortedBy({
        property,
        direction:
          sortedBy?.property === property && sortedBy?.direction === SortDirection.ASC
            ? SortDirection.DESC
            : SortDirection.ASC,
      });
    }),
    [setSortedBy, sortedBy],
  );

  if (isLoading) {
    return <RecurringListLoading />;
  }

  if (sortedItems?.length === 0) {
    return null;
  }

  return (
    <RecurringList>
      <RecurringListHeader>
        {/* 
          The marginRight property is needed to make sure header and rows in column align
          Because the first column in the row has a slight increased left padding, but not in the header
        */}
        <RecurringListCell $marginRight>
          <FlexContainer alignCenter gap="xsmall">
            <ExpandMoreIconButton expanded={!isCollapsed} onClick={toggleCollapsed} />
            <RecurringHeaderTitle>{title}</RecurringHeaderTitle>
          </FlexContainer>
        </RecurringListCell>
        <RecurringListCell>
          <RecurringHeaderText onClick={handleColumnNameClick('date')}>
            Next Due Date <DirectionIcon property="date" sortedBy={sortedBy} />
          </RecurringHeaderText>
        </RecurringListCell>
        <RecurringListCell>
          <RecurringHeaderText onClick={handleColumnNameClick('account')}>
            Payment Account <DirectionIcon property="account" sortedBy={sortedBy} />
          </RecurringHeaderText>
        </RecurringListCell>
        <RecurringListCell>
          <RecurringHeaderText onClick={handleColumnNameClick('category')}>
            Category <DirectionIcon property="category" sortedBy={sortedBy} />
          </RecurringHeaderText>
        </RecurringListCell>
        <RecurringListCell $align="right">
          <RecurringHeaderText
            $marginRight={AMOUNT_LABEL_MARGIN_RIGHT}
            onClick={handleColumnNameClick('amount')}
          >
            Amount <DirectionIcon property="amount" sortedBy={sortedBy} />
          </RecurringHeaderText>
        </RecurringListCell>
      </RecurringListHeader>

      <RecurringListRows open={!isCollapsed}>
        <FlexContainer column>
          {sortedItems.map(({ stream, account, category, nextForecastedTransaction }) => (
            <RecurringListRow key={stream.id}>
              <RecurringListCell>
                <RecurringStream
                  id={stream.merchant?.id ?? stream.creditReportLiabilityAccount?.account?.id ?? ''}
                  name={stream?.name ?? ''}
                  frequency={getFrequencyLabel(stream.frequency)}
                  logoUrl={stream.logoUrl}
                  startDate={startDate}
                  isMerchant={!!stream.merchant}
                />
              </RecurringListCell>
              <RecurringListCell>
                <PaymentDateLabel>
                  <DateText>
                    {
                      formatNextPaymentDateWithDaysAgo(nextForecastedTransaction.date, true)
                        ?.nextPaymentDateFormatted
                    }
                  </DateText>
                  <Text color="textLight">
                    (
                    {
                      formatNextPaymentDateWithDaysAgo(nextForecastedTransaction.date, true)
                        ?.daysUntilNextPaymentLabel
                    }
                    )
                  </Text>
                </PaymentDateLabel>
              </RecurringListCell>
              <RecurringListCell>
                {account ? (
                  <RecurringAccount account={account} />
                ) : (
                  <NotSelectedHelp columnType="account" isMerchant={!!stream.merchant} />
                )}
              </RecurringListCell>
              <RecurringListCell>
                {category ? (
                  <RecurringCategory category={category} startDate={startDate} />
                ) : (
                  <NotSelectedHelp columnType="category" isMerchant={!!stream.merchant} />
                )}
              </RecurringListCell>
              <RecurringListCell>
                <FlexContainer alignCenter gap="default" justifyEnd>
                  <RecurringAmountCell
                    amount={nextForecastedTransaction.amount}
                    isPast={false}
                    isApproximate={stream.isApproximate}
                  />

                  <RecurringMenu
                    merchantId={stream?.merchant?.id}
                    name={stream.name}
                    startDate={startDate}
                    refetch={refetch}
                    streamId={stream.id}
                    accountId={stream?.creditReportLiabilityAccount?.account?.id}
                  />
                </FlexContainer>
              </RecurringListCell>
            </RecurringListRow>
          ))}
        </FlexContainer>
      </RecurringListRows>
    </RecurringList>
  );
};

export default RecurringAllList;
