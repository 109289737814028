import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import type { ThemeType } from 'styled-components';
import styled, { css } from 'styled-components';

import Emoji from 'components/lib/ui/Emoji';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import CashFlowCurrency from 'components/lib/ui/currency/CashFlowCurrency';
import MerchantLogo from 'components/merchants/MerchantLogo';

import { getBarColorName } from 'common/lib/cashFlow';
import { formatPercent } from 'common/utils/Number';
import type { BreakdownEntry } from 'lib/cashFlow/BreakdownAdapters';

export type Color = keyof ThemeType['color'];

const Bar = styled.div<{ size: number; color: Color }>`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: ${({ size }) => size * 100}%;
  opacity: 0.3;
  transition:
    ${({ theme }) => theme.transition.default},
    width 600ms ease-out;

  background-color: ${({ theme, color }) => theme.color[color]};
  border-radius: ${({ theme }) => theme.radius.medium};
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;

const StyledFlexContainer = styled(FlexContainer)`
  overflow: hidden;
  max-width: calc(100vw - 500px);
`;

const Container = styled.div<{ $hasLink: boolean }>`
  position: relative;
  ${({ $hasLink }) =>
    $hasLink
      ? css`
          &:hover {
            cursor: pointer;

            ${Bar} {
              opacity: 0.4;
            }
          }
        `
      : null}
`;

const Info = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;
  padding-left: 24px;
  padding-right: 16px;
  box-sizing: border-box;
`;

const StyledMerchantLogo = styled(MerchantLogo)`
  margin-right: ${({ theme }) => theme.spacing.small};
`;

const CategoryName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Price = styled(Text)`
  white-space: nowrap;
`;

export type Props = {
  className?: string;
  color?: Color;
  entry: BreakdownEntry;
  max: number;
  total: number;
  hideAmounts: boolean;
};

// Make the typical value "positive"
export const balance = ({ type, value }: BreakdownEntry) => (type === 'expense' ? -value : value);

const BreakdownItem = ({ className, max, total, color, entry, hideAmounts }: Props) => {
  const history = useHistory();

  const { icon, logoUrl, name, value, type, link } = entry;
  const hasFlippedValue = (value > 0 && type === 'expense') || (value < 0 && type === 'income');
  // Math.min avoids overflowing for really odd cases (e.g. income categories with all transactions as expenses)
  const size = Math.min(1, Math.abs(value / max));
  const percent = value / total;
  const barColor = useMemo(
    () => color ?? (getBarColorName(type, hasFlippedValue) as Color),
    [color, type, hasFlippedValue],
  );

  return (
    <Container className={className} onClick={() => link && history.push(link)} $hasLink={!!link}>
      <Bar size={size} color={barColor} />
      <Info>
        <StyledFlexContainer gap="xxsmall" alignCenter justifyStart>
          {icon && <Emoji>{icon}</Emoji>}
          {logoUrl ? (
            <FlexContainer>
              <StyledMerchantLogo url={logoUrl} size={24} />
              <CategoryName>{name}</CategoryName>
            </FlexContainer>
          ) : (
            <CategoryName>{name}</CategoryName>
          )}
        </StyledFlexContainer>

        <Price>
          {hideAmounts ? (
            formatPercent(percent)
          ) : (
            <>
              <CashFlowCurrency emphasis={balance(entry) < 0} type={type} value={value} /> (
              {formatPercent(percent)})
            </>
          )}
        </Price>
      </Info>
    </Container>
  );
};

export default BreakdownItem;
