import React, { useEffect } from 'react';

import ModalCard from 'components/lib/ui/ModalCard';
import PostContent from 'components/posts/PostContent';

import rebrandWhatsNewPost from 'common/lib/posts/rebrandWhatsNewPost';
import type { Post } from 'common/lib/posts/types';
import useParams from 'lib/hooks/useParams';
import { useWhatsNew } from 'lib/hooks/useWhatsNew';

const SLUG_TO_POST: { [slug: string]: Post } = {
  'brand-refresh': rebrandWhatsNewPost,
};

type Props = {
  onClose: () => void;
};

const WhatsNewModal = ({ onClose }: Props) => {
  const { dismissWhatsNew } = useWhatsNew();

  const { slug } = useParams();
  const post = slug && SLUG_TO_POST[slug];

  useEffect(() => {
    if (!post) {
      onClose();
    } else {
      dismissWhatsNew();
    }
  }, [post]);

  if (!post) {
    return null;
  }

  return (
    <ModalCard title="What's new">
      <PostContent post={post} />
    </ModalCard>
  );
};

export default WhatsNewModal;
