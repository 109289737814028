import React from 'react';
import type { CartesianGridProps } from 'recharts';
import { CartesianGrid as RechartsCartesianGrid } from 'recharts';

import variables from 'common/lib/theme/variables';
import useTheme from 'lib/hooks/useTheme';

type Props = CartesianGridProps & { dashed?: boolean };

const CartesianGrid = ({ dashed, ...props }: Props) => {
  const theme = useTheme();
  const gridColor = variables.color.chart.axis({ theme });
  const { stroke = gridColor, ...restProps } = props;

  return (
    <RechartsCartesianGrid
      vertical={false}
      {...restProps}
      stroke={stroke}
      strokeDasharray={dashed ? '4 4' : undefined}
    />
  );
};

// Fixes a bug where this wasn't rendered on prod
CartesianGrid.displayName = 'CartesianGrid';

export default CartesianGrid;
