import { yupToFormErrors } from 'formik';
import { DateTime } from 'luxon';
import * as R from 'ramda';
import * as Yup from 'yup';

import type {
  DisplayBudget,
  DisplayGroup,
  DisplayItem,
  BudgetAmounts,
} from 'common/lib/budget/Adapters';
import type { OptimisticBudgetUpdateOptions } from 'common/lib/hooks/budget/useOptimisticBudgetUpdates';

import type { CategoryGroupType, Maybe, MoveMoneyMutationInput } from 'common/generated/graphql';

/** Minimum info needed to do calculations */
export type CalculableBudget = {
  details: {
    byCategory: DisplayItem[];
    byCategoryGroups: DisplayGroup[];
  };
};

export type FormValues = Pick<MoveMoneyMutationInput, 'fromCategoryId' | 'toCategoryId' | 'amount'>;

type DisplayError = {
  title: string;
  message: string;
};

type CategoryWithAmount = {
  id: string;
  amounts: Maybe<BudgetAmounts>;
};

export type MoveMoneyCalculationResult =
  | {
      fromUpdate: OptimisticBudgetUpdateOptions;
      toUpdate: OptimisticBudgetUpdateOptions;
    }
  | undefined;

export const getAmountsFromCalculableBudget = (
  budget: CalculableBudget,
  categoryOrGroupId: FormValues['fromCategoryId'],
  isGroup?: boolean,
) => {
  const isMatchingId = R.propEq('id', categoryOrGroupId);

  // Merge budgeted and unbudgeted categories so we only search once
  const allCategories = !isGroup
    ? budget.details.byCategoryGroups
        .flatMap(({ unbudgetedCategories = [] }) => unbudgetedCategories)
        .concat(budget.details.byCategory)
    : [];

  return isGroup
    ? budget.details.byCategoryGroups.find(isMatchingId)?.budgetedAmounts
    : allCategories.find(isMatchingId)?.amounts;
};

export const getAmountsFromDisplayBudget = (
  data: DisplayBudget | undefined,
  id: Maybe<string>,
  isGroup: boolean,
  type: CategoryGroupType,
) => {
  if (!data || !id) {
    return undefined;
  }

  const displayType = R.find(R.propEq('type', type), data.displayTypes);
  const displayGroups: DisplayGroup[] = displayType?.displayGroups ?? [];

  if (isGroup) {
    const group = R.find(R.propEq('id', id), displayGroups);
    return group?.budgetedAmounts;
  }

  const allCategories = R.chain(
    (group) => R.concat(group.budgetedCategories ?? [], group.unbudgetedCategories ?? []),
    displayGroups,
  );

  const category = R.find(R.propEq('id', id), allCategories);
  return category?.amounts;
};

export const calculateMoveMoneyOptimisticUpdates = (
  mutationInput: MoveMoneyMutationInput,
  budgetListData: DisplayBudget | undefined,
  categoryType: CategoryGroupType,
): MoveMoneyCalculationResult => {
  if (!budgetListData) {
    return undefined;
  }

  const fromId = mutationInput.fromCategoryGroupId ?? mutationInput.fromCategoryId;
  const toId = mutationInput.toCategoryGroupId ?? mutationInput.toCategoryId;

  const isFromGroup = !!mutationInput.fromCategoryGroupId;
  const isToGroup = !!mutationInput.toCategoryGroupId;

  const fromCategoryOrGroup = getAmountsFromDisplayBudget(
    budgetListData,
    fromId,
    isFromGroup,
    categoryType,
  );

  const toCategoryOrGroup = getAmountsFromDisplayBudget(
    budgetListData,
    toId,
    isToGroup,
    categoryType,
  );

  // If we don't have the category or group amounts for either the from or to,
  // return undefined because we can't calculate the updates
  if (!fromCategoryOrGroup || !fromId || !toCategoryOrGroup || !toId) {
    return undefined;
  }

  return {
    fromUpdate: {
      id: fromId,
      amount: (fromCategoryOrGroup.budgeted ?? 0) - mutationInput.amount,
      isGroup: isFromGroup,
      applyToFuture: false,
      startDate: DateTime.fromISO(mutationInput.startDate),
    },
    toUpdate: {
      id: toId,
      amount: (toCategoryOrGroup.budgeted ?? 0) + mutationInput.amount,
      isGroup: isToGroup,
      applyToFuture: false,
      startDate: DateTime.fromISO(mutationInput.startDate),
    },
  };
};

/**
 * Determines initial form values for the move money form based on the selected category's budget status
 */
export const getInitialFormValues = (
  budget: CalculableBudget,
  initialId: string,
  categoryGroupType: CategoryGroupType,
  isGroup?: boolean,
): FormValues => {
  // Get available amount for initial category/group
  const { available: rawAvailable } =
    getAmountsFromCalculableBudget(budget, initialId, isGroup) ?? {};
  const available = rawAvailable || 0;
  const availableAmount = available > 0 ? Math.floor(available) : Math.round(available);

  // Find categories with most/least available funds
  const categoryWithMostFunds = findCategoryWithMaxMinAvailableAmount(
    budget,
    categoryGroupType,
    true,
  );
  const categoryWithLeastFunds = findCategoryWithMaxMinAvailableAmount(
    budget,
    categoryGroupType,
    false,
  );

  // Ensure we don't select the same category for both from/to
  const getAlternateCategoryId = (id?: string) => (id === initialId ? undefined : id);

  if (availableAmount < 0) {
    // Handle negative balance - move from category with most funds
    return handleNegativeBalance(
      getAlternateCategoryId,
      availableAmount,
      initialId,
      categoryWithMostFunds,
    );
  }

  if (availableAmount > 0) {
    // Handle positive balance
    return handlePositiveBalance(
      getAlternateCategoryId,
      availableAmount,
      initialId,
      categoryWithLeastFunds,
      categoryWithMostFunds,
    );
  }

  // Handle zero balance - default to receiving funds from category with most available
  return {
    toCategoryId: initialId,
    fromCategoryId: getAlternateCategoryId(categoryWithMostFunds?.id),
    amount: categoryWithMostFunds?.amounts?.available ?? 0,
  };
};

const handleNegativeBalance = (
  getAlternateCategoryId: (id?: string) => string | undefined,
  availableAmount: number,
  initialId: string,
  categoryWithMostFunds?: CategoryWithAmount,
): FormValues => {
  const maxAvailable = Math.abs(categoryWithMostFunds?.amounts?.available ?? 0);
  const absAvailableAmount = Math.abs(availableAmount);
  return {
    toCategoryId: initialId,
    fromCategoryId: getAlternateCategoryId(categoryWithMostFunds?.id),
    // Limit the transfer amount to the maximum available funds in the source
    // category, even if the target category needs more
    amount: Math.min(absAvailableAmount, maxAvailable),
  };
};

const handlePositiveBalance = (
  getAlternateCategoryId: (id?: string) => string | undefined,
  availableAmount: number,
  initialId: string,
  categoryWithLeastFunds?: CategoryWithAmount,
  categoryWithMostFunds?: CategoryWithAmount,
): FormValues => {
  const isLeastFundedCategory = initialId === categoryWithLeastFunds?.id;

  if (isLeastFundedCategory) {
    return {
      toCategoryId: initialId,
      fromCategoryId: getAlternateCategoryId(categoryWithMostFunds?.id),
      amount: 0,
    };
  }

  const neededAmount = getOverBudgetAmount(categoryWithLeastFunds?.amounts?.available ?? 0);
  return {
    fromCategoryId: initialId,
    toCategoryId: getAlternateCategoryId(categoryWithLeastFunds?.id),
    amount: Math.min(availableAmount, neededAmount),
  };
};

export const validateValues = (
  { fromCategoryId, toCategoryId, amount }: Partial<FormValues>,
  budget: CalculableBudget,
): [FormValues | undefined, DisplayError | undefined] => {
  if (!fromCategoryId) {
    return [
      undefined,
      {
        title: 'Invalid Selection',
        message: 'Please select a budget to transfer from.',
      },
    ];
  }
  if (!toCategoryId) {
    return [
      undefined,
      {
        title: 'Invalid Selection',
        message: 'Please select a budget to transfer to.',
      },
    ];
  }
  if (!amount || amount < 0) {
    return [
      undefined,
      {
        title: 'Invalid Amount',
        message: 'Please enter an amount greater than 0.',
      },
    ];
  }
  if (fromCategoryId === toCategoryId) {
    return [
      undefined,
      {
        title: 'Invalid Selection',
        message: 'From budget must be different than to budget.',
      },
    ];
  }

  const isFromGroup = budget?.details.byCategoryGroups.find(({ id }) => id === fromCategoryId);

  const amountForCategory =
    getAmountsFromCalculableBudget(budget, fromCategoryId, !!isFromGroup)?.available ?? 0;
  const fromBudgetRemaining =
    amountForCategory > 0 ? Math.floor(amountForCategory) : Math.round(amountForCategory);

  if (amount > fromBudgetRemaining) {
    return [
      undefined,
      {
        title: 'Invalid Amount',
        message: `The amount you entered ($${amount}) is greater than the $${fromBudgetRemaining} remaining for this budget.`,
      },
    ];
  }
  return [
    {
      fromCategoryId,
      toCategoryId,
      amount,
    },
    undefined,
  ];
};

export const validateMoveMoneyForm = (values: FormValues, budget: CalculableBudget) => {
  const isGroup = !!budget.details.byCategoryGroups.find(({ id }) => id === values.fromCategoryId);

  const { available: fromCategoryAvailable } = values.fromCategoryId
    ? getAmountsFromCalculableBudget(budget, values.fromCategoryId, isGroup) ?? { available: 0 }
    : { available: 0 };
  const available = fromCategoryAvailable ?? 0;

  const schema = Yup.object().shape({
    fromCategoryId: Yup.string().required('Please select a category.'),
    toCategoryId: Yup.string().required('Please select a category.'),
    amount: Yup.number()
      .required('Please enter an amount.')
      .moreThan(0, 'Please enter an amount greater than $0.')
      .lessThan(
        available + 1,
        available > 0
          ? `Please ensure the amount is lower than or equal to $${available}.`
          : 'Please ensure the "From" category has a balance greater than $0.',
      ),
  });

  try {
    schema.validateSync(values);
  } catch (error) {
    return yupToFormErrors(error);
  }

  return {};
};

export const getAvailableAmount = (
  budget: CalculableBudget | undefined | null,
  fromCategoryType: CategoryGroupType,
  fromCategoryId: string | undefined,
  toCategoryId: string | undefined,
) => {
  if (!budget || !fromCategoryId || !toCategoryId) {
    return 0;
  }

  const isFromGroup = !!budget.details.byCategoryGroups.find(({ id }) => id === fromCategoryId);

  const isToGroup = !!budget.details.byCategoryGroups.find(({ id }) => id === toCategoryId);

  const { available: fromAvailable } =
    getAmountsFromCalculableBudget(budget, fromCategoryId, isFromGroup) ?? {};
  const { amount: toAmount } =
    getInitialFormValues(budget, toCategoryId, fromCategoryType, isToGroup) ?? {};

  const amount = Math.min(fromAvailable ?? 0, toAmount ?? 0);

  // Don't allow negative numbers
  return Math.floor(Math.max(0, amount));
};

/** Given an available amount, returns how much that available amount is over budget. Returns 0 if not over budget. */
const getOverBudgetAmount = (available: number) => Math.floor(Math.max(0, available * -1));

const findCategoryWithMaxMinAvailableAmount = (
  budget: CalculableBudget,
  categoryType: CategoryGroupType,
  maximum: boolean,
) => {
  const availableOptions = [
    ...budget.details.byCategory
      .filter(
        ({ groupType, groupLevelBudgetingEnabled }) =>
          groupType === categoryType && !groupLevelBudgetingEnabled,
      )
      .map(({ id, amounts }) => ({
        id,
        amounts,
      })),
    ...budget.details.byCategoryGroups
      .filter(
        ({ type, groupLevelBudgetingEnabled }) =>
          type === categoryType && groupLevelBudgetingEnabled,
      )
      .map(({ id, budgetedAmounts }) => ({
        id,
        amounts: budgetedAmounts,
      })),
  ];

  return availableOptions.reduce((extreme, current) => {
    if (maximum && (current.amounts?.available ?? 0) > (extreme.amounts?.available ?? 0)) {
      return current;
    } else if (!maximum && (current.amounts?.available ?? 0) < (extreme.amounts?.available ?? 0)) {
      return current;
    }
    return extreme;
  }, availableOptions[0]);
};

export const convertDisplayBudgetToCalculableBudget = (data: DisplayBudget): CalculableBudget => {
  const categories = R.flatten(
    data.displayTypes.map((type) => type.displayGroups.map((group) => group.budgetedCategories)),
  );

  const categoryGroups = R.flatten(data.displayTypes.map((type) => type.displayGroups));

  return {
    details: {
      byCategory: categories,
      byCategoryGroups: categoryGroups,
    },
  };
};
