import { ellipsis } from 'polished';
import React from 'react';
import styled from 'styled-components';

import DragHandle from 'components/lib/dnd/DragHandle';

import boxShadow from 'common/lib/styles/boxShadow';
import { color, fontSize, radius, spacing, transition } from 'common/lib/theme/dynamic';
import type { PlanCategory, PlanCategoryGroup } from 'lib/plan/types';

const MAX_WIDTH_PX = 180;

const HandleContainer = styled.div`
  transition: ${transition.default};
  opacity: 0.6;
`;

const Root = styled.div<{ $isActive?: boolean }>`
  display: flex;
  align-items: center;
  gap: ${spacing.small};
  background: ${color.white};
  border-radius: ${radius.small};
  font-size: ${fontSize.small};
  cursor: ${({ $isActive }) => ($isActive ? 'grabbing' : 'grab')};
  user-select: none;
  transition: ${transition.default};
  max-width: ${MAX_WIDTH_PX}px;
  ${({ $isActive }) => ($isActive ? boxShadow.large : boxShadow.small)};
  animation: ${({ $isActive }) =>
    // Creates an effect where the box shadow transitions from small to large when the item is being dragged
    $isActive ? 'transition-shadow 0.2s ease-in-out' : 'none'};
  animation-fill-mode: forwards;
  animation-iteration-count: 1;

  &:hover,
  &:active,
  &:focus {
    ${HandleContainer} {
      opacity: 1;
    }
  }

  @keyframes transition-shadow {
    from {
      ${boxShadow.small};
    }

    to {
      ${boxShadow.large};
    }
  }
`;

const Content = styled.div`
  padding: ${spacing.xxsmall} ${spacing.small} ${spacing.xxsmall} 0;
  ${ellipsis()}
`;

type Props = {
  item: PlanCategory | PlanCategoryGroup;
  /** Whether the item is being dragged */
  isActive?: boolean;
  isGroup?: boolean;
};

/**
 * Just a presentational component for the draggable item. All of the drag-and-drop logic is handled in the parent component.
 */
const OnboardingVariabilityItem = ({ item, isActive, isGroup }: Props) => (
  <Root $isActive={isActive} title={item.name}>
    <HandleContainer>
      <DragHandle />
    </HandleContainer>
    <Content>
      {!isGroup && ((item as PlanCategory).icon ?? '')} {item.name}
    </Content>
  </Root>
);

export default OnboardingVariabilityItem;
