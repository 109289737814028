import type { ActionType } from 'typesafe-actions';
import { createReducer } from 'typesafe-actions';

import { setFlag } from 'common/actions';
import type * as actions from 'common/state/flags/actions';

type FlagsAction = ActionType<typeof actions>;

export type FlagsState = {
  flags: { [key: string]: boolean };
};

const INITIAL_STATE: FlagsState = {
  flags: {},
};

/**
 * State used to store boolean flags for things like "user has seen this" or "user has dismissed this".
 *
 * Persisted on web and mobile.
 */
const flags = createReducer<FlagsState, FlagsAction>(INITIAL_STATE).handleAction(
  setFlag,
  (state, { payload: { flag, value } }) => ({
    ...state,
    flags: {
      ...state.flags,
      [flag]: value,
    },
  }),
);

export default flags;
