import type { DateTime } from 'luxon';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { useCallback, useMemo, useState } from 'react';

import ExpandMoreIconButton from 'components/lib/ui/ExpandMoreIconButton';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';
import NotSelectedHelp from 'components/recurring/NotSelectedHelp';
import RecurringAccount from 'components/recurring/RecurringAccount';
import RecurringCategory from 'components/recurring/RecurringCategory';
import RecurringStream from 'components/recurring/RecurringStream';
import RecurringAmountAndMenu from 'components/recurring/list/RecurringAmountAndMenu';
import {
  RecurringList,
  RecurringListHeader,
  RecurringListCell,
  RecurringHeaderTitle,
  RecurringHeaderText,
  RecurringListRows,
  RecurringListRow,
  PaymentDateLabel,
  SortDirection,
  DirectionIcon,
  SummaryBox,
  SummaryColumn,
  SummaryLabel,
  SummaryText,
  SummaryAmounts,
  DateText,
} from 'components/recurring/list/RecurringList';
import RecurringListLoading from 'components/recurring/list/RecurringListLoading';

import { formatNextPaymentDateWithDaysAgo, getFrequencyLabel } from 'common/lib/recurring';
import type {
  GroupSummaryByRecurringType,
  RecurringTransactionItem,
} from 'common/lib/recurring/types';
import { formatCurrency } from 'common/utils/Currency';
import formatTransactionAmount from 'common/utils/formatTransactionAmount';

const AMOUNT_LABEL_MARGIN_RIGHT = 52;

type Props = {
  title: string;
  items?: RecurringTransactionItem[];
  summary: Maybe<GroupSummaryByRecurringType>;
  startDate: DateTime;
  refetch: () => void;
  isLoading: boolean;
  isCollapsed?: boolean;
  toggleCollapsed?: () => void;
};

type SortedBy = {
  property: 'name' | 'date' | 'account' | 'category' | 'amount';
  direction: SortDirection;
};

const columnToPathMapping = {
  name: ['stream', 'name'],
  date: ['date'],
  account: ['account', 'displayName'],
  category: ['category', 'name'],
  amount: ['amount'],
};

const RecurringUpcomingList = (props: Props) => {
  const {
    startDate,
    refetch,
    items,
    title,
    isLoading = false,
    summary,
    isCollapsed,
    toggleCollapsed,
  } = props;
  const [sortedBy, setSortedBy] = useState<SortedBy | null>(null);

  const sortedItems = useMemo(() => {
    if (!sortedBy) {
      return items ?? [];
    }

    const directionFunc = sortedBy?.direction === SortDirection.ASC ? R.ascend : R.descend;
    const pathFunction = R.pathOr('', columnToPathMapping[sortedBy.property]);

    const sortByFunction = R.sortWith([
      directionFunc(R.compose(R.ifElse(RA.isNumber, Math.abs, R.trim), pathFunction)),
    ]);
    return sortByFunction(items ?? []);
  }, [items, sortedBy]);

  const handleColumnNameClick = useCallback(
    R.curry((property: SortedBy['property'], e: any) => {
      if (sortedBy?.property === property) {
        if (sortedBy?.direction === SortDirection.DESC) {
          setSortedBy(null);
          return;
        }
      }

      setSortedBy({
        property,
        direction:
          sortedBy?.property === property && sortedBy?.direction === SortDirection.ASC
            ? SortDirection.DESC
            : SortDirection.ASC,
      });
    }),
    [setSortedBy, sortedBy],
  );

  if (isLoading) {
    return <RecurringListLoading />;
  }

  if (sortedItems?.length === 0) {
    return null;
  }

  return (
    <div>
      <RecurringList>
        <RecurringListHeader>
          {/* 
            The marginRight property is needed to make sure header and rows in column align
            Because the first column in the row has a slight increased left padding, but not in the header
          */}
          <RecurringListCell $marginRight>
            <FlexContainer alignCenter gap="xsmall">
              <ExpandMoreIconButton expanded={!isCollapsed} onClick={toggleCollapsed} />
              <RecurringHeaderTitle>{title}</RecurringHeaderTitle>
            </FlexContainer>
          </RecurringListCell>
          <RecurringListCell>
            <RecurringHeaderText onClick={handleColumnNameClick('date')}>
              Date <DirectionIcon property="date" sortedBy={sortedBy} />
            </RecurringHeaderText>
          </RecurringListCell>
          <RecurringListCell>
            <RecurringHeaderText onClick={handleColumnNameClick('account')}>
              Payment Account <DirectionIcon property="account" sortedBy={sortedBy} />
            </RecurringHeaderText>
          </RecurringListCell>
          <RecurringListCell>
            <RecurringHeaderText onClick={handleColumnNameClick('category')}>
              Category <DirectionIcon property="category" sortedBy={sortedBy} />
            </RecurringHeaderText>
          </RecurringListCell>
          <RecurringListCell $align="right">
            <RecurringHeaderText
              $marginRight={AMOUNT_LABEL_MARGIN_RIGHT}
              onClick={handleColumnNameClick('amount')}
            >
              Amount <DirectionIcon property="amount" sortedBy={sortedBy} />
            </RecurringHeaderText>
          </RecurringListCell>
        </RecurringListHeader>

        <RecurringListRows open={!isCollapsed}>
          <FlexContainer column>
            {sortedItems.map(({ stream, date, account, category, isLate, amount, ...rest }) => (
              <RecurringListRow key={stream.id + date}>
                <RecurringListCell>
                  <RecurringStream
                    id={
                      stream.merchant?.id ?? stream.creditReportLiabilityAccount?.account?.id ?? ''
                    }
                    name={stream?.name ?? ''}
                    frequency={getFrequencyLabel(stream.frequency)}
                    logoUrl={stream.logoUrl}
                    startDate={startDate}
                    isMerchant={!!stream.merchant}
                  />
                </RecurringListCell>
                <RecurringListCell>
                  <PaymentDateLabel $late={!!stream.creditReportLiabilityAccount && isLate}>
                    <DateText>
                      {formatNextPaymentDateWithDaysAgo(date)?.nextPaymentDateFormatted}
                    </DateText>
                    <Text
                      color={
                        !!stream.creditReportLiabilityAccount && isLate ? 'orange' : 'textLight'
                      }
                    >
                      ({formatNextPaymentDateWithDaysAgo(date)?.daysUntilNextPaymentLabel})
                    </Text>
                  </PaymentDateLabel>
                </RecurringListCell>
                <RecurringListCell>
                  {account ? (
                    <RecurringAccount account={account} />
                  ) : (
                    <NotSelectedHelp columnType="account" isMerchant={!!stream.merchant} />
                  )}
                </RecurringListCell>
                <RecurringListCell>
                  {category ? (
                    <RecurringCategory category={category} startDate={startDate} />
                  ) : (
                    <NotSelectedHelp columnType="category" isMerchant={!!stream.merchant} />
                  )}
                </RecurringListCell>
                <RecurringListCell>
                  <RecurringAmountAndMenu
                    amount={amount ?? undefined}
                    item={{
                      stream,
                      date,
                      account,
                      category,
                      isLate,
                      amount,
                      ...rest,
                    }}
                    refetch={refetch}
                    startDate={startDate}
                  />
                </RecurringListCell>
              </RecurringListRow>
            ))}
          </FlexContainer>
        </RecurringListRows>
      </RecurringList>

      {summary ? (
        <SummaryBox>
          <SummaryColumn $align="left">
            <SummaryLabel>{title}</SummaryLabel>
            <SummaryText>Total</SummaryText>
          </SummaryColumn>

          <SummaryAmounts>
            <SummaryColumn>
              <SummaryLabel>Income</SummaryLabel>
              <SummaryText $isIncome={(summary?.income?.total ?? 0) > 0}>
                {formatTransactionAmount(Math.abs(summary?.income?.total ?? 0))}
              </SummaryText>
            </SummaryColumn>

            <SummaryColumn>
              <SummaryLabel>Credit cards</SummaryLabel>
              <SummaryText>{formatCurrency(Math.abs(summary?.creditCard?.total ?? 0))}</SummaryText>
            </SummaryColumn>

            <SummaryColumn>
              <SummaryLabel>Expenses</SummaryLabel>
              <SummaryText>{formatCurrency(Math.abs(summary?.expense?.total ?? 0))}</SummaryText>
            </SummaryColumn>
          </SummaryAmounts>
        </SummaryBox>
      ) : null}
    </div>
  );
};

export default RecurringUpcomingList;
