import { gql } from '@apollo/client';

import { PAYLOAD_ERRORS_FRAGMENT } from 'common/lib/graphQl/errors';

import { gql as newGql } from 'common/generated/gql';

export const UPDATE_MERCHANT_RECURRING = gql`
  mutation Common_RecurringUpdateMerchant($input: UpdateMerchantInput!) {
    updateMerchant(input: $input) {
      merchant {
        id
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
  ${PAYLOAD_ERRORS_FRAGMENT}
`;

export const UPDATE_LIABILITY_STATEMENT = newGql(/* GraphQL */ `
  mutation Common_RecurringUpdateLiabilityStatement(
    $creditReportLiabilityStatementId: ID!
    $userBillAmount: Decimal
    $revertToOriginal: Boolean
  ) {
    updateBillAmount(
      creditReportLiabilityStatementId: $creditReportLiabilityStatementId
      userBillAmount: $userBillAmount
      revertToOriginal: $revertToOriginal
    ) {
      creditReportLiabilityStatement {
        id
        billAmount
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);

export const UPDATE_LIABILITY_STATEMENT_PAYMENT_STATUS = newGql(/* GraphQL */ `
  mutation Common_MarkRecurringLiabilityStatementAsPaid(
    $creditReportLiabilityStatementId: ID!
    $isPaid: Boolean!
  ) {
    updateBillPaid(
      creditReportLiabilityStatementId: $creditReportLiabilityStatementId
      isPaid: $isPaid
    ) {
      creditReportLiabilityStatement {
        id
        billAmount
        userMarkedPaidAt
      }
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);

export const MARK_STREAM_AS_NOT_RECURRING = newGql(/* GraphQL */ `
  mutation Common_MarkAsNotRecurring($streamId: ID!) {
    markStreamAsNotRecurring(streamId: $streamId) {
      success
      errors {
        ...PayloadErrorFields
      }
    }
  }
`);

export const AGGREGATED_RECURRING_FRAGMENTS = newGql(/* GraphQL */ `
  fragment RecurringItemFields on RecurringTransactionCalendarItem {
    stream {
      ...RecurringItemStreamFields
    }
    date
    isPast
    isLate
    markedPaidAt
    isCompleted
    transactionId
    amount
    amountDiff
    isAmountDifferentThanOriginal
    creditReportLiabilityStatementId
    category {
      id
      name
      icon
    }
    account {
      id
      displayName
      icon
      logoUrl
    }
    liabilityStatement {
      ...RecurringItemLiabilityFields
    }
  }
  fragment RecurringItemStreamFields on RecurringTransactionStream {
    id
    frequency
    isActive
    amount
    isApproximate
    name
    logoUrl
    merchant {
      id
      name
      logoUrl
    }
    creditReportLiabilityAccount {
      id
      liabilityType
      account {
        id
      }
    }
  }
  fragment RecurringItemLiabilityFields on LiabilityStatement {
    id
    minimumPaymentAmount
    paymentsInformation {
      status
      remainingBalance
      transactions {
        id
        amount
        date
        category {
          id
          name
          icon
          group {
            id
            name
            type
          }
        }
      }
    }
  }
`);

export const GET_AGGREGATED_RECURRING_ITEMS = newGql(/* GraphQL */ `
  query Common_GetAggregatedRecurringItems(
    $startDate: Date!
    $endDate: Date!
    $filters: RecurringTransactionFilter
  ) {
    aggregatedRecurringItems(
      startDate: $startDate
      endDate: $endDate
      groupBy: "status"
      filters: $filters
    ) {
      groups {
        groupBy {
          status
        }

        results {
          ...RecurringItemFields
        }

        summary {
          expense {
            total
          }
          creditCard {
            total
          }
          income {
            total
          }
        }
      }

      aggregatedSummary {
        expense {
          completed
          remaining
          total
          count
          pendingAmountCount
        }
        creditCard {
          completed
          remaining
          total
          count
          pendingAmountCount
        }
        income {
          completed
          remaining
          total
        }
      }
    }
  }
`);
