// THIS FILE IS GENERATED, DO NOT MODIFY MANUALLY. ##checksum##: 3742718670
import { createAction } from 'typesafe-actions';

import type { SankeyGroupMode } from 'lib/cashFlow/sankey';
import type { ReportsTab, ReportsChart } from 'state/reports/types';

import type {
  ReportsGroupByEntity,
  ReportsGroupByTimeframe,
  TransactionOrdering,
} from 'common/generated/graphql';
import type { TransactionFilters } from 'types/filters';

export const patchReportsFilters = createAction('REPORTS/PATCH_REPORTS_FILTERS')<
  Partial<TransactionFilters>
>();

export const resetToDefaultView = createAction('REPORTS/RESET_TO_DEFAULT_VIEW')();

export const setChartTypeForTab = createAction('REPORTS/SET_CHART_TYPE_FOR_TAB')<{
  tab: ReportsTab;
  chartType: ReportsChart;
}>();

export const setGroupByEntity = createAction('REPORTS/SET_GROUP_BY_ENTITY')<ReportsGroupByEntity>();

export const setGroupByTimeframe = createAction(
  'REPORTS/SET_GROUP_BY_TIMEFRAME',
)<ReportsGroupByTimeframe>();

export const setReportsFilters = createAction(
  'REPORTS/SET_REPORTS_FILTERS',
)<Partial<TransactionFilters> | null>();

export const setReportsSankeyGroupMode = createAction(
  'REPORTS/SET_REPORTS_SANKEY_GROUP_MODE',
)<SankeyGroupMode>();

export const setReportsTransactionsSortBy = createAction(
  'REPORTS/SET_REPORTS_TRANSACTIONS_SORT_BY',
)<TransactionOrdering>();

export const setViewModeForTab = createAction('REPORTS/SET_VIEW_MODE_FOR_TAB')<{
  tab: ReportsTab;
  view: string;
}>();
