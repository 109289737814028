import type { DragEndEvent } from '@dnd-kit/core';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import DndKitContext from 'components/lib/dndKit/DndContext';
import Card from 'components/lib/ui/Card';
import OnboardingVariabilityCardSection from 'components/plan/onboarding/OnboardingVariabilityCardSection';
import OnboardingVariabilityItem from 'components/plan/onboarding/OnboardingVariabilityItem';

import { DEFAULT_BUDGET_VARIABILITY_IF_UNSET } from 'common/lib/budget/constants';
import { color } from 'common/lib/theme/dynamic';
import type { PlanCategory, PlanCategoryGroup } from 'lib/plan/types';

import { FIXED_FLEXIBLE_NON_MONTHLY_INTRODUCTION } from 'common/constants/copy';

import type { BudgetVariability, Common_GetJointPlanningDataQuery } from 'common/generated/graphql';
import type { PickPropsFromComponent } from 'common/types/utility';

const Container = styled(Card)`
  margin: 0 auto;
  border: 1px solid ${color.grayFocus};
`;

type Props = {
  groups: Common_GetJointPlanningDataQuery['categoryGroups'];
  onUpdateVariability: (
    id: string,
    budgetVariability: BudgetVariability,
    isGroup: boolean,
  ) => Promise<void>;
} & PickPropsFromComponent<
  typeof OnboardingVariabilityCardSection,
  'onEditCategory' | 'onHideFromBudget' | 'onDeleteOrDisableCategory'
>;

const OnboardingVariabilityCard = ({
  groups,
  onUpdateVariability,
  onEditCategory,
  onHideFromBudget,
  onDeleteOrDisableCategory,
}: Props) => {
  const allBudgetedCategoriesById = useMemo(
    () =>
      groups
        .flatMap((group) => (group.groupLevelBudgetingEnabled ? [] : group.categories))
        .reduce(
          (acc, category) => {
            acc[category.id] = category;
            return acc;
          },
          {} as Record<string, PlanCategory>,
        ),
    [groups],
  );

  const allBudgetedGroupsById = useMemo(
    () =>
      groups.reduce(
        (acc, group) => {
          if (group.groupLevelBudgetingEnabled) {
            acc[group.id] = group;
          }
          return acc;
        },
        {} as Record<string, PlanCategoryGroup>,
      ),
    [groups],
  );

  const budgetedCategoriesAndGroupsById = useMemo(
    () => ({
      ...allBudgetedCategoriesById,
      ...allBudgetedGroupsById,
    }),
    [allBudgetedCategoriesById, allBudgetedGroupsById],
  );

  const categoriesAndGroupsByVariability = useMemo(
    () =>
      Object.values(budgetedCategoriesAndGroupsById).reduce(
        (acc, categoryOrGroup) => {
          const variability =
            categoryOrGroup.budgetVariability ?? DEFAULT_BUDGET_VARIABILITY_IF_UNSET;
          acc[variability] = [...(acc[variability] ?? []), categoryOrGroup];
          return acc;
        },
        {} as Record<BudgetVariability, (PlanCategory | PlanCategoryGroup)[]>,
      ),
    [budgetedCategoriesAndGroupsById],
  );

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;
    const activeVariability = active.data.current?.variability;
    const overVariability = over?.data.current?.variability;

    const id = String(active.id);
    const isGroup = allBudgetedGroupsById[id] !== undefined;

    if (over && active.id !== over.id && activeVariability !== overVariability) {
      await onUpdateVariability(id, over.id as BudgetVariability, isGroup);
    }
  };

  return (
    <Container>
      <DndKitContext
        onDragEnd={handleDragEnd}
        dragOverlay={(activeId) => (
          <OnboardingVariabilityItem item={budgetedCategoriesAndGroupsById[activeId]} isActive />
        )}
      >
        {FIXED_FLEXIBLE_NON_MONTHLY_INTRODUCTION.map(
          ({ title, description, variability }, index) => (
            <OnboardingVariabilityCardSection
              key={variability}
              title={title}
              description={description}
              variability={variability}
              items={categoriesAndGroupsByVariability[variability] ?? []}
              isLast={index === FIXED_FLEXIBLE_NON_MONTHLY_INTRODUCTION.length - 1}
              onEditCategory={onEditCategory}
              onHideFromBudget={onHideFromBudget}
              onDeleteOrDisableCategory={onDeleteOrDisableCategory}
              onMoveTo={onUpdateVariability}
            />
          ),
        )}
      </DndKitContext>
    </Container>
  );
};

export default OnboardingVariabilityCard;
