import React, { useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import withDefaultErrorBoundary from 'components/lib/higherOrder/withDefaultErrorBoundary';
import type { Props as PageProps } from 'components/lib/ui/Page';
import Page from 'components/lib/ui/Page';
import OnboardingConnectAccountPrompt from 'components/onboarding/OnboardingConnectAccountPrompt';

import { setHasAccounts } from 'actions';
import useHasAccounts from 'common/lib/hooks/accounts/useHasAccounts';
import PageWithNoAccountsEmptyStateContext from 'lib/contexts/PageWithNoAccountsEmptyStateContext';
import useDispatch from 'lib/hooks/useDispatch';
import { getDoesNotHaveAccounts } from 'state/emptyState/selectors';

import * as COPY from 'common/constants/copy';

type Props = PageProps & {
  emptyIcon: string;
  emptyTitle: string;
  emptyButtonText?: string;
  onAccountAdded?: () => void;
};

/**
 * Wrapper around our standard Page component that checks for the existence of at least 1
 * account, otherwise shows an empty component
 */
const PageWithNoAccountsEmptyState = ({
  children,
  controls,
  onAccountAdded,
  emptyIcon,
  emptyTitle,
  emptyButtonText = COPY.DASHBOARD_EMPTY_BUTTON,
  overlayEmptyComponent,
  ...props
}: Props) => {
  const { hasAccounts, refetch, error } = useHasAccounts();

  const [onAccountAddedListeners, setOnAccountAddedListeners] = useState<(() => void)[]>([]);
  const addAccountAddedListener = useCallback(
    (listener: () => void) => {
      setOnAccountAddedListeners([...onAccountAddedListeners, listener]);
    },
    [onAccountAddedListeners, setOnAccountAddedListeners],
  );
  const contextValue = useMemo(
    () => ({
      addAccountAddedListener,
    }),
    [],
  );
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(setHasAccounts(hasAccounts));
  }, [dispatch, hasAccounts]);

  if (error?.networkError) {
    throw error.networkError;
  }

  // Only assume no accounts if the value is false (not undefined)
  const doesNotHaveAccounts = useSelector(getDoesNotHaveAccounts);
  return (
    <Page
      {...props}
      overlayEmptyComponent={
        doesNotHaveAccounts ? (
          <OnboardingConnectAccountPrompt
            emptyTitle={emptyTitle}
            emptyIcon={emptyIcon}
            emptyButtonText={emptyButtonText}
            onAccountAdded={() => {
              refetch();
              onAccountAdded?.();
              onAccountAddedListeners.forEach((listener) => listener());
            }}
          />
        ) : (
          overlayEmptyComponent
        )
      }
      controls={doesNotHaveAccounts ? null : controls}
    >
      <PageWithNoAccountsEmptyStateContext.Provider value={contextValue}>
        {children}
      </PageWithNoAccountsEmptyStateContext.Provider>
    </Page>
  );
};

export default withDefaultErrorBoundary(PageWithNoAccountsEmptyState);
