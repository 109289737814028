import * as R from 'ramda';

import type { FilterMenuSection } from 'components/reports/filters/types';

import type { SectionAdapterOptions } from 'lib/filters/types';
import { makeIsSelected, sortByOrder, transformCategory } from 'lib/filters/utils';

import { RECURRING_TRANSACTIONS_FREQUENCIES_INFO } from 'common/constants/recurringTransactions';

import type {
  Web_RecurringFilterQuery,
  RecurringTransactionFilter,
} from 'common/generated/graphql';
import { RecurringType } from 'common/generated/graphql';

/**
 * Adapt the filter sections from the query to the format expected by the FilterMenu component.
 */
export const getFilterSectionsFromQuery = (
  data: Maybe<Web_RecurringFilterQuery>,
  filters?: Partial<RecurringTransactionFilter>,
  options?: SectionAdapterOptions,
): FilterMenuSection[] => {
  if (!data) {
    return [];
  }

  const { renderAccountLogo } = options || {};
  const { categoryGroups = [], accounts = [] } = data;

  const accountsByType = R.groupBy(R.pathOr('', ['type', 'display']), accounts);
  const frequencies = Object.entries(RECURRING_TRANSACTIONS_FREQUENCIES_INFO).map(
    ([key, value]) => ({
      id: key,
      name: value,
    }),
  );

  return [
    {
      id: 'recurringTypes',
      name: 'Type',
      hideSelectAll: true,
      options: [
        {
          id: RecurringType.INCOME,
          name: 'Income',
          isSelected: makeIsSelected(filters?.recurringTypes)(RecurringType.INCOME),
        },
        {
          id: RecurringType.EXPENSE,
          name: 'Expense',
          isSelected: makeIsSelected(filters?.recurringTypes)(RecurringType.EXPENSE),
        },
        {
          id: RecurringType.CREDIT_CARD,
          name: 'Credit card',
          isSelected: makeIsSelected(filters?.recurringTypes)(RecurringType.CREDIT_CARD),
        },
      ],
    },
    {
      id: 'accounts',
      name: 'Payment account',
      options: Object.entries(accountsByType).map(([type, accountsOfType]) => ({
        id: type,
        name: type,
        options: accountsOfType.map((account) => ({
          id: account.id,
          name: R.pathOr('', ['displayName'], account),
          isSelected: makeIsSelected(filters?.accounts)(account.id),
          icon: renderAccountLogo?.(account),
        })),
      })),
    },
    {
      id: 'categories',
      name: 'Categories',
      options: sortByOrder(categoryGroups).map((group) => ({
        id: group.id,
        name: group.name,
        options: R.pipe(
          sortByOrder,
          R.map((category) => transformCategory(category, makeIsSelected(filters?.categories))),
        )(group.categories),
      })),
    },
    {
      id: 'frequencies',
      name: 'Frequency',
      hideSelectAll: true,
      options: frequencies.map((frequency) => ({
        id: frequency.id,
        name: frequency.name,
        isSelected: makeIsSelected(filters?.frequencies)(frequency.id),
      })),
    },
  ];
};
