import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import GoalsSetupHeader from 'components/goalsV2/GoalsSetupHeader';
import TargetAmountsGoalsList from 'components/goalsV2/TargetAmountsGoalsList';
import FlexContainer from 'components/lib/ui/FlexContainer';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Page from 'components/lib/ui/Page';
import SetupContentHeader from 'components/lib/ui/SetupContentHeader';
import PrimaryButton from 'components/lib/ui/button/PrimaryButton';

import variables from 'common/lib/theme/variables';
import { getProgressPercent } from 'lib/goals/progress';

import * as COPY from 'common/constants/copy';
import routes from 'constants/routes';

const StyledPage = styled(Page)`
  height: 100vh;
  background-color: ${variables.color.background.fullscreenHeader};
`;

const NextButton = styled(PrimaryButton)`
  width: 100%;
  max-width: 438px;
  margin-top: ${({ theme }) => theme.spacing.xlarge};
  margin-bottom: ${({ theme }) => theme.spacing.xxxlarge};
`;

const ListContainer = styled(FlexContainer)`
  @media (min-width: ${({ theme }) => theme.breakPoints.lg}px) {
    width: 502px;
    margin: auto;
  }
`;

const ButtonContainer = styled(FlexContainer).attrs({ center: true, column: true })`
  margin-top: ${({ theme }) => theme.spacing.xxxlarge};
`;

const GoalsTargetAmounts = () => {
  const { push } = useHistory();
  const [loading, setLoading] = useState(true);

  return (
    <StyledPage name="Goals setup" header={<GoalsSetupHeader progress={getProgressPercent(3)} />}>
      <FlexContainer center column>
        <SetupContentHeader
          title={COPY.GOALS.TARGET_AMOUNTS.TITLE}
          description={COPY.GOALS.TARGET_AMOUNTS.SUBTITLE}
        />
      </FlexContainer>
      <ListContainer>
        <TargetAmountsGoalsList onDoneLoading={() => setLoading(false)} />
      </ListContainer>
      <ButtonContainer>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <NextButton size="large" onClick={() => push(routes.goalsV2.mapAccounts())}>
            Next
          </NextButton>
        )}
      </ButtonContainer>
    </StyledPage>
  );
};

export default GoalsTargetAmounts;
