import React from 'react';

import CtaBlockRenderer from 'components/posts/CtaBlockRenderer';
import ImageBlockRenderer from 'components/posts/ImageBlockRenderer';
import TextBlockRenderer from 'components/posts/TextBlockRenderer';

import type { BlockRenderer, PostBlock } from 'common/lib/posts/types';

const BLOCK_RENDERERS: { [key in PostBlock['type']]: BlockRenderer<any> } = {
  h1: TextBlockRenderer,
  h2: TextBlockRenderer,
  p: TextBlockRenderer,
  image: ImageBlockRenderer,
  cta: CtaBlockRenderer,
};

type Props = {
  blocks: PostBlock[];
};

const PostBlocksRenderer = ({ blocks }: Props) => (
  <>
    {blocks.map((block, index) => {
      const BlockRenderer = BLOCK_RENDERERS[block.type];
      if (!BlockRenderer) {
        // eslint-disable-next-line no-console
        console.warn(`No renderer for block type: ${block.type}`);
        return null;
      }
      return <BlockRenderer key={index} data={block} />;
    })}
  </>
);

export default PostBlocksRenderer;
