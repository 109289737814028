import React from 'react';
import styled from 'styled-components';

type Props = {
  title: React.ReactNode;
  description: React.ReactNode;
};

const Description = styled.div`
  font-size: ${({ theme }) => theme.fontSize.base};
  color: ${({ theme }) => theme.color.text};
`;

const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  padding-bottom: ${({ theme }) => theme.spacing.xxxsmall};
  color: ${({ theme }) => theme.color.text};
`;

const SettingsTitle = ({ title, description }: Props) => (
  <>
    <Title>{title}</Title>
    <Description>{description}</Description>
  </>
);

export default SettingsTitle;
