import React from 'react';

import { useFormContext } from 'common/components/form/FormContext';
import LeftToBudgetFooter from 'components/plan/onboarding/LeftToBudgetFooter';
import type { FormValues } from 'components/routes/budget/OnboardingNonMonthly';

import { getAccumulatedAmountForVariabilities, getSummaryRows } from 'common/lib/budget/Adapters';
import type { AmountsMap } from 'common/lib/budget/Adapters';

type Props = {
  incomeAmount: number;
  accumulatedAmountsMap: AmountsMap;
  isLoading?: boolean;
};

const OnboardingNonMonthlyLeftToBudgetFooter = ({
  accumulatedAmountsMap,
  incomeAmount,
  isLoading,
}: Props) => {
  const { values } = useFormContext<FormValues>();
  const nonMonthlyTotal = Object.values(values).reduce(
    (acc, { monthlyBudget }) => acc + (monthlyBudget || 0),
    0,
  );
  const amountsMapWithUnsavedNonMonthlyTotal = {
    fixed: accumulatedAmountsMap.fixed,
    non_monthly: nonMonthlyTotal,
    flexible: accumulatedAmountsMap.flexible,
  };

  const accumulatedAmount = getAccumulatedAmountForVariabilities(
    amountsMapWithUnsavedNonMonthlyTotal,
  );
  const tooltipRows = getSummaryRows(incomeAmount, amountsMapWithUnsavedNonMonthlyTotal);

  return (
    <LeftToBudgetFooter
      value={incomeAmount - accumulatedAmount}
      tooltipRows={tooltipRows}
      isLoading={isLoading}
    />
  );
};

export default OnboardingNonMonthlyLeftToBudgetFooter;
