import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import QuestionnaireHeader from 'components/advice/QuestionnaireHeader';
import OnboardingCardPage from 'components/lib/layouts/OnboardingCardPage';
import OnboardingPage from 'components/lib/layouts/OnboardingPage';
import OnboardingAttribution from 'components/onboarding/OnboardingAttribution';
import OnboardingConnectAccountHypeScreen from 'components/onboarding/OnboardingConnectAccountHypeScreen';
import OnboardingConnectAccountSankey from 'components/onboarding/OnboardingConnectAccountSankey';
import OnboardingConnectSingleAccountBeforePaywall from 'components/onboarding/OnboardingConnectSingleAccountBeforePaywall';
import OnboardingConnectSingleAccountDone from 'components/onboarding/OnboardingConnectSingleAccountDone';
import OnboardingConnectSpendingAccount from 'components/onboarding/OnboardingConnectSpendingAccount';
import OnboardingCreatePassword from 'components/onboarding/OnboardingCreatePassword';
import OnboardingDemographics from 'components/onboarding/OnboardingDemographics';
import OnboardingInvitePartner from 'components/onboarding/OnboardingInvitePartner';
import OnboardingPlanSelect from 'components/onboarding/OnboardingPlanSelect';
import OnboardingPremiumUpsell from 'components/onboarding/OnboardingPremiumUpsell';
import OnboardingPriorities from 'components/onboarding/OnboardingPriorities';
import OnboardingTestimonials from 'components/onboarding/OnboardingTestimonials';
import OnboardingVerifyEmail from 'components/onboarding/OnboardingVerifyEmail';
import OnboardingWelcome from 'components/onboarding/OnboardingWelcome';
import BlockApp from 'components/site/BlockApp';
import type { RouteFlowInfo } from 'components/utils/RouteFlow';
import RouteFlow from 'components/utils/RouteFlow';

import { clearProviderEphemeralData, setTemporaryPromoCode } from 'actions';
import getSignupBlockingMessage from 'common/lib/api/signupBlockingMessage';
import typewriter from 'lib/analytics/typewriter';
import api from 'lib/api';
import useDispatch from 'lib/hooks/useDispatch';
import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';
import { useQueryParam } from 'lib/hooks/useQueryParams';
import {
  getIsSponsor,
  getProviderEphemeralData,
  getUserIsLoggedIn,
  getManageFinancesWithPartner,
} from 'selectors';

import routes, { externalUrls } from 'constants/routes';

interface StepProps {
  component: React.ComponentType<any>;
  route: any;
  title?: string;
  pageName: string;
  externalAuthExcluded?: boolean;
  canGetBackToPreviousStep?: boolean;
  isSkippable?: boolean;
  showIfManageFinancesWithPartner?: boolean;
}

const STEPS: StepProps[] = [
  {
    component: OnboardingWelcome,
    route: routes.signup,
    title: 'Start an account',
    pageName: 'Onboarding Welcome',
  },
  {
    component: OnboardingVerifyEmail,
    route: routes.signup.verifyEmail,
    title: 'Verify your email',
    pageName: 'Verify your email',
    externalAuthExcluded: true,
    canGetBackToPreviousStep: true,
  },
  {
    component: OnboardingCreatePassword,
    route: routes.signup.createPassword,
    title: 'Set your password',
    pageName: 'Onboarding Create Password',
    externalAuthExcluded: true,
    canGetBackToPreviousStep: false,
  },
  {
    component: OnboardingPriorities,
    route: routes.signup.priorities,
    pageName: 'What brings you to Monarch?',
    canGetBackToPreviousStep: false,
  },
  {
    component: OnboardingAttribution,
    route: routes.signup.attribution,
    pageName: 'How did you hear about Monarch?',
    canGetBackToPreviousStep: true,
  },
  {
    component: OnboardingDemographics,
    route: routes.signup.demographics,
    title: 'About you',
    pageName: 'Onboarding Demographics',
    canGetBackToPreviousStep: true,
  },
  {
    component: OnboardingTestimonials,
    route: routes.signup.testimonials,
    title: 'Welcome to Monarch',
    pageName: 'Onboarding Testimonials',
    canGetBackToPreviousStep: false,
  },
  {
    component: OnboardingConnectSingleAccountBeforePaywall,
    route: routes.signup.connectSingleAccountBeforePaywall,
    title: 'Connect Spending Account',
    pageName: 'Onboarding Connect Spending Account',
    canGetBackToPreviousStep: false,
  },
  {
    component: OnboardingConnectSingleAccountDone,
    route: routes.signup.connectSingleAccountDone,
    title: 'Connect your account',
    pageName: 'Onboarding Connect Spending Account - Done',
    canGetBackToPreviousStep: false,
  },
  {
    component: OnboardingPremiumUpsell,
    route: routes.signup.premiumUpsell,
    title: 'How your free trial works',
    pageName: 'Onboarding Premium Upsell',
    canGetBackToPreviousStep: false,
  },
  {
    component: OnboardingPlanSelect,
    route: routes.signup.selectPlan,
    title: 'Select a plan',
    pageName: 'Onboarding Plan Select',
    canGetBackToPreviousStep: true,
  },
  {
    component: OnboardingInvitePartner,
    route: routes.signup.invitePartner,
    title: 'Invite your partner',
    pageName: 'Onboarding Invite Partner',
    canGetBackToPreviousStep: false,
    isSkippable: true,
    showIfManageFinancesWithPartner: true,
  },
  {
    component: OnboardingConnectAccountHypeScreen,
    route: routes.signup.connectSpendingAccountHype,
    title: 'Connect your account',
    pageName: 'Onboarding Connect Account Hype Screen',
    canGetBackToPreviousStep: false,
  },
  {
    component: OnboardingConnectSpendingAccount,
    route: routes.signup.connectSpendingAccount,
    title: 'Connect Spending Account',
    pageName: 'Onboarding Connect Spending Account',
    canGetBackToPreviousStep: false,
  },
  {
    component: OnboardingConnectAccountSankey,
    route: routes.signup.sankey,
    title: 'Sankey Chart - Onboarding',
    pageName: 'Sankey Chart - Onboarding',
    canGetBackToPreviousStep: false,
  },
];

type SignupBlockingMessage = {
  title: string;
  message: string;
  callToAction: string;
  link: string;
};

const DEFAULT_BLOCKING_MESSAGE: SignupBlockingMessage = {
  title: 'App Unavailable',
  message:
    'We apologize, but the app is temporarily unavailable. Our team is addressing the issue. Please check back soon.',
  callToAction: 'Visit our help center',
  link: externalUrls.zendeskHelpCenter,
};

const OnboardingFlow = () => {
  /**
   * This feature flag is used to block signups with a message. We want to show the message
   * on the welcome screen, so we can block users from starting the sign up process, but
   * we don't prevent them from finishing it if they already started it.
   */
  const isSignupBlocked = useIsFeatureFlagOn('block-signups-with-message');

  const dispatch = useDispatch();

  /**
   * Set coupons to be used in the onboarding flow
   */
  const promoCodeParam = useQueryParam('c');

  useEffect(() => {
    if (RA.isNotNil(promoCodeParam)) {
      dispatch(setTemporaryPromoCode(promoCodeParam));
      typewriter.webAppQueryParamPromoCodeLoaded({
        promoCode: promoCodeParam,
      });
    }
  }, [promoCodeParam]);

  const history = useHistory();
  const { pathname } = useLocation();

  const isLoggedIn = useSelector(getUserIsLoggedIn);
  const isSponsor = useSelector(getIsSponsor);

  const manageFinancesWithPartner = useSelector(getManageFinancesWithPartner);

  const ephemeralData = useSelector(getProviderEphemeralData);
  const clearEphemeralData = useCallback(() => dispatch(clearProviderEphemeralData()), [dispatch]);

  useEffect(() => {
    if (isLoggedIn && pathname !== routes.signup.premiumUpsell()) {
      history.replace(routes.dashboard());
    }
  }, []);

  const isFromFederatedSignIn = RA.isNotNil(ephemeralData);

  const steps = useMemo(() => {
    let filteredSteps = STEPS;

    // removes steps that requires the user managing finances with partner
    if (!manageFinancesWithPartner) {
      filteredSteps = filteredSteps.filter(
        ({ showIfManageFinancesWithPartner }) => !showIfManageFinancesWithPartner,
      );
    }

    if (isFromFederatedSignIn) {
      filteredSteps = filteredSteps.filter(({ externalAuthExcluded }) => !externalAuthExcluded);
    }

    return filteredSteps;
  }, [isFromFederatedSignIn, manageFinancesWithPartner]);

  const routeSteps = useMemo(
    () => steps.map(({ component, route }) => [component, route] as RouteFlowInfo),
    [steps],
  );

  const activeStepIndex = R.clamp(
    0,
    steps.length - 1,
    steps.findIndex(({ route }) => pathname === route()),
  );

  const { pageName, title, canGetBackToPreviousStep, isSkippable } = steps[activeStepIndex];

  const progress = useMemo(() => activeStepIndex / (steps.length - 1), [activeStepIndex, steps]);

  const handleFlowCompleted = useCallback(() => {
    history.push(isSponsor ? routes.advisorPortal.clients() : routes.dashboard());
    clearEphemeralData();
  }, [history, isSponsor, clearEphemeralData]);

  const flow = <RouteFlow steps={routeSteps} onComplete={handleFlowCompleted} />;

  const isOnPaywall = pathname !== routes.signup.premiumUpsell();

  // The query param will override the default behavior of the canGetBackToPreviousStep set on the STEP.
  const queryParamBack = useQueryParam('back');

  // allow users skipping the onboarding flow - we're securing the endpoints, so they can only navigate on settings
  const queryParamSkippable = useQueryParam('skippable') === 'true';

  const abTestShouldRemoveSkipButtonFromPaywall = useIsFeatureFlagOn(
    'ab-test-remove-app-upsell-and-skip',
    {
      trackImpression: true,
    },
  );

  // The A/B test overrides the skippable query parameter.
  const shouldShowSkipButton =
    (!abTestShouldRemoveSkipButtonFromPaywall && queryParamSkippable) || isSkippable;

  /*
    If the user is in the paywall, it will redirect to the billing page.

    Otherwise, it will go to the next page of the flow unless it is the last page.
  */
  const onClickSkipButton = useMemo(() => {
    if (isOnPaywall) {
      if (activeStepIndex === steps.length - 1) {
        return handleFlowCompleted;
      }

      return () => history.push(steps[activeStepIndex + 1].route());
    } else {
      return () => history.replace(routes.settings.billing());
    }
  }, [activeStepIndex, steps, handleFlowCompleted, history]);

  const hideBackButton = queryParamBack ? queryParamBack === 'false' : !canGetBackToPreviousStep;

  const [response, setResponse] = useState<SignupBlockingMessage>();

  useEffect(() => {
    if (isSignupBlocked) {
      const fetchData = async () => {
        const signupBlockingMessage =
          await getSignupBlockingMessage(api).getSignupBlockingMessage();

        setResponse(signupBlockingMessage ?? DEFAULT_BLOCKING_MESSAGE);
      };

      fetchData();
    }
  }, [isSignupBlocked]);

  if (isSignupBlocked && activeStepIndex === 0) {
    /**
     * TODO: the naming got weird on web bc we're reusing an existing component.
     * Not worried bc we will move the dynamic config to Split at some point.
     */
    return (
      <BlockApp
        message={response?.title}
        description={response?.message}
        link={response?.link}
        callToAction={response?.callToAction}
      />
    );
  }

  return activeStepIndex === 0 ? (
    <OnboardingCardPage name={pageName} parentRoute={null} overrideTitle={title}>
      {flow}
    </OnboardingCardPage>
  ) : (
    <OnboardingPage
      name={pageName}
      parentRoute={null}
      overrideTitle={title}
      header={
        <QuestionnaireHeader
          progress={progress}
          showBackButton={!hideBackButton}
          showSkipButton={shouldShowSkipButton}
          onClickBackButton={() => history.goBack()}
          onClickSkipButton={onClickSkipButton}
        />
      }
    >
      {flow}
    </OnboardingPage>
  );
};

export default OnboardingFlow;
