import { createSelector } from 'reselect';

import type { RootState } from 'state/types';

const getPlanState = ({ plan }: RootState) => plan;

export const getPlanCollapsedGroups = createSelector(
  getPlanState,
  (_: ReturnType<typeof getPlanState>, id: string) => id,
  ({ collapsedGroups }, id) => (collapsedGroups ? collapsedGroups[id] : false),
);

export const getPlanCollapsedSections = createSelector(
  getPlanState,
  ({ collapsedSections }) => collapsedSections ?? {},
);

export const getUnplannedExpandedByGroup = createSelector(
  getPlanState,
  (_: ReturnType<typeof getPlanState>, id: string) => id,
  ({ isUnplannedExpandedByGroup }, id) =>
    isUnplannedExpandedByGroup ? isUnplannedExpandedByGroup[id] : false,
);

export const getExpandedGroupRow = createSelector(
  getPlanState,
  (_: ReturnType<typeof getPlanState>, id: string) => id,
  ({ expandedGroupRows }, id) => (expandedGroupRows ? expandedGroupRows[id] : false),
);
