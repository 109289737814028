import { useMutation } from '@apollo/client';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import FormContext from 'common/components/form/FormContext';
import FieldCell from 'components/lib/form/FieldCell';
import Form from 'components/lib/form/Form';
import FormSubmitButton from 'components/lib/form/FormSubmitButton';
import RadioGroupField from 'components/lib/form/RadioGroupField';
import SelectField from 'components/lib/form/SelectField';
import TextField from 'components/lib/form/TextField';
import ToggleField from 'components/lib/form/ToggleField';
import CardFooter from 'components/lib/ui/CardFooter';
import Link from 'components/lib/ui/Link';
import ModalCard from 'components/lib/ui/ModalCard';

import { getRolloverStartMonthOptions } from 'common/lib/budget/Rollovers';
import { CATEGORY_GROUP_BUDGET_TYPE_OPTIONS } from 'common/lib/categories/constants';
import { CREATE_CATEGORY_GROUP } from 'common/lib/graphQl/categoryGroups';

import { BudgetRolloverPeriodType, ROLLOVER_ENABLED_FIELD_NAME } from 'common/constants/budget';
import { CACHE_KEYS } from 'common/constants/cache';
import { HELP_CENTER_ROLLOVER_ARTICLE_URL } from 'common/constants/externalUrls';

import { CategoryGroupType, BudgetVariability } from 'common/generated/graphql';

type Props = {
  onDone: () => void;
  categoryType: CategoryGroupType;
};

const Root = styled.div`
  padding: ${({ theme }) => theme.spacing.xlarge};
`;

const StyledFieldCell = styled(FieldCell)`
  margin-top: ${({ theme }) => theme.spacing.large};
`;

const CreateCategoryGroupModal = ({ onDone, categoryType }: Props) => {
  const [createCategoryGroup] = useMutation(CREATE_CATEGORY_GROUP, {
    update: (cache) => {
      cache.evict({ fieldName: CACHE_KEYS.CATEGORY_GROUPS });
    },
  });

  const rolloverStartMonthOptions = useMemo(() => getRolloverStartMonthOptions(), []);

  return (
    <ModalCard title="Create Group">
      <Form
        initialValues={{
          name: '',
          type: categoryType,
          groupLevelBudgetingEnabled: false,
          rolloverEnabled: false,
          rolloverStartMonth: DateTime.local().startOf('month').toISODate(),
          rolloverType: BudgetRolloverPeriodType.Monthly, // This is the only one we support for now
        }}
        onSubmitSuccess={onDone}
        mutation={createCategoryGroup}
      >
        <Root>
          <TextField name="name" required autoComplete="off" />

          <SelectField
            name="groupLevelBudgetingEnabled"
            label="Budget"
            options={CATEGORY_GROUP_BUDGET_TYPE_OPTIONS}
          />

          <FormContext.Consumer>
            {({ values }) => (
              <>
                {categoryType === CategoryGroupType.EXPENSE &&
                  values.groupLevelBudgetingEnabled && (
                    <StyledFieldCell
                      rightAccessory={<ToggleField name={ROLLOVER_ENABLED_FIELD_NAME} hideLabel />}
                      title="Make this category group a monthly rollover"
                      subtitle={
                        <>
                          Every month the remaining balance will roll over to the next month.{' '}
                          <Link href={HELP_CENTER_ROLLOVER_ARTICLE_URL} target="_blank">
                            Learn more
                          </Link>
                        </>
                      }
                    >
                      <FormContext.Consumer>
                        {({ values }) =>
                          values.rolloverEnabled ? (
                            <SelectField
                              name="rolloverStartMonth"
                              label="Starting Month"
                              options={rolloverStartMonthOptions}
                            />
                          ) : null
                        }
                      </FormContext.Consumer>
                    </StyledFieldCell>
                  )}

                {values.groupLevelBudgetingEnabled &&
                  categoryType === CategoryGroupType.EXPENSE && (
                    <RadioGroupField
                      name="budgetVariability"
                      label="Type"
                      tooltip="We use this information to calculate your “Safe to spend” amount each month, which is the amount of budget remaining in “Flexible” categories and groups."
                      options={[
                        {
                          value: BudgetVariability.FIXED,
                          label: 'Fixed',
                          description:
                            'Spending is usually the same every month, and cannot be easily reduced. Great for utilities, mortgage, bills, etc.',
                        },
                        {
                          value: BudgetVariability.FLEXIBLE,
                          label: 'Flexible',
                          description:
                            'Spending changes monthly, and can be reduced when you want to save more money. Great for restaurants, entertainment, etc.',
                        },
                      ]}
                    />
                  )}
              </>
            )}
          </FormContext.Consumer>
        </Root>
        <CardFooter>
          <FormSubmitButton size="small">Save</FormSubmitButton>
        </CardFooter>
      </Form>
    </ModalCard>
  );
};

export default CreateCategoryGroupModal;
