import storage from 'redux-persist/lib/storage';
import { createReducer } from 'typesafe-actions';

import { GroupByValues } from 'common/lib/recurring';
import { setRecurringFilters, toggleCollapsedRecurringGroup } from 'state/recurring/actions';

export const recurringPersistConfig = {
  key: 'recurring',
  storage,
  whitelist: ['filters', 'collapsedGroupsMap'],
};

export const groupByOptions = [
  {
    label: 'Category',
    value: GroupByValues.Category,
  },
  {
    label: 'Frequency',
    value: GroupByValues.Frequency,
  },
];

export type RecurringFilters = {
  accounts?: Maybe<string[]>;
  categories?: Maybe<string[]>;
  recurringTypes?: Maybe<string[]>;
  frequencies?: Maybe<string[]>;
  groupBy?: GroupByValues;
};

export type RecurringState = {
  filters: RecurringFilters;
  collapsedGroupsMap?: {
    [key: string]: boolean;
  };
};

const INITIAL_STATE: RecurringState = {
  filters: {},
  collapsedGroupsMap: {},
};

const recurring = createReducer<RecurringState>(INITIAL_STATE)
  .handleAction(setRecurringFilters, (state, { payload: filters }) => ({
    ...state,
    filters,
  }))
  .handleAction(toggleCollapsedRecurringGroup, (state, { payload: groupId }) => ({
    ...state,
    collapsedGroupsMap: {
      ...state.collapsedGroupsMap,
      [groupId]: !state.collapsedGroupsMap?.[groupId],
    },
  }));

export default recurring;
