import * as R from 'ramda';
import React, { useCallback, useMemo, useState } from 'react';

import type { Props as TransactionListHeaderProps } from 'components/transactions/TransactionListHeader';
import TransactionsList from 'components/transactions/TransactionsList';
import TransactionsListLoading, {
  TransactionsRowsLoading,
} from 'components/transactions/TransactionsListLoading';

import type { BulkUpdateStateFunc } from 'common/lib/hooks/transactions/useBulkUpdateTransactionState';
import useToggle from 'common/lib/hooks/useToggle';
import groupTransactionsByAmount from 'common/utils/groupTransactionsByAmount';
import groupTransactionsByDate from 'common/utils/groupTransactionsByDate';
import { mergeNextPage } from 'common/utils/pagination';
import type {
  AutoFocusOptions,
  TransactionsListContextType,
} from 'lib/contexts/TransactionsListContext';
import TransactionsListContext from 'lib/contexts/TransactionsListContext';
import useTransactionDrawerState from 'lib/hooks/transactions/useTransactionDrawerState';
import useMockDataWhenNoAccountsQuery from 'lib/hooks/useMockDataWhenNoAccountsQuery';

import { gql } from 'common/generated/gql';
import type { Web_GetTransactionsListQueryVariables } from 'common/generated/graphql';
import { TransactionOrdering } from 'common/generated/graphql';
import type { TransactionFilters } from 'types/filters';

export type Props = {
  transactionFilters: Partial<TransactionFilters>;
  quickViewFilters?: Partial<TransactionFilters>;
  isReviewModeActive?: boolean | null;
  emptyComponent: React.ReactNode;
  pageSize?: number;
  allowBulkUpdate?: boolean;
  hideHeader?: boolean;
  hideHeaderIfNoContent?: boolean;
  overrideDrawer?: {
    drawerTransactionId: GraphQlUUID | null;
    setDrawerTransactionId: (id: GraphQlUUID | null) => void;
    onTransactionDrawerClose: () => void;
  };
  onBulkUpdateStateChange?: BulkUpdateStateFunc;
  onRemoveQuickViewFilters?: (filters: Partial<TransactionFilters>) => void;
} & Pick<
  TransactionListHeaderProps,
  'overrideTitle' | 'extraControls' | 'renderCustomEditButton' | 'sortBy' | 'onChangeSortBy'
> &
  Pick<TransactionsListContextType, 'showAccountColumn'>;

const TransactionsListContainer = ({
  transactionFilters,
  quickViewFilters = {},
  isReviewModeActive,
  emptyComponent,
  overrideDrawer,
  pageSize = 20,
  allowBulkUpdate = false,
  hideHeader,
  hideHeaderIfNoContent = false,
  overrideTitle,
  extraControls,
  showAccountColumn = true,
  sortBy = TransactionOrdering.DATE,
  onChangeSortBy,
  renderCustomEditButton,
  onBulkUpdateStateChange,
  onRemoveQuickViewFilters,
}: Props) => {
  const [autoFocusOptions, setAutoFocusOptions] = useState<AutoFocusOptions>({
    notes: false,
    tags: false,
  });

  const variables: Web_GetTransactionsListQueryVariables = useMemo(() => {
    const allFilters: Partial<TransactionFilters> = R.mergeRight(
      transactionFilters,
      quickViewFilters,
    );
    return {
      orderBy: sortBy,
      limit: pageSize,
      filters: allFilters as Web_GetTransactionsListQueryVariables['filters'],
      offset: undefined,
    };
  }, [pageSize, transactionFilters, quickViewFilters, sortBy]);

  const {
    data,
    fetchMore,
    refetch,
    isLoadingInitialData: isLoadingTransactions,
  } = useMockDataWhenNoAccountsQuery(GET_TRANSACTION_LIST, {
    variables,
  });

  const currentRuleCount = data?.transactionRules?.length;
  const {
    totalCount: fetchedTotalCount = 0,
    totalSelectableCount,
    results: allTransactions = [],
  } = data?.allTransactions ?? {};
  const [
    drawerTransactionId,
    { setDrawerTransactionId, previousTransactionId, nextTransactionId },
  ] = useTransactionDrawerState(
    data,
    overrideDrawer?.drawerTransactionId,
    overrideDrawer?.setDrawerTransactionId,
  );
  const transactions = useMemo(
    () =>
      // extra filter on client side so when transaction is marked as reviewed it disappears optimistically
      transactionFilters.needsReview
        ? allTransactions.filter(({ needsReview }) => needsReview)
        : allTransactions,
    [allTransactions, transactionFilters],
  );
  // in the event that we filter out transactions on the client side, we need to adjust the total count
  // this happens when transactions have been updated to no longer match the applied filters (specifically needsReview)
  // but the transactions & totalCount in the apollo cache have not been updated
  const filteredOutClientSideCount = allTransactions.length - transactions.length;
  const totalCount = fetchedTotalCount - filteredOutClientSideCount;

  const [isBulkUpdateFormOpen, { setOn: openBulkUpdateForm, setOff: closeBulkUpdateForm }] =
    useToggle(false);

  const isOrderingByAmount =
    sortBy === TransactionOrdering.AMOUNT || sortBy === TransactionOrdering.INVERSE_AMOUNT;
  const isOrderingByDateDescending = sortBy === TransactionOrdering.DATE;

  const sections = useMemo(
    () =>
      isOrderingByAmount
        ? groupTransactionsByAmount(transactions)
        : groupTransactionsByDate({
            transactions,
            groupingFn: R.prop('date'),
            isDescending: isOrderingByDateDescending,
            accumulateTransfersAmounts: false,
          }),
    [transactions, isOrderingByAmount, isOrderingByDateDescending],
  );

  // Refetch entire list through all paginations (0 -> transaction count)
  const refetchAll = useCallback(
    () =>
      refetch({
        ...variables,
        // Fetch at minimum pageSize in case we refetch before initial query finishes
        limit: Math.max(pageSize, transactions.length),
      }),
    [refetch, transactions, variables, pageSize],
  );

  const onFetchMore = useCallback(
    () =>
      fetchMore({
        variables: { offset: transactions.length },
        updateQuery: (prev, { fetchMoreResult }) =>
          R.evolve(
            {
              allTransactions: {
                results: mergeNextPage(fetchMoreResult?.allTransactions?.results),
              },
            },
            prev,
          ),
      }),
    [fetchMore, transactions],
  );

  const context = useMemo(
    (): TransactionsListContextType => ({
      autoFocus: autoFocusOptions,
      currentRuleCount,
      filters: transactionFilters,
      quickViewFilters,
      queryVariables: variables,
      refetchAll,
      setAutoFocus: (options) => setAutoFocusOptions(R.mergeLeft(options)),
      showAccountColumn,
    }),
    [transactionFilters, quickViewFilters, currentRuleCount, variables],
  );

  const LoadingComponent = useMemo(
    () => (hideHeader ? TransactionsRowsLoading : TransactionsListLoading),
    [hideHeader],
  );

  return (
    <TransactionsListContext.Provider value={context}>
      {isLoadingTransactions ? (
        <LoadingComponent />
      ) : (
        <TransactionsList
          sections={sections}
          totalCount={totalCount}
          setDrawerTransactionId={setDrawerTransactionId}
          previousTransactionId={previousTransactionId}
          nextTransactionId={nextTransactionId}
          drawerTransactionId={drawerTransactionId}
          allowBulkUpdate={allowBulkUpdate}
          isReviewModeActive={isReviewModeActive}
          onDrawerClose={
            overrideDrawer?.onTransactionDrawerClose ?? (() => setDrawerTransactionId(null))
          }
          emptyComponent={emptyComponent}
          hasNextPage={transactions.length < totalCount}
          isBulkUpdateFormOpen={isBulkUpdateFormOpen}
          openBulkUpdateForm={() => {
            setDrawerTransactionId(null);
            openBulkUpdateForm();
          }}
          onCloseBulkUpdateForm={closeBulkUpdateForm}
          filters={transactionFilters}
          quickViewFilters={quickViewFilters}
          onRemoveQuickViewFilters={onRemoveQuickViewFilters}
          isLoadingTransactions={isLoadingTransactions}
          onRequestNextPage={onFetchMore}
          hideHeader={hideHeader || (hideHeaderIfNoContent && R.isEmpty(sections))}
          overrideTitle={overrideTitle}
          extraControls={extraControls}
          renderCustomEditButton={renderCustomEditButton}
          onBulkUpdateStateChange={onBulkUpdateStateChange}
          sortBy={sortBy}
          onChangeSortBy={onChangeSortBy}
          totalSelectableCount={totalSelectableCount}
        />
      )}
    </TransactionsListContext.Provider>
  );
};

// Do not change the name of this query, this is used in a few places for refetchQueries
export const GET_TRANSACTION_LIST = gql(/* GraphQL */ `
  query Web_GetTransactionsList(
    $offset: Int
    $limit: Int
    $filters: TransactionFilterInput
    $orderBy: TransactionOrdering
  ) {
    allTransactions(filters: $filters) {
      totalCount
      totalSelectableCount
      results(offset: $offset, limit: $limit, orderBy: $orderBy) {
        id
        ...TransactionOverviewFields
      }
    }
    transactionRules {
      id
    }
  }
`);

export default TransactionsListContainer;
