import * as R from 'ramda';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import DividerLine from 'components/lib/ui/DividerLine';
import FlexContainer from 'components/lib/ui/FlexContainer';
import ReportsConfigurationsMenuButton from 'components/reports/ReportsConfigurationsMenuButton';
import ReportsFilterMenuButton from 'components/reports/ReportsFilterMenuButton';
import TransactionDateRangeButton from 'components/transactions/header/TransactionDateRangeButton';

import { patchReportsFilters } from 'actions';
import { useDispatch } from 'lib/hooks';
import useIsFeatureFlagOn from 'lib/hooks/useIsFeatureFlagOn';
import { INITIAL_STATE as REPORTS_INITIAL_STATE } from 'state/reports/reducer';
import { selectReportsFilters } from 'state/reports/selectors';

import type { ReportConfiguration } from 'common/generated/graphql';
import type DateRange from 'common/types/DateRange';

const REPORTS_DEFAULT_DATE_RANGE = R.pick(['startDate', 'endDate'], REPORTS_INITIAL_STATE.filters);

type Props = {
  reportConfigurations: ReportConfiguration[];
  selectedReportConfigurationId?: string;
  onSelectReportConfiguration?: (reportConfiguration: ReportConfiguration) => void;
  onSaveReportConfiguration?: () => void;
  onEditReportConfiguration?: (reportConfiguration: ReportConfiguration) => void;
  onResetToDefaultReportConfiguration?: () => void;
};

const ReportsHeaderControls = ({
  reportConfigurations,
  selectedReportConfigurationId,
  onSelectReportConfiguration,
  onSaveReportConfiguration,
  onEditReportConfiguration,
  onResetToDefaultReportConfiguration,
}: Props) => {
  const showReportsSavedViews = useIsFeatureFlagOn('reports-saved-views');

  const { startDate, endDate } = useSelector(selectReportsFilters);

  const dispatch = useDispatch();

  const handleDateRangeChange = useCallback(
    ({ startDate, endDate }: DateRange) =>
      dispatch(
        patchReportsFilters({
          startDate: startDate ?? undefined,
          endDate: endDate ?? undefined,
        }),
      ),
    [dispatch],
  );

  return (
    <FlexContainer gap="small" alignCenter justifyEnd>
      <TransactionDateRangeButton
        dateRange={{ startDate, endDate }}
        defaultRange={REPORTS_DEFAULT_DATE_RANGE}
        onChangeDateRange={handleDateRangeChange}
      />
      <ReportsFilterMenuButton />
      {showReportsSavedViews && (
        <>
          <DividerLine />
          <ReportsConfigurationsMenuButton
            reportConfigurations={reportConfigurations}
            selectedReportConfigurationId={selectedReportConfigurationId}
            onSelectReportConfiguration={onSelectReportConfiguration}
            onSaveReportConfiguration={onSaveReportConfiguration}
            onEditReportConfiguration={onEditReportConfiguration}
            onResetToDefaultReportConfiguration={onResetToDefaultReportConfiguration}
          />
        </>
      )}
    </FlexContainer>
  );
};

export default ReportsHeaderControls;
