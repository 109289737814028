/*
TODO: remove after rebrand launch

This is a one-off just for the rebrand launch, this content should really be loaded from an external
source (Sanity or other).

I (Mason) did get Sanity working, but I don't have enough time to make it production ready before the rebrand.
*/
import type { Post } from 'common/lib/posts/types';

const featuredImage = {
  url: 'https://monarch-static-assets.s3.us-east-1.amazonaws.com/posts/brand-refresh/feature-mobile.png',
  darkModeUrl:
    'https://monarch-static-assets.s3.us-east-1.amazonaws.com/posts/brand-refresh/feature-mobile-dark.png',
};

const rebrandWhatsNewPost: Post = {
  title: `Monarch's New Look and Product Updates`,
  cta: 'Check it out',
  featuredImage,
  content: [
    {
      type: 'image',
      ...featuredImage,
    },
    {
      type: 'h2',
      text: `Monarch's New Look and Product Updates`,
    },
    {
      type: 'p',
      text: `Today we rolled out Monarch's new look along with product updates to make managing your money easier than ever!`,
    },
    {
      type: 'cta',
      text: 'Learn more',
      href: 'https://www.monarchmoney.com/monarch-brand-refresh',
    },
    {
      type: 'h2',
      text: `Refreshed Logo and Colors`,
    },
    {
      type: 'image',
      url: 'https://monarch-static-assets.s3.us-east-1.amazonaws.com/posts/brand-refresh/dark-mode-inline.png',
    },
    {
      type: 'p',
      text: `With our updated colors, Monarch keeps its vibrant look while adding a sense of warmth and approachability. The expanded palette improves contrast, making it easier to scan your budget, read reports, and update transactions. And true dark mode is here!`,
    },
    {
      type: 'h2',
      text: `More of Everything In One Place`,
    },
    {
      type: 'image',
      url: 'https://monarch-static-assets.s3.us-east-1.amazonaws.com/posts/brand-refresh/dashboard_before_after.gif',
    },
    {
      type: 'p',
      text: `We've adjusted padding and whitespace to pack in more information where it matters most. You'll notice these updates on your dashboard, accounts and transactions pages.`,
    },
    {
      type: 'h2',
      text: `Interactive Reports`,
    },
    {
      type: 'image',
      url: 'https://monarch-static-assets.s3.us-east-1.amazonaws.com/posts/brand-refresh/inline-transactions.png',
    },
    {
      type: 'p',
      text: `Interact with your charts directly. Click on a category and immediately drill down into your spending details, without having to jump to another page.`,
    },
    {
      type: 'h2',
      text: `And more...`,
    },
    {
      type: 'p',
      text: `There's a whole lot more we've packed into this update. Head to our What's New post below to dive in deeper.`,
    },
    {
      type: 'cta',
      text: `Learn more`,
      href: 'https://www.monarchmoney.com/monarch-brand-refresh',
    },
  ],
};

export default rebrandWhatsNewPost;
