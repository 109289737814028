import type { DateTime } from 'luxon';
import { isNil } from 'ramda';
import React, { useCallback } from 'react';
import deepIsEqual from 'react-fast-compare';

import type { PlanCellError, PlanCellMethods } from 'components/plan/PlanCell';
import PlanCell from 'components/plan/PlanCell';
import PlanColumns from 'components/plan/PlanColumns';
import { PlanGridColumn } from 'components/plan/PlanGrid';

import { isBudgetVariabilityOfType } from 'common/lib/budget/Budget';
import useHouseholdPreferences from 'common/lib/hooks/household/useHouseholdPreferences';
import type { PlanAmounts } from 'common/lib/planning';
import { isNilOrZero } from 'common/utils/Number';
import { usePlanContext } from 'lib/contexts/PlanContext';
import { SAVINGS_PLAN_SECTION_TYPE } from 'lib/plan';
import type { PlanAmountsByDate, PlanSectionType } from 'lib/plan/Adapters';
import { RowType } from 'lib/plan/Adapters';

import { BudgetVariability } from 'common/generated/graphql';

type Props = {
  amounts: PlanAmountsByDate;
  numColumns: number;
  sectionType: PlanSectionType;
  itemId?: string;
  itemName?: string;
  itemIcon?: React.ReactNode | string;
  itemHasRolloverEnabled?: boolean;
  isAggregate?: boolean;
  isUnplannedAggregate?: boolean;
  groupLevelBudgetingEnabled?: boolean;
  excludeFromBudget?: boolean;
  openEditGroupModal?: () => void;
  canBeBudget: boolean;
  canMoveBudget: boolean;
  rowType?: RowType;
  hasParentRow?: boolean;
  error?: PlanCellError;
  budgetVariability?: Maybe<BudgetVariability>;
  flexibleBudgetCellRef?: React.RefObject<PlanCellMethods>;
  isOverspentButCoveredByFlexibleAmount?: boolean;
  updateFlexBudgetValue?: () => void;
};

const PlanColumnAmounts = ({
  amounts,
  numColumns,
  sectionType,
  itemId,
  itemName,
  itemIcon,
  itemHasRolloverEnabled,
  isAggregate = false,
  isUnplannedAggregate = false,
  groupLevelBudgetingEnabled = false,
  excludeFromBudget = false,
  canBeBudget,
  canMoveBudget,
  rowType,
  hasParentRow = false,
  error,
  budgetVariability,
  flexibleBudgetCellRef,
  isOverspentButCoveredByFlexibleAmount,
  openEditGroupModal,
  updateFlexBudgetValue,
}: Props) => {
  const {
    getIsColumnLoading,
    updateCellValue,
    getDateForColumn,
    getAmountTypeForColumn,
    getCanEditColumn,
  } = usePlanContext();
  const { isFlexBudgeting } = useHouseholdPreferences();

  const isCategoryGroupRow = rowType === RowType.CategoryGroup;
  const isFlexibleGroup =
    isFlexBudgeting && isBudgetVariabilityOfType(budgetVariability, BudgetVariability.FLEXIBLE);

  const shouldShowAmountColumn = useCallback(
    (amountType: string, isCategoryGroup: boolean, planAmounts: Maybe<PlanAmounts>) => {
      if (excludeFromBudget) {
        return false;
      }

      const isRemainingColumn = amountType === 'remaining';
      const isBudgetedAmountNilOrZero = isNilOrZero(planAmounts?.budgeted);
      const isRemainingAmountPositiveOrZero = (planAmounts?.remaining ?? 0) >= 0;
      const doesNotHaveRollover = isNil(planAmounts?.rolloverType);

      // Handle flexible budget specific logic
      if (
        isFlexibleGroup &&
        isRemainingColumn &&
        isBudgetedAmountNilOrZero &&
        doesNotHaveRollover
      ) {
        return false;
      }

      // Show for aggregate or category group rows (top-level cell)
      if (isAggregate || isCategoryGroup) {
        return true;
      }

      // Always show actual amounts (except for excluded budgets, handled earlier)
      if (amountType === 'actual') {
        return true;
      }

      // Handle remaining amount logic for non-flexible budgets
      if (
        isRemainingColumn &&
        isBudgetedAmountNilOrZero &&
        isRemainingAmountPositiveOrZero &&
        doesNotHaveRollover
      ) {
        return false;
      }

      // Always show if group level budgeting is disabled
      if (!groupLevelBudgetingEnabled) {
        return true;
      }

      // Don't show if the row has a parent (only in fixed/flex mode)
      if (hasParentRow) {
        return false;
      }

      // Default case
      return false;
    },
    [excludeFromBudget, groupLevelBudgetingEnabled, isAggregate, hasParentRow, isFlexibleGroup],
  );

  const memoizedUpdateCellValue = useCallback(
    (date: DateTime) => updateCellValue?.(itemId ?? '', date, sectionType, isCategoryGroupRow),
    [updateCellValue, itemId, sectionType, isCategoryGroupRow],
  );

  return (
    <PlanColumns numColumns={numColumns} getDateForColumn={getDateForColumn}>
      {(column, date) => {
        const columnAmounts = amounts[date.toISODate()];
        // Added condition for undefined in case the flag groupLevelBudgetingEnabled is changed
        // then we need to load some more data and not to leave the column with nothing we change to loading
        const isLoading = getIsColumnLoading(column) || columnAmounts?.loadingGroupBudget;
        const amountType = getAmountTypeForColumn(column);

        if (!shouldShowAmountColumn(amountType, isCategoryGroupRow, columnAmounts)) {
          return <PlanGridColumn />;
        }

        const showTooltip =
          amountType === 'remaining' && sectionType !== SAVINGS_PLAN_SECTION_TYPE && canBeBudget;
        const allowMoveMoney = amountType === 'remaining' && canBeBudget && canMoveBudget;
        const isEditable = (canBeBudget && getCanEditColumn?.(column)) ?? false;
        const isFlexibleExpenseBudgetCell =
          isAggregate && // isAggregate means that it's the top level cell
          amountType === 'budgeted' &&
          sectionType === 'expense' &&
          itemId === 'flexible';

        return (
          <PlanGridColumn>
            <PlanCell
              // We only want to pass the ref to the flexible expense budget cell because it's the only one that
              // needs to be changed from outside of the PlanCell component. It's currently used when the user
              // clicks on the tooltip amount when the cell is in an error state.
              ref={isFlexibleExpenseBudgetCell ? flexibleBudgetCellRef : undefined}
              showTooltip={showTooltip}
              date={date}
              canEdit={isEditable}
              amountType={amountType}
              amounts={columnAmounts}
              onChangeValue={memoizedUpdateCellValue(date)}
              isCategoryGroup={isCategoryGroupRow}
              isAggregate={isAggregate}
              isUnplannedAggregate={isUnplannedAggregate}
              sectionType={sectionType}
              itemId={itemId}
              itemName={itemName}
              itemIcon={itemIcon}
              itemHasRolloverEnabled={itemHasRolloverEnabled}
              isLoading={isLoading}
              groupLevelBudgetingEnabled={groupLevelBudgetingEnabled}
              openEditGroupModal={openEditGroupModal}
              allowMoveMoney={allowMoveMoney}
              error={error}
              updateFlexBudgetValue={updateFlexBudgetValue}
              budgetVariability={budgetVariability}
              isOverspentButCoveredByFlexibleAmount={isOverspentButCoveredByFlexibleAmount}
            />
          </PlanGridColumn>
        );
      }}
    </PlanColumns>
  );
};

export default React.memo(PlanColumnAmounts, deepIsEqual);
