import React, { useEffect } from 'react';

import AuthButton from 'components/lib/ui/button/AuthButton';

import type { AuthProviderResponse } from 'lib/hooks/auth/useAuthProvider';
import useAuthProvider from 'lib/hooks/auth/useAuthProvider';
import useAppleId from 'lib/hooks/external/useAppleId';

type Props = {
  skipConfirmation?: boolean;
  className?: string;
  staySignedIn?: boolean;
  extraData?: Record<string, unknown>;
  onConfirmedAuthentication?: (response: AuthProviderResponse) => void;
  setIsPending?: (isPending: boolean) => void;
  onError?: (error?: { detail: string; code: string }) => void;
};

const AppleSignInButton = ({
  skipConfirmation,
  className,
  staySignedIn,
  extraData,
  setIsPending,
  onConfirmedAuthentication,
  onError,
}: Props) => {
  const { isLoading, authenticate } = useAuthProvider({
    provider: 'apple',
    onError,
    staySignedIn,
    onConfirmedAuthentication,
    extraData,
  });
  const { isLoaded, onClick } = useAppleId({
    onSuccess: (data) => {
      const { authorization, user } = data;
      authenticate({
        idToken: authorization.id_token,
        authorizationCode: authorization.code,
        skipConfirmation,
        user,
      });
    },
  });

  useEffect(() => {
    setIsPending?.(isLoading);
  }, [isLoading, setIsPending]);

  return (
    <AuthButton
      className={className}
      provider="apple"
      text="Continue with Apple"
      onClick={onClick}
      disabled={!isLoaded}
      pending={!setIsPending && isLoading}
    />
  );
};

export default AppleSignInButton;
