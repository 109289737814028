import { mergeLeft, range } from 'ramda';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { sensitiveClassProps } from 'components/lib/higherOrder/withSensitiveData';
import Banner from 'components/lib/ui/Banner';
import Card from 'components/lib/ui/Card';
import Flex from 'components/lib/ui/Flex';
import LoadingSpinner from 'components/lib/ui/LoadingSpinner';
import Skeleton from 'components/lib/ui/Skeleton';
import TextButton from 'components/lib/ui/TextButton';
import ManualLink from 'components/lib/ui/link/ManualLink';
import PremiumBadge from 'components/premium/PremiumBadge';
import PremiumFeatureOverlayTrigger from 'components/premium/PremiumFeatureOverlayTrigger';

import useQuery from 'common/lib/hooks/useQuery';
import { color, fontSize, spacing } from 'common/lib/theme/dynamic';
import { formatThousands } from 'common/utils/Number';
import { isoDateToAbbreviatedMonthDayAndYear } from 'common/utils/date';
import formatTransactionAmount from 'common/utils/formatTransactionAmount';
import noop from 'common/utils/noop';
import { amountMixin } from 'lib/styles/amountMixin';
import { convertFiltersToInput, DEFAULT_FILTERS, equalTo } from 'lib/transactions/Filters';

import { ProductFeature } from 'common/constants/premium';

import { gql } from 'common/generated/gql';
import type { TransactionsSummaryFieldsFragment } from 'common/generated/graphql';
import type { TransactionFilters } from 'types/filters';

const CardItem = styled(Flex).attrs({ justifyBetween: true })`
  font-size: ${fontSize.small};
  color: ${color.textLight};

  :not(:last-child) {
    margin-bottom: ${spacing.default};
  }
`;

const CardInner = styled.div`
  padding: ${spacing.xlarge};
`;

const CardFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${spacing.xlarge};
`;

const FooterButton = styled(TextButton)`
  padding: ${spacing.default} 0;
`;

const Spinner = styled(LoadingSpinner)`
  margin: ${spacing.default} 0;
  width: 21px;
  height: 21px;
`;

const ErrorMessage = styled(Banner).attrs({ type: 'error' })`
  display: block;
  margin-bottom: ${spacing.small};
  margin-top: ${spacing.small};
`;

const StyledCardFooter = styled(CardFooter)`
  flex-direction: column;
  border-top: 1px solid ${color.grayBackground};
`;

const SkeletonRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${spacing.default};
  height: ${fontSize.base};

  &:not(:last-child) {
    margin-bottom: ${spacing.gutter};
  }

  > ${Skeleton}:last-child {
    flex-basis: 50%;
  }
`;

type ValueTextProps = {
  isGreen?: boolean;
};

const ValueText = styled.span<ValueTextProps>`
  ${({ theme, isGreen }) => amountMixin({ theme, isGreen, defaultColor: 'text' })};

  font-size: ${fontSize.base};
  font-weight: ${({ theme, isGreen }) =>
    isGreen ? theme.fontWeight.medium : theme.fontWeight.book};
`;

const TransactionsSummaryCardAmountRow = ({
  text,
  value,
  onClick,
}: {
  text: string;
  value: number;
  onClick?: () => void;
}) => (
  <CardItem>
    <span>{text}</span>
    <ValueText isGreen={value > 0} {...sensitiveClassProps}>
      {onClick ? (
        <ManualLink stealthy onClick={onClick}>
          {formatTransactionAmount(value)}
        </ManualLink>
      ) : (
        formatTransactionAmount(value)
      )}
    </ValueText>
  </CardItem>
);

const TransactionsSummaryCardRow = ({ text, value }: { text: string; value: string | number }) => (
  <CardItem>
    <span>{text}</span>
    <ValueText>{value}</ValueText>
  </CardItem>
);

type Props<TSummary> = {
  transactionsSummary: Maybe<TSummary>;
  isDownloadingCsv?: boolean;
  showFirstAndLast?: boolean;
  filters: Partial<TransactionFilters>;
  empty?: React.ReactNode;
  errorMessage?: string;
  isLoading?: boolean;
  onClickDownloadCsv?: () => Promise<void>;
  onChangeFilters: (filters: Partial<TransactionFilters>) => void;
};

const TransactionsSummaryCard = <TSummary extends Partial<TransactionsSummaryFieldsFragment>>({
  empty,
  errorMessage,
  filters,
  isDownloadingCsv,
  showFirstAndLast = true,
  transactionsSummary,
  isLoading,
  onClickDownloadCsv,
  onChangeFilters,
}: Props<TSummary>) => {
  const filtersInput = useMemo(
    () => convertFiltersToInput(mergeLeft(filters, DEFAULT_FILTERS)),
    [filters],
  );

  const { data, isLoadingInitialData: isLoadingInitialCount } = useQuery(QUERY, {
    variables: { filters: filtersInput },
  });
  const count = data?.allTransactions.totalCount ?? 0;
  const {
    max,
    maxExpense,
    avg,
    sumIncome = 0,
    sumExpense = 0,
    first,
    last,
  } = transactionsSummary ?? {};

  // Existing comment:
  // We're only checking `count` and not `transactionsSummary` here because `transactionsSummary` is nil
  // when we're filtering by transfer categories. This is because the parent aggregates query doesn't include
  // transfers in the calculation. However, `count` will always be the number of transactions shown in the list.
  // So either we'll show at least the count of transactions, or we'll show the empty state.
  //
  // Later investigation:
  // I don't see a case where `transactionsSummary` can be undefined or null, including cases where
  // we're filtering by transfer categories. However, we do want to use the count from
  // allTransactions rather than the count from the summary as the summary may not include hidden
  // transactions. And while we might want the max or average to not include hidden transactions, we
  // expect the count to reflect the number of transactions shown in the list (which includes
  // hidden). And if the user downloads a CSV, they expect it to include all the transactions in the
  // list.
  const hasTransactions = count > 0;

  const setExactAmountFilter = (amount: number) => {
    onChangeFilters({
      ...filters,
      absAmountGte: amount,
      absAmountLte: amount,
      amountFilter: equalTo,
    });
  };

  if (isLoading || isLoadingInitialCount) {
    return (
      <Card title="Summary">
        <CardInner>
          {range(0, 5).map((i) => (
            <SkeletonRow key={i}>
              <Skeleton />
              <Skeleton />
            </SkeletonRow>
          ))}
        </CardInner>
        <StyledCardFooter style={{ height: 54 }}>
          <SkeletonRow>
            <Skeleton style={{ flexBasis: '100%' }} />
          </SkeletonRow>
        </StyledCardFooter>
      </Card>
    );
  }

  return (
    <Card title="Summary">
      {!hasTransactions ? (
        empty
      ) : (
        <>
          <CardInner>
            <TransactionsSummaryCardRow text="Total transactions" value={formatThousands(count)} />
            {!!max && (
              <TransactionsSummaryCardAmountRow
                text="Largest transaction"
                value={max}
                onClick={() => setExactAmountFilter(max)}
              />
            )}
            {!!maxExpense && (
              <TransactionsSummaryCardAmountRow
                text="Largest expense"
                value={maxExpense}
                onClick={() => setExactAmountFilter(maxExpense)}
              />
            )}
            {!!avg && <TransactionsSummaryCardAmountRow text="Average transaction" value={avg} />}
            {sumIncome > 0 && (
              <TransactionsSummaryCardAmountRow text="Total income" value={sumIncome} />
            )}
            {/* Always show this, whether its positive or negative */}
            <TransactionsSummaryCardAmountRow text="Total spending" value={sumExpense} />

            {showFirstAndLast && !!first && (
              <TransactionsSummaryCardRow
                text="First transaction"
                value={isoDateToAbbreviatedMonthDayAndYear(first)}
              />
            )}
            {showFirstAndLast && !!last && (
              <TransactionsSummaryCardRow
                text="Last transaction"
                value={isoDateToAbbreviatedMonthDayAndYear(last)}
              />
            )}
          </CardInner>
          {onClickDownloadCsv && (
            <PremiumFeatureOverlayTrigger
              feature={ProductFeature.download_csv}
              placement="left-end"
            >
              {({ hasAccess }) => (
                <StyledCardFooter>
                  {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                  {isDownloadingCsv ? (
                    <Spinner />
                  ) : (
                    <FooterButton onClick={hasAccess ? onClickDownloadCsv : noop}>
                      Download CSV
                    </FooterButton>
                  )}
                  {!hasAccess && <PremiumBadge />}
                </StyledCardFooter>
              )}
            </PremiumFeatureOverlayTrigger>
          )}
        </>
      )}
    </Card>
  );
};

const QUERY = gql(/* GraphQL */ `
  query Web_GetTransactionsSummaryCard($filters: TransactionFilterInput!) {
    allTransactions(filters: $filters) {
      totalCount
    }
  }
`);

TransactionsSummaryCard.fragments = {
  TransactionsSummaryFields: gql(/* GraphQL */ `
    fragment TransactionsSummaryFields on TransactionsSummary {
      avg
      count
      max
      maxExpense
      sum
      sumIncome
      sumExpense
      first
      last
    }
  `),
};

export default TransactionsSummaryCard;
