import { DateTime } from 'luxon';
import * as RA from 'ramda-adjunct';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Switch, { Case } from 'common/components/utils/Switch';
import CreateBudgetButton from 'components/budget/CreateBudgetButton';
import {
  PlanSummaryOverview,
  BudgetTypeCategoryBreakdown,
} from 'components/budget/PlanSummaryWidget';
import Select from 'components/lib/form/Select';
import DashboardWidget from 'components/lib/ui/DashboardWidget';
import EmptyStateCard from 'components/lib/ui/EmptyStateCard';
import FlexContainer from 'components/lib/ui/FlexContainer';
import Text from 'components/lib/ui/Text';

import { setPlanDashboardWidgetViewIndex } from 'actions';
import { getMonthProgressForMonthStart } from 'common/lib/budget/Progress';
import useBudgetData from 'common/lib/hooks/budget/useBudgetData';
import { isoDateToMonthAndYear } from 'common/utils/date';
import useDispatch from 'lib/hooks/useDispatch';
import { getPlanDashboardWidgetViewIndex } from 'selectors';

import routes from 'constants/routes';

const SelectContainer = styled(FlexContainer).attrs({ justifyEnd: true, alignCenter: true })``;

const ViewSelect = styled(Select)`
  width: 150px;
`;

const defaultViewIndex = 1; // Expenses view

const PlanDashboardWidget = () => {
  const { push } = useHistory();
  const dispatch = useDispatch();

  const persistedPlanDashboardWidgetViewIndex = useSelector(getPlanDashboardWidgetViewIndex);
  const planDashboardWidgetViewIndex = RA.isNotUndefined(persistedPlanDashboardWidgetViewIndex)
    ? persistedPlanDashboardWidgetViewIndex
    : defaultViewIndex;

  const startDate = useMemo(() => DateTime.local().startOf('month'), []);
  const { budgetStatusData, budgetSummaryData, isFlexBudgetSystem, isLoadingInitialData } =
    useBudgetData(startDate);

  const expensesData = useMemo(
    () => budgetSummaryData.find(({ type }) => type === 'expense'),
    [budgetSummaryData],
  );

  const ticks = useMemo(() => [{ percent: getMonthProgressForMonthStart(startDate) }], [startDate]);

  const setView = (viewIndex: number) => dispatch(setPlanDashboardWidgetViewIndex(viewIndex));

  // We only want to use the saved view index if the user is on the flex budget system.
  // If they are on category budget system, we always want to show the summary view.
  const effectiveViewIndex = isFlexBudgetSystem ? planDashboardWidgetViewIndex : 0;

  return (
    <DashboardWidget
      title="Budget"
      loading={isLoadingInitialData}
      description={isoDateToMonthAndYear(startDate.toISODate())}
      headerLink={routes.budget()}
      headerRight={
        isFlexBudgetSystem ? (
          <SelectContainer>
            <ViewSelect
              options={[
                { label: 'Summary', value: 0 },
                { label: 'Expenses', value: 1 },
              ]}
              value={effectiveViewIndex}
              onChange={({ value }: { value: number }) => setView(value)}
              isDisabled={isLoadingInitialData}
              small
            />
          </SelectContainer>
        ) : null
      }
    >
      <Switch>
        <Case when={!budgetStatusData?.hasBudget}>
          <EmptyStateCard title="You don't have a budget yet" iconName="map">
            <Text>We&apos;ll create one for you based on your spending history.</Text>
            <CreateBudgetButton
              date={startDate}
              onCompleted={() => push(routes.budget({ queryParams: { introModal: true } }))}
              variant="default"
              size="xsmall"
            />
          </EmptyStateCard>
        </Case>
        <Case when={effectiveViewIndex === 0}>
          <PlanSummaryOverview
            data={budgetSummaryData}
            isLoading={isLoadingInitialData}
            ticks={ticks}
            titleSize="base"
          />
        </Case>
        <Case when={effectiveViewIndex === 1}>
          <BudgetTypeCategoryBreakdown
            parentGroup={expensesData}
            breakdownType="budgetVariabilities"
            isLoading={isLoadingInitialData}
            ticks={ticks}
            titleSize="base"
          />
        </Case>
      </Switch>
    </DashboardWidget>
  );
};

export default React.memo(PlanDashboardWidget);
