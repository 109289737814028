import { useMutation } from '@apollo/client';

import useEventCallback from 'common/lib/hooks/useEventCallback';

import { gql } from 'common/generated';
import type { BudgetVariability } from 'common/generated/graphql';
import type {
  ExtractResponseFromDocument,
  MutationHookOptionsFromDocument,
} from 'common/types/graphql';

type Options = MutationHookOptionsFromDocument<typeof UPDATE_CATEGORY_GROUP_BUDGET_VARIABILITY>;

export const useUpdateCategoryGroupBudgetVariability = (options?: Options) => {
  const [updateCategoryGroupBudgetVariability, mutationResult] = useMutation(
    UPDATE_CATEGORY_GROUP_BUDGET_VARIABILITY,
    options,
  );

  const setCategoryGroupBudgetVariability = useEventCallback(
    (id: string, budgetVariability: BudgetVariability) =>
      updateCategoryGroupBudgetVariability({
        // @ts-expect-error: TODO: change graphql codegen to use optionals for nullable fields
        variables: { input: { id, budgetVariability } },
        optimisticResponse: getOptimisticResponse(id, budgetVariability),
      }),
  );

  return [setCategoryGroupBudgetVariability, mutationResult] as const;
};

const getOptimisticResponse = (
  id: string,
  budgetVariability: BudgetVariability,
): ExtractResponseFromDocument<typeof UPDATE_CATEGORY_GROUP_BUDGET_VARIABILITY> => ({
  __typename: 'Mutation',
  updateCategoryGroup: {
    __typename: 'UpdateCategoryGroupMutation',
    categoryGroup: {
      __typename: 'CategoryGroup',
      id,
      budgetVariability,
      updatedAt: new Date().toISOString(),
    },
  },
});

const UPDATE_CATEGORY_GROUP_BUDGET_VARIABILITY = gql(/* GraphQL */ `
  mutation Common_UpdateCategoryGroupBudgetVariability($input: UpdateCategoryGroupInput!) {
    updateCategoryGroup(input: $input) {
      categoryGroup {
        id
        budgetVariability
        updatedAt
      }
    }
  }
`);
