import React, { useMemo } from 'react';

import { sensitiveClassProps } from 'components/lib/higherOrder/withSensitiveData';

import useTheme from 'lib/hooks/useTheme';
import type { Identifiable, Link, NodeWithExtraProps } from 'lib/ui/sankey';

const DEFAULT_NODE_LABEL_PADDING_PX = 10;
const MAX_LABEL_CHARACTER_LENGTH = 30;

type Props<N extends Identifiable, L extends Link> = {
  node: NodeWithExtraProps<N, L>;
  diagramWidth: number;
  labelHorizontalPadding?: number;
  formatValue?: (node: NodeWithExtraProps<N, L>) => string | undefined;
  onMouseEnter: (node: NodeWithExtraProps<N, L>) => void;
  onMouseLeave: () => void;
  onClick?: (node: NodeWithExtraProps<N, L>) => void;
};

const SankeyDiagramNode = <N extends Identifiable, L extends Link>({
  node,
  diagramWidth,
  labelHorizontalPadding: labelPadding = DEFAULT_NODE_LABEL_PADDING_PX,
  formatValue,
  onMouseEnter,
  onMouseLeave,
  onClick,
}: Props<N, L>) => {
  const theme = useTheme();
  const { x0 = 0, x1 = 0, y0 = 0, y1 = 0, color, index, label, isClickable = !!onClick } = node;
  const formattedValue = useMemo(() => formatValue?.(node) ?? node.value, [node, formatValue]);

  const maybeTruncatedLabel = useMemo(
    () =>
      label.length > MAX_LABEL_CHARACTER_LENGTH
        ? `${label.slice(0, MAX_LABEL_CHARACTER_LENGTH)}...`
        : label,
    [label],
  );

  const diagramMidpoint = diagramWidth / 2;
  const nodeRectWidth = x1 - x0;
  const nodeRectHorizontalMidpoint = x0 + nodeRectWidth / 2;
  const nodeRectHeight = y1 - y0;
  const nodeRectMidpoint = (y1 + y0) / 2;

  return (
    <g
      className={['node', isClickable && 'is-clickable'].filter(Boolean).join(' ')}
      data-id={node.id}
      onClick={() => onClick?.(node)}
      onMouseEnter={() => onMouseEnter(node)}
      onMouseLeave={onMouseLeave}
    >
      <rect
        x={x0}
        y={y0}
        width={nodeRectWidth}
        height={nodeRectHeight}
        fill={color ?? theme.color.grayFocus}
        data-index={index}
        shapeRendering="crispEdges"
      />

      {/* hides the label if there is no formattedValue */}
      {!!formattedValue && (
        <>
          <text
            x={nodeRectHorizontalMidpoint < diagramMidpoint ? x1 + labelPadding : x0 - labelPadding}
            y={nodeRectMidpoint}
            dy="-12px"
            textAnchor={nodeRectHorizontalMidpoint < diagramMidpoint ? 'start' : 'end'}
            fontSize={theme.fontSize.small}
            fill={theme.color.text}
            dominantBaseline="central"
            className="node-label"
          >
            {maybeTruncatedLabel}
          </text>
          <text
            x={nodeRectHorizontalMidpoint < diagramMidpoint ? x1 + labelPadding : x0 - labelPadding}
            y={nodeRectMidpoint}
            dy="8px"
            textAnchor={nodeRectHorizontalMidpoint < diagramMidpoint ? 'start' : 'end'}
            fontWeight={500}
            fill={theme.color.text}
            fontSize={theme.fontSize.base}
            dominantBaseline="central"
            {...sensitiveClassProps}
          >
            {formattedValue}
          </text>
        </>
      )}
    </g>
  );
};

export default SankeyDiagramNode;
